export const securityType = [
  { key: 'existing', label: 'Existing real estate asset' },
  { key: 'purchase', label: 'New Purchase' },
]

export const transactions = [
  { key: 'owns', label: 'Owns' },
  { key: 'purchasing', label: 'Purchasing' },
  { key: 'transfer', label: 'Transfer' },
  { key: 'ownsexistingmortgage', label: 'Owns Existing Mortgage' },
  { key: 'sold', label: 'Sold' },
]

export const propertyType = [
  { key: 'stdresidential', label: 'Std Residential' },
  { key: 'crosslease', label: 'Cross Lease' },
  { key: 'residentialleasehold', label: 'Residential Leasehold' },
  { key: 'stdapartment', label: 'Std Apartment' },
  { key: 'bedsitterbachelor', label: 'Bedsitter Bachelor' },
  { key: 'boardinghouse', label: 'Boarding House' },
  { key: 'combishopresidence', label: 'Combi Shop Residence' },
  { key: 'commercial', label: 'Commercial' },
  { key: 'companytitleunit', label: 'Company Title Unit' },
  { key: 'convertedcommercialproperty', label: 'Converted Commercial Property' },
  { key: 'convertedeindustrialproperty', label: 'Converted Industrial Property' },
  { key: 'convertedmotelunits', label: 'Converted Motel Units' },
  { key: 'convertedproperty', label: 'Converted Property' },
  { key: 'displayhome', label: 'Display Home' },
  { key: 'duplex', label: 'Duplex' },
  { key: 'fullydetachedhouse', label: 'Fully Detached House' },
  { key: 'govtrentalguarantee', label: 'Govt Rental Guarantee' },
  { key: 'hobbyfarm', label: 'Hobby Farm' },
  { key: 'holidayhome', label: 'Holiday Home' },
  { key: 'holidayrental', label: 'Holiday Rental' },
  { key: 'industrial', label: 'Industrial' },
  { key: 'innercityapartment', label: 'Inner City Apartment' },
  { key: 'kithome', label: 'Kit Home' },
  {
    key: 'licensedbuilderhouseconstruction',
    label: 'Licensed Builder House Construction',
  },
  { key: 'luxuryhouse', label: 'Luxury House' },
  { key: 'luxuryother', label: 'Luxury Other' },
  { key: 'multipleontitle', label: 'Multiple On Title' },
  { key: 'newstratatitleunit', label: 'New Strata Title Unit' },
  { key: 'nursinghome', label: 'Nursing Home' },
  { key: 'ownerbuilderhouseconstruction', label: 'Owner Builder House Construction' },
  { key: 'propertydevelopment', label: 'Property Development' },
  { key: 'relocatablehome', label: 'Relocatable Home' },
  { key: 'rentalguarantee', label: 'Rental Guarantee' },
  { key: 'resortunit', label: 'Resort Unit' },
  { key: 'retirementunit', label: 'Retirement Unit' },
  { key: 'semidetachedhouse', label: 'SemiDetached House' },
  { key: 'servicedapt', label: 'Serviced Apt' },
  { key: 'singlebedroomless50m2', label: 'Single Bedroom Less 50m2' },
  { key: 'snowlease', label: 'Snow lease' },
  { key: 'stratatitleunit', label: 'Strata Title Unit' },
  { key: 'studentaccommodation', label: 'Student Accommodation' },
  { key: 'studiowarehouseapt', label: 'Studio Warehouse Apt' },
  { key: 'terrace', label: 'Terrace' },
  { key: 'timeshare', label: 'Timeshare' },
  { key: 'townhouse', label: 'Town house' },
  { key: 'transportablehome', label: 'Transportable Home' },
  { key: 'unitstudentccom', label: 'Unit Student Ccom' },
  { key: 'vacantland', label: 'Vacant Land' },
  { key: 'villa', label: 'Villa' },
  { key: 'warehouseconversion', label: 'Warehouse Conversion' },
  { key: 'attachedunit', label: 'Attached Unit' },
  { key: 'lifestyle', label: 'Lifestyle' },
  { key: 'maoriland', label: 'Maori Land' },
]

export const primaryUsage = [
  { key: 'industrial', label: 'Industrial' },
  { key: 'residential', label: 'Residential' },
  { key: 'rural', label: 'Rural' },
  { key: 'commercial', label: 'Commercial' },
  { key: 'other', label: 'Other' },
]

export const primaryPurpose = [
  { key: 'owneroccupied', label: 'Owner Occupied' },
  { key: 'investment', label: 'Investment' },
]

export const holdings = [
  { key: 'sole', label: 'Sole' },
  { key: 'jointtenants', label: 'Joint Tenants' },
  { key: 'tennantcommoninsharesof', label: 'Tennant Common in Shares Of' },
  { key: 'commoninequalshares', label: 'Common In Equal Shares' },
  { key: 'companyshare', label: 'Company Share' },
  { key: 'timeshare', label: 'Time Share' },
  { key: 'tennant', label: 'Tennant' },
  { key: 'contractofsale', label: 'Contract of Sale' },
]

export const estimateBasis = [
  { key: 'certifiedvaluation', label: 'Certified Valuation' },
  { key: 'marketassessment', label: 'Market Assessment' },
  { key: 'applicantestimate', label: 'Applicant Estimate' },
  { key: 'actualvalue', label: 'Actual Value' },
  { key: 'purchaseprice', label: 'Purchase Price' },
  { key: 'governmentvaluation', label: 'Government Valuation' },
]

export const rentalIncomeFrequency = [
  { key: 'yearly', label: 'Yearly' },
  { key: 'half-yearly', label: 'Half-yearly' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'monthly', label: 'Monthly' },
  { key: 'fortnightly', label: 'Fortnightly' },
  { key: 'weekly', label: 'Weekly' },
]
