import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { applicationAdapter } from './module'

const selectAllApplications = prop('application')
export const selectTotal = createSelector(selectAllApplications, prop('total'))

export const {
  selectIsPending: selectIsFetchApplicationPending,
  selectHasFulfilled: selectHasFetchApplicationFulfilled,
  selectError: selectFetchApplicationError,
} = createAsyncThunkSelectors('applications/getApplication')

export const {
  selectIsPending: selectIsFetchApplicationsPending,
} = createAsyncThunkSelectors('applications/getLoanApplications')

export const {
  selectIsPending: selectIsFetchingApplicationEntities,
} = createAsyncThunkSelectors('applications/fetchLoanApplicationEntities')

export const {
  selectIsPending: selectIsUpdatingApplication,
} = createAsyncThunkSelectors('application/updateLoanApplication')

export const {
  selectAll: selectApplications,
  selectById,
} = applicationAdapter.getSelectors(
  /**
   * @param {import('../../store').RootState} s
   */
  (s) => s.application
)

/**
 * Provide sensible default values when the server doesn't return data for some fields. This helps avoiding `undefined/null` runtime error and reduces the number of default/nullish coalescing in components
 * @type {import('./types').Application}
 */
const defaultApplication = {
  id: '',
  householdInfo: { numberOfAdults: 0, numberOfDependants: 0 },
  legalEntities: { trusts: [], individuals: [] },
}

/**
 * @type {import('./types').selectApplicationById}
 */
export const selectApplicationById = (id) => (state) =>
  selectById(state, id) ?? defaultApplication
