import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Col, Row } from '@vega/styled'
import { Button, NumberField, SelectField } from '@vega/components'
import { Formik as FormProvider, Form } from 'formik'
import { s } from '@vega/styled/v2'
import { createUmiReportItem } from 'modules/umiReportItems'
import { formatCurrency } from 'utils/formatters'
import { ebit } from './utils'

export const AddIncomeForm = ({ householdRef, closeForm }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [recordType, setRecordType] = useState('business')

  const handleSubmit = (newIncome) => {
    dispatch(
      createUmiReportItem({
        versionedLoanApplicationId: id,
        umiReportItem: newIncome
      })
    )

    closeForm()
  }

  const initialValuesForRecordType = () => {
    switch (recordType) {
      case 'primary': return {
        versionedLoanApplicationId: id,
        householdRef,
        umiRecordType: 'umiIncome',
        details: {
          umiIncomeType: 'primary',
          source: 'salary',
          taxType: 'net',
          frequency: 'weekly',
          verifiedAmount: 0,
          declaredAmount: 0,
          kiwiSaverRate: '3',
          employmentType: 'fullTime',
          taxYear: 2022
        }
      }
      case 'secondary': return {
        versionedLoanApplicationId: id,
        householdRef,
        umiRecordType: 'umiIncome',
        details: {
          umiIncomeType: 'secondary',
          source: 'salary',
          taxType: 'net',
          frequency: 'weekly',
          verifiedAmount: 0,
          declaredAmount: 0,
          kiwiSaverRate: '3',
          employmentType: 'fullTime',
          taxYear: 2022
        }
      }
      case 'business': return {
        versionedLoanApplicationId: id,
        householdRef,
        umiRecordType: 'umiIncome',
        details: {
          umiIncomeType: 'business',
          ownershipPercentage: 100,
          yearOneLabel: `${new Date().getFullYear() - 1}`,
          yearOneNetProfitBeforeTax: 0,
          yearOneInterestPaid: 0,
          yearOneShareholderSalaries: 0,
          yearOneHomeOffice: 0,
          yearOneDepreciation: 0,
          yearTwoLabel: `${new Date().getFullYear()}`,
          yearTwoNetProfitBeforeTax: 0,
          yearTwoInterestPaid: 0,
          yearTwoShareholderSalaries: 0,
          yearTwoHomeOffice: 0,
          yearTwoDepreciation: 0,
        }
      }
      default: return {}
    }
  }

  const valueOrZero = (value) => Number.parseFloat(value) || 0

  const yearOneEbit = (values) => {
    return ebit(
      valueOrZero(values.details.yearOneNetProfitBeforeTax),
      valueOrZero(values.details.yearOneInterestPaid),
      valueOrZero(values.details.yearOneShareholderSalaries),
      valueOrZero(values.details.yearOneHomeOffice),
      valueOrZero(values.details.yearOneDepreciation),
    )
  }

  const yearTwoEbit = (values) => {
    return ebit(
      valueOrZero(values.details.yearTwoNetProfitBeforeTax),
      valueOrZero(values.details.yearTwoInterestPaid),
      valueOrZero(values.details.yearTwoShareholderSalaries),
      valueOrZero(values.details.yearTwoHomeOffice),
      valueOrZero(values.details.yearTwoDepreciation),
    )
  }

  return (
    <FormProvider enableReinitialize initialValues={initialValuesForRecordType()} onSubmit={handleSubmit}>
      {({ values, setFieldValue, ...props }) => (
        <Form onSubmit={props.handleSubmit}>
          <h4>Add income source</h4>
          <Row style={s('mb-4')}>
            <Col span={12}>
              <label
                style={s('pr-2')}
              >
                Income type
              </label>
              <select
                onChange={(e) => setRecordType(e.target.value)}
                value={recordType}
                style={s('rounded-lg py-2 px-4')}
              >
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="business">Business</option>
              </select>
            </Col>
          </Row>

          {
            values.details.umiIncomeType === 'primary' && (
              <>
                <h5>PRIMARY INCOME</h5>
                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.source"
                      label="Primary income source"
                      aria-label="Primary income source"
                      options={[
                        { label: 'Salary / wages', value: 'salary' },
                        { label: 'Business income', value: 'businessIncome' },
                        { label: 'Retals', value: 'rentals' },
                        { label: 'Overtime / allowances / bonus', value: 'overtime' },
                        { label: 'Beneficiaries', value: 'beneficiaries' },
                        { label: 'Income in non-NZD', value: 'incomeNoInNZD' },
                        { label: 'Commission', value: 'commission' },
                        { label: 'Interest', value: 'interest' },
                        { label: 'Dividends', value: 'dividends' },
                        { label: 'Pensions / Superannuation / Annuity', value: 'superannuation' },
                        { label: 'Boarders / Flatmates', value: 'boarders' },
                        { label: 'Child support maintenance', value: 'childSupport' },
                      ]}
                    />
                  </Col>

                  <Col span={12}>
                    <SelectField
                      name="details.taxType"
                      label="Tax type"
                      options={[
                        { label: 'Gross', value: 'gross' },
                        { label: 'Net', value: 'net' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.frequency"
                      label="Frequency"
                      aria-label="Frequency"
                      options={[
                        { label: 'Weekly', value: 'weekly' },
                        { label: 'Fortnightly', value: 'fortnightly' },
                        { label: 'Monthly', value: 'monthly' },
                        { label: 'Quarterly', value: 'quarterly' },
                        { label: 'Half-yearly', value: 'halfYearly' },
                        { label: 'Annually', value: 'annually' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={24}>
                    <NumberField
                      name="details.verifiedAmount"
                      label="Amount"
                      placeholder=" Amount"
                      prefix="$"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          {
            values.details.umiIncomeType === 'secondary' && (
              <>
                <h5>SECONDARY INCOME</h5>
                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.source"
                      label="Secondary income source"
                      placeholder="Secondary income source"
                      aria-label="Secondary income source"
                      options={[
                        { label: 'Salary / wages', value: 'salary' },
                        { label: 'Business income', value: 'businessIncome' },
                        { label: 'Retals', value: 'rentals' },
                        { label: 'Overtime / allowances / bonus', value: 'overtime' },
                        { label: 'Beneficiaries', value: 'beneficiaries' },
                        { label: 'Income in non-NZD', value: 'incomeNoInNZD' },
                        { label: 'Commission', value: 'commission' },
                        { label: 'Interest', value: 'interest' },
                        { label: 'Dividends', value: 'dividends' },
                        { label: 'Pensions / Superannuation / Annuity', value: 'superannuation' },
                        { label: 'Boarders / Flatmates', value: 'boarders' },
                        { label: 'Child support maintenance', value: 'childSupport' },
                      ]}
                    />
                  </Col>

                  <Col span={12}>
                    <SelectField
                      name="details.taxType"
                      label="Tax type"
                      options={[
                        { label: 'Gross', value: 'gross' },
                        { label: 'Net', value: 'net' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.frequency"
                      label="Frequency"
                      aria-label="Frequency"
                      options={[
                        { label: 'Weekly', value: 'weekly' },
                        { label: 'Fortnightly', value: 'fortnightly' },
                        { label: 'Monthly', value: 'monthly' },
                        { label: 'Quarterly', value: 'quarterly' },
                        { label: 'Half-yearly', value: 'halfYearly' },
                        { label: 'Annually', value: 'annually' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={24}>
                    <NumberField
                      name="details.declaredAmount"
                      label="Amount"
                      placeholder=" Amount"
                      prefix="$"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          {
            values.details.umiIncomeType === 'business' && (
              <>
                <h5>BUSINESS INCOME</h5>
                <Row style={s('mb-4')}>
                  <Col span={8}>
                    <NumberField
                      name="details.ownershipPercentage"
                      label="Ownership share/ attributable %"
                      placeholder="% owned"
                    />
                  </Col>
                </Row>

                <p style={s('font-semibold mt-6')}>
                  {values.details.yearOneLabel} -
                  {' '}
                  {formatCurrency(yearOneEbit(values))} EBIT
                </p>

                <Row style={s('mb-4')}>
                  <Col span={8}>
                    <NumberField
                      name="details.yearOneLabel"
                      label="Year"
                      placeholder="Year"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearOneNetProfitBeforeTax"
                      label="Net profit before tax"
                      placeholder=" Net profit before tax"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearOneInterestPaid"
                      label="Interest paid"
                      placeholder=" Interest paid"
                      prefix="$"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={8}>
                    <NumberField
                      name="details.yearOneShareholderSalaries"
                      label="Shareholder salaries"
                      placeholder=" Shareholder salaries"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearOneHomeOffice"
                      label="Home office"
                      placeholder=" Home office"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearOneDepreciation"
                      label="Depreciation"
                      placeholder=" Depreciation"
                      prefix="$"
                    />
                  </Col>
                </Row>

                <p style={s('font-semibold mt-12')}>
                  {values.details.yearTwoLabel} -
                  {' '}
                  {formatCurrency(yearTwoEbit(values))} EBIT
                </p>

                <Row style={s('mb-4')}>
                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoLabel"
                      label="Year"
                      placeholder="Year"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoNetProfitBeforeTax"
                      label="Net profit before tax"
                      placeholder=" Net profit before tax"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoInterestPaid"
                      label="Interest paid"
                      placeholder=" Interest paid"
                      prefix="$"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoShareholderSalaries"
                      label="Shareholder salaries"
                      placeholder=" Shareholder salaries"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoHomeOffice"
                      label="Home office"
                      placeholder=" Home office"
                      prefix="$"
                    />
                  </Col>

                  <Col span={8}>
                    <NumberField
                      name="details.yearTwoDepreciation"
                      label="Depreciation"
                      placeholder=" Depreciation"
                      prefix="$"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          <div style={s('flex', {flexDirection: 'row-reverse'})}>
            <Button
              type="submit"
              style={s('ml-2')}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              onClick={() => closeForm()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </FormProvider>
  )
}
