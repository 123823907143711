/* eslint-disable no-prototype-builtins */
import React, { useRef, useEffect } from 'react'
import { Formik as FormProvider, Form } from 'formik'
import { s, styled } from '@vega/styled/v2'
import {
  TextField as TextFieldBase,
  SelectField,
  NumberField,
  Button,
  Anchor,
  DateField,
  MenuField as MenuFieldBase,
  ValocityAddressField,
} from '@vega/components'
import { useDispatch } from 'react-redux'
import { Col, Row } from '@vega/styled'
import {
  titlesOptions,
  gendersOptions,
  housingStatusOptions,
  maritalStatusOptions,
  residencyStatusOptions,
  citizenshipOptions,
} from './dropdown'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'

import { updateLegalEntity, fetchLoanApplication } from 'modules/versionedApplication'
import { AddressInfo } from '../AddressInfo'

import * as Yup from 'yup'
import { Item } from '@react-stately/collections'

const rowStyles = s('mt-4')
const sectionStyles = s('mt-8')
const Container = styled.div()

const SubTitle = styled.h3(
  s('text-xs font-normal text-primary font-semibold mt-4 mb-0', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const MenuField = styled(MenuFieldBase, {
  shouldForwardProp: (prop) => prop !== 'side',
})(s('w-full', { minHeight: 55, marginTop: -0.5 }), ({ side }) =>
  side === 'left' ? s('mx-2') : s('mr-2')
)
export const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1')} {...props} />
))()

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)
const SearchIcon = styled(SearchIconBase)(s('mr-2'))

const Title = styled.span(
  s('text-grey-900 text-2xl font-extrabold font-semibold inline-block ')
)

const ContentCard = styled.div(
  s('mb-5 mt-10 px-5 rounded-lg bg-white p-8 w-full', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('A valid email is required'),
  citizenship: Yup.string().required('Citizenship is required'),
  dateOfBirth: Yup.string().required('Date Of Birth type is required'),
  gender: Yup.string().required('Gender is required'),
  firstName: Yup.string().required('First Name is required'),
  surname: Yup.string().required('Surname is required'),
  nameTitle: Yup.string().required('Name Title is required'),
  countryCode: Yup.number().required('Country Code is required'),
  newZealandDialingCode: Yup.number().required('New Zealand Dialing Code is required'),
  number: Yup.number().required('Phone Number is required'),
  residencyStatus: Yup.string().required('Residency Status is required'),
  maritalStatus: Yup.string().required('Marital Status is required'),
  startDate: Yup.string().required('Accomodation Start Date is required'),
  housingStatus: Yup.string().required('Housing Status is required'),
})

const differenceInMonths = (date) => {
  date = new Date(Date.parse(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')))
  const today = new Date()
  const diffInMonths =
    (today.getFullYear() - date.getFullYear()) * 12 +
    (today.getMonth() - date.getMonth())
  return diffInMonths
}

const convertDateFormat = (dateString) => {
  const parts = dateString.split('/')
  const day = parts[0]
  const month = parts[1]
  const year = parts[2]
  const newDate = `${year}/${month}/${day}`
  return newDate
}

const scrollToForm = (anchorRef) => {
  anchorRef.current.scrollIntoView()
}

const fillEmptyObj = (data, latestVersionId, propertyId, externalRef) => {
  const emptyObj = {
    contact: {
      currentAddress: {
        startDate: convertDateFormat(data.startDate),
        duration: {
          length: differenceInMonths(data.startDate),
          units: 'Months',
        },
        housingStatus: '',
      },
      emailAddresses: [
        {
          externalRef: null,
          email: '',
          emailType: 'Home',
        },
      ],
      mobile: {
        countryCode: '',
        number: '',
        overseasDialingCode: null,
        newZealandDialingCode: '',
      },
      previousAddresses: [],
    },
    citizenship: '',
    solicitorRef: null,
    dateOfBirth: convertDateFormat(data.dateOfBirth),
    gender: '',
    applicantRole: 'Guarantor',
    isPrimaryApplicant: false,
    personName: {
      firstName: '',
      nameTitle: '',
      surname: '',
      middleNames: '',
    },
    residencyStatus: '',
    loanApplicationId: latestVersionId,
    externalRef,
    legalEntityType: 'individual',
    maritalStatus: '',
    propertyId: data?.fullAddress?.propertyId || propertyId,
    guarantees: {
      securityGuarantee: parseInt(data.securityGuaranteeAmount, 10),
      servicingGuarantee: parseInt(data.servicingGuaranteeAmount, 10),
    },
  }

  emptyObj.contact.currentAddress.housingStatus = data.housingStatus
  emptyObj.contact.emailAddresses[0].email = data.email
  emptyObj.contact.mobile.countryCode = data.countryCode
  emptyObj.contact.mobile.number = data.number
  emptyObj.contact.mobile.newZealandDialingCode = data.newZealandDialingCode
  emptyObj.citizenship = data.citizenship
  emptyObj.gender = data.gender
  emptyObj.personName.firstName = data.firstName
  emptyObj.personName.middleNames = data.middleNames
  emptyObj.personName.surname = data.surname
  emptyObj.personName.nameTitle = data.nameTitle
  emptyObj.residencyStatus = data.residencyStatus
  emptyObj.maritalStatus = data.maritalStatus

  return emptyObj
}

export const UpdateGuarantorForm = ({
  latestVersionId,
  versionedLoanApplicationId,
  setShowEditForm,
  dob = '-',
  email = '-',
  addresses = [],
  maritalStatus = '-',
  mobile = {},
  residencyStatus = '-',
  citizenship = '-',
  index,
  servicingGuarantee = '',
  securityGuarantee = '',
  gender = '-',
  personName = {},
  externalRef = '',
  entityId = '',
  propertyId = '',
}) => {
  const dispatch = useDispatch()
  const anchorRef = useRef(null)

  const { firstName, surname, middleNames, nameTitle } = personName

  const { countryCode, newZealandDialingCode, number } = mobile

  const { address, status, startDate, duration, endDate } = addresses[0]

  const handleSubmit = async (data) => {
    setShowEditForm(false)
    let newGuarantor = fillEmptyObj(data, latestVersionId, propertyId, externalRef)
    if (!externalRef.includes('Neo')) {
      const { guarantees } = newGuarantor
      newGuarantor = { guarantees }
    }
    await dispatch(
      updateLegalEntity({ entityId, newGuarantor, versionedLoanApplicationId })
    )
    dispatch(fetchLoanApplication(versionedLoanApplicationId))
  }

  const disableComponent = !externalRef.includes('Neo')

  useEffect(() => {
    scrollToForm(anchorRef)
  }, [])

  return (
    <>
      <Container>
        <ContentCard>
          <Anchor ref={anchorRef} />
          <FormProvider
            initialValues={{
              email,
              citizenship,
              dateOfBirth: dob,
              gender,
              firstName,
              surname,
              middleNames,
              nameTitle,
              countryCode,
              newZealandDialingCode,
              number,
              residencyStatus,
              maritalStatus,
              securityGuaranteeAmount: securityGuarantee,
              servicingGuaranteeAmount: servicingGuarantee,
              startDate,
              housingStatus: status,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <Title>Update Guarantor Details</Title>
                <Row style={sectionStyles}>
                  <Col span={24}>
                    <SubTitle>PERSONAL INFORMATION</SubTitle>
                    <Row style={rowStyles}>
                      <Col span={3}>
                        <Label>Title</Label>
                        <SelectField
                          name="nameTitle"
                          options={titlesOptions.map((title) => ({
                            label: title.label,
                            value: title.label,
                            key: title.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Title'}
                          disabled={disableComponent}
                        />
                      </Col>
                      <Col span={9}>
                        <Label>First Name</Label>
                        <TextField
                          name="firstName"
                          placeholder="First Name"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Middle Name</Label>
                        <TextField
                          name="middleNames"
                          placeholder="Middle Name"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.middleNames}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Last Name</Label>
                        <TextField
                          name="surname"
                          placeholder="Last Name"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.surname}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Email</Label>
                        <TextField
                          name="email"
                          placeholder="Email"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label style={s('mb-1')}>Date of Birth</Label>
                        <DateField
                          name="dateOfBirth"
                          placeholder="DD/MM/YYYY"
                          containerStyle={s('border-grey-200')}
                          style={s('text-sm')}
                          inputFormat="dd/MM/yyyy"
                          isReadOnly={disableComponent}
                          disabledLabel={true}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Marital Status</Label>
                        <SelectField
                          name="maritalStatus"
                          options={maritalStatusOptions.map((status) => ({
                            label: status.label,
                            value: status.label,
                            key: status.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select Marital Status'}
                          disabled={disableComponent}
                        />
                      </Col>
                      <Col span={3}>
                        <Label>Country Code</Label>
                        <NumberField
                          name="countryCode"
                          placeholder="Code"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.countryCode}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={3}>
                        <Label>Prefix</Label>
                        <NumberField
                          name="newZealandDialingCode"
                          placeholder="Prefix"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newZealandDialingCode}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={6}>
                        <Label> Mobile Number</Label>
                        <NumberField
                          name="number"
                          placeholder="Mobile Number"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.number}
                          isDisabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Residency Status</Label>
                        <SelectField
                          name="residencyStatus"
                          options={residencyStatusOptions.map((status) => ({
                            label: status.label,
                            value: status.label,
                            key: status.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select Residency Status'}
                          disabled={disableComponent}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Gender</Label>
                        <SelectField
                          name="gender"
                          options={gendersOptions.map((gender) => ({
                            label: gender.label,
                            value: gender.label,
                            key: gender.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select Gender'}
                          disabled={disableComponent}
                        />
                      </Col>

                      <Col span={12}>
                        <Label>Citizenship</Label>
                        <MenuField
                          displayTextSelector={(code) => {
                            const { name } = citizenshipOptions.find(
                              (city) => city.code === code
                            )
                            return name
                          }}
                          name="citizenship"
                          readonly={disableComponent}
                        >
                          {citizenshipOptions.map((city) => (
                            <Item key={city.code}>{city.name}</Item>
                          ))}
                        </MenuField>
                      </Col>
                    </Row>
                    <Row style={sectionStyles}>
                      <Col span={24}>
                        {disableComponent ? (
                          <AddressInfo
                            key={index}
                            address={address}
                            duration={duration}
                            endDate={endDate}
                            startDate={startDate}
                            status={status}
                            style={s('mb-6')}
                            type={'address'}
                          />
                        ) : (
                          <>
                            <SubTitle>ADDRESS DETAILS</SubTitle>
                            <Row style={rowStyles}>
                              <Col span={24}>
                                <Label>Search Address</Label>
                                <ValocityAddressField
                                  name="fullAddress"
                                  placeholder={address}
                                  aria-label="property address"
                                  // onSelectionChange={onAddressSelectionChange}
                                  style={s('bg-white')}
                                  InputPrefixIcon={<SearchIcon />}
                                  menuStyles={s('max-h-24 mt-8', {
                                    overflowY: 'auto',
                                  })}
                                  value={values.fullAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Col>
                              <Col span={12}>
                                <Label style={s('mb-1')}>Start Date</Label>
                                <DateField
                                  name="startDate"
                                  placeholder="DD/MM/YYYY"
                                  containerStyle={s('border-grey-200')}
                                  style={s('text-sm')}
                                  disabledLabel={true}
                                />
                              </Col>
                              <Col span={12}>
                                <Label>Housing Status</Label>
                                <SelectField
                                  name="housingStatus"
                                  options={housingStatusOptions.map((options) => ({
                                    label: options.label,
                                    value: options.label,
                                    key: options.key,
                                  }))}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={'Select Housing Status'}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row style={sectionStyles}>
                      <Col span={24}>
                        <SubTitle>GUARANTEE DETAILS</SubTitle>
                        {disableComponent ? (
                          <Row style={rowStyles}>
                            <Col span={12}>
                              <Label> Security Guarantee Amount</Label>
                              <NumberField
                                name="securityGuaranteeAmount"
                                placeholder="$"
                                containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.securityGuaranteeAmount}
                              />
                            </Col>
                            <Col span={12}>
                              <Label> Servicing Guarantee Amount</Label>
                              <NumberField
                                name="servicingGuaranteeAmount"
                                placeholder="$"
                                containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.servicingGuaranteeAmount}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Row style={rowStyles}>
                            <Col span={12}>
                              <Label> Security Guarantee Amount</Label>
                              <NumberField
                                name="securityGuaranteeAmount"
                                placeholder="$"
                                containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.securityGuaranteeAmount}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={s('flex items-center justify-end mt-8')}>
                  <Button
                    variant="outlined"
                    onClick={() => setShowEditForm(false)}
                    type="button"
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // disabled={isNilOrEmpty(values.fullAddress)}
                    style={s('ml-4')}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </FormProvider>
        </ContentCard>
      </Container>
    </>
  )
}
