/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'

const IndividualEntitySubtitle = styled.h3(
  s('m-0 mb-4 text-grey-900 text-xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        tableHeaderStyles: headerStyleBase({ width: '40%' }),
        tableCellStyles: cellStyleBase({ width: '40%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        tableHeaderStyles: headerStyleBase({ width: '40%' }),
        tableCellStyles: cellStyleBase({ width: '40%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    [],
  )
}

export const ExpensesTable = ({ expenses = [] }) => {
  const tableData = expenses.reduce((tableDataSoFar) => {
    // TODO: looks like categories are thing for this
    const expenseRowData = {
      type: '-',
      frequency: '-',
      amount: '-',
    };

    return tableDataSoFar.concat(expenseRowData);
  }, []);

  const totalMonthlyIncome = expenses
    .map(expense => expense.amount)
    .reduce((total, amount) => { return total + amount }, 0)
    / 12

  if (tableData.length === 0) {
    return (
      <>
        <IndividualEntitySubtitle>
          ExpensesTable
        </IndividualEntitySubtitle>

        <p style={s('text-grey-600 font-semibold text-sm')}>
          No data found
        </p>
      </>
    );
  }

  return (
    <>
      <IndividualEntitySubtitle>
        Income
      </IndividualEntitySubtitle>

      <Table
        columns={Columns()}
        data={tableData}
        tableHeight="initial"
        rootStyle={{margin: 0}}
      />

      <div style={{ textAlign: 'right'}}>
        <h5>Monthly total {formatCurrency(totalMonthlyIncome)}</h5>
      </div>
    </>
  )
}
