const VALOCITY_REPORT_SECTION_IDS = /** @type {const} */ ({
  GENERAL_DETAILS: 'general-details',
  PROPERTY_DETAILS: 'property-details',
  IVAL_ESTIMATE_RANGE: 'ival-estimate-range',
  PROPERTY_FEATURES: 'property-features',
  RISK_FLAGS: 'risk-flags',
  RATING_VALUATION: 'rating-valuation',
  LAST_KNOWN_SALE: 'last-known-sale',
  COMPARABLE_PROPERTY_SALES: 'comparable-property-sales',
})

export { VALOCITY_REPORT_SECTION_IDS }
