import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'

const Value = styled.div()

/**
 * @typedef {Object} Props
 * @property {string | number}[] value
 */

/** @param {Props} props */

const VersionedValue = ({ values = [], valueStyles = {}, testId, ...props }) => {
  const singleVersionStyles = valueStyles
  const multipleVersionStyles = s('bg-yellow-light')
  const previousValue = values.length > 1 ? values[values.length - 2] : '-'
  const displayValue = values.length === 0 ? '-' : values[values.length - 1]

  if (values.length === 1 || displayValue === previousValue) {
    return <Value style={singleVersionStyles}>{displayValue}</Value>
  }

  return (
    <Tooltip title={`Previously ${previousValue}`} {...props}>
      <Value data-test-id={testId} style={singleVersionStyles}>
        <span style={multipleVersionStyles}>{displayValue}</span>
      </Value>
    </Tooltip>
  )
}

export { VersionedValue }
