import React from 'react'

import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'

export const UMIExpenseSummary = ({
  totalMonthlyExpenses,
  totalMonthlyBenchmarkExpenses,
}) => {
  return (
    <div style={s('flex')}>
      <h3 style={s('font-normal text-grey-600 mr-8 my-1')}>
        Monthly total household expenses (validated)
        <br />
        <span style={s('text-2xl font-bold text-grey-900')}>
          {formatCurrency(totalMonthlyExpenses)}
        </span>
      </h3>

      <h3 style={s('font-normal text-grey-600 mr-8 my-1')}>
        Monthly total benchmark household expenses
        <br />
        <span style={s('text-2xl font-bold text-grey-900')}>
          {formatCurrency(totalMonthlyBenchmarkExpenses)}
        </span>
      </h3>
    </div>
  )
}
