import React from 'react'
import { s } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { OtherIncomesTable } from './OtherIncomesTable'
import { ExpensesTable } from './ExpensesTable'
import { RealEstateTable } from './RealEstateTable'
import { NonRealAssetTable } from './NonRealAssetTable'
import { NetPositionEquation } from './NetPositionEquation'
import { NetEquityEquation } from './NetEquityEquation'
import { InsuranceDeclaration } from './InsuranceDeclaration'
import { DetailsToggle } from './DetailsToggle'
import { NoteSection } from 'features/common/NoteSection'
import {
  financialsByType,
  financialsForEntities,
  entityInsuranceDeclaration,
} from './utils'

export const TrustFinancialPosition = ({
  versionedLoanApplicationId,
  latestVersionId,
  trust,
  versionedFinancials = [],
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)

  const trustIncomes = financialsForEntities(
    financialsByType(versionedFinancials, 'income'),
    [trust],
    'Equal'
  )

  const trustRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'real_estate_asset'),
    [trust],
    'Equal'
  )

  const trustNonRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'non_real_estate_asset'),
    [trust],
    'Equal'
  )

  return (
    <>
      <div style={s('flex')}>
        <h1 style={{ color: '#490B2D', flexGrow: 1 }}>
          <span style={s('text-grey-600 block text-sm font-semibold')}>
            {"Trustee's financial position"}
          </span>
          {trust.trustName}
        </h1>

        <DetailsToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>

      <NetPositionEquation incomes={trustIncomes} expenses={[]} />

      {isExpanded && (
        <>
          <ContentCard style={s('mb-4')}>
            <OtherIncomesTable otherIncomes={trustIncomes} />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <ExpensesTable expenses={[]} />
          </ContentCard>
        </>
      )}

      <NetEquityEquation
        assets={trustRealAssets.concat(trustNonRealAssets)}
        liabilities={[]}
      />

      {isExpanded && (
        <>
          <ContentCard style={s('mb-4')}>
            <RealEstateTable realAssetFinancials={trustRealAssets} />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <NonRealAssetTable nonRealAssetFinancials={trustNonRealAssets} />
          </ContentCard>

          <InsuranceDeclaration
            declarations={entityInsuranceDeclaration(trust.trustName)}
          />
        </>
      )}

      <div style={s('mb-4')}>
        <NoteSection
          id="note-section"
          versionedLoanApplicationId={versionedLoanApplicationId}
          loanApplicationId={latestVersionId}
          source={`loan-applications/financial/trust/${trust.externalRef}`}
        />
      </div>
    </>
  )
}
