import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { Trust } from './Trust'
import {
  SectionTitle as SectionTitleBase,
  ContentCard as ContentCardBase,
} from '../components'

const { TRUSTS } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const Text = styled.p(s('my-0'))

const Trusts = ({ trusts = [], ...props }) => {
  return (
    <Container id={TRUSTS} {...props}>
      <SectionTitle title="Trust(s)" />

      <ContentCard>
        {isNilOrEmpty(trusts) ? (
          <Text>No trust information to display</Text>
        ) : (
          trusts?.map((trust, index, { length }) => {
            const last = index === length - 1
            return (
              <Trust
                key={trust.externalRef}
                trust={trust}
                index={index}
                style={s(last ? '' : 'mb-6')}
              />
            )
          })
        )}
      </ContentCard>
    </Container>
  )
}

export { Trusts }
