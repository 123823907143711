/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { formatCurrency } from 'utils/formatters'
import { s } from '@vega/styled/v2'
import { VersionedValue } from '../../common'

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        tableHeaderStyles: headerStyleBase({ width: '60%' }),
        tableCellStyles: cellStyleBase({ width: '60%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={[value]} />
          </TextOverflowEllipsisBox>
        ),
      },
      {
        Header: 'Ownership*',
        accessor: 'ownership',
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={[value]} />
          </TextOverflowEllipsisBox>
        ),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        tableHeaderStyles: headerStyleBase({ width: '10%', textAlign: 'right' }),
        tableCellStyles: cellStyleBase({ width: '10%', flexDirection: 'row-reverse' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={value} />
          </TextOverflowEllipsisBox>
        ),
      },
    ],
    []
  )
}

export const LivingExpense = ({ expenses = [] }) => {
  const expensesByCategory = [
    ...new Set(expenses.map((expense) => expense.category)),
  ].map((category) => {
    const matchingExpenses = expenses.filter((expense) => expense.category === category)

    return { label: category, expenses: matchingExpenses }
  })

  const ownershipLabel = (expense) => {
    const {
      percentResponsible: { owners },
    } = expense

    if (owners.length === 1) {
      return `${owners[0].legalEntity.firstName} ${owners[0].percent}%`
    }
    return owners.map((owner) => `${owner.percent}%`).join(' - ')
  }

  const categoryTables = expensesByCategory.map((category) => {
    const tableData = category.expenses.map((expense) => {
      return {
        type: expense.description,
        ownership: ownershipLabel(expense),
        frequency: expense.frequency,
        amount: expense.amount,
      }
    })

    const categoryTotal = category.expenses.reduce((total, expense) => {
      const lastSubmission = expense?.amount?.length - 1
      const amount = parseInt(expense?.amount[lastSubmission]?.replace(/[$,]/g, ''), 10)
      return total + amount
    }, 0)

    if (tableData.length === 0) {
      return (
        <div key={category.label}>
          <p style={s('mb-6 pb-2 font-medium', { borderBottom: '1px solid #F1F5F9' })}>
            {category.label}
            <br />
            <span style={s('text-sm font-semibold text-grey-600')}>No data found</span>
          </p>
        </div>
      )
    }
    return (
      <div key={category.label}>
        <p style={s('font-medium')}>{category.label}</p>

        <Table
          columns={Columns()}
          data={tableData}
          tableHeight="initial"
          rootStyle={{ margin: 0 }}
        />

        <div style={{ textAlign: 'right' }}>
          <h5 style={{ color: '#490B2D' }}>
            Monthly total
            <span style={s('inline-block w-8')}>{formatCurrency(categoryTotal)}</span>
          </h5>
        </div>
      </div>
    )
  })

  const totalExpenses = expenses.reduce((total, expense) => {
    const lastSubmission = expense?.amount?.length - 1
    const amount = parseInt(expense?.amount[lastSubmission]?.replace(/[$,]/g, ''), 10)
    return total + amount
  }, 0)

  const ownershipDescription = !expenses[0]
    ? '-'
    : expenses[0].percentResponsible.owners
        .map((owner) => `${owner.legalEntity.firstName} ${owner.legalEntity.lastName}`)
        .join(' - ')

  return (
    <div style={s('mb-8')}>
      <h3 style={{ color: '#490B2D' }}>Expenses</h3>

      {categoryTables}

      <div style={{ textAlign: 'right' }}>
        <h5 style={{ color: '#490B2D' }}>
          Total Monthly Expenses
          <span style={s('inline-block w-8')}>{formatCurrency(totalExpenses)}</span>
        </h5>
      </div>

      <span style={s('text-sm text-grey')}>*Ownership = {ownershipDescription}</span>
    </div>
  )
}
