/* eslint-disable complexity */
import React from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { Button as ButtonBase } from '@vega/components'
import { styled, s } from '@vega/styled/v2'
import { ReactComponent as TickIcon } from '@vega/components/src/assets/images/tick.svg'

import { useIllionDataRetrieval } from './useIllionDataRetrieval'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { SectionTitle } from '../components'
import { ActionPanel } from './ActionPanel'
import { useXaiLinking } from './useXaiLinking'

const { XAI_CONNECTIONS } = APPLICATION_INFO_SECTION_IDS

const Prompt = styled.p(s('text-grey-700 text-sm mb-1'))

const XaiConnectionBtn = styled(ButtonBase)(
  ({ isXaiActivated }) =>
    isXaiActivated && {
      ':hover:not([disabled])': s('bg-secondary', { boxShadow: 'none' }),
    }
)

const renderActivateBtnText = (
  isActivatingXai,
  isXaiActivated,
  reactivationRequired
) => {
  if (isActivatingXai) return 'Activating Xai'
  if (isXaiActivated) return 'Activated'
  if (reactivationRequired) return 'Reactivate Xai'

  return 'Activate Xai'
}

const assessmentReferenceToIndividualGroup = (individualCompletedAssessment = []) => {
  const groups = {}

  for (const individual of individualCompletedAssessment) {
    const { assessmentReference } = individual.affordabilityAssessment || {}

    const targetGroupDoesNotExist = isNilOrEmpty(groups[assessmentReference])
    if (targetGroupDoesNotExist) groups[assessmentReference] = []

    groups[assessmentReference].push(individual)
  }

  return groups
}

export const XaiConnections = ({
  versionedLoanApplication,
  individuals = [],
  loanApplicationId,
  affordabilityAssessments = [],
}) => {
  const {
    activateXai,
    updateXai,
    isActivatingXai,
    isXaiActivated,
    isReactivationRequired,
    isUpdatingXaiApplication,
    assessmentStatusToIndividuals: { individualsCompletedAssessment },
  } = useXaiLinking(individuals, affordabilityAssessments)

  const {
    sendIllionLink,
    entitiesLinkingIllion,
    entityIdToRetrievalStatus,
  } = useIllionDataRetrieval(
    versionedLoanApplication.id,
    loanApplicationId,
    individualsCompletedAssessment,
    affordabilityAssessments
  )

  const handleXaiBtnClicked = async (reactivationRequired = false) => {
    if (reactivationRequired) {
      activateXai(versionedLoanApplication.id)
    }
  }

  return (
    <div id={XAI_CONNECTIONS} style={{ marginBottom: 52 }}>
      <SectionTitle title="Xai Connections" style={s('mb-4')} />

      {isActivatingXai && <Prompt>Note: Xai activation is in progress</Prompt>}
      {isReactivationRequired && !isActivatingXai && (
        <Prompt style={s('text-accent-red')}>Note: Xai activation failed</Prompt>
      )}

      <XaiConnectionBtn
        loading={isActivatingXai}
        StartIcon={isXaiActivated && <TickIcon style={s('mr-1')} />}
        onClick={() => handleXaiBtnClicked(isReactivationRequired)}
        isXaiActivated={isXaiActivated}
        style={s('mb-4', {
          width: isActivatingXai ? 140 : 'initial',
        })}
      >
        {renderActivateBtnText(isActivatingXai, isXaiActivated, isReactivationRequired)}
      </XaiConnectionBtn>

      {isXaiActivated &&
        Object.values(
          assessmentReferenceToIndividualGroup(individualsCompletedAssessment)
        )?.map((individualsGroup = []) => {
          return (
            <ActionPanel
              individualsGroup={individualsGroup}
              affordabilityAssessments={affordabilityAssessments}
              updateXai={updateXai}
              sendIllionLink={sendIllionLink}
              entityIdToIllionRetrievalStatus={entityIdToRetrievalStatus}
              entitiesLinkingIllion={entitiesLinkingIllion}
              isUpdatingXai={isUpdatingXaiApplication}
              key={individualsGroup[0].entityId}
              style={s('mb-6')}
            />
          )
        })}
    </div>
  )
}
