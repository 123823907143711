import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { asyncStateReducer } from '@vega/redux.async'
import { profileReducer } from '@vega/redux.profile'
import { applicationReducer } from 'modules/application'
import { applicationReducer as versionedApplicationReducer } from 'modules/versionedApplication'
import { creditReportReducer } from 'modules/creditReport'
import { affordabilityAssessmentReducer } from 'modules/affordabilityAssessment'
import { supportingDocumentReducer } from 'modules/supportingDocument'
import { financialReducer } from 'modules/financial'
import { propertiesReducer } from 'modules/property'
import { activityLogsReducer } from 'modules/activityLog'
import { noteReducer } from 'modules/note'
import { productsReducer } from 'modules/product'
import { calculationReducer } from 'modules/calculation'
import { loanSplitReducer } from 'modules/loanSplit'
import { notificationReducer } from './modules/notification'
import { comparableSaleReducer } from 'modules/comparableSale'
import { userReducer } from './modules/user'
import { securityReducer } from 'modules/security'
import { umiReportItemReducer } from 'modules/umiReportItems'
import { googleDocsReducer } from 'modules/google'

/**
 * inferred type needs to be defined here, not in *.d.ts
 * @typedef {ReturnType<typeof rootReducer>} RootState
 */
const rootReducer = combineReducers({
  asyncState: asyncStateReducer,
  profile: profileReducer,
  application: applicationReducer,
  versionedApplication: versionedApplicationReducer,
  creditReport: creditReportReducer,
  affordabilityAssessment: affordabilityAssessmentReducer,
  supportingDocument: supportingDocumentReducer,
  financial: financialReducer,
  property: propertiesReducer,
  activityLogs: activityLogsReducer,
  note: noteReducer,
  product: productsReducer,
  calculation: calculationReducer,
  loanSplit: loanSplitReducer,
  notification: notificationReducer,
  comparableSale: comparableSaleReducer,
  user: userReducer,
  securities: securityReducer,
  umiReportItems: umiReportItemReducer,
  googleDocs: googleDocsReducer,
})

export default configureStore({ reducer: rootReducer })
