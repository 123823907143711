import React from 'react'
import { Link as LinkBase, useParams, useLocation, useHistory } from 'react-router-dom'
import { noop } from '@solta/ramda-extra'
import { styled, s } from '@vega/styled/v2'
import neoLogo from '@vega/components/src/assets/images/neo-logo.png'
import { routes } from 'navigation/routes'

const HeaderContainer = styled.div(
  s(
    'border-1 border-grey-200 border-solid bg-white items-center justify-between fixed flex py-3 w-full z-2',
    { height: 96, paddingLeft: 152, paddingRight: 152 }
  )
)
const Link = styled(LinkBase)(s('no-decoration'))
const NavigationButton = styled.button(
  s(
    'bg-transparent border-transparent flex items-center px-4 py-8 text-grey-700 text-sm',
    { cursor: 'pointer', ':hover': s('bg-grey-100') }
  ),
  ({ isActive }) => isActive && s('text-grey-900')
)
const NeoLogo = styled.img(s('h-2', { cursor: 'pointer' }))
const NextButton = styled.button(
  s('bg-secondary border-transparent font-semibold px-4 py-2 text-grey-900 text-sm', {
    borderRadius: 6,
    cursor: 'pointer',
  }),
  ({ disabled }) => disabled && s('bg-grey-100', { cursor: 'default' })
)
const StepNumber = styled.div(
  s(
    'bg-grey-200 border-transparent flex items-center justify-center mr-2 rounded-3xl text-grey-700',
    { height: 24, width: 24 }
  ),
  ({ isActive }) => isActive && s('bg-primary text-white')
)

export const TopNav = () => {
  /**
   * @type {{id: string}}
   */
  const history = useHistory()
  const { pathname } = useLocation()
  const { id } = useParams()

  const {
    loanApp: {
      assessment: {
        info,
        financial,
        security,
        serviceability,
        structure,
        offer,
        settlement
      },
    },
  } = routes

  /**
   * @param {string} url
   * @returns {string}
   */
  const insertId = (url) => url.replace(':id', id)

  const urlMap = {
    [insertId(info)]: {
      previous: noop,
      next: insertId(financial),
    },
    [insertId(financial)]: {
      previous: insertId(info),
      next: insertId(security.root),
    },
    [insertId(security.root)]: {
      previous: insertId(financial),
      next: insertId(structure),
    },
    [insertId(serviceability)]: {
      previous: insertId(security.root),
      next: insertId(structure),
    },
    [insertId(structure)]: {
      previous: insertId(serviceability),
      next: insertId(offer),
    },
    [insertId(offer)]: {
      previous: insertId(structure),
      next: insertId(settlement),
    },
    [insertId(settlement)]: {
      previous: insertId(offer),
      next: noop,
    },
  }

  const next = () => history.push(urlMap[pathname].next)

  const checkIsActive = (route) => pathname.includes(route)

  return (
    <HeaderContainer>
      <NeoLogo src={neoLogo} onClick={() => history.push(`/loan-applications`)} />

      <Link to={insertId(info)}>
        <NavigationButton isActive={checkIsActive(insertId(info))}>
          <StepNumber isActive={checkIsActive(insertId(info))}>1</StepNumber>
          Application info
        </NavigationButton>
      </Link>

      <Link to={insertId(financial)}>
        <NavigationButton isActive={checkIsActive(insertId(financial))}>
          <StepNumber isActive={checkIsActive(insertId(financial))}>2</StepNumber>
          Financial Position
        </NavigationButton>
      </Link>

      <Link to={insertId(security.root)}>
        <NavigationButton isActive={checkIsActive(insertId(security.root))}>
          <StepNumber isActive={checkIsActive(insertId(security.root))}>3</StepNumber>
          Security Info
        </NavigationButton>
      </Link>

      <Link to={insertId(serviceability)}>
        <NavigationButton isActive={checkIsActive(insertId(serviceability))}>
          <StepNumber isActive={checkIsActive(insertId(serviceability))}>4</StepNumber>
          Serviceability
        </NavigationButton>
      </Link>

      <Link to={insertId(structure)}>
        <NavigationButton isActive={checkIsActive(insertId(structure))}>
          <StepNumber isActive={checkIsActive(insertId(structure))}>5</StepNumber>
          Loan Structure
        </NavigationButton>
      </Link>

      <Link to={insertId(offer)}>
        <NavigationButton isActive={checkIsActive(insertId(offer))}>
          <StepNumber isActive={checkIsActive(insertId(offer))}>6</StepNumber>
          Offer
        </NavigationButton>
      </Link>

      <Link to={insertId(settlement)}>
        <NavigationButton isActive={checkIsActive(insertId(settlement))}>
          <StepNumber isActive={checkIsActive(insertId(settlement))}>7</StepNumber>
          Settlement
        </NavigationButton>
      </Link>

      <NextButton onClick={next} disabled={pathname === insertId(settlement)}>
        Next &gt;
      </NextButton>
    </HeaderContainer>
  )
}
