import { useDispatch } from 'react-redux'
import { isNilOrEmpty, any, isNotNilOrEmpty } from '@solta/ramda-extra'
import { round } from 'mathjs'
import { useDebouncedCallback } from 'use-debounce'
import { useFormikContext } from 'formik'
import * as calculationModule from 'modules/calculation'

export const useRepaymentCalculation = (fieldPrefix, structureIdx) => {
  const dispatch = useDispatch()
  const { values, errors, setFieldValue } = useFormikContext()

  // eslint-disable-next-line complexity
  const calculateRepayment = useDebouncedCallback(async () => {
    if (isNilOrEmpty(values?.loanStructures?.[structureIdx])) {
      return
    }

    const {
      product,
      loanAmount,
      loanTermInYears,
      repaymentFrequency,
      loading,
    } = values?.loanStructures?.[structureIdx]

    const loanStructureErrors = errors?.loanStructures?.[structureIdx]

    const {
      product: productError,
      loanAmount: loanAmountError,
      loanTermInYears: loanTermInYearsError,
      repaymentFrequency: repaymentFrequencyError,
      loading: loadingError,
    } = loanStructureErrors || {}

    const hasInvalidArgument = any(isNotNilOrEmpty)([
      productError,
      loanAmountError,
      loanTermInYearsError,
      repaymentFrequencyError,
      loadingError,
    ])

    if (hasInvalidArgument) return

    const productId = product?.id

    const result = await dispatch(
      calculationModule.calculateRepayment({
        productId,
        loanAmount,
        loanTermInYears,
        repaymentRecurrence: repaymentFrequency,
        loading,
      })
    )

    const periodicRepayment = result.payload?.periodicRepayment

    setFieldValue(
      `${fieldPrefix}.repaymentAmount`,
      periodicRepayment ? round(periodicRepayment || 0, 2) : periodicRepayment
    )
  }, 800)

  return {
    calculateRepayment,
  }
}
