/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { Col, Row } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { ContentCard as ContentCardBase } from '../info/components'
import { Button, TextField, Loading } from '@vega/components'

import { Formik as FormProvider, Form } from 'formik'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  createGoogleDoc,
  selectIsFetchingGoogleDoc,
  selectIsCreatingGoogleDoc,
} from 'modules/google'
import { GOOGLE_CONSTANTS } from '@neo/constants'

const {
  TEMPLATE: { OFFER },
} = GOOGLE_CONSTANTS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('mb-4'))
const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const GoogleDocs = ({ googleDoc }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const isFetchingGoogleDoc = useSelector(selectIsFetchingGoogleDoc)
  const isCreatingGooggleDoc = useSelector(selectIsCreatingGoogleDoc)

  const templateName = OFFER

  const handleSubmit = async () => {
    const payload = {
      versionedLoanApplicationId: id,
      templateName,
    }
    await dispatch(createGoogleDoc(payload))
  }
  const initialValues = {
    status: googleDoc?.status,
    title: googleDoc?.title,
  }

  const handleLinkClick = (e) => {
    e.stopPropagation()
    if (googleDoc?.url) {
      window.open(googleDoc.url, 'myWindow')
    }
  }

  if (isFetchingGoogleDoc)
    return (
      <ContentCard>
        <div style={s('my-3')}>
          <Loading />
        </div>
      </ContentCard>
    )

  return (
    <Container>
      <ContentCard>
        <FormProvider initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue, handleChange, isSubmitting, ...props }) => (
            <Form onSubmit={props.handleSubmit}>
              <Row>
                <Col span={12}>
                  <Label>Google Document</Label>
                  <TextField
                    isReadOnly={!googleDoc?.title}
                    name="title"
                    inputStyle={s('text-indigo-dark underline', {
                      '&:hover': s('', {
                        cursor: 'pointer',
                      }),
                    })}
                    onClick={(e) => handleLinkClick(e)}
                    inputContainerStyle={s('w-full')}
                  />
                </Col>

                <Col span={6}>
                  <Label>Document Status</Label>
                  <TextField
                    isReadOnly={!googleDoc?.status}
                    name="status"
                    inputStyle={{ pointerEvents: 'none' }}
                  />
                </Col>

                <Col span={24}>
                  <Button type="submit" loading={isCreatingGooggleDoc}>
                    Generate Google Document
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </FormProvider>
      </ContentCard>
    </Container>
  )
}

export { GoogleDocs }
