import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { thunkErrorProcessor } from '@vega/error-standardizer'
import { affordabilityAssessmentService } from 'apiService'

export const fetchAffordabilityAssessments = createAsyncThunk(
  'affordabilityAssessment/fetchAffordabilityAssessments',
  async (versionedLoanApplicationId, { rejectWithValue }) => {
    try {
      return await affordabilityAssessmentService.readAssessments(versionedLoanApplicationId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const conductAffordabilityAssessment = createAsyncThunk(
  'affordabilityAssessment/conductAffordabilityAssessment',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.conductAssessment(loanApplicationId)
      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateAffordabilityAssessment = createAsyncThunk(
  'affordabilityAssessment/updateAffordabilityAssessment',
  async (
    { versionedLoanApplicationId, assessmentReference, acitivityLogId },
    { rejectWithValue }
  ) => {
    try {
      await affordabilityAssessmentService.updateAssessment(
        versionedLoanApplicationId,
        assessmentReference,
        acitivityLogId
      )

      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const retrieveFinancialsData = createAsyncThunk(
  'affordabilityAssessment/retrieveFinancialsData',
  async ({ versionedLoanApplicationId, legalEntityExternalRef }, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.retrieveFinancialsData(
        versionedLoanApplicationId,
        legalEntityExternalRef
      )

      return null
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const exportUMIReport = createAsyncThunk(
  'affordabilityAssessment/exportUMIReport',
  async ({ referenceId }, { rejectWithValue }) => {
    try {
      await affordabilityAssessmentService.exportUMIReport(referenceId)
      return null
    } catch (err) {
      const error = await thunkErrorProcessor(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchFinancialData = createAsyncThunk(
  'affordabilityAssessment/readFinancialData',
  async (assessmentReference, { rejectWithValue }) => {
    try {
      const results = await affordabilityAssessmentService.readFinancialData(
        assessmentReference
      )

      return { id: assessmentReference, results }
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const creditReportAdapter = createEntityAdapter()
export const affordabilityAssessmentAdapter = createEntityAdapter({
  selectId: (assessment) => `${assessment.legalEntityExternalRef}-${assessment.assessmentReference}`,
})

const initialState = creditReportAdapter.getInitialState()

const affordabilityAssessmentSlice = createSlice({
  name: 'affordabilityAssessment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialData.fulfilled, (state, action) => {
        creditReportAdapter.upsertOne(state, action.payload)
      })
      .addCase(fetchAffordabilityAssessments.fulfilled, (state, action) => {
        affordabilityAssessmentAdapter.setAll(state, action.payload)
      })
  }
})

const { reducer: affordabilityAssessmentReducer } = affordabilityAssessmentSlice

export { affordabilityAssessmentReducer }
