import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { s } from '@vega/styled/v2'
import { formatCurrency, capitaliseFirstLetter } from 'utils/formatters'
import { grossToNetNZ } from './utils'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

import { Tooltip } from '@mui/material'
import { ReactComponent as QuestionMarkCircleIcon } from '@vega/components/src/assets/images/question-mark-circle.svg'

import { Formik, Field } from 'formik'

const grossToNet = (income) => {
  const formattedKiwisaver = Number.parseInt(income.details.kiwiSaverRate, 10) / 100
  return grossToNetNZ(income.details.declaredAmount * 12, formattedKiwisaver) / 12
}

export const PrimaryIncomeTable = ({ incomes }) => {
  const dispatch = useDispatch()
  const [currentIncomes, setCurrentIncomes] = useState(incomes)

  if (incomes.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No primary income currently recorded
      </p>
    )
  }

  const monthlyNetTotal = incomes.reduce((acc, curr) => (
    acc + curr.details.verifiedAmount
  ), 0)

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const readOnlyIncomeRow = (income) => (
    <tr key={income.id}>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {capitaliseFirstLetter(income.details.source)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {capitaliseFirstLetter(income.details.employmentType)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {`${income.details.kiwiSaverRate}%` || '0%'}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {capitaliseFirstLetter(income.details.frequency)}
      </td>

      <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
        {formatCurrency(grossToNet(income))}
        {' '}
        {income.details.declaredAmountBreakdown.length > 0 && (
          <Tooltip
            title={
              <>
                <table>
                  <tbody>
                    {income.details.declaredAmountBreakdown.map((item, index) => (
                      <tr key={index}>
                        <td>{formatCurrency(income.amount)}</td>
                        <td>{item.reference}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            }
          >
            <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
          </Tooltip>
        )}
      </td>

      <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
        {formatCurrency(income.details.verifiedAmount)}
        {' '}
        <Tooltip
          title={<p style={s('text-sm')}>{income.details.validatedAmountReference}</p>}
        >
          <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
        </Tooltip>
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })} />
    </tr>
  )

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const editableIncomeRow = (income) => (
    <Formik
      key={income.id}
      initialValues={income}
      onSubmit={(values) => {
        setCurrentIncomes(
          currentIncomes.filter((income) => income.id !== values.id).concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.source"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="salary">Salary / wages</option>
              <option value="businessIncome">Business income</option>
              <option value="rentals">Retals</option>
              <option value="overtime">Overtime / allowances / bonus</option>
              <option value="beneficiaries">Beneficiaries</option>
              <option value="incomeNoInNZD">Income in non-NZD</option>
              <option value="commission">Commission</option>
              <option value="interest">Interest</option>
              <option value="dividends">Dividends</option>
              <option value="superannuation">
                Pensions / Superannuation / Annuity
              </option>
              <option value="boarders">Boarders / Flatmates</option>
              <option value="childSupport">Child support maintenance</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.employmentType"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="fullTime">Full-time</option>
              <option value="partTime">Part-time</option>
              <option value="unemployed">Unemployed</option>
              <option value="student">Student</option>
              <option value="retired">Retired</option>
              <option value="homeDuties">Home duties</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.kiwiSaverRate"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="0">0%</option>
              <option value="3">3%</option>
              <option value="4">4%</option>
              <option value="6">6%</option>
              <option value="8">8%</option>
              <option value="10">10%</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.frequency"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="halfYearly">Half-yearly</option>
              <option value="annually">Annually</option>
            </Field>
          </td>

          <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
            {formatCurrency(grossToNet(income))}{' '}
            {income.details.declaredAmountBreakdown.length > 0 && (
              <Tooltip
                title={
                  <>
                    <table style={s('text-sm')}>
                      <tbody>
                        {income.details.declaredAmountBreakdown.map((item, index) => (
                          <tr key={index}>
                            <td>{formatCurrency(item.amount)}</td>
                            <td>{item.reference}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                }
              >
                <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
              </Tooltip>
            )}
          </td>

          <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.verifiedAmount"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td
            style={s('py-2 text-right', {
              borderBottom: '1px solid #F1F5F9',
              width: '5%',
            })}
          >
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: income.id,
                    versionedLoanApplicationId: income.versionedLoanApplicationId,
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { cursor: 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  )

  const incomeRows = incomes.map((income) =>
    income.readOnly ? readOnlyIncomeRow(income) : editableIncomeRow(income)
  )

  return (
    <div style={s('mb-8')}>
      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Source</th>
            <th>Type</th>
            <th>KiwiSaver (%)</th>
            <th>Frequency</th>
            <th style={s('text-right')}>Declared amount (monthly net)</th>
            <th style={s('text-right')}>Validated amount (monthly net)</th>
            <th />
          </tr>
        </thead>
        <tbody>{incomeRows}</tbody>
      </table>

      {incomes.length !== 0 && (
        <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
          <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
            Total monthly net income (primary)
          </span>
          <span style={s('font-semibold text-sm')}>
            {formatCurrency(monthlyNetTotal)}
          </span>
        </div>
      )}
    </div>
  )
}
