import { isNil } from '@solta/ramda-extra'
import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class NotificationService extends BaseService {
  listNotifications() {
    return this.client.get('notifications').json()
  }

  updateNotificationAsSeen(notificationId, profileId) {
    if (isNil(notificationId)) throw new Error('Invalid Notification Id')
    if (isNil(profileId)) throw new Error('Invalid Profile Id')

    const queryParams = QueryBuilder().setParam('profileId', profileId).build()

    return this.client.put(`notifications/${notificationId}/seen?${queryParams}`)
  }
}
