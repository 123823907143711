import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectActivityLog = prop('activityLogs')

export const {
  selectIsPending: selectIsSearchingActivityLogs,
} = createAsyncThunkSelectors('activityLog/searchActivityLogs')

export const selectTotal = createSelector(selectActivityLog, prop('total'))
