/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Tooltip } from '@mui/material'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'
import { ReactComponent as QuestionMarkCircleIcon } from '@vega/components/src/assets/images/question-mark-circle.svg'

const UMITile = ({ title, description, standaloneValue, withGaurantorValue }) => {
  return (
    <div style={s('bg-grey-200 my-6 mr-6 p-6 text-lg font-light', { flexGrow: 1 })}>
      <div style={s('mb-4')}>
        {title}
        <Tooltip title={description}>
          <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
        </Tooltip>
      </div>

      <table>
        <tbody>
          <tr>
            <td>Applicants standalone:</td>
            <td style={s('font-semibold text-xl pl-6')}>
              {formatCurrency(standaloneValue)}
            </td>
          </tr>

          <tr>
            <td>With guarantor(s):</td>
            <td style={s('font-semibold text-xl pl-6')}>
              {formatCurrency(withGaurantorValue)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const FinalOutcomeTile = ({ value }) => {
  const colorClasses = value >= 100 ? 'bg-green-200' : 'bg-red text-white'

  return (
    <div style={s(`${colorClasses} m-6 p-6 text-lg font-light`)}>
      <div style={s('mb-4')}>Final outcome UMI</div>
      <span style={s('font-semibold text-xl')}>{formatCurrency(value)}</span>
    </div>
  )
}

export const UMIDashboard = ({ dashboardResults }) => {
  const tiles = [
    <FinalOutcomeTile key="finalOutcome" value={dashboardResults.finalUMI} />,

    <UMITile
      key="customerUMIWithoutHaircuts"
      title="Customer UMI (without haircuts)"
      description={
        'An estimate of the actual net income surplus given currently reported ' +
        'income, expenses and debt service costs. This calculation assists the ' +
        'customer in understanding how the home purchase or loan refinance will ' +
        'affect their current financial position.'
      }
      standaloneValue={dashboardResults.customerUMIWithoutHaircuts.applicantsStandalone}
      withGaurantorValue={dashboardResults.customerUMIWithoutHaircuts.withGaurantors}
    />,

    <UMITile
      key="customerUMIWithHaircuts"
      title="Customer UMI (with haircuts)"
      description={
        'An estimate of the net income surplus under financial distress including ' +
        'reduction to volatile income, minimum sustainable on-going living expenses ' +
        'and increased interest rates. This calculation is the basis of the credit decision.'
      }
      standaloneValue={dashboardResults.customerUMIWithHaircuts.applicantsStandalone}
      withGaurantorValue={dashboardResults.customerUMIWithHaircuts.withGaurantors}
    />,

    <UMITile
      key="benchmarkUMI"
      title="Benchmark UMI (with haircuts)"
      description={
        'An estimate of the net income surplus under financial distress including ' +
        'reduction to volatile income, actual continuing living expenses and ' +
        'increased interest rates.'
      }
      standaloneValue={dashboardResults.benchmarkUMI.applicantsStandalone}
      withGaurantorValue={dashboardResults.benchmarkUMI.withGaurantors}
    />,
  ]

  return (
    <>
      <h2 style={s('mb-1')}>Overall UMI</h2>
      <div style={s('bg-white rounded-lg shadow-lg flex')}>{tiles}</div>
    </>
  )
}
