import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik as FormProvider, Form } from 'formik'
import * as Validator from 'yup'
import { s } from '@vega/styled/v2'
import { NumberField } from '@vega/components'
import { TextField, OutlinedBtn, Row, CardContainer } from './common'
import { updateProfile } from '@vega/redux.profile'
import { TEST_IDS } from '@neo/constants'

const { object, string } = Validator

const validationSchema = object({
  phoneNumber: string().required('Phone number is required'),
})

export const checkHasObjectChanged = (obj1, obj2) =>
  JSON.stringify(obj1) !== JSON.stringify(obj2)

export const ContactDetailsCard = ({ email, phoneNumber }) => {
  const dispatch = useDispatch()

  const handleSubmit = (data) => {
    dispatch(updateProfile(data))
  }

  return (
    <FormProvider
      initialValues={{ email, phoneNumber }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values: formValues, resetForm }) => {
        const isFormEdited = checkHasObjectChanged(formValues, {
          email,
          phoneNumber,
        })

        return (
          <Form>
            <CardContainer>
              <TextField
                name="email"
                label="Email Address"
                style={s('mb-6')}
                isDisabled
                testId={TEST_IDS.ACCOUNT_EMAIL_INPUT}
              />

              <NumberField
                name="phoneNumber"
                aria-label="phone number"
                label="Phone Number"
                prefix="+64"
                format="## ### ####"
                placeholder="+64 00 000 0000"
              />

              {isFormEdited ? (
                <Row style={s('mt-6 justify-start')}>
                  <OutlinedBtn variant="contained" style={s('mr-4')}>
                    Save Changes
                  </OutlinedBtn>

                  <OutlinedBtn onClick={resetForm}>Cancel</OutlinedBtn>
                </Row>
              ) : (
                <OutlinedBtn type="button" style={s('mt-6')}>
                  Save Changes
                </OutlinedBtn>
              )}
            </CardContainer>
          </Form>
        )
      }}
    </FormProvider>
  )
}
