/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'

const IndividualEntitySubtitle = styled.h3(
  s('m-0 mb-4 text-grey-900 text-xl', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    color: '#490B2D',
  })
)

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Employer Name',
        accessor: 'employerName',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Role',
        accessor: 'role',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Type',
        accessor: 'type',
        tableHeaderStyles: headerStyleBase({ width: '15%' }),
        tableCellStyles: cellStyleBase({ width: '15%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Probation',
        accessor: 'probation',
        tableHeaderStyles: headerStyleBase({ width: '5%' }),
        tableCellStyles: cellStyleBase({ width: '5%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Basis',
        accessor: 'basis',
        tableHeaderStyles: headerStyleBase({ width: '10%', textAlign: 'right' }),
        tableCellStyles: cellStyleBase({ width: '10%', flexDirection: 'row-reverse' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    []
  )
}

const formatDuration = (months) => {
  if (months === 0) {
    return '-'
  }
  const years = Math.floor(months / 12)
  months %= 12
  const duration = `${years} years ${months} months`
  return duration
}
const getRelatedCompany = (xEmployer, relatedCompanies) =>
  relatedCompanies.find((item) => item.externalRef === xEmployer)?.companyName || '-'

// create flat object with employment history data for processing by the table component
const getEmploymentTableData = (employments, relatedCompanies) => {
  let result = {}
  const employmentTableData = employments.map((employment) => {
    if (employment.PAYE.startDate !== null) {
      result = {
        startDate: employment.PAYE.startDate,
        status: employment.PAYE.status,
        employerName: getRelatedCompany(employment.PAYE.xEmployer, relatedCompanies),
        role: employment.PAYE.occupation,
        duration: formatDuration(employment.PAYE.duration.length),
        type: 'PAYE',
        probation: employment.PAYE.onProbation ? 'Yes' : 'No',
        basis: employment.PAYE.basis,
      }
    }
    if (employment.notEmployed.startDate !== null) {
      result = {
        startDate: employment.notEmployed.startDate,
        status: employment.notEmployed.status,
        employerName: '-',
        role:
          employment.notEmployed.homeDuties === true
            ? 'Home'
            : employment.notEmployed.student === true
            ? 'Student'
            : 'Not Employed',
        duration: formatDuration(employment.notEmployed.duration.length),
        type:
          employment.notEmployed.homeDuties === true
            ? 'Home Duties'
            : employment.notEmployed.student === true
            ? 'Student'
            : 'Not Employed',
        probation: '-',
        basis: '-',
      }
    }
    if (employment.selfEmployed.startDate !== null) {
      result = {
        startDate: employment.selfEmployed.startDate,
        status: employment.selfEmployed.status,
        employerName: getRelatedCompany(
          employment.selfEmployed.xEmployer,
          relatedCompanies
        ),
        role: employment.selfEmployed.occupation,
        duration: formatDuration(employment.selfEmployed.duration.length),
        type: 'Self Employed',
        probation: '-',
        basis: employment.selfEmployed.basis,
      }
    }
    if (employment.foreignEmployed.startDate !== null) {
      result = {
        startDate: employment.foreignEmployed.startDate,
        status: employment.foreignEmployed.status,
        employerName: getRelatedCompany(
          employment.foreignEmployed.xEmployer,
          relatedCompanies
        ),
        role: employment.foreignEmployed.occupation,
        duration: formatDuration(employment.foreignEmployed.duration.length),
        type: 'Foreign Employed',
        probation: employment.foreignEmployed.onProbation,
        basis: employment.foreignEmployed.basis,
      }
    }
    return result
  })

  return employmentTableData.sort((x, y) => {
    const a = new Date(x.startDate)
    const b = new Date(y.startDate)
    return b - a
  })
}

export const EmploymentTable = ({ employments = [], relatedCompanies = [] }) => {
  const employmentTableData = getEmploymentTableData(employments, relatedCompanies)

  if (employments.length === 0) {
    return (
      <>
        <IndividualEntitySubtitle>Employment History</IndividualEntitySubtitle>

        <p style={s('text-grey-600 font-semibold text-sm')}>No data found</p>
      </>
    )
  }

  return (
    <>
      <IndividualEntitySubtitle>Employment History</IndividualEntitySubtitle>

      <Table
        columns={Columns()}
        data={employmentTableData}
        tableHeight="initial"
        rootStyle={{ margin: 0 }}
      />
    </>
  )
}
