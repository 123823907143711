import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, breakpoints } from '@vega/styled'
import './index.css'
import store from './store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import RootRouter from './navigation/RootRouter'
import { AccessController } from '@neo/app-access-controller'
import { AuthSdk as Wallet } from '@midas/idkit'
import { USER_ROLES } from '@vega/constants'
import { APPS } from '@neo/constants'

const { SUPER_ADMIN, CREDIT_ASSESSOR, LEAD_ASSESSOR } = USER_ROLES

const region = process.env.REACT_APP_AUTH_REGION
const userPoolId = process.env.REACT_APP_AUTH_USER_POOL_ID
const userPoolWebClientId = process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID
const identityPoolId = process.env.REACT_APP_AUTH_IDENTITY_POOL_ID

Wallet.configure({
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={{ breakpoints }}>
        <BrowserRouter>
          <AccessController
            targetAppName={APPS.LOAN_ORIGINATION}
            permittedRoles={[SUPER_ADMIN, LEAD_ASSESSOR, CREDIT_ASSESSOR]}
          >
            <RootRouter />
          </AccessController>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
