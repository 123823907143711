import { prop } from '@solta/ramda-extra'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { notificationAdapter } from './module'

export const {
  selectIsPending: selectIsListNotificationsPending,
  selectHasFulfilled: selectHasListNotificationsFulfilled,
  selectError: selectListNotificationsError,
} = createAsyncThunkSelectors('notification/listNotifications')

export const {
  selectIsPending: selectIsUpdateNotificationAsSeenPending,
  selectHasFulfilled: selectHasUpdateNotificationAsSeenFulfilled,
  selectError: selectUpdateNotificationAsSeenError,
} = createAsyncThunkSelectors('notification/updateNotificationAsSeen')

export const { selectAll: selectAllNotifications } = notificationAdapter.getSelectors(
  prop('notification')
)
