/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'
import { VersionedValue } from '../../common'

const IndividualEntitySubtitle = styled.h3(
  s('m-0 mb-4 text-grey-900 text-xl', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    color: '#490B2D',
  })
)

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Details',
        accessor: 'employers',
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Occupation',
        accessor: 'occupation',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={value} />
          </TextOverflowEllipsisBox>
        ),
      },
      {
        Header: 'Ownership*',
        accessor: 'ownership',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Amount',
        accessor: 'formattedAmount',
        tableHeaderStyles: headerStyleBase({ width: '10%', textAlign: 'right' }),
        tableCellStyles: cellStyleBase({ width: '10%', flexDirection: 'row-reverse' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={value} />
          </TextOverflowEllipsisBox>
        ),
      },
    ],
    []
  )
}

export const IncomesTable = ({ incomes = [] }) => {
  const totalIncomeAmount = incomes.reduce((total, income) => {
    return total + income.monthlyAmount
  }, 0)

  if (incomes.length === 0) {
    return (
      <>
        <IndividualEntitySubtitle>Income</IndividualEntitySubtitle>

        <p style={s('text-grey-600 font-semibold text-sm')}>No data found</p>
      </>
    )
  }

  return (
    <>
      <IndividualEntitySubtitle>Income</IndividualEntitySubtitle>

      <Table
        columns={Columns()}
        data={incomes}
        tableHeight="initial"
        rootStyle={{ margin: 0 }}
      />

      <div style={{ textAlign: 'right' }}>
        <h5 style={{ color: '#490B2D' }}>
          Monthly total gross
          <span style={s('inline-block w-8')}>{formatCurrency(totalIncomeAmount)}</span>
        </h5>
      </div>
    </>
  )
}
