/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { s } from '@vega/styled/v2'
import { formatCurrency, buildAddress, formatStringToNumber } from 'utils/formatters'
import { VersionedValue } from 'features/common'

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const tableColumns = (displayOwnership) => {
  const columns = [
    {
      Header: 'Address',
      accessor: 'address',
      tableHeaderStyles: headerStyleBase({ width: displayOwnership ? '50%' : '60%' }),
      tableCellStyles: cellStyleBase({ width: displayOwnership ? '50%' : '60%' }),
      Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
    },
    {
      Header: 'Type',
      accessor: 'type',
      tableHeaderStyles: headerStyleBase({ width: '20%' }),
      tableCellStyles: cellStyleBase({ width: '20%' }),
      Cell: ({ value }) => (
        <TextOverflowEllipsisBox>
          <VersionedValue values={value} />
        </TextOverflowEllipsisBox>
      ),
    },
    {
      Header: 'Ownership*',
      accessor: 'ownership',
      tableHeaderStyles: headerStyleBase({ width: '10%' }),
      tableCellStyles: cellStyleBase({ width: '10%' }),
      Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
    },
    {
      Header: 'Security?',
      accessor: 'security',
      tableHeaderStyles: headerStyleBase({ width: '10%' }),
      tableCellStyles: cellStyleBase({ width: '10%' }),
      Cell: ({ value }) => (
        <TextOverflowEllipsisBox>
          <VersionedValue values={value} />
        </TextOverflowEllipsisBox>
      ),
    },
    {
      Header: 'Value',
      accessor: 'value',
      tableHeaderStyles: headerStyleBase({ width: '10%', textAlign: 'right' }),
      tableCellStyles: cellStyleBase({ width: '10%', flexDirection: 'row-reverse' }),
      Cell: ({ value }) => (
        <TextOverflowEllipsisBox>
          <VersionedValue values={value} />
        </TextOverflowEllipsisBox>
      ),
    },
  ]

  if (!displayOwnership) {
    columns.splice(2, 1)
  }

  return columns
}

export const RealEstateTable = ({
  realAssetFinancials = [],
  displayOwnership = false,
}) => {
  const ownershipLabel = (asset) => {
    const {
      percentOwned: { owners },
    } = asset

    const contributingOwners = owners
      .filter((owner) => owner.percent > 0)
      .map((owner) => {
        return {
          name: owner.legalEntity.firstName || owner.legalEntity.businessName,
          percent: owner.percent,
        }
      })

    if (contributingOwners.length === 1) {
      return `${contributingOwners[0].name} ${contributingOwners[0].percent}%`
    }

    return contributingOwners.map((owner) => `${owner.percent}%`).join(' - ')
  }

  const tableData = realAssetFinancials
    .filter((asset) => asset.transaction !== 'Purchasing')
    .sort((a, b) => b.estimatedValue.value - a.estimatedValue.value)
    .reduce((tableDataSoFar, asset) => {
      const { address, propertyType, toBeUsedAsSecurity, estimatedValue } = asset

      const typeLabel = propertyType.propertyTypeName

      const addressLabel = buildAddress(
        {
          streetName: address?.standard?.streetName,
          streetNumber: address?.standard?.streetNumber,
          streetType: address?.standard?.streetType,
        },
        address?.suburb,
        address?.city,
        address?.countryISO,
        address?.standard?.unit,
        address?.newZealandPostCode
      )

      const datum = {
        address: addressLabel,
        type: typeLabel,
        security: toBeUsedAsSecurity,
        value: estimatedValue.value,
      }

      if (displayOwnership) {
        datum.ownership = ownershipLabel(asset)
      }

      return tableDataSoFar.concat([datum])
    }, [])

  const totalValue = realAssetFinancials
    .filter((asset) => asset.transaction !== 'Purchasing')
    .map((asset) => {
      const { value } = asset.estimatedValue
      const amount = value[value.length - 1]
      return formatStringToNumber(amount)
    })

    .reduce((total, assetValue) => {
      return total + assetValue
    }, 0)

  const totalSecurityValue = realAssetFinancials
    .filter((asset) => asset.transaction !== 'Purchasing')
    .filter(
      (asset) => asset.toBeUsedAsSecurity[asset.toBeUsedAsSecurity.length - 1] === 'Yes'
    )
    .map((asset) => {
      const { value } = asset.estimatedValue
      const amount = value[value.length - 1]
      return formatStringToNumber(amount)
    })
    .reduce((total, assetValue) => {
      return total + assetValue
    }, 0)

  const ownershipDescription = () => {
    return [
      ...new Set(
        realAssetFinancials
          .map((asset) => asset.percentOwned.owners)
          .flat()
          .filter((owner) => owner.percent > 0)
          .map((owner) => {
            return owner.legalEntity.businessName
              ? owner.legalEntity.businessName
              : `${owner.legalEntity.firstName} ${owner.legalEntity.lastName}`
          })
      ),
    ].join(' - ')
  }

  if (tableData.length === 0) {
    return (
      <>
        <h3 style={{ color: '#490B2D' }}>Real estate assets</h3>

        <p style={s('text-grey-600 font-semibold text-sm')}>No data found</p>
      </>
    )
  }

  return (
    <>
      <h3 style={{ color: '#490B2D' }}>Real estate assets</h3>

      <Table
        columns={tableColumns(displayOwnership)}
        data={tableData}
        tableHeight="initial"
        rootStyle={{ margin: 0 }}
      />

      <div style={{ textAlign: 'right' }}>
        <h5 style={{ color: '#490B2D' }}>
          Total value
          <span style={s('inline-block w-8')}>{formatCurrency(totalValue)}</span>
        </h5>

        <h5 style={{ color: '#490B2D' }}>
          Total security value
          <span style={s('inline-block w-8')}>
            {formatCurrency(totalSecurityValue)}
          </span>
        </h5>
      </div>

      {displayOwnership && (
        <span style={s('text-sm text-grey')}>
          *Ownership = {ownershipDescription()}
        </span>
      )}
    </>
  )
}
