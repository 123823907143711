/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Tooltip } from '@mui/material'
import { s } from '@vega/styled/v2'
import { formatDateString } from 'utils/formatters'

const headerStyleBase = (customStyles) =>
  s(
    'px-4 flex items-center text-grey-900 text-left text-sm font-semibold border-0 border-b-1 border-solid border-grey-200 h-4',
    {
      ...customStyles,
    }
  )

const cellStyleBase = (customStyles) =>
  s(
    'px-4 inline-block text-grey-900 text-sm text-left flex items-center border-0 border-b-1 border-solid border-grey-200',
    {
      ...customStyles,
    }
  )

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <Tooltip title={children}>
      <div
        style={s('flex items-center h-full', {
          overflow: 'hidden',
        })}
      >
        <span
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </span>
      </div>
    </Tooltip>
  )
}

export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Date & time',
        accessor: (row) => formatDateString(row.createdAt, '-', 'dd/MM/yyyy, h:mma'),
        tableHeaderStyles: headerStyleBase({ width: '25%' }),
        tableCellStyles: cellStyleBase({ width: '25%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Actions',
        accessor: (row) => row.description || '-',
        tableHeaderStyles: headerStyleBase({ width: '45%' }),
        tableCellStyles: cellStyleBase({ width: '45%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Users',
        accessor: (row) => row?.user?.name || '-',
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    []
  )
}

export const Rows = ({ activityLogs }) => {
  return React.useMemo(() => activityLogs, [activityLogs])
}
