import React, { useState } from 'react'
import { ExpenseTable } from './ExpenseTable'
import { AddExpenseForm } from './AddExpenseForm'
import { Button } from '@vega/components'
import { s } from '@vega/styled/v2'

export const UMIExpenseTable = ({
  householdRef,
  expenses,
  benchmarkLivingExpenditure
}) => {
  const [showAddForm, setShowAddForm] = useState(false)

  return (
    <>
      <div style={s('bg-white rounded-lg p-8 mb-4')}>
        <h3>Fixed commitments</h3>
        <ExpenseTable
          expenses={expenses}
          expenseType="fixed"
        />

        <h3>Essential living costs</h3>
        <ExpenseTable
          expenses={expenses}
          expenseType="essential"
          benchmarkLivingExpenditure={benchmarkLivingExpenditure}
        />

        <h3>Discretionary living costs</h3>
        <ExpenseTable
          expenses={expenses}
          expenseType="discretionary"
        />
      </div>

      {
        showAddForm && (
          <div style={s('bg-white rounded-lg p-8')}>
            <AddExpenseForm
              householdRef={householdRef}
              closeForm={() => {setShowAddForm(false)}}
            />
          </div>
        )
      }

      <h4 style={s('my-1')}>
        Add another expense item?
      </h4>
      <Button
        onClick={() => setShowAddForm(!showAddForm)}
        style={s('mb-16')}
      >
        Add expense
      </Button>
    </>
  )
}
