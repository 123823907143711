import { round } from 'mathjs'
import { isNilOrEmpty } from '@solta/ramda-extra'

const isNaN = (value) => Number.isNaN(Number(value))
const defaultToZero = (v) => {
  // Including cases where value is empty string
  return isNilOrEmpty(v) ? 0 : v
}

export const calculateTotalInterestRate = (baseInterestRate = 0, loading = 0) => {
  if (isNaN(baseInterestRate) || isNaN(loading)) return baseInterestRate

  return round(
    parseFloat(defaultToZero(baseInterestRate)) + parseFloat(defaultToZero(loading)),
    2
  )
}
