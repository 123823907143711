import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const {
  selectIsPending: selectIsFetchingPropertyDetails,
  selectHasFulfilled: selectHasFetchingPropertyDetailsFulfilled,
} = createAsyncThunkSelectors('property/getSecurityDetails')

const selectPropertiesState = prop('property')

const selectInspectedPropertyId = createSelector(
  selectPropertiesState,
  prop('inspectedPropertyId')
)

const selectSearchedProperties = createSelector(
  selectPropertiesState,
  prop('searchedProperties')
)

const selectSearchedProperty = (propertyId) =>
  createSelector(selectPropertiesState, ({ searchedProperties }) =>
    searchedProperties.find((value) => value.propertyId === propertyId)
  )

const selectTotal = createSelector(selectPropertiesState, prop('total'))

export {
  selectSearchedProperties,
  selectSearchedProperty,
  selectInspectedPropertyId,
  selectTotal,
}
