/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Tooltip } from '@mui/material'
import { s } from '@vega/styled/v2'
import { formatDateString } from 'utils/formatters'
import { Link } from 'react-router-dom'

const headerStyleBase = (customStyles) =>
  s(
    'px-4 flex items-center text-grey-900 text-left text-sm font-semibold border-0 border-b-1 border-solid border-grey-200 h-4',
    {
      ...customStyles,
    }
  )

const cellStyleBase = (customStyles) =>
  s(
    'px-4 inline-block text-grey-900 text-sm text-left flex items-center border-0 border-b-1 border-solid border-grey-200',
    {
      ...customStyles,
    }
  )

const TextOverflowEllipsisBoxTooltip = ({ children }) => {
  return (
    <Tooltip title={children}>
      <div
        style={s('flex items-center h-full', {
          flexGrow: '1',
          overflow: 'hidden',
          userSelect: 'none',
        })}
      >
        <span
          style={{
            flexGrow: '1',
            width: '0',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {children}
        </span>
      </div>
    </Tooltip>
  )
}

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
        userSelect: 'none',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

export const Columns = (loanApplicationId) => {
  return React.useMemo(
    () => [
      {
        Header: 'Date & time',
        accessor: (row) => formatDateString(row.createdAt, '-', 'dd/MM/yyyy, h:mma'),
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Section',
        accessor: (row) => row?.section || '-',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value }) => (
          <Link to={`/loan-applications/${loanApplicationId}/${value.toLowerCase()}`}>
            <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>
          </Link>
        ),
      },
      {
        Header: 'Notes',
        accessor: (row) => row?.content || '-',
        tableHeaderStyles: headerStyleBase({ width: '50%' }),
        tableCellStyles: cellStyleBase({ width: '50%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBoxTooltip>{value}</TextOverflowEllipsisBoxTooltip>
        ),
      },
      {
        Header: 'Users',
        accessor: (row) => row?.profileName || '-',
        tableHeaderStyles: headerStyleBase({ width: '20%' }),
        tableCellStyles: cellStyleBase({ width: '20%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
    ],
    [loanApplicationId]
  )
}

export const Rows = ({ notes }) => {
  return React.useMemo(() => notes, [notes])
}
