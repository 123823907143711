import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'
import { notificationService } from 'apiService'

export const listNotifications = createAsyncThunk(
  'notification/listNotifications',
  async (_, { rejectWithValue }) => {
    try {
      return await notificationService.listNotifications()
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateNotificationAsSeen = createAsyncThunk(
  'notification/updateNotificationAsSeen',
  async ({ notificationId, profileId }, { dispatch, rejectWithValue }) => {
    try {
      await notificationService.updateNotificationAsSeen(notificationId, profileId)

      return await dispatch(listNotifications())
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const notificationAdapter = createEntityAdapter()

const initialState = notificationAdapter.getInitialState()

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listNotifications.fulfilled, (state, { payload }) => {
        notificationAdapter.setAll(state, payload)
      })
      .addCase(updateNotificationAsSeen.fulfilled, (state, { payload }) => {
        notificationAdapter.updateOne(state, payload)
      })
  },
})

export const { reducer: notificationReducer } = notificationSlice
