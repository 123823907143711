import React from 'react'
import { append } from '@solta/ramda-extra'
import { s } from '@vega/styled/v2'
import { useFormikContext } from 'formik'
import { RecommendedLoanStructureBox } from './structurePreviewBox'
import { defaultLoanStructure } from './shared'

export const RecommendedLoanSection = ({ loanApplication, ...otherProps }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <div style={s('w-full')} {...otherProps}>
      {values.recommendedLoanStructures.map((recommendedLoan, idx) => {
        if (recommendedLoan?.isEditMode) return null

        const {
          structureId,
          recommendationReference,
          loanAmount,
          loanTermInYears,
          associatedBorrowers,
          guarantors,
          securityProperties,
        } = recommendedLoan

        const addRecommendedStructure = () => {
          setFieldValue(
            'loanStructures',
            append(
              {
                ...defaultLoanStructure,
                structureId,
                recommendationReference,
                loanAmount,
                loanTermInYears,
                associatedBorrowers,
                guarantors,
                securityProperties,
              },
              values.loanStructures
            )
          )

          setFieldValue(`recommendedLoanStructures.${idx}.isEditMode`, true)
        }

        return (
          <RecommendedLoanStructureBox
            key={recommendationReference}
            {...recommendedLoan}
            addRecommendedStructure={addRecommendedStructure}
            style={s('mb-8')}
          />
        )
      })}
    </div>
  )
}
