import React from 'react'
import { Modal } from '@vega/components'
import { ContentCard as ContentCardBase } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'

import { ReactComponent as CloseIconBase } from '@vega/components/src/assets/images/x.svg'
import { useDispatch } from 'react-redux'
import { deleteNote } from 'modules/note'

const ContentCard = styled(ContentCardBase)(s('p-6 pt-1', { width: 560, height: 195 }))

const CloseIcon = styled(CloseIconBase)(
  s('w-1 h-1 mt-5', {
    cursor: 'pointer',
  })
)

const CardTitle = styled.h3(s('mb-2 text-accent-red'))
const DescriptionText = styled.p(s('text-sm mt-0'))

const DeleteButton = styled.button(
  s(
    'border-transparent rounded-lg text-center w-full mt-8 py-2 bg-accent-red font-semibold',
    {
      cursor: 'pointer',
    }
  )
)
const CancelButton = styled.button(
  s('border-transparent bg-transparent text-right w-7 mt-8 py-2 pr-4 font-semibold ', {
    cursor: 'pointer',
  })
)

export const DeleteNoteModel = ({ isOpen, setIsOpen, note }) => {
  const close = () => setIsOpen(false)
  const dispatch = useDispatch()

  const removeNote = (note) => {
    dispatch(deleteNote({ id: note?.id, loanApplicationId: note?.loanApplicationId }))
    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ContentCard>
        <Row gutter={[0, 0]}>
          <Col span={22}>
            <CardTitle>Delete Note</CardTitle>
          </Col>
          <Col span={2}>
            <div style={s('flex flex-row justify-end')}>
              <CloseIcon onClick={close} />
            </div>
          </Col>
          <Col span={24}>
            <DescriptionText>
              Are you sure you want to delete this note?
            </DescriptionText>
          </Col>
          <Col span={19}>
            <div style={s('flex flex-row justify-end')}>
              <CancelButton onClick={close}>No, keep it</CancelButton>
            </div>
          </Col>

          <Col span={5}>
            <DeleteButton onClick={() => removeNote(note)}>Yes, delete it</DeleteButton>
          </Col>
        </Row>
      </ContentCard>
    </Modal>
  )
}
