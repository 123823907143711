import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { ContentCard } from 'features/loanAssessment/info/components'
import { PieChart } from './PieChart'
import { formatCurrency } from 'utils/formatters'
import { round } from 'mathjs'

const pieColors = [
  'rgba(60,175,165,1)',
  'rgba(135,207,145,1)',
  '#FAF54F',
  '#00FFCD',
  '#F9C2E8',
  '#F35A56',
  '#490B2D',
  '#D2D2B0',
  '#E4E6E6',
]

const Value = styled.div(s('mt-2 mb-2 text-primary w-full font-bold'), {
  fontSize: 31,
})

const Label = styled.div(
  s('text-sm font-normal text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

const mapAllocatedStructuresToChartData = (
  allocatedLoanStructures = [],
  totalRequestedLoanAmount
) =>
  allocatedLoanStructures.map((loanStructure, idx) => {
    return {
      label: `Loan Split ${idx + 1}`,
      frequency: round(loanStructure?.loanAmount / totalRequestedLoanAmount, 2) * 100,
      color: pieColors[idx] || getRandomColor(),
    }
  })

const getUnallocatedLoanPercentageChartData = (
  totalRequestedLoanAmount = 0,
  totalAllocatedLoanAmount = 0
) => {
  const unallocatedLoanAmount = totalRequestedLoanAmount - totalAllocatedLoanAmount

  return {
    label: `Unallocated Loan Amount`,
    frequency: round(unallocatedLoanAmount / totalRequestedLoanAmount, 2) * 100,
    color: s('text-grey-400').color,
  }
}

export const LoanSummaryChart = ({
  allocatedLoanStructures = [],
  totalRequestedLoanAmount,
  totalAllocatedLoanAmount,
}) => {
  const allocatedStructuresChartData = mapAllocatedStructuresToChartData(
    allocatedLoanStructures,
    totalRequestedLoanAmount
  )
  const chartData = [
    getUnallocatedLoanPercentageChartData(
      totalRequestedLoanAmount,
      totalAllocatedLoanAmount
    ),
    ...allocatedStructuresChartData,
  ]

  return (
    <div style={s('w-full mb-16')}>
      <ContentCard>
        <Row>
          <Col span={4}>
            <Row>
              <Col>
                <Label>Total Loan Requested</Label>
                <Value> {formatCurrency(totalRequestedLoanAmount)}</Value>
              </Col>
            </Row>
          </Col>

          <Col span={16} style={s('ml-32')}>
            <PieChart chartData={chartData} />
          </Col>
        </Row>
      </ContentCard>
    </div>
  )
}
