import BaseService from '../baseService'

export class AffordabilityAssessmentService extends BaseService {
  readAssessments(versionedLoanApplicationId) {
    return this.client
      .get(`affordability-assessments/${versionedLoanApplicationId}`)
      .json()
  }

  conductAssessment(loanApplicationId) {
    const config = { json: { loanApplicationId }, timeout: false }

    return this.client.post('affordability-assessments', config).json()
  }

  updateAssessment(versionedLoanApplicationId, assessmentReference, acitivityLogId) {
    const config = {
      json: { versionedLoanApplicationId, assessmentReference, acitivityLogId },
      timeout: false,
    }
    return this.client.put('affordability-assessments', config).json()
  }

  retrieveFinancialsData(versionedLoanApplicationId, legalEntityRef) {
    const config = {
      json: { versionedLoanApplicationId, legalEntityRef },
    }

    return this.client
      .post(`affordability-assessments/request-financials-data`, config)
      .json()
  }

  async exportUMIReport(assessmentReference) {
    const buffer = await this.client
      .get(`affordability-assessments/request-UMI-Export-data/${assessmentReference}`)
      .arrayBuffer()
    convertAndSave('UMI Report', buffer)
  }

  async readFinancialData(assessmentReference) {
    if (!assessmentReference) throw new Error('Invalid assessmentReference')

    return this.client
      .get(`affordability-assessments/read-financial-data/${assessmentReference}`)
      .json()
  }
}

function convertAndSave(reportName, contentInByte) {
  const blob = new Blob([contentInByte], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = reportName
  link.download = fileName
  link.click()
}
