/* eslint-disable complexity */
import React from 'react'
import { styled, s } from '@vega/styled/v2'
// TODO: Insert values into preferences endpoint
const getColourScheme = (lastNameFirstChar) => {
  const letterList = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]

  const alphabeticalNumber = letterList.indexOf(lastNameFirstChar)
  switch (true) {
    case alphabeticalNumber <= 5:
      return 'colourScheme1'

    case alphabeticalNumber <= 11:
      return 'colourScheme2'

    case alphabeticalNumber <= 17:
      return 'colourScheme3'

    case alphabeticalNumber <= 19:
      return 'colourScheme4'

    case alphabeticalNumber <= 23:
      return 'colourScheme5'

    default:
      return 'colourScheme6'
  }
}

const pickBackgroundColour = (colourScheme) => {
  switch (colourScheme) {
    case 'colourScheme1':
      return '#F1F1E8'
    case 'colourScheme2':
      return '#490B2D'
    case 'colourScheme3':
      return '#00FFCD'
    case 'colourScheme4':
      return '#F9C2E8'
    case 'colourScheme5':
      return '#F35A56'
    default:
      return '#FAF54F'
  }
}

const pickFontColour = (colorScheme) =>
  colorScheme === 'colourScheme2' ? '#FBFBFB' : '#490B2D'

export const ProfileIcon = ({ fullName }) => {
  const nameList = fullName.split(' ')
  const colourScheme = getColourScheme(nameList?.[1]?.charAt(0)?.toUpperCase())

  const LetterContainer = styled.div(
    s('rounded-3xl w-2 mr-4 h-2 font-medium text-center text-xl', {
      backgroundColor: pickBackgroundColour(colourScheme),
      color: pickFontColour(colourScheme),
    })
  )
  return <LetterContainer>{nameList?.[0]?.charAt(0)?.toUpperCase()}</LetterContainer>
}
