import React from 'react'
import { s, styled } from '@vega/styled/v2'

const Container = styled.div(
  s('w-full flex items-center mb-1', {
    cursor: 'pointer',
    height: 40,
    ':hover': { backgroundColor: '#F2F3F2' },
  })
)
const Text = styled.a(
  s('w-full pl-4 pr-3 text-base font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
    textDecoration: 'none',
  }),
  ({ isActive }) => isActive && s('font-semibold', { color: '#141616' })
)
const ActiveBar = styled.div(
  s('h-full', { padding: 1 }),
  ({ isActive }) => isActive && s('bg-secondary')
)

const SideNavigationReportItem = ({ isActive, link, name, ...props }) => {
  return (
    <Container {...props}>
      <ActiveBar isActive={isActive} />
      <Text isActive={isActive}>{name}</Text>
    </Container>
  )
}

export { SideNavigationReportItem }
