/**
 * @typedef {import('modules/application/types').AssessmentStatus} AssessmentStatus
 */

export const ASSESSMENT_URI_TYPE = {
  ANALYTICAL_DASHBOARD: 'analyticalDashboard',
  BANK_STATEMENT_RETRIEVAL: 'bankStatementRetrieval',
}

export const ASSESSMENT_STATUS = {
  CONDUCTED: 'conducted',
  PENDING: 'pending',
  RETRY_REQUIRED: 'retry_required',
  FINANCIALS_UPDATED: 'financials_updated',
}
