import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
} from '../components'
import { TEST_IDS } from '@neo/constants'

const { MYCRM_ADVISER_NOTES } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const Text = styled.p(s('my-0', { whiteSpace: 'pre-line' }))
const Title = styled.p(s('my-0 text-primary text-xs'))

export const AdviserNotes = ({ adviserNotes = [], ...props }) => (
  <Container id={MYCRM_ADVISER_NOTES} {...props}>
    <SectionTitle title="MyCRM Adviser Notes" />

    <ContentCard>
      {adviserNotes?.map(({ comment, title = '' }, index) => {
        const isNotFirstNote = index !== 0

        return (
          <div key={index}>
            <Title style={s(isNotFirstNote ? 'mt-4' : '')}>
              {title?.toUpperCase()}
            </Title>

            <Text
              data-test-id={`${TEST_IDS.NOTE_INFO}${index}`}
              style={{ userSelect: 'none' }}
            >
              {comment}
            </Text>
          </div>
        )
      })}
    </ContentCard>
  </Container>
)
