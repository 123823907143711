import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class RepaymentService extends BaseService {
  async calculateRepayment(
    productId,
    loanAmount,
    loanPeriodInMonths,
    repaymentRecurrence,
    interestRateModifier
  ) {
    const searchParams = QueryBuilder()
      .setParam('productId', productId)
      .setParam('loanAmount', loanAmount)
      .setParam('loanPeriodInMonths', loanPeriodInMonths)
      .setParam('repaymentRecurrence', repaymentRecurrence)
      .setParam('interestRateModifier', interestRateModifier)
      .build()

    return this.client.get(`calculations/repayment?${searchParams}`).json()
  }
}
