/* eslint-disable complexity */
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { routes } from './routes'
import {
  Info,
  Financial,
  Serviceability,
  Structure,
  Security,
  Offer,
  SecurityDetails,
  SettlementForm,
  ValocityReport,
} from 'features/loanAssessment'
import { TopNav } from 'features/loanAssessment/AssessmentTopNav'
import { LoanAssignmentHeader } from '../features/loanAssessment/LoanAssignmentHeader'


const Root = styled.div(s('flex flex-column h-full w-full bg-primary-background'), {
  msOverflowStyle: 'none',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})
const ContentContainer = styled.div(
  s('px-4 ', {
    paddingTop: 52,
    paddingBottom: 52,
  })
)
const Content = styled.div(s('h-full'))

export const LoanAssessmentRouter = () => {
  return (
    <Root id="router-root">
      <TopNav />
      <LoanAssignmentHeader />

      <ContentContainer>
        <Row justify="center" style={s('h-full')}>
          <Col span={24} style={s('h-full')}>
            <Content>
              <Switch>
                <Route
                  exact
                  path={routes.loanApp.assessment.info}
                  component={Info}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.financial}
                  component={Financial}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.offer}
                  component={Offer}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.serviceability}
                  component={Serviceability}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.structure}
                  component={Structure}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.security.root}
                  component={Security}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.security.details}
                  component={SecurityDetails}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.security.ivalReport}
                  component={ValocityReport}
                />
                <Route
                  exact
                  path={routes.loanApp.assessment.settlement}
                  component={SettlementForm}
                />
                <Redirect to={routes.loanApp.assessment.info} />
              </Switch>
            </Content>
          </Col>
        </Row>
      </ContentContainer>
    </Root>
  )
}
