import React from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DataDisplay } from '../components'
import { TEST_IDS } from '@neo/constants'

const Container = styled.div()
const Heading = styled.div(
  s('mb-2 text-xs text-primary font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const AddressInfo = ({
  address,
  duration,
  endDate,
  index = 0,
  startDate,
  status,
  type,
  applicantIndex,
  ...props
}) => {
  const title = type === 'mailingAddress' ? 'POSTAL ADDRESS' : `ADDRESS ${index + 1}`

  return (
    <Container {...props}>
      <Heading>{title}</Heading>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DataDisplay
            label="Address"
            value={address}
            testId={`${TEST_IDS.APPLICANT_ADDRESS}`}
          />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Start Date"
            value={startDate}
            testId={`${TEST_IDS.APPLICANT_START_DATE}`}
          />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="End Date"
            value={endDate}
            testId={`${TEST_IDS.APPLICANT_END_DATE}`}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Duration" value={duration} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Status"
            value={status}
            testId={`${TEST_IDS.APPLICANT_STATUS}`}
          />
        </Col>
      </Row>
    </Container>
  )
}

export { AddressInfo }
