/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react'
import { noop } from '@solta/ramda-extra'
import { s, styled } from '@vega/styled/v2'
import { Col, Row, Container } from '@vega/styled'
import { Button } from '@vega/components'
import { ContentCard } from 'features/loanAssessment/info/components'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'
import { formatCurrency } from 'utils/formatters'

const Title = styled.p(s('text-grey-900 font-bold'))

const Description = styled.p(s('text-grey-900'))

const InfoBox = ({ label, text, ...otherProps }) => {
  return (
    <div style={s('bg-grey-200 rounded-md p-8')} {...otherProps}>
      <p style={s('m-0 mb-2 text-grey-600')}>{label}</p>
      <p style={s('m-0 text-2xl text-grey-900 font-bold')}>{text}</p>
    </div>
  )
}

const Label = styled.span(s('text-grey-600'))
const Value = styled.span(s('text-grey-900 font-bold'))

const ViewDetails = ({ associatedBorrowers = [], guarantors = [] }) => {
  const [isExpanded, setExpanded] = useState(false)

  const entities = [...associatedBorrowers, ...guarantors]

  return (
    <div style={s('bg-primary-background px-6 py-5')}>
      {isExpanded ? (
        <Container fluid>
          <Row style={s('mb-5')} gutter={[0, 100]}>
            <Col style={s('mb-4')}>
              <Row style={s('mb-2')}>
                <Col>
                  <Label>Primary Applicant</Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Value>{associatedBorrowers[0].fullName || '-'}</Value>
                </Col>
              </Row>
            </Col>

            <Col>
              <Row style={s('mb-2')}>
                <Col>
                  <Label>Entities</Label>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Value>{entities.map((e) => e?.fullName).join(', ')}</Value>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row justify="center">
            <Button
              onClick={() => setExpanded(false)}
              variant="text"
              EndIcon={<ChevronUp width={18} height={20} style={s('ml-1')} />}
            >
              Collapse
            </Button>
          </Row>
        </Container>
      ) : (
        <Container style={s('flex justify-center')}>
          <Button
            onClick={() => setExpanded(true)}
            variant="text"
            EndIcon={<ChevronDown width={18} height={20} style={s('ml-1')} />}
          >
            View Details
          </Button>
        </Container>
      )}
    </div>
  )
}

export const RecommendedLoanStructureBox = ({
  product,
  loanTermInYears,
  loanAmount,
  associatedBorrowers,
  guarantors,
  addRecommendedStructure = noop,
  ...otherProps
}) => {
  return (
    <ContentCard {...otherProps}>
      <Row>
        <Col span={24}>
          <Title>Recommended from MyCRM</Title>
        </Col>

        <Col span={24}>
          <Description>
            Product: <strong>{product || 'No product selected'}</strong> ,{' '}
            <strong>{loanTermInYears || '()'} years</strong> for{' '}
            <strong>{associatedBorrowers.length || 0} borrower(s)</strong> and{' '}
            <strong>{guarantors.length || 0} guarantor(s)</strong>
          </Description>
        </Col>

        <Col span={24}>
          <ViewDetails
            associatedBorrowers={associatedBorrowers}
            guarantors={guarantors}
          />
        </Col>

        <Col span={24} style={s('my-6')}>
          <div style={s('border-solid border-0 border-t-2 border-grey-200')} />
        </Col>

        <Col span={24} style={s('mb-4')}>
          <Row>
            <Col span={12} xl={6}>
              <InfoBox label={'Total Loan Amount'} text={formatCurrency(loanAmount)} />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={s('flex justify-end')}>
          <Button type="button" onClick={addRecommendedStructure}>
            Add Loan Split
          </Button>
        </Col>
      </Row>
    </ContentCard>
  )
}
