import { isNilOrEmpty } from '@solta/ramda-extra'
import {
  capitaliseFirstLetter,
  formatDateString,
  buildDuration,
  formatFullName,
  buildAddress,
  capitaliseFirstLetterOfAllWords,
} from 'utils'

export const mapToAdviserNotes = (comments = []) => {
  const adviserNoteComment = comments.find(({ contextDescription }) =>
    contextDescription.includes('Advisor Notes - ')
  )
  const specificGoalsAndObjectives = comments.find(
    ({ contextDescription }) => contextDescription === 'Immediate goals & objectives'
  )

  if (isNilOrEmpty(adviserNoteComment && isNilOrEmpty(specificGoalsAndObjectives)))
    return [{ comment: 'No notes to display' }]

  const { comment: clientComment, contextDescription } = adviserNoteComment

  const adviserNote = {
    title: 'Additional Info / Notes to Lender',
    comment: contextDescription.substring(16),
  }

  const clientBackground = {
    title: 'Client Background / Character',
    comment: clientComment,
  }

  const specificGoals = {
    title: 'Specific Goals / Objectives',
    comment: specificGoalsAndObjectives?.comment,
  }

  return [adviserNote, clientBackground, specificGoals]
}

const formatAddresses = (addresses = []) =>
  addresses?.map(
    ({
      duration: { length, units },
      endDate,
      housingStatus,
      startDate,
      fullAddress,
      type = 'address',
    }) => {
      return {
        address: fullAddress || '-',
        duration: buildDuration(length, units),
        endDate: endDate === 'present' ? 'Present' : formatDateString(endDate),
        startDate: formatDateString(startDate),
        status: capitaliseFirstLetterOfAllWords(housingStatus),
        type,
      }
    }
  )

/** @param {Individual[]} individuals */
export const mapToApplicants = (individuals = []) =>
  individuals?.map(
    ({
      contact,
      dateOfBirth,
      entityId,
      isPrimaryApplicant,
      maritalStatus,
      personName,
      applicantRole,
      residencyStatus,
      guarantees = {},
      propertyId,
      externalRef,
      versionedState,
      ...others
    }) => {
      const {
        currentAddress = {},
        currentMailingAddress = {},
        email,
        phone,
        mobile,
        previousAddresses = [],
      } = contact

      const { firstName, nameTitle, surname: lastName, middleNames } = personName

      const addresses = [currentAddress]

      const { securityGuarantee, servicingGuarantee } = guarantees

      if (previousAddresses.length !== 0)
        previousAddresses.forEach((address) => addresses.push(address))

      addresses.push({ type: 'mailingAddress', ...currentMailingAddress })

      return {
        ...others,
        addresses: formatAddresses(addresses),
        applicantRole,
        dob: formatDateString(dateOfBirth),
        email,
        mobile,
        entityId,
        fullName: formatFullName({ nameTitle, firstName, middleNames, lastName }),
        personName,
        isPrimaryApplicant,
        maritalStatus: capitaliseFirstLetter(maritalStatus),
        phone,
        residencyStatus,
        versionedState,
        securityGuarantee,
        servicingGuarantee,
        propertyId,
        externalRef,
      }
    }
  )

export const mapToBusinesses = (companies = []) =>
  companies?.map(
    ({
      applicantRole,
      businessStructure,
      contact: { address },
      NZBN,
      directors,
      fullName,
      versionedState,
    }) => {
      const {
        city,
        countryISO,
        fullAddress,
        newZealandPostCode,
        standard: { streetName, streetNumber, streetType, unit },
        suburb,
      } = address

      const formattedAddress = buildAddress(
        { streetName, streetNumber, streetType },
        suburb,
        city,
        countryISO,
        unit,
        newZealandPostCode,
        fullAddress
      )

      return {
        address: formattedAddress,
        name: fullName,
        nzbn: NZBN,
        structure: businessStructure,
        type: applicantRole,
        directors,
        versionedState,
      }
    }
  )

export const mapToVersionedStatedEntities = (versionedApplication, entityType) => {
  const addVersionedState = (entity, stateToAdd) => ({
    ...entity,
    versionedState: stateToAdd,
  })
  const versions = versionedApplication?.versions ? versionedApplication.versions : []

  const currentEntities = versions
    .filter((version) => version.applicationId === versionedApplication.latestVersionId)
    .map((version) => version.application.legalEntities[entityType])
    .flat()

  // First versions are always unchanged, early bail out
  if (versions.length === 1) {
    return currentEntities.map((entity) => addVersionedState(entity, 'unchanged'))
  }

  const sortedPreviousEntities = versions
    .filter((version) => version.applicationId !== versionedApplication.latestVersionId)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((version) => version.application.legalEntities[entityType])
    .flat()

  const uniquePreviousEntityRefs = [
    ...new Set(sortedPreviousEntities.map((entity) => entity.externalRef)),
  ]

  const previousEntites = uniquePreviousEntityRefs.map((ref) => {
    return sortedPreviousEntities.findLast((entity) => entity.externalRef === ref)
  })

  const currentEntityRefs = currentEntities.map((entity) => entity.externalRef)

  const removedEntities = previousEntites.filter(
    (previousEntity) => !currentEntityRefs.includes(previousEntity.externalRef)
  )

  const addedEntities = currentEntities.filter(
    (entity) => !uniquePreviousEntityRefs.includes(entity.externalRef)
  )

  const addedEntityRefs = addedEntities.map((entity) => entity.externalRef)

  const unchangedEntities = currentEntities.filter(
    (entity) => !addedEntityRefs.includes(entity.externalRef)
  )

  return []
    .concat(unchangedEntities.map((entity) => addVersionedState(entity, 'unchanged')))
    .concat(addedEntities.map((entity) => addVersionedState(entity, 'added')))
    .concat(removedEntities.map((entity) => addVersionedState(entity, 'removed')))
}

export const getPreviousEntities = (versionedApplication, entityType) => {
  const versions = versionedApplication?.versions ? versionedApplication.versions : []

  const sortedPreviousEntities = versions
    .filter((version) => version.applicationId !== versionedApplication.latestVersionId)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((version) => version.application.legalEntities[entityType])
    .flat()

  const uniquePreviousEntityRefs = [
    ...new Set(sortedPreviousEntities.map((entity) => entity.externalRef)),
  ]

  const previousEntites = uniquePreviousEntityRefs.map((ref) => {
    return sortedPreviousEntities.findLast((entity) => entity.externalRef === ref)
  })

  return previousEntites
}
