import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Col, Row } from '@vega/styled'
import { Button, NumberField, SelectField } from '@vega/components'
import { Formik as FormProvider, Form } from 'formik'
import { s } from '@vega/styled/v2'
import { createUmiReportItem } from 'modules/umiReportItems'

export const AddExpenseForm = ({
  householdRef,
  closeForm
}) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleSubmit = (newExpense) => {
    dispatch(
      createUmiReportItem({
        versionedLoanApplicationId: id,
        umiReportItem: newExpense
      })
    )

    closeForm()
  }

  const initialValues = {
    versionedLoanApplicationId: id,
    householdRef,
    umiRecordType: 'umiExpense',
    details: {
      expenseType: 'fixed',
      expenseCategory: '',
      frequency: 'monthly',
      validatedAmount: 0,
      declaredAmount: 0
    }
  }

  const fixedExpenseCategories = [
    { value: 'rent', label: 'Rent / board' },
    { value: 'childSupport', label: 'Child/support maintenance' },
    { value: 'propertyOwnershipPrimary', label: 'Property owernship costs (primary)' },
    { value: 'propertyOwnershipSecondary', label: 'Property owernship costs (secondary)' },
    { value: 'superannuation', label: 'Superannuation' },
    { value: 'personalInsurances', label: 'Personal insurances' },
    { value: 'other', label: 'Other' },
  ]

  const essentialExpenseCategories = [
    { value: 'food', label: 'Food and groceries' },
    { value: 'transport', label: 'Transport / vehicle running costs / gas' },
    { value: 'power', label: 'Power' },
    { value: 'water', label: 'Water' },
    { value: 'internet', label: 'Internet' },
    { value: 'phone', label: 'Phone' },
    { value: 'education', label: 'Education / childcare' },
    { value: 'medical', label: 'Required medical costs' },
    { value: 'other', label: 'Other' },
  ]

  const discretionaryExpenseCategories = [
    { value: 'privateEducation', label: 'Private school / education' },
    { value: 'donnations', label: 'Regular donnations' },
    { value: 'other', label: 'Other regular discretionary living costs' },
  ]

  const expenseCategories = {
    fixed: fixedExpenseCategories,
    essential: essentialExpenseCategories,
    discretionary: discretionaryExpenseCategories,
  }

  return (
    <FormProvider
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, ...props }) => (
        <Form onSubmit={props.handleSubmit}>
          <h4>Add expense item</h4>

          <Row style={s('mb-4')}>
            <Col span={12}>
              <SelectField
                name="details.expenseType"
                label="Expense category"
                aria-label="Expense category"
                options={[
                  { label: 'Fixed living costs', value: 'fixed' },
                  { label: 'Essential living costs', value: 'essential' },
                  { label: 'Discretionary living costs', value: 'discretionary' },
                ]}
              />
            </Col>
          </Row>

          <Row style={s('mb-4')}>
            <Col span={12}>
              <SelectField
                name="details.expenseCategory"
                label="Expense type"
                placeholder="Expense type"
                aria-label="Expense type"
                options={
                 expenseCategories[values.details.expenseType]
                }
              />
            </Col>

            <Col span={12}>
              <SelectField
                name="details.frequency"
                label="Frequency"
                aria-label="Frequency"
                options={[
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Fortnightly', value: 'fortnightly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Quarterly', value: 'quarterly' },
                  { label: 'Half-yearly', value: 'halfYearly' },
                  { label: 'Annually', value: 'annually' },
                ]}
              />
            </Col>
          </Row>

          <Row style={s('mb-4')}>
            <Col span={24}>
              <NumberField
                name="details.validatedAmount"
                label="Amount"
                placeholder=" Amount"
                prefix="$"
              />
            </Col>
          </Row>

          <div style={s('mt-4 flex', {flexDirection: 'row-reverse'})}>
            <Button
              type="submit"
              style={s('ml-2')}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              onClick={() => closeForm()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )
    }

    </FormProvider>
  )
}
