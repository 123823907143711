import React from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { round } from 'mathjs'
import { s, styled } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { FormMessage, NumberField } from '@vega/components'
import { useFormikContext } from 'formik'
import { isNotNilOrEmpty } from 'ramda-adjunct'

const Container = styled.div(s('bg-primary-background rounded-lg p-6'))
const Title = styled.h3(s('m-0 text-primary text-xl font-bold'))
const Label = styled.span(s('text-grey-900'))
const Value = styled.span(s('text-grey-900 font-bold'))
const Pill = styled.div(s('py-3 px-4 bg-secondary rounded-full'))

const defaultToZero = (v) => {
  // Including cases where value is empty string
  return isNilOrEmpty(v) ? 0 : v
}

const getPropertyDetails = (securityProperties = []) => {
  let totalAllocatedPercentage = 0

  // eslint-disable-next-line no-unused-expressions
  securityProperties?.forEach((property) => {
    totalAllocatedPercentage +=
      parseFloat(defaultToZero(property?.allocationPercentage)) || 0
  })

  return {
    totalAllocatedPercentage: round(totalAllocatedPercentage, 2),
  }
}

export const SecurityConfiguration = ({ idx, fieldPrefix }) => {
  const { values, errors } = useFormikContext()

  const securityProperties = values?.loanStructures?.[idx]?.securityProperties || []

  const { totalAllocatedPercentage } = getPropertyDetails(securityProperties)

  const unallocatedSecurityPropertyError =
    errors?.loanStructures?.[idx]?.customErrors?.unallocatedSecurityProperty

  return (
    <Container>
      <Row>
        <Col>
          <Title>Security Configuration</Title>
        </Col>
      </Row>

      <Row style={s('mb-8')}>
        <Col span={20} style={s('flex justify-end')}>
          <Label>Security Type</Label>
        </Col>

        <Col span={4} style={s('flex justify-end')}>
          <Label>Allocation (%)</Label>
        </Col>
      </Row>

      {securityProperties.map(({ id, address }, securityIdx) => (
        <Row
          key={id}
          style={s('py-4 flex items-center', {
            borderBottom: '1px solid #E4E6E6',
          })}
        >
          <Col span={16}>
            <Value>{address}</Value>
          </Col>

          <Col span={4} style={s('flex justify-end')}>
            <Pill>
              <span>Property</span>
            </Pill>
          </Col>

          <Col span={4} style={s('flex justify-end items-center')}>
            <NumberField
              name={`${fieldPrefix}.securityProperties.${securityIdx}.allocationPercentage`}
              placeholder={0}
              containerStyle={s('w-6 mr-2')}
              inputContainerStyle={s('bg-white')}
            />{' '}
            <span style={s('text-grey-900')}>%</span>
          </Col>
        </Row>
      ))}

      <Row
        style={s('py-6 flex items-center', {
          borderBottom: '1px solid #E4E6E6',
        })}
      >
        <Col span={20} style={s('flex')}>
          <Value>Total</Value>
        </Col>

        <Col span={4} style={s('flex justify-end')}>
          <Value style={s('mr-4')}>{totalAllocatedPercentage}</Value>
          <span style={s('text-grey-900')}>%</span>
        </Col>
      </Row>

      <Row style={s('mt-2')}>
        <Col span={24} style={s('flex justify-end')}>
          <FormMessage
            message={unallocatedSecurityPropertyError}
            visible={isNotNilOrEmpty(unallocatedSecurityPropertyError)}
          />
        </Col>
      </Row>
    </Container>
  )
}
