import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { s, styled } from '@vega/styled/v2'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from '@vega/styled'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { ReactComponent as LinkIcon } from '@vega/components/src/assets/images/link.svg'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { Button, Loading, toast } from '@vega/components'
import { NoteSection } from 'features/common/NoteSection'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import { UMIDashboard } from './UMIDashboard'
import { UMISections } from './UMISections'
import { dashboardResults } from './utils'
import {
  fetchUmiReportItems,
  selectAllUmiReportItems,
  refreshUmiReportItems,
  selectIsFetchingUMIReport,
  selectIsRefreshingUMIReport,
  selectRefreshUMIReportError,
} from 'modules/umiReportItems'
import {
  fetchAffordabilityAssessments,
  selectAllAffordabilityAssessments,
} from 'modules/affordabilityAssessment'
import { XaiConnections } from '../info/xaiConnections'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

/* eslint-disable complexity */
export const Serviceability = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const umiReportItems = useSelector(selectAllUmiReportItems)
  const versionedApplication = useSelector(selectApplicationById(id))
  const affordabilityAssessments = useSelector(selectAllAffordabilityAssessments)
  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const { legalEntities = [] } = latestApplicationVersion
  const { legalEntities: { individuals = [] } } = latestApplicationVersion

  const [incomes, setIncomes] = useState([])
  const [expenses, setExpenses] = useState([])
  const [liabilities, setLiabilities] = useState([])
  const [householdStructures, setHouseholdStructures] = useState([])

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const isFetchingUMIReport = useSelector(selectIsFetchingUMIReport)

  const isRefreshingUmiReport = useSelector(selectIsRefreshingUMIReport)
  const refreshingUmiReportError = useSelector(selectRefreshUMIReportError)

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
    dispatch(fetchAffordabilityAssessments(id))
  }, [dispatch, id])

  useEffect(() => {
    if (refreshingUmiReportError && !isFetchingUMIReport) {
      toast('Failed to generate/refresh UMI records')
    }
  }, [refreshingUmiReportError, isFetchingUMIReport])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const umiIncomeRecords = umiReportItems.filter(
      (record) => record.umiRecordType === 'umiIncome'
    )

    const umiExpenseRecords = umiReportItems.filter(
      (record) => record.umiRecordType === 'umiExpense'
    )

    const umiLiabilityRecords = umiReportItems.filter(
      (record) => record.umiRecordType === 'umiLiability'
    )

    const umiStructureRecords = umiReportItems.filter(
      (record) => record.umiRecordType === 'umiHouseholdStructure'
    )

    setIncomes(umiIncomeRecords)
    setExpenses(umiExpenseRecords)
    setLiabilities(umiLiabilityRecords)
    setHouseholdStructures(umiStructureRecords)
  }, [umiReportItems])

  useEffect(() => {
    dispatch(fetchUmiReportItems({ versionedLoanApplicationId: id }))
  }, [dispatch, id])

  if (isFetchingApplication) {
    return <Loading />
  }

  if (umiReportItems.length === 0) {
    return (
      <div style={s('px-24 mx-8 ml-24')}>
        <h1>No serviceability records genenerated yet</h1>
        <p>Once Xai data have been connected please click below:</p>

        <Button
          onClick={() =>
            dispatch(refreshUmiReportItems({ versionedLoanApplicationId: id }))
          }
          loading={isRefreshingUmiReport}
          style={s('mb-16')}
        >
          Refresh UMI data
        </Button>

        <div style={s('mx-6')}>
          <XaiConnections
            versionedLoanApplication={versionedApplication}
            affordabilityAssessments={affordabilityAssessments}
            individuals={individuals}
            loanApplicationId={latestVersionId}
          />
        </div>
      </div>
    )
  }

  const borrowerHouseholdRefs = [
    ...new Set(
      legalEntities.individuals
        .filter((entity) => entity.applicantRole === 'Borrower')
        .map((entity) => entity.household.externalRef)
    ),
  ]

  const guarantorHouseholdRefs = [
    ...new Set(
      legalEntities.individuals
        .filter((entity) => entity.applicantRole === 'Guarantor')
        .map((entity) => entity.household.externalRef)
    ),
  ]

  const umiItemsForHousehold = (umiItems, householdRef) =>
    umiItems.filter((umiItem) => umiItem.householdRef === householdRef)

  const titleFor = (householdRef) => {
    return legalEntities.individuals
      .filter((entity) => entity.household.externalRef === householdRef)
      .map((entity) => entity.fullName)
      .join(' & ')
  }

  const xaiLinks = affordabilityAssessments.flatMap((affordabilityAssessment) =>
    affordabilityAssessment.uris.find((uri) => uri.name === 'analyticalDashboard')?.value
  )

  const xaiButtons = xaiLinks.map((xaiLink, index) => (
    <Button
      key={index}
      StartIcon={<LinkIcon style={s('mr-1')} />}
      style={{ height: 'fit-content' }}
      variant="text"
      onClick={() => window.open(xaiLink)}
    >
      View Xai {index === 0 ? '' : index}
    </Button>
  ))

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/security`)}
      >
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      <div style={s('mx-24 mb-10 flex')}>
        <h1 style={s('font-semibold mb-10', { flexGrow: 1 })} className="sohne">
          Loan Serviceability
        </h1>

        <Button
          style={s('mr-1', { height: 'fit-content' })}
          onClick={() =>
            dispatch(refreshUmiReportItems({ versionedLoanApplicationId: id }))
          }
          loading={isRefreshingUmiReport}
        >
          Refresh UMI data
        </Button>

        {xaiButtons}
      </div>

      <div style={s('px-24 mb-4')}>
        <UMIDashboard
          dashboardResults={dashboardResults({
            incomes,
            expenses,
            liabilities,
            structures: borrowerHouseholdRefs?.flatMap((householdRef) => {
              return umiItemsForHousehold(householdStructures, householdRef)
            }),
          })}
        />
      </div>

      {borrowerHouseholdRefs.map((householdRef) => (
        <React.Fragment key={householdRef}>
          <Row style={s('px-24')}>
            <Col span={24}>
              <h2 style={{ color: '#490B2D' }}>
                <span style={s('text-grey-600 block text-sm font-semibold')}>
                  Joint Applicant/s serviceability
                </span>
                {titleFor(householdRef)}
              </h2>
              <UMISections
                householdRef={householdRef}
                incomes={umiItemsForHousehold(incomes, householdRef)}
                expenses={umiItemsForHousehold(expenses, householdRef)}
                liabilities={umiItemsForHousehold(liabilities, householdRef)}
                structures={umiItemsForHousehold(householdStructures, householdRef)}
              />
            </Col>
          </Row>

          <div style={s('mb-4 px-24')}>
            <NoteSection
              id="note-section"
              versionedLoanApplicationId={id}
              loanApplicationId={latestVersionId}
              source={`loan-applications/serviceability/${householdRef}`}
            />
          </div>
        </React.Fragment>
      ))}

      {guarantorHouseholdRefs.map((householdRef) => (
        <React.Fragment key={householdRef}>
          <Row style={s('px-24')}>
            <Col span={24}>
              <h2 style={{ color: '#490B2D' }}>
                <span style={s('text-grey-600 block text-sm font-semibold')}>
                  Guarantor/s serviceability
                </span>
                {titleFor(householdRef)}
              </h2>
              <UMISections
                householdRef={householdRef}
                incomes={umiItemsForHousehold(incomes, householdRef)}
                expenses={umiItemsForHousehold(expenses, householdRef)}
                liabilities={umiItemsForHousehold(liabilities, householdRef)}
                structures={umiItemsForHousehold(householdStructures, householdRef)}
              />
            </Col>
          </Row>

          <div style={s('mb-4 px-24')}>
            <NoteSection
              id="note-section"
              versionedLoanApplicationId={id}
              loanApplicationId={latestVersionId}
              source={`loan-applications/serviceability/${householdRef}`}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
