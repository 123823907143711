import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { googleService } from 'apiService'
import { normalizeError } from '@vega/services'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { createCustomError } from '@vega/error-standardizer'

export const fetchGoogleDoc = createAsyncThunk(
  'google/fetchGoogleDoc',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await googleService.fetchGoogleDoc(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createGoogleDoc = createAsyncThunk(
  'google/createGoogleDoc',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(payload))
        throw createCustomError({
          type: 'googleDocCreateFailed',
          description: 'GoogleDoc Create Failed',
        })

      await googleService.createGoogleDoc(payload)
      const id = payload.versionedLoanApplicationId
      return dispatch(fetchGoogleDoc({ id, payload }))
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

// This function enables the use of any 'unique' mongo field as the Redux store 'unique identifier'.
export const googleAdapter = createEntityAdapter({
  selectId: (googleDoc) => googleDoc.versionedLoanApplicationId,
})

const initialState = googleAdapter.getInitialState()

const googleDocSlice = createSlice({
  name: 'googleDocs',
  initialState,
  extraReducers: {
    [fetchGoogleDoc.fulfilled]: googleAdapter.upsertOne,
  },
})

const { reducer: googleDocsReducer } = googleDocSlice
export { googleDocsReducer }
