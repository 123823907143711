import { prop } from '@solta/ramda-extra'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { createSelector } from '@reduxjs/toolkit'

const selectComparableSales = prop('comparableSale')

export const { selectIsPending: selectIsFetchingFeatures } = createAsyncThunkSelectors(
  'comparableSale/fetchComparableSales'
)

export const selectAllCoordinates = createSelector(
  selectComparableSales,
  prop('coordinates')
)
