/* eslint-disable complexity */
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  ContentCard as ContentCardBase,
  DataDisplay,
  Divider,
} from '@vega/components/src/v2'
import { useDispatch } from 'react-redux'

import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { ReactComponent as ChevronRightBase } from '@vega/components/src/assets/images/chevron-right.svg'
import { ReactComponent as ChainLinkBase } from '@vega/components/src/assets/images/chain-link.svg'
import { ReactComponent as EditIcon } from '@vega/components/src/assets/images/pencil.svg'

import { formatCurrency, capitaliseFirstLetterOfAllWords } from 'utils'
import { routes } from 'navigation/routes'
import { SecurityOwnerSegment } from './SecurityOwnerSegment'
import { TEST_IDS } from '@neo/constants'
import { getSecurityDetails } from 'modules/property'
import { updateFinance } from 'modules/financial'
import { fetchLoanApplication } from 'modules/versionedApplication'
import { Button } from '@vega/components'
import { UpdateSecurityForm } from './UpdateSecurityForm'
import { versioningSecurity } from './utils'
import { VersionedSecurityCard } from './VersionedSecurityCard'

const {
  loanApp: {
    assessment: {
      security: { details },
    },
  },
} = routes

const ContentCard = styled(ContentCardBase)(s('mb-5 px-5'))

const RemoveButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center py-2 px-4 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)

const EditButton = styled(Button)(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center py-2 px-4 text-sm mr-2',
    { '&:hover': { cursor: 'pointer', boxShadow: 'none !important' } }
  )
)

const ViewPropertyButton = styled.button(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center py-2 px-4 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  )
)

const LinkToValocityButton = styled.button(
  s(
    'bg-secondary border-1 border-transparent rounded-lg text-primary font-semibold self-end text-center py-2 px-4 mr-2 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  ),
  ({ isLinked }) => isLinked && s('hidden')
)

const ReLinkToValocityButton = styled.button(
  s(
    'bg-secondary border-1 border-transparent rounded-lg text-primary font-semibold self-end text-center py-2 px-4 mr-2 text-sm',
    { '&:hover': { cursor: 'pointer' } }
  ),
  ({ isLinked }) => !isLinked && s('hidden')
)

const ValocityNotLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-accent-red text-xs mb-2',
    { width: 145 }
  )
)

const ValocityLinkedPill = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-secondary text-xs mb-2',
    { width: 115 }
  )
)

const RemovedTag = styled.div(
  s(
    'rounded-2xl px-1 py-3 h-1 flex items-center justify-center bg-accent-red text-xs mb-2 mt-3',
    { width: 100 }
  )
)

const getRentalIncome = (rentalIncomeArray) => {
  if (rentalIncomeArray?.length === 0) return null
  const sumOfAllIncomes = rentalIncomeArray
    .map((rentalIncome) => rentalIncome?.rentalAmount)
    .reduce((previousAmount, currentAmount) => previousAmount + currentAmount, 0)
  return sumOfAllIncomes
}

const ChevronRight = styled(ChevronRightBase)(
  s('w-1 p-0', { marginTop: 2, height: 16 })
)

const ChainLink = styled(ChainLinkBase)(s('mr-2', { marginTop: 2 }))

const getLinkedStatus = (linkedDetails) => {
  if (isNilOrEmpty(linkedDetails) || isNilOrEmpty(linkedDetails?.address)) {
    return false
  }
  return true
}

export const SecurityCard = ({
  security,
  loanApplicationId,
  customSecurityValuations,
  legalEntities,
  setIsModalOpened,
  setModalSecurity,
  setIsRemoveModalOpened,
  cannotRemoveSecurity,
  allBorrowers,
  allGuarantors,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const {
    id: securityId,
    estimatedValue,
    propertyType,
    primaryUsage,
    isSecurity,
    primaryPurpose,
    holding,
    rentalIncome,
    linkedDetails,
    percentOwned,
    source,
  } = security || {}

  const [showEditForm, setShowEditForm] = useState(false)

  const largerThanZeroPercentOwned = percentOwned?.owners.filter(
    (owner) => owner.percent > 0
  )
  const customSecurityValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )
  const goToSecurityDetails = () => {
    history.push({
      pathname: details
        .replace(':id', loanApplicationId)
        .replace(':securityId', securityId),
      state: {
        fullAddress: security?.address?.fullAddress,
        linkedDetails,
        security,
      },
    })
  }

  const isLinked = getLinkedStatus(linkedDetails)

  const handleOnLinkBtnClicked = (security) => {
    setIsModalOpened(true)
    setModalSecurity(security)
  }

  const handleOnReLinkBtnClicked = async (security) => {
    const { payload } = await dispatch(
      getSecurityDetails({
        requestId: security?.id,
        propertyId: security?.linkedDetails?.externalRef,
      })
    )

    await dispatch(updateFinance({ id: security?.id, payload }))
    dispatch(fetchLoanApplication(id))
  }

  const openRemoveModal = (security) => {
    setIsRemoveModalOpened(true)
    setModalSecurity(security)
  }

  const customEstimatedValue = customSecurityValuation?.value
  const ivalEstimatedValue = linkedDetails?.valuation?.estimatedValue
  const myCrmEstimatedValue = estimatedValue?.value

  const finalAssetValue =
    customEstimatedValue || ivalEstimatedValue || myCrmEstimatedValue || undefined

  const estimateValueLabel =
    source === 'MyCRM' ? 'MyCRM Estimated Value' : 'Estimated Value'

  const openEditForm = () => {
    setShowEditForm(true)
  }

  const versionedSecurity = versioningSecurity(security)

  return (
    <>
      {showEditForm && (
        <UpdateSecurityForm
          setShowEditForm={setShowEditForm}
          security={security}
          allBorrowers={allBorrowers}
          allGuarantors={allGuarantors}
        />
      )}
      {!showEditForm && (
        <ContentCard>
          <Row>
            <Col span={17}>
              <DataDisplay
                label="Address"
                testId={`${TEST_IDS.PROPERTY_ADDRESS}`}
                value={
                  isLinked
                    ? capitaliseFirstLetterOfAllWords(linkedDetails?.address)
                    : security?.address?.fullAddress
                }
                valueStyle={s('text-xl')}
              />
              {security?.status === 'Removed' && <RemovedTag>REMOVED</RemovedTag>}
            </Col>

            <Col span={7}>
              <div style={s('flex flex-row justify-end')}>
                {security?.status === 'Active' && (
                  <>
                    <LinkToValocityButton
                      onClick={() => handleOnLinkBtnClicked(security)}
                      isLinked={isLinked}
                    >
                      <div style={s('flex flex-row')}>
                        <ChainLink />
                        Link Valocity
                      </div>
                    </LinkToValocityButton>

                    <ReLinkToValocityButton
                      onClick={() => handleOnReLinkBtnClicked(security)}
                      isLinked={isLinked}
                    >
                      <div style={s('flex flex-row')}>Re-Link Valocity</div>
                    </ReLinkToValocityButton>
                  </>
                )}

                <ViewPropertyButton onClick={goToSecurityDetails}>
                  <div style={s('flex flex-row')}>
                    View details
                    <ChevronRight />
                  </div>
                </ViewPropertyButton>
              </div>
            </Col>
            {security?.status === 'Active' && (
              <Col span={24}>
                {isLinked ? (
                  <ValocityLinkedPill data-test-id={`${TEST_IDS.VELOCITY_LINK}`}>
                    VALOCITY LINKED
                  </ValocityLinkedPill>
                ) : (
                  <ValocityNotLinkedPill data-test-id={`${TEST_IDS.VELOCITY_NOT_LINK}`}>
                    VALOCITY NOT LINKED
                  </ValocityNotLinkedPill>
                )}
              </Col>
            )}
          </Row>
          {!versionedSecurity && (
            <Row>
              <Col span={6}>
                <DataDisplay
                  label={estimateValueLabel}
                  testId={`${TEST_IDS.MYCRM_ESTIMATE}`}
                  value={formatCurrency(estimatedValue?.value)}
                  valueStyle={s('mt-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  testId={`${TEST_IDS.PROPERTY_TYPE}`}
                  label="Property Type"
                  value={propertyType?.propertyTypeName}
                  valueStyle={s('mt-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  label="Property Usage"
                  testId={`${TEST_IDS.PROPERTY_USAGE}`}
                  value={primaryUsage}
                  valueStyle={s('mt-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  label="Used as Security"
                  testId={`${TEST_IDS.USED_AS_SECURITY}`}
                  value={isSecurity ? 'Yes' : 'No'}
                  valueStyle={s('mt-1 ml-1')}
                  labelStyle={s('ml-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  label="Monthly Rental Income"
                  testId={`${TEST_IDS.RENTAL_INCOME}`}
                  value={formatCurrency(getRentalIncome(rentalIncome))}
                  valueStyle={s('mt-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  label="Primary Purpose"
                  testId={`${TEST_IDS.PRIMARY_PURPOSE}`}
                  value={primaryPurpose}
                  valueStyle={s('mt-1')}
                />
              </Col>

              <Col span={6}>
                <DataDisplay
                  label="Holding"
                  testId={`${TEST_IDS.PROPERTY_HOLDING}`}
                  value={holding}
                  valueStyle={s('mt-1')}
                />
              </Col>
              <Col span={24}>
                <div style={s('mt-3')}>
                  <Divider />
                </div>
              </Col>

              <Col span={24}>
                <h4 style={s('mt-1 mb-2')}>Owners</h4>
              </Col>

              {largerThanZeroPercentOwned.map((owner, index) => (
                <Col span={6} key={index}>
                  <SecurityOwnerSegment owner={owner} legalEntities={legalEntities} />
                </Col>
              ))}
            </Row>
          )}

          {versionedSecurity && (
            <VersionedSecurityCard
              versionedSecurity={versionedSecurity}
              estimateValueLabel={estimateValueLabel}
              getRentalIncome={getRentalIncome}
              largerThanZeroPercentOwned={largerThanZeroPercentOwned}
              legalEntities={legalEntities}
            />
          )}
          <Row>
            <Col span={24}>
              <div style={s('mt-3')}>
                <Divider />
              </div>
            </Col>

            <Col span={24}>
              <h4 style={s('mt-1 mb-2')}>Security Value</h4>
            </Col>

            <Col span={24}>
              {isLinked ? (
                <Row gutter={[0, 0]}>
                  <Col span={4}>
                    <DataDisplay
                      testId={`${TEST_IDS.FINAL_ASSET_VALUE}`}
                      label="Final Assigned Property Value"
                      value={formatCurrency(finalAssetValue)}
                    />
                  </Col>

                  <Col span={4}>
                    <DataDisplay
                      label="iVal Results"
                      testId={`${TEST_IDS.IVAL_RESULT}`}
                      value={formatCurrency(linkedDetails?.valuation?.estimatedValue)}
                    />
                  </Col>

                  <Col span={4}>
                    <DataDisplay
                      label="Full Market Results"
                      value={formatCurrency(customSecurityValuation?.value)}
                    />
                  </Col>
                </Row>
              ) : (
                <div style={s('flex flex-column w-full justify-center')}>
                  <div style={s('text-center text-sm font-semibold mb-2')}>
                    No Results Yet
                  </div>
                  <div style={s('text-center text-sm')}>
                    Please connect to Valocity to get iVal Results
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {security?.status === 'Active' && (
            <Row>
              <Col span={24}>
                {!cannotRemoveSecurity && (
                  <div style={s('flex flex-row justify-end')}>
                    <EditButton
                      type="button"
                      variant="outlined"
                      onClick={openEditForm}
                      StartIcon={<EditIcon style={s('mr-2')} />}
                    >
                      Edit
                    </EditButton>
                    <RemoveButton onClick={() => openRemoveModal(security)}>
                      <div style={s('flex flex-row')}>Remove</div>
                    </RemoveButton>
                  </div>
                )}
              </Col>
            </Row>
          )}
        </ContentCard>
      )}
    </>
  )
}
