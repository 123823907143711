import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { versionedApplicationAdapter } from './module'

const selectAllApplications = prop('versionedApplication')
export const selectTotal = createSelector(selectAllApplications, prop('total'))

export const {
  selectIsPending: selectIsFetchApplicationPending,
  selectHasFulfilled: selectHasFetchApplicationFulfilled,
  selectError: selectFetchApplicationError,
} = createAsyncThunkSelectors('versionedApplications/getApplication')

export const {
  selectIsPending: selectIsFetchApplicationsPending,
} = createAsyncThunkSelectors('versionedApplications/getLoanApplications')

export const {
  selectIsPending: selectIsFetchingApplicationEntities,
} = createAsyncThunkSelectors('versionedApplications/fetchLoanApplicationEntities')

export const {
  selectIsPending: selectIsUpdatingApplication,
} = createAsyncThunkSelectors('versionedApplication/updateLoanApplication')

export const {
  selectAll: selectApplications,
  selectById,
} = versionedApplicationAdapter.getSelectors(
  /**
   * @param {import('../../store').RootState} s
   */
  (s) => s.versionedApplication
)

/**
 * Provide sensible default values when the server doesn't return data for some fields. This helps avoiding `undefined/null` runtime error and reduces the number of default/nullish coalescing in components
 * @type {import('./types').Application}
 */
const defaultVersionedApplication = {
  id: '',
  versions: [
    {
      application: {
        householdInfo: { numberOfAdults: 0, numberOfDependants: 0 },
        legalEntities: { trusts: [], individuals: [], companies: [] },
      }
    }
  ]
}

/**
 * @type {import('./types').selectApplicationById}
 */
export const selectApplicationById = (id) => (state) =>
  selectById(state, id) ?? defaultVersionedApplication
