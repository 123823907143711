import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Table } from '@vega/components/src/v2'
import {
  SectionTitle as SectionTitleBase,
  ContentCard as ContentCardBase,
} from '../components'
import { Rows, Columns } from './TableFormat'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'

const { SUMMARY_NOTES } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('p-0', { overflow: 'hidden' }))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const SummaryNotes = ({ allNotes, loanApplicationId, ...props }) => {
  allNotes.sort((x, y) => {
    const a = new Date(x.createdAt)
    const b = new Date(y.createdAt)
    return b - a
  })

  // trim and format section values capitalized
  // Example regex input: "loan-applications/financial/stuff"
  // Expected regex output: "financial"
  const notes = allNotes.map((note) => {
    const newSource = note.source.replace(/^[^\\/]*s\//, '').replace(/\/.*/, '')
    const section = newSource.charAt(0).toUpperCase() + newSource.slice(1)

    return { ...note, section }
  })

  const data = Rows({
    notes,
  })

  const columns = Columns(loanApplicationId)

  return (
    <Container id={SUMMARY_NOTES} {...props}>
      <SectionTitle title="Summary Notes" />

      <ContentCard>
        <Table
          style={s('m-0')}
          tableHeight={550}
          columns={columns}
          data={data}
        />
      </ContentCard>
    </Container>
  )
}

export { SummaryNotes }
