import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { umiReportItemService } from 'apiService'
import { normalizeError } from '@vega/services'
import { createCustomError } from '@vega/error-standardizer'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const fetchUmiReportItems = createAsyncThunk(
  'umiReportItems/fetchUmiReportItems',
  async ({ versionedLoanApplicationId }, { rejectWithValue}) => {
    try {
      return await umiReportItemService.fetchUmiReportItems(
        versionedLoanApplicationId
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createUmiReportItem = createAsyncThunk(
  'umiReportItems/createUmiReportItem',
  async ({ versionedLoanApplicationId, umiReportItem }, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(versionedLoanApplicationId || umiReportItem))
        throw createCustomError({
          type: 'umiReportItemCreateFailed',
          description: 'UMI Report Item Create Failed',
        })

      await umiReportItemService.createUmiReportItem(umiReportItem)

      return dispatch(
        fetchUmiReportItems({
          versionedLoanApplicationId
        })
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateUmiReportItem = createAsyncThunk(
  'umiReportItems/updateUmiReportItem',
  async ({ umiReportItemId, umiReportItem}, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(umiReportItemId || umiReportItem))
        throw createCustomError({
          type: 'umiReportItemUpdateFailed',
          description: 'UMI Report Item Update Failed',
        })

      await umiReportItemService.updateUmiReportItem(
        umiReportItemId, umiReportItem)

      return dispatch(
        fetchUmiReportItems({
          versionedLoanApplicationId: umiReportItem.versionedLoanApplicationId
        })
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const deleteUmiReportItem = createAsyncThunk(
  'umiReportItems/deleteUmiReportItem',
  async ({ umiReportItemId, versionedLoanApplicationId }, { dispatch, rejectWithValue }) => {
    try {
      await umiReportItemService.deleteUmiReportItem(umiReportItemId)

      return dispatch(fetchUmiReportItems({ versionedLoanApplicationId }))

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const refreshUmiReportItems = createAsyncThunk(
  'umiReportItems/refreshUmiReportItems',
  async ({ versionedLoanApplicationId }, { dispatch, rejectWithValue }) => {
    try {
      await umiReportItemService.refreshUmiReportItems(versionedLoanApplicationId)

      return dispatch(fetchUmiReportItems({ versionedLoanApplicationId }))

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const umiReportItemAdapter = createEntityAdapter()

const initialState = umiReportItemAdapter.getInitialState()

const umiReportItemSlice = createSlice({
  name: 'umiReportItem',
  initialState,
  extraReducers: {
    [fetchUmiReportItems.fulfilled]: (state, { payload }) => {
      umiReportItemAdapter.setAll(state, payload)
    },
  },
})

const { reducer: umiReportItemReducer } = umiReportItemSlice

export { umiReportItemReducer }
