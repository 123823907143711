import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class FinancialService extends BaseService {
  readByLoanId(loanApplicationId, showFullHistory) {
    const queryParams = QueryBuilder()
      .setParam('versionedLoanApplicationId', loanApplicationId)
      .setParam('showFullHistory', showFullHistory)
      .build()

    return this.client.get(`financials?${queryParams}`, { timeout: 40000 }).json()
  }

  updateFinance(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`financials/${id}`, config).json()
  }
}
