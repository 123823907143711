/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DataDisplay, Divider as DividerBase } from '../components'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { insertSeparatorsToString, formatFullName } from 'utils/formatters'

const Container = styled.div()
const Heading = styled.div(
  s('mb-2 text-xs text-primary font-semibold inline-block', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Divider = styled(DividerBase)(s('my-5'))

const AddedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mx-2 bg-green-300 text-white font-semibold text-sm', {
    paddingTop: 2,
    paddingBottom: 2,
  })
)
const RemovedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mx-2 text-sm font-semibold text-white', {
    backgroundColor: 'red',
    paddingTop: 2,
    paddingBottom: 2,
  })
)

// eslint-disable-next-line complexity
const Trust = ({ trust, index, ...props }) => {
  const {
    trustName,
    contact,
    applicantRole,
    establishmentDate,
    beneficiaries,
    trustees,
    versionedState,
  } = trust

  const beneficiaryNames = beneficiaries
    .map(
      (beneficiary) =>
        beneficiary?.personName?.knownAs ||
        formatFullName(beneficiary?.personName) ||
        '-'
    )
    .join(', ')

  return (
    <Container {...props}>
      <div className="flex">
        <Heading>TRUST {index + 1}</Heading>
        {versionedState === 'added' && <AddedTag>ADDED</AddedTag>}
        {versionedState === 'removed' && <RemovedTag>REMOVED</RemovedTag>}
      </div>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <DataDisplay label="Business Name" value={trustName} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Type" value={applicantRole} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Establishment Date"
            value={moment(establishmentDate).format('DD/MM/YYYY') ?? '-'}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Beneficiaries" value={beneficiaryNames || '-'} />
        </Col>

        <Col span={12}>
          <DataDisplay
            label="Business Address"
            value={contact.address.fullAddress || '-'}
          />
        </Col>

        <Col span={12}>
          <DataDisplay label="Email Address" value={contact.email || '-'} />
        </Col>
      </Row>
      {isNotNilOrEmpty(trustees) ? (
        <div style={s('mt-4')}>
          <Heading>TRUSTEES</Heading>
          {trustees.map((trustee) => (
            <Row style={s('mb-4')} key={trustee.entityId}>
              <Col span={12}>
                <DataDisplay
                  label="Full Name"
                  value={
                    formatFullName({
                      firstName: trustee?.firstName,
                      lastName: trustee?.surname,
                    }) || '-'
                  }
                />
              </Col>
              <Col span={12}>
                <DataDisplay
                  label="Phone Number"
                  value={
                    insertSeparatorsToString(trustee?.phone, ' ', `### ### ####`) ||
                    insertSeparatorsToString(
                      trustee?.phoneNumber,
                      ' ',
                      `## ## ### ####`
                    ) ||
                    '-'
                  }
                />
              </Col>

              <Col span={12}>
                <DataDisplay label="Email Address" value={trustee?.email || '-'} />
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <div />
      )}
      <Divider />
    </Container>
  )
}

export { Trust }
