import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import { financialService } from 'apiService'

/**
 * @type {import('./types').FetchApplicationThunk}
 */
export const fetchLoanFinancials = createAsyncThunk(
  'financials/fetchLoanFinancials',
  async ({ id, showFullHistory }, { rejectWithValue }) => {
    try {
      return await financialService.readByLoanId(id, showFullHistory)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateFinance = createAsyncThunk(
  'financials/updateFinance',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { propertyId, address, details, valuation, imageSrc, updatedAt } = payload
      return await financialService.updateFinance(id, {
        propertyId,
        address,
        details,
        valuation,
        imageSrc,
        updatedAt,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const financialAdapter = createEntityAdapter({
  selectId: (financials) => financials.financialType,
})

const initialState = financialAdapter.getInitialState({
  entities: {},
  ids: [],
  individualEntityEmployments: [],
  households: [],
  versionedFinancials: [],
})

const financialSlice = createSlice({
  name: 'financials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoanFinancials.fulfilled, (state, { payload }) => {
      if (payload) {
        const [financials, versionedFinancials, employments, households] = [
          [],
          [],
          [],
          [],
        ]

        payload.map((arr) => {
          financials.push(arr?.values?.financials)
          versionedFinancials.push(arr?.values?.financials)
          employments.push(arr?.values?.individualEntityEmployments)
          households.push(arr?.values?.households)
          return []
        })

        financialAdapter.setAll(state, financials[financials?.length - 1])
        state.versionedFinancials = versionedFinancials
        state.individualEntityEmployments = employments
        state.households = households
      }
    })
  },
})

const { reducer: financialReducer } = financialSlice

export { financialReducer }
