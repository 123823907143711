import React from 'react'
import { s, styled } from '@vega/styled/v2'

const Container = styled.div(s('bg-secondary p-8 rounded-md w-full'))
const Label = styled.p(
  s('mb-2 mt-0 text-base text-grey-600', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const Value = styled.p(
  s('font-bold m-0 text-2xl text-grey-900', {
    letterSpacing: '0.04em',
    lineHeight: 1.2,
  })
)

export const LoanSplitDisplayItem = ({ label, value = '-', ...props }) => (
  <Container {...props}>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Container>
)
