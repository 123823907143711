import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import { ReactComponent as Calculator } from '@vega/components/src/assets/images/calculator.svg'

const CalculatorIcon = styled(Calculator)(s('h-4 w-4 mr-4 mt-1'), {
  path: { stroke: s('text-primary').color },
})

const Equation = styled('div')(s('flex mb-4'))
const EquationValue = styled('h2')(s('m-0 text-primary'))
const EquationLabel = styled('span')(s('text-sm text-grey-600 font-medium'))
const EquationSymbol = styled('h2')(s('mx-6 text-primary'))

export const NetPositionEquation = ({ incomes, expenses }) => {
  const totalMonthlyIncome = incomes
    .map((income) => income?.monthlyAmount)
    .reduce((total, amount) => {
      return total + amount
    }, 0)

  const totalMonthlyExpenses = expenses
    .map((expense) => expense.amount)
    .reduce((total, amount) => {
      const lastSubmission = amount?.length - 1
      const newAmount = formatStringToNumber(amount[lastSubmission])
      return total + newAmount
    }, 0)

  const netPosition = Math.round(totalMonthlyIncome) - Math.round(totalMonthlyExpenses)

  return (
    <Equation>
      <CalculatorIcon />

      <EquationValue>
        <EquationLabel>Total income</EquationLabel>
        <br />
        {formatCurrency(totalMonthlyIncome)}
      </EquationValue>

      <EquationSymbol>-</EquationSymbol>

      <EquationValue>
        <EquationLabel>Total expenses</EquationLabel>
        <br />
        {formatCurrency(totalMonthlyExpenses)}
      </EquationValue>

      <EquationSymbol>=</EquationSymbol>

      <EquationValue>
        <EquationLabel>Net position</EquationLabel>
        <br />
        {formatCurrency(netPosition)}
      </EquationValue>
    </Equation>
  )
}
