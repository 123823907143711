import { isNotNilOrEmpty, propEq } from '@solta/ramda-extra'
import * as Validator from 'yup'
import { round } from 'mathjs'
import { formatCurrency } from 'utils/formatters'

/**
 * For example, the current field has path 'loanStructures.[0].product'
 * We want to get the parent path -  'loanStructures.[0]'
 * so we remove .product from the current path
 */
// eslint-disable-next-line no-unused-vars
const getParentPath = (currentFieldPath, currentFieldName) =>
  currentFieldPath.substring(0, currentFieldPath.indexOf(`.${currentFieldName}`))

const { object, array, string, number, boolean, mixed } = Validator

const requiredString = string().required()
const requiredNumber = number().required()

// New loan structure should not have allocated loan amount
// Allocated loan structure should already have allocated loan amount
const getAllocatedLoanAmountOfCurrentStructure = (
  isAllocated,
  allocatedLoanAmountBeforeEditing = 0
) => (isAllocated ? allocatedLoanAmountBeforeEditing : 0)

// We want to keep track on the loan amount that can be allocated (allocatable).
// For example the loan application is requesting 10m, then we can not allocate loan for > 10m otherwise it doesn't make sense
const calculateAllocatableLoanAmount = (
  totalRequestedLoanAmount = 0,
  totalAllocatedLoanAmount = 0,
  allocatedLoanAmountOfCurrentStructure = 0,
  isStructureAllocated
) =>
  isStructureAllocated
    ? /**
       * Scenario - Editing allocated loan
       * - total request amount = 10m
       * - current allocated loan has allocated amount of 5m
       * To calculate the allocatable loan amount for current loan structure, we have to start fresh by ignoring the previously allocated amount of the structure we are editing (5m in this case)
       * So that when we change the loan amount text field in the structure form, it won't be 10m - 5m (previously allocated amount) - whatever we type
       */
      totalRequestedLoanAmount +
      allocatedLoanAmountOfCurrentStructure -
      totalAllocatedLoanAmount
    : totalRequestedLoanAmount - totalAllocatedLoanAmount

const productTest = (totalRequestedLoanAmount, totalAllocatedLoanAmount) => (
  product,
  context
) => {
  const { allocatedStructureBeforeEditing, isAllocated } = context.parent

  const allocatedLoanAmountOfCurrentStructure = getAllocatedLoanAmountOfCurrentStructure(
    isAllocated,
    allocatedStructureBeforeEditing?.loanAmount
  )

  const allocatableLoanAmount = calculateAllocatableLoanAmount(
    totalRequestedLoanAmount,
    totalAllocatedLoanAmount,
    allocatedLoanAmountOfCurrentStructure,
    isAllocated
  )

  const requiredLoanAmountExceeded = product?.minLoanAmount - allocatableLoanAmount

  if (isNotNilOrEmpty(product?.minLoanAmount) && requiredLoanAmountExceeded > 0)
    return context.createError({
      message: `Please select another product. The minimum loan amount by the selected product is ${formatCurrency(
        product?.minLoanAmount
      )}, which is more than the allocatable loan amount by ${formatCurrency(
        requiredLoanAmountExceeded
      )}`,
      path: `${context.path}.id`,
    })

  return true
}

// eslint-disable-next-line complexity
const loanAmountTest = (totalRequestedLoanAmount, totalAllocatedLoanAmount) => (
  loanAmount,
  context
) => {
  const { product, allocatedStructureBeforeEditing, isAllocated } = context.parent

  if (isNotNilOrEmpty(product?.minLoanAmount) && loanAmount < product?.minLoanAmount)
    return context.createError({
      message: `Loan amount must be greater than ${formatCurrency(
        product?.minLoanAmount
      )}`,
    })

  if (isNotNilOrEmpty(product?.maxLoanAmount) && loanAmount > product?.maxLoanAmount)
    return context.createError({
      message: `Loan amount must be lower than ${formatCurrency(
        product?.maxLoanAmount
      )}`,
    })

  const allocatedLoanAmountOfCurrentStructure = getAllocatedLoanAmountOfCurrentStructure(
    isAllocated,
    allocatedStructureBeforeEditing?.loanAmount
  )

  const allocatableLoanAmount = calculateAllocatableLoanAmount(
    totalRequestedLoanAmount,
    totalAllocatedLoanAmount,
    allocatedLoanAmountOfCurrentStructure,
    isAllocated
  )
  const loanAmountExceeded = loanAmount - allocatableLoanAmount

  const isAllocatingExceededLoanAmount = loanAmountExceeded > 0

  if (isAllocatingExceededLoanAmount)
    return context.createError({
      message: `
 Allocating exceeding loan amount by ${formatCurrency(loanAmountExceeded)}
 `,
    })

  return true
}

const loanTermTest = (loanTerm, context) => {
  const { product = {} } = context.parent
  const { minTermInYears, maxTermInYears } = product

  if (isNotNilOrEmpty(minTermInYears) && loanTerm < round(minTermInYears, 2))
    return context.createError({
      message: `Loan term must be greater or equal to ${round(
        minTermInYears,
        2
      )} years`,
    })

  if (isNotNilOrEmpty(maxTermInYears) && loanTerm > round(maxTermInYears, 2))
    return context.createError({
      message: `Loan term must be lower or equal to ${round(maxTermInYears, 2)} years`,
    })

  return true
}

const loadingTest = (loading = 0, context) => {
  const { baseInterestRate } = context.parent

  const totalInterestRate = baseInterestRate + loading

  if (totalInterestRate < 0) {
    const minLoadingRequired = -baseInterestRate
    return context.createError({
      message: `Please specify at least ${minLoadingRequired} for loading, otherwise the final interest will be negative`,
    })
  }

  return true
}

const associatedBorrowersTest = (associatedBorrowers = [], context) => {
  const { primaryApplicant } = context.parent

  const isPrimaryApplicantIncludedInBorrowers = associatedBorrowers.find(
    propEq('id', primaryApplicant)
  )

  if (primaryApplicant && !isPrimaryApplicantIncludedInBorrowers)
    return context.createError({
      message: `Please include selected primary applicant`,
    })

  return true
}

const productSchema = object({
  id: requiredString.label('Product'),
  maxLoanSplits: requiredNumber.label('Product'),
  minTermInYears: requiredNumber.label('Product'),
  maxTermInYears: requiredNumber.label('Product'),
  minLoanAmount: requiredNumber.label('Product'),
  maxLoanAmount: requiredNumber.label('Product'),
  name: requiredString.label('Product'),
})

const entitySchema = object({
  id: string(),
  firstName: string(),
  surname: string(),
  legalEntityType: string(),
})

const securityPropertySchema = object({
  id: string(),
  address: string(),
  allocationPercentage: number()
    .required('Please specify the percentage')
    .min(0)
    .label('Allocation Percentage'),
  value: number(),
})

export const validationSchema = (totalRequestedLoanAmount, totalAllocatedLoanAmount) =>
  object({
    loanStructures: array().of(
      object({
        product: productSchema
          .required()
          .label('Product')
          .test(
            'product-test',
            '',
            productTest(totalRequestedLoanAmount, totalAllocatedLoanAmount)
          ),
        loanAmount: requiredNumber
          .min(0)
          .test(
            'loan-amount-test',
            '',
            loanAmountTest(totalRequestedLoanAmount, totalAllocatedLoanAmount)
          )
          .label('Loan Amount'),
        loanTermInYears: requiredNumber.min(0).test(loanTermTest).label('Loan Term'),
        baseInterestRate: number().min(0).label('Base interest rate'),
        loading: requiredNumber
          .typeError('Please specify a number')
          .test(loadingTest)
          .label('Loading'),
        loanPurpose: requiredString.label('Loan purpose'),
        occupancyType: requiredString.label('Occupancy Type'),
        repaymentAmount: number()
          .required(
            'Please fill in product, loan amount, loan term, loading and frequency to calculate repayment amount'
          )
          .min(0)
          .label('Repayment amount'),
        repaymentFrequency: requiredString.label('Repayment frequency'),
        dayOfTheWeek: mixed().when('repaymentFrequency', {
          is: (frequency) => frequency === 'weekly',
          then: requiredString.label('Day of the week'),
          otherwise: string().notRequired(),
        }),
        dayOfTheMonth: mixed().when('repaymentFrequency', {
          is: (frequency) => frequency === 'monthly',
          then: requiredNumber.min(0).label('Day of the month'),
          otherwise: number().min(0).notRequired(),
        }),
        dayOfTheFortnight: mixed().when('repaymentFrequency', {
          is: (frequency) => frequency === 'fortnightly',
          then: requiredNumber.min(0).label('Day of the fortnight'),
          otherwise: number().min(0).notRequired(),
        }),
        associatedBorrowers: array()
          .of(entitySchema)
          .min(0)
          .test(associatedBorrowersTest)
          .label('Associated entities'),
        guarantors: array().of(entitySchema).min(0).label('Guarantors'),
        primaryApplicant: requiredString.label('Primary applicant'),
        securityProperties: array()
          .of(securityPropertySchema)
          .min(1)
          .label('Security properties'),
        isCrossCollateral: mixed().when('securityProperties', {
          is: (securities) => securities.length > 0,
          then: boolean()
            .required()
            .typeError(
              'Please specify if the security properties are cross collateral'
            ),
          otherwise: boolean().notRequired(),
        }),
        isAllocated: boolean(),
        isEditMode: boolean(),
        // Add custom error validation logic in the customErrors
        customErrors: mixed().test('is-within-product-bound', '', (_, context) => {
          const loanStructure = context.parent

          const totalSecurityAllocatedPercentages = loanStructure.securityProperties.reduce(
            (total, property) => property?.allocationPercentage + total,
            0
          )

          if (totalSecurityAllocatedPercentages !== 100) {
            return context.createError({
              message: `Total allocated security percentage must be 100%`,
              path: `${context.path}.unallocatedSecurityProperty`,
            })
          }

          return true
        }),
      })
    ),
  })
