import React, { useState } from 'react'
import { Button } from '@vega/components'
import { AddLiabilityForm } from './AddLiabilityForm'
import { RevolvingCreditProductTable } from './RevolvingCreditProductTable'
import { StudentLoanLeaseHirePurchaseTable } from './StudentLoanLeaseHirePurchaseTable'
import { LongTermLoanMortgageTable } from './LoanTermLoanMortgageTable'
import { s } from '@vega/styled/v2'

export const UMILiabilityTable = ({
  householdRef,
  liabilities,
}) => {
  const [showAddForm, setShowAddForm] = useState(false)

  const liabilitiesForType = (liabilityType) => liabilities.filter(
    (liability) => liability.details.umiLiabilityType === liabilityType
  )

  const revolvingCreditLiabilities = liabilitiesForType('revolvingCredit')
  const studentLoanLeaseHirePurchaseLiabilities = liabilitiesForType('studentLoanLeaseHirePurchase')
  const loanTermLoanMortgageLiabilities = liabilitiesForType('longTermLoanMortgage')

  return (
    <>
      <div style={s('bg-white rounded-lg p-8 mb-4')}>
        <h3>Revolving credit products</h3>
        <RevolvingCreditProductTable
          liabilities={revolvingCreditLiabilities}
        />

        <h3>Student loan, leases, hire purchase</h3>
        <StudentLoanLeaseHirePurchaseTable
          liabilities={studentLoanLeaseHirePurchaseLiabilities}
        />

        <h3>Long term loans and mortages</h3>
        <LongTermLoanMortgageTable
          liabilities={loanTermLoanMortgageLiabilities}
        />
      </div>

      {
        showAddForm && (
          <div style={s('bg-white rounded-lg p-8')}>
            <AddLiabilityForm
              householdRef={householdRef}
              closeForm={() => {setShowAddForm(false)}}
            />
          </div>
        )
      }

      <h4 style={s('my-1')}>
        Add another financial liability item?
      </h4>
      <Button
        onClick={() => setShowAddForm(!showAddForm)}
        style={s('mb-16')}
      >
        Add financial liability
      </Button>
    </>
  )
}
