import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { formatCurrency } from 'utils/formatters'
import { TextOverflowEllipsisBox } from '../TextOverflowEllipsisBox'
import { s } from '@vega/styled/v2'
import {
  costOfServicing,
  effectiveCostOfServicing,
  sensitisedInterestRate,
  totalCostOfServicing,
  totalEffectiveCostOfServicing,
} from './utils'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

import { Formik, Field } from 'formik'

export const LongTermLoanMortgageTable = ({ liabilities }) => {
  const dispatch = useDispatch()
  const [currentLiabilities, setCurrentLiabilities] = useState(liabilities)

  if (liabilities.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No long term loan or mortgage liabilities currently recorded
      </p>
    )
  }

  const rows = liabilities.map((liability) => (
    <Formik
      key={liability.id}
      initialValues={liability}
      onSubmit={(values) => {
        setCurrentLiabilities(
          currentLiabilities
            .filter((liability) => liability.id !== values.id)
            .concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.committedLimit"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.repaymentType"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="principalAndInterest">P & I</option>
              <option value="interestOnly1Year">IO 1 year</option>
              <option value="interestOnly2Year">IO 2 year</option>
              <option value="interestOnly3Year">IO 3 year</option>
              <option value="interestOnly4Year">IO 4 year</option>
              <option value="interestOnly5Year">IO 5 year</option>
              <option value="interestOnly6Year">IO 6 year</option>
              <option value="interestOnly7Year">IO 7 year</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.purpose"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="ownerOccupied">OO</option>
              <option value="residentialInvestmentLending">RIL</option>
              <option value="nonHousing">Non-housing</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.remainingTermYears"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.interestRate"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            {sensitisedInterestRate(liability)}%
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
            <TextOverflowEllipsisBox>
              {formatCurrency(costOfServicing(props.values))}
            </TextOverflowEllipsisBox>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
            <TextOverflowEllipsisBox>
              {formatCurrency(effectiveCostOfServicing(props.values))}
            </TextOverflowEllipsisBox>
          </td>

          <td
            style={s('py-2 text-right', {
              borderBottom: '1px solid #F1F5F9',
              width: '5%',
            })}
          >
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: liability.id,
                    versionedLoanApplicationId: liability.versionedLoanApplicationId,
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { cursor: 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  ))

  return (
    <div style={s('mb-4')}>
      <h4 style={s('font-semibold text-sm w-16')}>Long term loan or mortgage</h4>

      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Committed limit</th>
            <th>Repayment type</th>
            <th>Purpose</th>
            <th>Remaining term</th>
            <th>Interest rate</th>
            <th>Sensitised</th>
            <th>Cost of servicing ($pm)</th>
            <th>Effective cost of servicing ($pm)</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      {liabilities.length !== 0 && (
        <>
          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
            <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
              Total monthly cost of servicing
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalCostOfServicing(liabilities))}
            </span>
          </div>

          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
            <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
              Total monthly cost of servicing (effective)
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalEffectiveCostOfServicing(liabilities))}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
