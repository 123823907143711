import { prop } from '@solta/ramda-extra'
import { loanSplitsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const { selectIsPending: selectIsCreatingLoanSplit } = createAsyncThunkSelectors(
  'loanSplit/createLoanSplit'
)

export const { selectIsPending: selectIsUpdatingLoanSplit } = createAsyncThunkSelectors(
  'loanSplit/updateLoanSplit'
)

export const {
  selectIsPending: selectIsFetchingLoanSplits,
} = createAsyncThunkSelectors('loanSplit/fetchLoanSplits')

export const { selectAll: selectAllLoanSplits } = loanSplitsAdapter.getSelectors(
  prop('loanSplit')
)
