import React from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { DataDisplay, Divider as DividerBase } from '../components'
import { formatPhoneNumber, formatFullName } from 'utils'

const Container = styled.div()
const Heading = styled.div(
  s('mb-2 text-xs text-primary font-semibold inline-block', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const Divider = styled(DividerBase)(s('my-5'))

const AddedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mx-2 bg-green-300 text-white text-sm font-semibold', {
    paddingTop: 2,
    paddingBottom: 2,
  })
)
const RemovedTag = styled.div(
  s('inline-block mb-2 rounded-md px-2 mx-2 text-sm font-semibold text-white', {
    backgroundColor: 'red',
    paddingTop: 2,
    paddingBottom: 2,
  })
)

const Business = ({ business = {}, index, ...props }) => {
  const { address, name, nzbn, structure, type, directors, versionedState } = business

  return (
    <Container {...props}>
      <div className="flex">
        <Heading>BUSINESS {index + 1}</Heading>
        {versionedState === 'added' && <AddedTag>ADDED</AddedTag>}
        {versionedState === 'removed' && <RemovedTag>REMOVED</RemovedTag>}
      </div>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <DataDisplay label="Business Name" value={name} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Type" value={type} />
        </Col>

        <Col span={12}>
          <DataDisplay label="NZBN" value={nzbn} />
        </Col>

        <Col span={12}>
          <DataDisplay label="Business Structure" value={structure} />
        </Col>

        <Col span={24}>
          <DataDisplay label="Business Address" value={address} />
        </Col>
      </Row>

      {directors.map((director, index) => (
        <Row gutter={[16, 16]} style={s('mt-2')} key={index}>
          <Col span={24}>
            <Heading style={s('mb-0 ')}>DIRECTOR {index + 1}</Heading>
          </Col>
          <Col span={12}>
            <DataDisplay
              label="Name"
              value={formatFullName({
                firstName: director?.firstName,
                lastName: director?.surname,
              })}
            />
          </Col>
          <Col span={12}>
            <DataDisplay label="Email" value={director.email} />
          </Col>
          <Col span={12}>
            <DataDisplay label="Phone" value={formatPhoneNumber(director.phone)} />
          </Col>
        </Row>
      ))}
      <Divider />
    </Container>
  )
}

export { Business }
