import { buildEntityName } from 'utils/entity'
import { isNotNilOrEmpty } from '@solta/ramda-extra'

const getCurrentAddress = (linkedAddress, initialAddress) => {
  return isNotNilOrEmpty(linkedAddress) ? linkedAddress : initialAddress
}

const mapSecurity = (security, loanApplication) => {
  const { estimatedValue, linkedDetails, id: securityId, address = {} } = security
  const { customSecurityValuations } = loanApplication

  const customSecurityValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )

  const linkedAddress = linkedDetails?.address

  const customEstimatedValue = customSecurityValuation?.value
  const ivalEstimatedValue = linkedDetails?.valuation?.estimatedValue
  const myCrmEstimatedValue = estimatedValue?.value

  const currentFullAddress = getCurrentAddress(linkedAddress, address?.fullAddress)

  return {
    ...security,
    address: {
      ...address,
      fullAddress: currentFullAddress,
    },
    finalAssetValue:
      customEstimatedValue || ivalEstimatedValue || myCrmEstimatedValue || undefined,
  }
}

export const mapLegalEntity = (legalEntity) => {
  return {
    ...legalEntity,
    fullName: buildEntityName(
      {
        firstName: legalEntity?.personName?.firstName,
        lastName: legalEntity?.personName?.surname,
      },
      legalEntity?.trustName,
      legalEntity?.businessName,
      legalEntity?.companyName
    ),
  }
}

const mapLoanApplication = (loanApplication) => {
  return {
    ...loanApplication,
    securities: loanApplication?.securities?.map((s) =>
      mapSecurity(s, loanApplication)
    ),
    legalEntities: {
      individuals: loanApplication?.legalEntities?.individuals?.map(mapLegalEntity),
      trusts: loanApplication?.legalEntities?.trusts?.map(mapLegalEntity),
      companies: loanApplication?.legalEntities?.companies?.map(mapLegalEntity),
    },
  }
}

export { mapLoanApplication }
