import React, { useState } from 'react'
import { PrimaryIncomeTable } from './PrimaryIncomeTable'
import { SecondaryIncomeTable } from './SecondaryIncomeTable'
import { BusinessIncomeTable } from './BusinessIncomeTable'
import { AddIncomeForm } from './AddIncomeForm'
import { Button } from '@vega/components'
import { s } from '@vega/styled/v2'

export const IncomeUMITable = ({
  householdRef,
  incomes,
}) => {
  const [showAddForm, setShowAddForm] = useState(false)

  const incomesOfType = (incomeType) => incomes.filter(
    (income) => income.details.umiIncomeType === incomeType
  )

  const declaredSecondaryTaxRate = () => {
    const totalDeclaredIncomeAnnual = incomes
      .reduce((acc, curr) => (acc + curr.details.declaredAmount || 0), 0)
      * 12

    switch (true) {
      case (totalDeclaredIncomeAnnual <= 14000): return 0.105
      case (totalDeclaredIncomeAnnual <= 48000): return 0.175
      case (totalDeclaredIncomeAnnual <= 70000): return 0.3
      case (totalDeclaredIncomeAnnual <= 180000): return 0.33
      default: return 0.39
    }
  }

  return (
    <>
      <div style={s('bg-white rounded-lg p-6 mb-2')}>
        <h3 style={s('my-1')}>
          Primary income
        </h3>
        <PrimaryIncomeTable
          incomes={incomesOfType('primary')}
        />

        <h3 style={s('my-1')}>
          Secondary income
        </h3>
        <SecondaryIncomeTable
          incomes={incomesOfType('secondary')}
          declaredTaxRate={declaredSecondaryTaxRate()}
        />

        <h3 style={s('my-1')}>
          Business income
        </h3>
        <BusinessIncomeTable
          incomes={incomesOfType('business')}
        />
      </div>

      {
        showAddForm && (
          <div style={s('bg-white rounded-lg p-8')}>
            <AddIncomeForm
              householdRef={householdRef}
              closeForm={() => {setShowAddForm(false)}}
            />
          </div>
        )
      }

      <h4 style={s('my-1')}>
        Add another income soure?
      </h4>
      <Button
        onClick={() => setShowAddForm(!showAddForm)}
        style={s('mb-16')}
      >
        Add income
      </Button>
    </>
  )
}
