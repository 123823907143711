import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { s } from '@vega/styled/v2'
import { formatCurrency, capitaliseFirstLetter } from 'utils/formatters'
import { totalMonthlyExpenses } from './utils'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

import { Formik, Field } from 'formik'
import { Tooltip } from '@mui/material'
import { ReactComponent as QuestionMarkCircleIcon } from '@vega/components/src/assets/images/question-mark-circle.svg'

export const ExpenseTable = ({ expenseType, expenses, benchmarkLivingExpenditure }) => {
  const dispatch = useDispatch()

  const relevantExpenses = expenses.filter(
    (expense) => expense.details.expenseType === expenseType
  )

  const [currentExpenses, setCurrentExpenses] = useState(relevantExpenses)

  if (relevantExpenses.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No expenses currently recorded
      </p>
    )
  }

  const monthlyMaxofDeclaredAndBenchmark = Math.max(
    benchmarkLivingExpenditure,
    totalMonthlyExpenses(relevantExpenses)
  )

  const expenseTypeLabels = {
    fixed: 'fixed commitment costs',
    essential: 'essential living costs',
    discretionary: 'discretionary living costs',
  }

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const readOnlyExpenseRow = (expense) => (
    <tr key={expense.id}>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '20%' })}>
        {capitaliseFirstLetter(expense.details.expenseCategory)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '20%' })}>
        {capitaliseFirstLetter(expense.details.frequency)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
        {formatCurrency(expense.details.declaredAmount)}{' '}
        {expense.details.declaredAmountBreakdown.length > 0 ? (
          <Tooltip
            title={
              <table style={s('text-sm')}>
                <tbody>
                  {expense.details.declaredAmountBreakdown.map((item, index) => (
                    <tr key={index}>
                      <td>${item.amount}</td>
                      <td>{item.reference}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          >
            <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
          </Tooltip>
        ) : (
          ''
        )}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
        {formatCurrency(expense.details.validatedAmount)}{' '}
        {expense.details.validatedAmountBreakdown.length > 0 ? (
          <Tooltip
            title={
              <table style={s('text-sm')}>
                <tbody>
                  {expense.details.validatedAmountBreakdown.map((item, index) => (
                    <tr key={index}>
                      <td>${item.amount}</td>
                      <td>{item.reference}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          >
            <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
          </Tooltip>
        ) : (
          ''
        )}
      </td>
    </tr>
  )

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const editableExpenseRow = (expense) => (
    <Formik
      key={expense.id}
      initialValues={expense}
      onSubmit={(values) => {
        setCurrentExpenses(
          currentExpenses.filter((expense) => expense.id !== values.id).concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '20%' })}>
            {capitaliseFirstLetter(expense.details.expenseCategory)}
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.frequency"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="halfYearly">Half-yearly</option>
              <option value="annually">Annually</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
            {formatCurrency(expense.details.declaredAmount)}{' '}
            {expense.details.declaredAmountBreakdown.length > 0 ? (
              <Tooltip
                title={
                  <table style={s('text-sm')}>
                    <tbody>
                      {expense.details.declaredAmountBreakdown.map((item, index) => (
                        <tr key={index}>
                          <td>${item.amount}</td>
                          <td>{item.reference}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              >
                <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
              </Tooltip>
            ) : (
              ''
            )}
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.validatedAmount"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td
            style={s('py-2 text-right', {
              borderBottom: '1px solid #F1F5F9',
              width: '5%',
            })}
          >
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: expense.id,
                    versionedLoanApplicationId: expense.versionedLoanApplicationId,
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { cursor: 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  )

  const expenseRows = relevantExpenses.map((expense) => {
    return expense.readOnly ? readOnlyExpenseRow(expense) : editableExpenseRow(expense)
  })

  return (
    <div style={s('mb-8')}>
      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Type</th>
            <th>Frequency</th>
            <th>Declared amount</th>
            <th>Validated amount</th>
            <th />
          </tr>
        </thead>
        <tbody>{expenseRows}</tbody>
      </table>
      {relevantExpenses.length !== 0 && (
        <>
          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
            <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
              Total monthly {expenseTypeLabels[expenseType]}
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalMonthlyExpenses(relevantExpenses))}
            </span>
          </div>

          {expenseType === 'essential' && (
            <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
              <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
                Monthly maximum of declared and benchmark (household structure)
              </span>
              <span style={s('font-semibold text-sm')}>
                {formatCurrency(monthlyMaxofDeclaredAndBenchmark)}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  )
}
