import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { formatCurrency } from 'utils/formatters'
import { s } from '@vega/styled/v2'
import { ebit, attributablyBusinessIncome } from './utils'
import { Formik, Field } from 'formik'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

export const BusinessIncomeTable = ({ incomes }) => {
  const dispatch = useDispatch()
  const [currentIncomes, setCurrentIncomes] = useState(incomes)

  if (incomes.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No secondary income currently recorded
      </p>
    )
  }

  const totalAttributableIncome = incomes
    .reduce((curr, acc) => curr + attributablyBusinessIncome(acc), 0)
    / 12

  const rows = incomes.map(income => (
    <Formik
      key={income.id}
      initialValues={income}
      onSubmit={(values) => {
        setCurrentIncomes(
          currentIncomes.filter((income) => income.id !== values.id).concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.ownershipPercentage"
              placeholder="Ownership %"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneLabel"
              placeholder="Year"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoLabel"
              placeholder="Year"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>
          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneNetProfitBeforeTax"
              type="number"
              placeholder="Net profit before tax"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoNetProfitBeforeTax"
              type="number"
              placeholder="Net profit before tax"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneInterestPaid"
              type="number"
              placeholder="Interest paid"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoInterestPaid"
              type="number"
              placeholder="Interest paid"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneShareholderSalaries"
              type="number"
              placeholder="Shareholder salaries"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoShareholderSalaries"
              type="number"
              placeholder="Shareholder salaries"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneHomeOffice"
              type="number"
              placeholder="Home office"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoHomeOffice"
              type="number"
              placeholder="Home office"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <Field
              as="input"
              name="details.yearOneDepreciation"
              type="number"
              placeholder="Depreciation"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4 block mb-1')}
            />

            <Field
              as="input"
              name="details.yearTwoDepreciation"
              type="number"
              placeholder="Depreciation"
              onChange={(e) => {
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4')}
            />
          </td>

          <td style={s('py-2', {borderBottom: '1px solid #F1F5F9'})}>
            <div>
              {
                formatCurrency(
                  ebit(
                    props.values.details.yearOneNetProfitBeforeTax,
                    props.values.details.yearOneInterestPaid,
                    props.values.details.yearOneShareholderSalaries,
                    props.values.details.yearOneHomeOffice,
                    props.values.details.yearOneDepreciation
                  )
                )
              }
            </div>
            <div>
              {
                formatCurrency(
                  ebit(
                    props.values.details.yearTwoNetProfitBeforeTax,
                    props.values.details.yearTwoInterestPaid,
                    props.values.details.yearTwoShareholderSalaries,
                    props.values.details.yearTwoHomeOffice,
                    props.values.details.yearTwoDepreciation
                  )
                )
              }
            </div>
          </td>

          <td style={s('py-2 text-right', {borderBottom: '1px solid #F1F5F9'})}>
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: income.id,
                    versionedLoanApplicationId: income.versionedLoanApplicationId
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { 'cursor': 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  ))

  return (
    <div style={s('mb-4')}>
      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Ownership</th>
            <th>Financial year</th>
            <th>Net profit before tax</th>
            <th>Interest paid</th>
            <th>Shareholder salaries</th>
            <th>Home office</th>
            <th>Depreciation</th>
            <th>EBIT</th>
          <th />
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>

      {
        incomes.length !== 0 && (
          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9'})}>
            <span style={s('font-semibold text-sm', {flexGrow: 1})}>
              Total monthly attributable business income
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalAttributableIncome)}
            </span>
          </div>
        )
      }
    </div>
  )
}
