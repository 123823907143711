import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { s } from '@vega/styled/v2'
import { formatCurrency, capitaliseFirstLetter } from 'utils/formatters'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

import { Tooltip } from '@mui/material'
import { Formik, Field } from 'formik'
import { ReactComponent as QuestionMarkCircleIcon } from '@vega/components/src/assets/images/question-mark-circle.svg'

export const SecondaryIncomeTable = ({ incomes, declaredTaxRate }) => {
  const dispatch = useDispatch()
  const [currentIncomes, setCurrentIncomes] = useState(incomes)

  const grossToNet = (income) => {
    if (income.details.source === 'beneficiaries') {
      return income.details.declaredAmount
    }

    return income.details.declaredAmount * (1 - declaredTaxRate) || 0
  }

  if (incomes.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No business income currently recorded
      </p>
    )
  }

  // TODO: should this be in utils
  const monthlyGrossIncome = incomes.reduce(
    (acc, curr) => acc + curr.details.verifiedAmount,
    0
  )

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const readOnlyIncomeRow = (income) => (
    <tr key={income.id}>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {capitaliseFirstLetter(income.details.source)}
      </td>

      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
        {capitaliseFirstLetter(income.details.frequency)}
      </td>

      <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
        {formatCurrency(grossToNet(income))}{' '}
        {income.details.declaredAmountReference && (
          <Tooltip
            title={<p style={s('text-sm')}>{income.details.declaredAmountReference}</p>}
          >
            <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
          </Tooltip>
        )}
      </td>

      <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
        {formatCurrency(income.details.verifiedAmount)}{' '}
        {income.details.validatedAmountReference && (
          <Tooltip
            title={
              <p style={s('text-sm')}>{income.details.validatedAmountReference}</p>
            }
          >
            <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
          </Tooltip>
        )}
      </td>
      <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })} />
    </tr>
  )

  // TODO: Split out to own file
  /* eslint-disable react/no-multi-comp */
  const editableIncomeRow = (income) => (
    <Formik
      key={income.id}
      initialValues={income}
      onSubmit={(values) => {
        setCurrentIncomes(
          currentIncomes.filter((income) => income.id !== values.id).concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.source"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="salary">Salary / wages</option>
              <option value="businessIncome">Business income</option>
              <option value="rentals">Retals</option>
              <option value="overtime">Overtime / allowances / bonus</option>
              <option value="beneficiaries">Beneficiaries</option>
              <option value="incomeNoInNZD">Income in non-NZD</option>
              <option value="commission">Commission</option>
              <option value="interest">Interest</option>
              <option value="dividends">Dividends</option>
              <option value="superannuation">
                Pensions / Superannuation / Annuity
              </option>
              <option value="boarders">Boarders / Flatmates</option>
              <option value="childSupport">Child support maintenance</option>
              <option value="bonus">Bonus</option>
              <option value="carAllowance">Car allowance</option>
              <option value="regularOvertime">Regular Overtime</option>
              <option value="workAllowanceAmount">Work Allowance</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.frequency"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="weekly">Weekly</option>
              <option value="fortnightly">Fortnightly</option>
              <option value="monthly">Monthly</option>
              <option value="quarterly">Quarterly</option>
              <option value="halfYearly">Half-yearly</option>
              <option value="annually">Annually</option>
            </Field>
          </td>

          <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
            {formatCurrency(grossToNet(income))}{' '}
            {income.details.declaredAmountReference && (
              <Tooltip
                title={
                  <p style={s('text-sm')}>{income.details.declaredAmountReference}</p>
                }
              >
                <QuestionMarkCircleIcon style={s('h-1 w-1', { cursor: 'help' })} />
              </Tooltip>
            )}
          </td>

          <td style={s('py-2 text-right', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.verifiedAmount"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', {
                appearance: 'none',
                textAlign: 'right',
              })}
            />
          </td>

          <td
            style={s('py-2 text-right', {
              borderBottom: '1px solid #F1F5F9',
              width: '5%',
            })}
          >
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: income.id,
                    versionedLoanApplicationId: income.versionedLoanApplicationId,
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { cursor: 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  )

  const incomeRows = incomes.map((income) =>
    income.readOnly ? readOnlyIncomeRow(income) : editableIncomeRow(income)
  )

  return (
    <div style={s('mb-8')}>
      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Source</th>
            <th>Frequency</th>
            <th style={s('text-right')}>Declared amount (monthly net)</th>
            <th style={s('text-right')}>Validated amount (monthly net)</th>
            <th />
          </tr>
        </thead>
        <tbody>{incomeRows}</tbody>
      </table>
      {incomes.length !== 0 && (
        <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
          <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
            Total monthly net income (secondary)
          </span>
          <span style={s('font-semibold text-sm')}>
            {formatCurrency(monthlyGrossIncome)}
          </span>
        </div>
      )}
    </div>
  )
}
