import React from 'react'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'

export const IncomeUMISummary = ({
  monthlyNetIncome,
  sensitisedMonthlyNetIncome
}) => {

  return (
    <div style={s('flex')}>
      <h3 style={s('font-normal text-grey-600 mr-8 my-1')}>
        Monthly net income after tax
        <br />
        <span style={s('text-2xl font-bold text-grey-900')}>
          {formatCurrency(monthlyNetIncome)}
        </span>
      </h3>

      <h3 style={s('font-normal text-grey-600 mr-8 my-1')}>
        Sensitised monthly net income after tax
        <br />
        <span style={s('text-2xl font-bold text-grey-900')}>
          {formatCurrency(sensitisedMonthlyNetIncome)}
        </span>
      </h3>
    </div>
  )
}
