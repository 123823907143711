/* eslint-disable complexity */
const monthlyAmount = (expense) => {
  const amountByMonthlySplit = (split) =>
    (expense.details.validatedAmount * split) / 12

  switch (expense.details.frequency) {
    case 'weekly': return amountByMonthlySplit(52)
    case 'fortnightly': return amountByMonthlySplit(26)
    case 'monthly': return amountByMonthlySplit(12)
    case 'quarterly': return amountByMonthlySplit(4)
    case 'halfYearly': return amountByMonthlySplit(2)
    case 'annually': return amountByMonthlySplit(1)
    default: return amountByMonthlySplit(1)
  }
}

export const totalMonthlyExpenses = (expenses) =>
  expenses.reduce((total, expense) => total + monthlyAmount(expense), 0);
