import React from 'react'
import { useDispatch } from 'react-redux'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'
import { updateUmiReportItem } from 'modules/umiReportItems'
import { benchmarkLivingExpenditure } from './utils'
import { Formik, Field } from 'formik'

export const UMIHouseholdStructureTable = ({ structures, monthlyIncome }) => {
  const dispatch = useDispatch()

  const rows = structures.map((structure) => (
    <Formik
      key={structure.id}
      initialValues={structure}
      onSubmit={(values) => {
        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.numberOfAdults"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="oneAdult">1 adult</option>
              <option value="twoAdults">2 adults</option>
              <option value="greaterThanTwoAdults">&gt;2 adults</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.numberOfDependants"
              type="number"
              max={5}
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            {formatCurrency(benchmarkLivingExpenditure(props.values, monthlyIncome))}
          </td>
        </tr>
      )}
    </Formik>
  ))

  return (
    <div style={s('bg-white rounded-lg p-8 mb-24')}>
      <h3>Household information</h3>
      {structures.length === 0 && (
        <p style={s('text-sm font-semibold text-grey-600')}>
          No structure currently recorded
        </p>
      )}
      {structures.length > 0 && (
        <table style={s('w-full')}>
          <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
            <tr>
              <th>No. of adult in household</th>
              <th>No. of dependents</th>
              <th>Benchmark essential living expenditure ($pm)</th>
              <th />
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      )}
    </div>
  )
}
