import React from 'react'
import { s } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { IncomesTable } from './IncomesTable'
import { LivingExpense } from './LivingExpense'
import { RealEstateTable } from './RealEstateTable'
import { NonRealAssetTable } from './NonRealAssetTable'
import { LiabilitiesTable } from './LiabilitiesTable'
import { NetPositionEquation } from './NetPositionEquation'
import { CircumstanceChanges } from './CircumstanceChanges'
import { HousehouldArrears } from './HouseholdArrears'
import { InsuranceDeclaration } from './InsuranceDeclaration'
import { NoteSection } from 'features/common/NoteSection'
import { DetailsToggle } from './DetailsToggle'
import { NetEquityEquation } from './NetEquityEquation'
import {
  financialsByType,
  financialsForEntities,
  mergeIndividualsDetails,
  householdTitle,
  mapToAdverseChanges,
  rentalIncomesFromAssets,
  householdArrearsSummary,
  entityInsuranceDeclaration,
  employmentIncomesForIndividual,
  expensesForEntities,
  financialsOtherIncomes,
} from './utils'

export const HouseholdFinancialPosition = ({
  versionedLoanApplicationId,
  latestVersionId,
  householdIndividuals = [],
  versionedFinancials = [],
  householdFinancials = [],
  relatedCompanies,
  loanSecurities = [],
  isGuarantor = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)
  const circumstanceChanges = mapToAdverseChanges(householdIndividuals)

  const householdExpenses = expensesForEntities(
    (householdFinancials || []).flatMap((household) =>
      household?.livingExpenses?.concat(household?.otherExpenses)
    ),
    householdIndividuals
  )

  const householdRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'real_estate_asset'),
    householdIndividuals
  )

  const householdEmploymentIncomes = householdIndividuals
    .map((individual) => employmentIncomesForIndividual(individual, relatedCompanies))
    .flat()

  const householdRentalIncomes = rentalIncomesFromAssets(
    mergeIndividualsDetails(householdRealAssets, householdIndividuals)
  )

  const financialIncomes = financialsForEntities(
    financialsByType(versionedFinancials, 'income'),
    householdIndividuals
  )

  const otherIncomes = financialsOtherIncomes(
    mergeIndividualsDetails(financialIncomes, householdIndividuals)
  )

  const householdIncomes = householdRentalIncomes
    .concat(householdEmploymentIncomes)
    .concat(otherIncomes)

  const householdNonRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'non_real_estate_asset'),
    householdIndividuals
  )

  const householdLiabilities = financialsForEntities(
    financialsByType(versionedFinancials, 'liability'),
    householdIndividuals
  )

  return (
    <>
      <div style={s('flex')}>
        <h1 style={{ color: '#490B2D', flexGrow: 1 }}>
          <span style={s('text-grey-600 block text-sm font-semibold')}>
            {isGuarantor
              ? "Guarantor's financial position"
              : "Joint applicant's financial position"}
          </span>

          {householdTitle(householdIndividuals)}
        </h1>

        <DetailsToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>

      <NetPositionEquation incomes={householdIncomes} expenses={householdExpenses} />

      {isExpanded && (
        <>
          <ContentCard style={s('mb-4')}>
            <IncomesTable incomes={householdIncomes} />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <LivingExpense expenses={householdExpenses} />
          </ContentCard>
        </>
      )}

      <NetEquityEquation
        assets={householdRealAssets.concat(householdNonRealAssets)}
        liabilities={householdLiabilities}
      />

      {isExpanded && (
        <>
          <ContentCard style={s('mb-4')}>
            <RealEstateTable
              realAssetFinancials={mergeIndividualsDetails(
                householdRealAssets,
                householdIndividuals
              )}
              displayOwnership={true}
            />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <NonRealAssetTable
              nonRealAssetFinancials={mergeIndividualsDetails(
                householdNonRealAssets,
                householdIndividuals
              )}
              displayOwnership={true}
            />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <LiabilitiesTable
              liabilities={mergeIndividualsDetails(
                householdLiabilities,
                householdIndividuals
              )}
              loanSecurities={loanSecurities}
            />
          </ContentCard>

          <CircumstanceChanges circumstances={circumstanceChanges} />

          <HousehouldArrears
            arrears={householdArrearsSummary(householdTitle(householdIndividuals))}
          />

          <InsuranceDeclaration
            declarations={entityInsuranceDeclaration(
              householdTitle(householdIndividuals)
            )}
          />
        </>
      )}

      <div style={s('mb-4')}>
        <NoteSection
          id="note-section"
          versionedLoanApplicationId={versionedLoanApplicationId}
          loanApplicationId={latestVersionId}
          source={`loan-applications/financial/household/${householdIndividuals[0].household.externalRef}`}
        />
      </div>
    </>
  )
}
