const routes = /** @type {const} -- make this readonly */ ({
  loanApp: {
    root: '/loan-applications',
    assessment: {
      root: '/loan-applications/:id',
      info: '/loan-applications/:id/info',
      financial: '/loan-applications/:id/financial',
      security: {
        root: '/loan-applications/:id/security',
        details: '/loan-applications/:id/security/:securityId',
        ivalReport: '/loan-applications/:id/security/:securityId/ival-report',
      },
      serviceability: '/loan-applications/:id/serviceability',
      structure: '/loan-applications/:id/structure',
      offer: '/loan-applications/:id/offer',
      settlement: '/loan-applications/:id/settlement',
    },
  },
  account: '/account',
})

export { routes }
