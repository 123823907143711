import {
  capitaliseFirstLetter,
  capitaliseFirstLetterOfAllWords,
} from 'utils/formatters'

const formatType = (type, value) => {
  let formattedType = capitaliseFirstLetter(type)

  if (type !== 'months' && type !== 'years') formattedType = ''

  if (type === 'months' && value === '1') formattedType = 'Month'

  return formattedType
}

const formatValue = (type, value) => {
  let formattedValue = capitaliseFirstLetterOfAllWords(value)

  if (value === 'n') formattedValue = 'No'

  if (value === 'y') formattedValue = 'Yes'

  if (type === 'amount') formattedValue = `$${value}`

  return formattedValue
}

export const mapSummaryItem = (item) => {
  const { name, type, value = '-' } = item

  const formattedName = name
    .split('-')
    .map((word) => capitaliseFirstLetter(word))
    .join(' ')

  const formattedType = formatType(type, value)

  const formattedValue = formatValue(type, value)

  return { name: formattedName, type: formattedType, value: formattedValue }
}
