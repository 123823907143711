/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { s } from '@vega/styled/v2'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import { VersionedValue } from 'features/common'

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const tableColumns = (displayOwnership) => {
  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      tableHeaderStyles: headerStyleBase({ width: '35%' }),
      tableCellStyles: cellStyleBase({ width: '35%' }),
      Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
    },
    {
      Header: 'Details',
      accessor: 'details',
      tableHeaderStyles: headerStyleBase({ width: displayOwnership ? '35%' : '45%' }),
      tableCellStyles: cellStyleBase({ width: displayOwnership ? '35%' : '45%' }),
      Cell: ({ value }) => (
        <TextOverflowEllipsisBox>
          <VersionedValue values={value} />
        </TextOverflowEllipsisBox>
      ),
    },
    {
      Header: 'Ownership*',
      accessor: 'ownership',
      tableHeaderStyles: headerStyleBase({ width: '10%' }),
      tableCellStyles: cellStyleBase({ width: '10%' }),
      Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
    },
    {
      Header: 'Value',
      accessor: 'value',
      tableHeaderStyles: headerStyleBase({ width: '20%', textAlign: 'right' }),
      tableCellStyles: cellStyleBase({ width: '20%', flexDirection: 'row-reverse' }),
      Cell: ({ value }) => (
        <TextOverflowEllipsisBox>
          <VersionedValue values={value} />
        </TextOverflowEllipsisBox>
      ),
    },
  ]

  if (!displayOwnership) {
    columns.splice(2, 1)
  }

  return columns
}

const findAssetDescriptionField = (financial) => {
  let description
  let domainType
  switch (financial.domainType) {
    case 'Motor Vehicle and Transport':
      description = financial.motorVehicle.description
      domainType = financial.motorVehicle.domainType
      break
    case 'Financial Asset':
      description = financial.financialAsset.description
      domainType = financial.financialAsset.domainType
      break
    case 'Business':
      description = financial.business.mainBusinessActivity
      domainType = 'Business'
      break
    case 'Tools of Trade':
      description = financial.toolsOfTrade.description
      domainType = financial.toolsOfTrade.domainType
      break
    default:
      description = financial.otherAsset?.description
      domainType = financial.otherAsset?.domainType
  }
  return { description, domainType }
}

export const NonRealAssetTable = ({
  nonRealAssetFinancials = [],
  displayOwnership,
}) => {
  const ownershipLabel = (asset) => {
    const {
      percentOwned: { owners },
    } = asset

    const contributingOwners = owners
      .filter((owner) => owner.percent > 0)
      .map((owner) => {
        return {
          name: owner.legalEntity.firstName || owner.legalEntity.businessName,
          percent: owner.percent,
        }
      })

    if (contributingOwners.length === 1) {
      return `${contributingOwners[0].name} ${contributingOwners[0].percent}%`
    }

    return contributingOwners.map((owner) => `${owner.percent}%`).join(' - ')
  }

  const tableData = nonRealAssetFinancials

    .sort(
      (a, b) =>
        formatStringToNumber(
          b.estimatedValue?.value[b.estimatedValue.value.length - 1]
        ) -
        formatStringToNumber(a.estimatedValue?.value[a.estimatedValue.value.length - 1])
    )

    .reduce((tableDataSoFar, asset) => {
      const { estimatedValue } = asset

      const typeLabel = findAssetDescriptionField(asset)?.domainType
      const descriptionLabel = findAssetDescriptionField(asset)?.description

      const datum = {
        type: typeLabel,
        details: descriptionLabel,
        value: estimatedValue.value,
      }

      if (displayOwnership) {
        datum.ownership = ownershipLabel(asset)
      }

      return tableDataSoFar.concat([datum])
    }, [])

  const totalSecurityValue = nonRealAssetFinancials
    .map((asset) => {
      const { value } = asset.estimatedValue
      const amount = value[value.length - 1]
      return formatStringToNumber(amount)
    })
    .reduce((total, assetValue) => {
      return total + assetValue
    }, 0)

  const ownershipDescription = () => {
    return [
      ...new Set(
        nonRealAssetFinancials
          .map((asset) => asset.percentOwned.owners)
          .flat()
          .filter((owner) => owner.percent > 0)
          .map((owner) => {
            return owner.legalEntity.businessName
              ? owner.legalEntity.businessName
              : `${owner.legalEntity.firstName} ${owner.legalEntity.lastName}`
          })
      ),
    ].join(' - ')
  }

  if (tableData.length === 0) {
    return (
      <>
        <h3 style={{ color: '#490B2D' }}>Assets</h3>
        <p style={s('text-grey-600 text-sm font-semibold')}>No data found</p>
      </>
    )
  }

  return (
    <>
      <h3 style={{ color: '#490B2D' }}>Assets</h3>

      <Table
        columns={tableColumns(displayOwnership)}
        data={tableData}
        tableHeight="initial"
        rootStyle={{ margin: 0 }}
      />

      <div style={{ textAlign: 'right' }}>
        <h5 style={{ color: '#490B2D' }}>
          Total asset
          <span style={s('inline-block w-8')}>
            {formatCurrency(totalSecurityValue)}
          </span>
        </h5>
      </div>

      {displayOwnership && (
        <span style={s('text-sm text-grey')}>
          *Ownership = {ownershipDescription()}
        </span>
      )}
    </>
  )
}
