import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { isNilOrEmpty } from '@solta/ramda-extra'

const Label = styled.p(
  s('font-normal m-0 text-grey-700 text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Value = styled.p(
  s('font-semibold m-0 text-base text-grey-800', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

/**
 * @typedef {Object} Props
 * @property {string} label
 * @property {string | number} value
 */

/** @param {Props} props */
const DataDisplay = ({
  label,
  labelStyles,
  value = '-',
  valueStyles,
  testId,
  ...props
}) => {
  return (
    <div {...props}>
      <Label style={labelStyles}>{label}</Label>
      <Value data-test-id={testId} style={valueStyles}>
        {isNilOrEmpty(value) ? '-' : value}
      </Value>
    </div>
  )
}

export { DataDisplay }
