import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { formatBoolean } from 'utils/formatters'

const Subtitle = styled.p(
  s('font-semi-bold my-3 text-black text-lg', {
    letterSpacing: '0.01em',
    lineHeight: '21px',
    fontSize: '14px'
  })
)

const SubtitleValue = styled.span(s(
  'font-semibold text-primary',
  { float: 'right' }
))

const Title = styled.h3(s(
  'mb-0 text-grey-900 text-xl',
  {
    letterSpacing: '-0.01em',
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: '-4px',
    marginBottom: '21px'
  })
)

export const HousehouldArrears = ({ arrears }) => {
  const {
    householdTitle,
    arrearsSummary,
    currentArrears,
    previousArrears,
    directorLast7Years,
    goodAccountSatement
  } = arrears;

  const hasMyCrmData = false;

  if (!hasMyCrmData) {
    return (
      <ContentCard style={s('mb-4')}>
        <Title >
          {`${householdTitle} arrears`}
        </Title>

        <span style={s('text-sm font-semibold text-grey-600')}>
          Data not yet in myCRM payload
        </span>
      </ContentCard>
    )
  }

  return (
    <ContentCard style={s('mb-4')}>
      <Title >
        {`${householdTitle} arrears`}
      </Title>

      <Subtitle>
        Previous/Current Arrear(s): Any previous issues to debt arrears including
        credit cards, personal loans, car loans, short term loans, monies owing
        to friends/family, education loans, credit declines/bankruptcy.

        <SubtitleValue>
          {arrearsSummary}
        </SubtitleValue>
      </Subtitle>

      <Subtitle>
        Current Arrears?
        <SubtitleValue>
          {formatBoolean(currentArrears)}
        </SubtitleValue>
      </Subtitle>

      <Subtitle>
        Previous Arrears?
        <SubtitleValue>
          {formatBoolean(previousArrears)}
        </SubtitleValue>
      </Subtitle>

      <Subtitle>
        Director in the last 7 years?

        <SubtitleValue>
          {formatBoolean(directorLast7Years)}
        </SubtitleValue>
      </Subtitle>

      <Subtitle>
        Do you have a good account conduct (e.g. no missed payments, dishonours
        or unarranged overdrafts)?

        <SubtitleValue>
          {formatBoolean(goodAccountSatement)}
        </SubtitleValue>
      </Subtitle>
    </ContentCard>
  )
}
