/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { ValocityAddressField, Modal } from '@vega/components'
import { ContentCard as ContentCardBase } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { isNotNilOrEmpty, noop } from '@solta/ramda-extra'

import * as Validator from 'yup'

import { ReactComponent as CloseIconBase } from '@vega/components/src/assets/images/x.svg'
import { ReactComponent as SearchIconBase } from '@vega/components/src/assets/images/search.svg'
import { Formik, Form } from 'formik'
import { useManualLinkProperty } from './useManualLinkProperty'

const ContentCard = styled(ContentCardBase)(s('p-6 pt-1', { width: 703, height: 312 }))
const SearchIcon = styled(SearchIconBase)(s('mr-2'))

const CloseIcon = styled(CloseIconBase)(s('w-1 h-1 mt-5'), {
  '&:hover': { cursor: 'pointer' },
})

const CardTitle = styled.h3(s('mb-2'))
const DescriptionText = styled.p(s('text-sm mt-0'))

const LinkButton = styled.button(
  s('border-transparent rounded-lg text-center w-full mt-8 py-2'),
  { '&:hover': { cursor: 'pointer' } },
  ({ allowsLink }) => allowsLink && s('bg-secondary')
)

const { string, object } = Validator

const validationSchema = object({
  startDate: string().required('You must input an address'),
})

export const LinkValocityModal = ({ isOpen, setIsOpen, security }) => {
  const close = () => setIsOpen(false)

  const {
    onAddressSelectionChange,
    onPropertyLink,
    currentSearchedProperty,
  } = useManualLinkProperty(
    security,
    useCallback(() => close(), [])
  )

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Formik
        enableReinitialize
        initialValues={{ search: '' }}
        validationSchema={validationSchema}
        onSubmit={noop}
      >
        <Form>
          <ContentCard>
            <Row gutter={[0, 0]}>
              <Col span={22}>
                <CardTitle>Link a property to Valocity</CardTitle>
              </Col>
              <Col span={2}>
                <div style={s('flex flex-row justify-end')}>
                  <CloseIcon onClick={close} />
                </div>
              </Col>
              <Col span={24}>
                <DescriptionText>
                  To get started, type in the address you want to link to Valocity. iVal
                  report is available after the connection
                </DescriptionText>
              </Col>
              <Col span={24}>
                <ValocityAddressField
                  name="search"
                  placeholder="Enter the address"
                  aria-label="property address"
                  style={s('bg-white')}
                  onSelectionChange={onAddressSelectionChange}
                  InputPrefixIcon={<SearchIcon />}
                  menuStyles={s('max-h-24 mt-8', {
                    overflowY: 'auto',
                  })}
                />
              </Col>
              <Col span={24}>
                <div style={s('text-sm text-left text-grey-600 mt-1')}>
                  New Zealand Addresses only!
                </div>
              </Col>
              <Col span={24}>
                <LinkButton
                  onClick={onPropertyLink}
                  allowsLink={isNotNilOrEmpty(currentSearchedProperty)}
                >
                  Link Now
                </LinkButton>
              </Col>
            </Row>
          </ContentCard>
        </Form>
      </Formik>
    </Modal>
  )
}
