import { propEq } from '@solta/ramda-extra'
import { round } from 'mathjs'
import { capitaliseFirstLetterOfAllWords } from 'utils/formatters'

export class LoanStructuresMapper {
  constructor(
    allocatedLoanStructures = [],
    allProducts = [],
    allSecurities = [],
    allBorrowers = [],
    allGuarantors = [],
    customSecurityValuations = []
  ) {
    this.allocatedLoanStructures = allocatedLoanStructures
    this.allProducts = allProducts
    this.allSecurities = allSecurities
    this.allBorrowers = allBorrowers
    this.allGuarantors = allGuarantors
    this.customSecurityValuations = customSecurityValuations
  }

  map() {
    return this.allocatedLoanStructures.map((s) => this.mapAllocatedLoanStructure(s))
  }

  mapAllocatedLoanStructure(allocatedLoanStructure) {
    const {
      id,
      productId,
      loanAmount,
      repaymentDay,
      repaymentFrequency,
      associatedBorrowers,
      guarantors,
      primaryApplicantId,
      loanTermInYears,
      securities,
      isCrossCollateral,
      loading,
      loanPurpose,
      occupancyType,
    } = allocatedLoanStructure

    const enrichedProduct = getEnrichedProduct(this.allProducts, productId)
    const baseInterestRate = enrichedProduct?.fields?.ongoingRate

    const totalInterestRate = baseInterestRate + loading

    const mappedAllocatedLoanSplit = {
      structureId: id,
      loanTermInYears,
      product: this.mapToProduct(enrichedProduct),
      repaymentFrequency,
      dayOfTheMonth:
        repaymentFrequency === 'monthly' ? repaymentDay.toString() : undefined,
      dayOfTheWeek:
        repaymentFrequency === 'weekly' ? repaymentDay.toString() : undefined,
      dayOfTheFortnight:
        repaymentFrequency === 'fortnightly' ? repaymentDay.toString() : undefined,
      repaymentDay,
      primaryApplicant: primaryApplicantId,
      associatedBorrowers: this.mapEntities(associatedBorrowers, this.allBorrowers),
      guarantors: this.mapEntities(guarantors, this.allGuarantors),
      securityProperties: this.mapSecurities(securities),
      loanAmount,
      loading,
      baseInterestRate,
      totalInterestRate,
      isCrossCollateral,
      loanPurpose,
      occupancyType,
    }

    return {
      ...mappedAllocatedLoanSplit,
      allocatedStructureBeforeEditing: mappedAllocatedLoanSplit,
      isAllocated: true,
      isEditMode: false,
    }

    function getEnrichedProduct(enrichProducts, productId) {
      return enrichProducts.find((product) => product?.id === productId)
    }
  }

  mapToProduct(product) {
    if (!product) return {}

    const {
      id,
      name,
      fields: { maxLoanSplits, minTerm, maxTerm, minLoanAmount, maxLoanAmount },
    } = product

    const minTermInYears = minTerm ? round(minTerm / 12, 2) : minTerm
    const maxTermInYears = maxTerm ? round(maxTerm / 12, 2) : maxTerm

    return {
      id,
      maxLoanSplits,
      minTermInYears,
      maxTermInYears,
      minLoanAmount,
      maxLoanAmount,
      name,
    }
  }

  mapEntities(entities = [], enrichedEntities = []) {
    const mappedEntities = []

    entities.forEach((entity) => {
      const matchedEntity = enrichedEntities.find(propEq('entityId', entity?.id))

      if (matchedEntity)
        mappedEntities.push({
          id: matchedEntity?.entityId,
          fullName: matchedEntity?.fullName,
          legalEntityType: matchedEntity?.legalEntityType,
        })
    })

    return mappedEntities
  }

  mapSecurities(securities = []) {
    return securities.map((security) => {
      const matchedSecurity = this.allSecurities.find(propEq('id', security?.id))
      const { linkedDetails, address, finalAssetValue } = matchedSecurity || {}

      return {
        id: security?.id,
        value: finalAssetValue,
        address:
          capitaliseFirstLetterOfAllWords(linkedDetails?.address) ||
          address?.fullAddress,
        allocationPercentage: security?.securityPercentage,
      }
    })
  }
}
