/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */

import { formatCurrency, formatBoolean, formatNull } from 'utils/formatters'

export const versionedValues = (loanInfo = {}, versionedLoanInfo = {}) => {
  const { requestedLoans, contributionFunds, needsAnalysis } = loanInfo

  const {
    versionedTotalLoanAmount,
    versionedLoanPurpose,
    versionedRequestedLoans,
    versionedContributionFunds,
    versionedNeedsAnalysis,
  } = versionedLoanInfo

  const versionedLoanInfoValues = {
    versionedTotalLoanAmount,
    versionedAmountRequested: [],
    versionedTermTypeCycle: [],
    versionedTotalTermDuration: [],
    versionedPurpose: [],
    versionedIoDuration: [],
    versionedOwners: [],
    versionedContributionFundsTotal: [],
    versionedFunds: [],
    versionedNeedsAnalysisValues: {},
  }

  requestedLoans?.map((loan, index) => {
    versionedLoanInfoValues.versionedAmountRequested[index]
      ? (versionedLoanInfoValues.versionedAmountRequested[index] = [
          ...versionedLoanInfoValues.versionedAmountRequested[index],
          formatCurrency(loan?.amountRequested),
        ])
      : versionedLoanInfoValues.versionedAmountRequested.push([
          formatCurrency(loan?.amountRequested),
        ])

    const termCycle = `${loan?.term?.paymentType}/${loan?.statementCycle}`
    versionedLoanInfoValues.versionedTermTypeCycle[index]
      ? (versionedLoanInfoValues.versionedTermTypeCycle[index] = [
          ...versionedLoanInfoValues.versionedTermTypeCycle[index],
          termCycle,
        ])
      : versionedLoanInfoValues.versionedTermTypeCycle.push([termCycle])

    const duration = `${
      loan?.term?.totalTermDuration
    } ${loan.term.totalTermUnits.toLowerCase()}`
    versionedLoanInfoValues.versionedTotalTermDuration[index]
      ? (versionedLoanInfoValues.versionedTotalTermDuration[index] = [
          ...versionedLoanInfoValues.versionedTotalTermDuration[index],
          duration,
        ])
      : versionedLoanInfoValues.versionedTotalTermDuration.push([duration])

    const purpose = versionedLoanPurpose[index]?.primaryPurpose
    versionedLoanInfoValues?.versionedPurpose[index]
      ? (versionedLoanInfoValues.versionedPurpose[index] = [
          ...versionedLoanInfoValues.versionedPurpose[index],
          purpose,
        ])
      : versionedLoanInfoValues.versionedPurpose.push([purpose])

    const ioDuration =
      loan.term?.paymentTypeDuration && loan.term?.paymentTypeUnits
        ? `${loan.term.paymentTypeDuration} ${loan.term.paymentTypeUnits.toLowerCase()}`
        : '-'
    versionedLoanInfoValues.versionedIoDuration[index]
      ? (versionedLoanInfoValues.versionedIoDuration[index] = [
          ...versionedLoanInfoValues.versionedIoDuration[index],
          ioDuration,
        ])
      : versionedLoanInfoValues.versionedIoDuration.push([ioDuration])

    const previousBorrowers =
      versionedRequestedLoans[
        versionedRequestedLoans?.length === 1 ? 0 : versionedRequestedLoans?.length - 2
      ][index]?.borrowers?.owners || []

    const currentBorrowers = loan?.borrowers?.owners

    const versionedOwnerData = currentBorrowers?.map((currentBorrower) => {
      const previousBorrower = previousBorrowers?.find(
        (previousBorrower) =>
          previousBorrower?.externalRef === currentBorrower?.externalRef
      )

      return {
        name:
          previousBorrower?.name !== undefined
            ? [previousBorrower?.name, currentBorrower.name]
            : [currentBorrower.name],
        percent:
          previousBorrower?.percent !== undefined
            ? [`${previousBorrower?.percent}%`, `${currentBorrower.percent}%`]
            : [`${currentBorrower.percent}%`],
      }
    })

    versionedLoanInfoValues?.versionedOwners?.push(versionedOwnerData)
    return []
  })

  versionedContributionFunds?.map((array) => {
    versionedLoanInfoValues.versionedContributionFundsTotal = [
      ...versionedLoanInfoValues?.versionedContributionFundsTotal,
      formatCurrency(array?.totalAmount),
    ]
    return []
  })

  const fundData = []
  contributionFunds?.funds?.map((fund, index) => {
    versionedContributionFunds?.map((version) => {
      if (fundData[index]) {
        fundData[index].push({
          amounts: version?.funds[index]?.amount || '-',
          descriptions: version?.funds[index]?.description || '-',
          domainTypes: version?.funds[index]
            ? `FUND ${index + 1} - ${version?.funds[index]?.domainType}`
            : '-',
        })
      } else {
        fundData.push([
          {
            amounts: version?.funds[index]?.amount || '-',
            descriptions: version?.funds[index]?.description || '-',
            domainTypes: version?.funds[index]
              ? `FUND ${index + 1} - ${version?.funds[index]?.domainType}`
              : '-',
          },
        ])
      }
      return []
    })
    return []
  })
  versionedLoanInfoValues.versionedFunds = fundData?.map((arr) => {
    const result = arr.reduce(
      (acc, curr) => {
        acc.amounts = acc.amounts.concat(curr.amounts)
        acc.descriptions = acc.descriptions.concat(curr.descriptions)
        acc.domainTypes = acc.domainTypes.concat(curr.domainTypes)
        return acc
      },
      { amounts: [], descriptions: [], domainTypes: [] }
    )
    return result
  })

  const createNewObject = (obj) => {
    const newObj = {}
    for (const key in obj) {
      if (obj[key] instanceof Object) {
        newObj[key] = createNewObject(obj[key])
      } else {
        newObj[key] = []
      }
    }
    return newObj
  }

  versionedLoanInfoValues.versionedNeedsAnalysisValues = createNewObject(needsAnalysis)

  const addObjectValues = (obj, newObj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
        const isObject = typeof obj[key] === 'object' && obj[key] !== null
        const shouldPush =
          key === 'conflictExists' ||
          key === 'preferredLenders' ||
          key === 'risksExplained' ||
          key === 'offsetRisksExplained' ||
          key === 'explainedIncreaseInterestRisk' ||
          key === 'explainedIncreaseLoanTermRisk' ||
          key === 'planToCloseOrReduceCreditCard' ||
          key === 'closeToEndOfCurrentLoanTerm' ||
          key === 'dissatisfactionWithCurrentLender' ||
          key === 'closeToEndOfCurrentLoanTermgreaterFlexibility' ||
          key === 'increaseTotalLoanAmount'

        if (isObject) {
          addObjectValues(obj[key], newObj[key])
        } else if (shouldPush) {
          newObj[key].push(formatBoolean(obj[key]))
        } else if (obj[key] === null) {
          newObj[key].push(formatNull(obj[key]))
        } else {
          newObj[key].push(obj[key])
        }
      }
    }
  }

  versionedNeedsAnalysis.map((version) => {
    addObjectValues(version, versionedLoanInfoValues.versionedNeedsAnalysisValues)
    return []
  })

  return versionedLoanInfoValues
}
