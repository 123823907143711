/* eslint-disable complexity */
import React, { useCallback, useState } from 'react'

import {
  fetchLoanApplications,
  selectApplications,
  selectTotal,
} from 'modules/versionedApplication'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader, Menu } from '@vega/components'
import { Table } from '@vega/components/src/v2'
import FiltersBar from './FiltersBar'
import { Notifications } from '../common/notifications'
import { Columns, Rows } from './TableFormat'
import { SectionTitle } from 'features/loanAssessment/info/components'
import { useTableFetching } from 'hooks'
import { Item } from '@react-stately/collections'
import { ReactComponent as Search } from '@vega/components/src/assets/images/search.svg'
import { LOAN_APPLICATION } from '@neo/constants'

const { SEARCH_DISPLAY_TEXT } = LOAN_APPLICATION

const Root = styled.div(s('flex flex-column w-full mx-8'))
const Row = styled.div(s('flex flex-row pb-4 pt-4'))
const SearchAndFiltersContainer = styled.div(
  s('flex my-4 justify-between', { gap: 16 })
)

const Container = styled.div(s('flex justify-between w-full h-full', { gap: 10 }))

const SearchFilter = styled(Menu)(
  s('', {
    minWidth: '195px',
    maxWidth: '195px',
  })
)

const SearchBarContainer = styled.div(
  s('flex items-center w-full h-full rounded-lg rounded-tr-none bg-green-50', {
    minHeight: '52px',
    whiteSpace: 'nowrap',
    outline: 'none',
  })
)
const SearchIcon = styled(Search)(s('absolute ml-4'))

const Input = styled.input(
  s(
    'pl-12 pr-4 pt-3 pb-3 w-full h-full border-solid border-1 border-grey-400 text-base rounded-lg text-grey-500',
    {
      backgroundcolor: 'white',
      '&:hover': s('border-1 border-primary', { cursor: 'pointer' }),
      ':focus-within': s('border-1 border-primary', {
        outline: '2px solid',
        outlineColor: s('text-primary').color,
      }),
    }
  )
)

const TableContainer = styled.div(
  s('mt-0 h-full mb-1 bg-transparent rounded-t-lg rounded-b-lg', {
    overflow: 'hidden',
  })
)

export const ApplicationList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const totalItems = useSelector(selectTotal)
  const [hasFirstFetchFulfilled, setHasFirstFetchFulfilled] = useState(false)

  const applications = useSelector(selectApplications)

  const [searchFilter, setSearchFilter] = useState({
    key: 'name',
    label: 'Applicant Name',
    searchLabel: 'Search applicant name',
  })

  const fetchApplications = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(fetchLoanApplications({ searchParams, pageIndex })),
    [dispatch]
  )

  const {
    searchProps: { searchParams },
    updateSearchProps,
    paginationProps,
    isFetchingData,
  } = useTableFetching(totalItems, fetchApplications)

  React.useEffect(() => {
    async function fetchLoanApplicationsOnMount() {
      await fetchApplications({
        searchParams: { limit: searchParams.limit },
        pageIndex: 0,
      })
      setHasFirstFetchFulfilled(true)
    }

    fetchLoanApplicationsOnMount()
  }, [dispatch, fetchApplications, searchParams.limit])

  const handleActionBtnClicked = useCallback(
    (id) => history.push(`/loan-applications/${id}/info`),
    [history]
  )

  const onTextSearch = ({ searchTerm }, filters) => {
    updateSearchProps({
      searchTerm,
      filters,
    })
  }

  const data = Rows({ applications })
  const columns = Columns({ handleAction: handleActionBtnClicked })

  if (!hasFirstFetchFulfilled) {
    return (
      <div style={s('flex justify-center items-center h-full')}>
        <DottedCircleLoader size="lg" />
      </div>
    )
  }

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value

    // regex to validate search text input - not allowing some special characters

    if (/[!"`'#%&,:;<>={}~$()*/\\?[\]^|]+/i.test(searchTerm) === true) {
      document.getElementById('searchTerm').style.outlineColor = 'red'
      return
    }
    document.getElementById('searchTerm').style.outlineColor = '#490B2D'

    if (!searchTerm || searchTerm.length === 0)
      onTextSearch({ searchTerm: '' }, { searchFilter: searchFilter.key })
    if (searchTerm.length > 2 || searchFilter.key === 'applicationNumber')
      onTextSearch({ searchTerm }, { searchFilter: searchFilter.key })
  }

  const handleSearchFilterAction = (searchFilterValue) => {
    if (!searchFilterValue || searchFilterValue.length === 0) searchFilterValue = 'name'
    const updateFilterObject = SEARCH_DISPLAY_TEXT.find(
      (filter) => filter.key === searchFilterValue
    )
    setSearchFilter(updateFilterObject)
  }

  return (
    <Row style={s('p-0 h-full flex-1')}>
      <Root>
        <Row style={s('items-center justify-between')}>
          <SectionTitle title="MyCRM Applications" style={s('text-3xl mt-9 ')} />

          <Notifications />
        </Row>

        <SearchAndFiltersContainer>
          <Container>
            <SearchFilter
              label={searchFilter.label || 'Search applicants'}
              aria-label="loans search filter"
              onAction={handleSearchFilterAction}
            >
              {SEARCH_DISPLAY_TEXT.map((option) => (
                <Item key={option.key} value={option.key}>
                  {option.label}
                </Item>
              ))}
            </SearchFilter>
            <SearchBarContainer>
              <SearchIcon height={20} width={20} />
              <Input
                placeholder={searchFilter.searchLabel || 'Search'}
                onChange={handleSearchChange}
                name="searchTerm"
                id="searchTerm"
                type="search"
              />
            </SearchBarContainer>
          </Container>

          <FiltersBar
            updateSearchProps={updateSearchProps}
            filters={searchParams.filters}
            setSearchFilter={setSearchFilter}
          />
        </SearchAndFiltersContainer>

        <TableContainer style={s('pb-6')}>
          <Table
            columns={columns}
            data={data}
            loading={isFetchingData}
            paginationProps={paginationProps}
            style={s('rounded-lg')}
          />
        </TableContainer>
      </Root>
    </Row>
  )
}
