import { formatFullName } from 'utils'

// eslint-disable-next-line complexity
export const buildEntityName = (
  personName = {},
  trustName,
  businessName,
  companyName,
  placeholder = '-'
) => {
  try {
    const { nameTitle, firstName, lastName } = personName
    const isIndividual = firstName || lastName

    if (isIndividual)
      return formatFullName({ nameTitle, firstName, lastName }, placeholder)

    if (trustName) return trustName

    return businessName || companyName || placeholder
  } catch (error) {
    return placeholder
  }
}
