import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useInterval } from 'hooks/useInterval'
import {
  retrieveFinancialsData,
  fetchAffordabilityAssessments
} from 'modules/affordabilityAssessment'
// import { fetchLoanApplicationEntities } from 'modules/versionedApplication'
import { listSupportingDocuments } from 'modules/supportingDocument'
import { append, reject } from '@solta/ramda-extra'

const ILLION_POLLING_INTERVAL_MS = 20000

const useCompletedRetrievalDetection = (
  numOfBorrowersStartedRetrieval,
  versionedLoanApplicationId,
  latestVersionId
) => {
  const dispatch = useDispatch()
  const fetchRetrievalStatus = () =>
    dispatch(fetchAffordabilityAssessments(versionedLoanApplicationId))

  const [
    prevNumOfBorrowersStartedRetrieval,
    setPrevNumOfBorrowersStartedRetrieval,
  ] = useState()

  useInterval(
    () => {
      setPrevNumOfBorrowersStartedRetrieval(numOfBorrowersStartedRetrieval)
      fetchRetrievalStatus()
    },
    numOfBorrowersStartedRetrieval > 0,
    ILLION_POLLING_INTERVAL_MS
  )

  // Fetch bank statements when retrieval is detected to be completed
  useEffect(() => {
    const detectedCompletionSinceLastInterval =
      prevNumOfBorrowersStartedRetrieval > numOfBorrowersStartedRetrieval

    if (detectedCompletionSinceLastInterval)
      dispatch(listSupportingDocuments(latestVersionId))
  }, [
    dispatch,
    latestVersionId,
    numOfBorrowersStartedRetrieval,
    prevNumOfBorrowersStartedRetrieval,
  ])
}

const getIllionRetrievalDetails = (
  borrowersCompletedAssessment = [],
  affordabilityAssessments = []
) => {
  function getBankStatementRetrievalStatus(dataRetrieval) {
    return dataRetrieval?.find(
      (retrieval) => retrieval?.type === 'bankStatementRetrieval'
    )?.status
  }

  const entityIdToRetrievalStatus = {}
  let numOfBorrowersStartedRetrieval = 0

  for (const borrower of borrowersCompletedAssessment) {

    const matchingAssessment = affordabilityAssessments
      .find((assessment) => assessment.legalEntityExternalRef === borrower.externalRef)

    if (matchingAssessment) {
      const bankStatementRetrievalStatus = getBankStatementRetrievalStatus(matchingAssessment.dataRetrieval)
      entityIdToRetrievalStatus[borrower.externalRef] = bankStatementRetrievalStatus
      if (bankStatementRetrievalStatus === 'started') numOfBorrowersStartedRetrieval += 1
    }
  }

  return {
    entityIdToRetrievalStatus,
    numOfBorrowersStartedRetrieval,
  }
}

export function useIllionDataRetrieval(
  versionedLoanApplicationId,
  latestVersionId,
  borrowersCompletedAssessment,
  affordabilityAssessments,
) {
  const dispatch = useDispatch()
  const [entitiesLinkingIllion, setEntitiesLinkingIllion] = useState([])

  const sendIllionLink = useCallback(
    async (legalEntityExternalRef) => {
      setEntitiesLinkingIllion(append(legalEntityExternalRef))
      await dispatch(retrieveFinancialsData({ versionedLoanApplicationId, legalEntityExternalRef }))
      await dispatch(fetchAffordabilityAssessments(versionedLoanApplicationId))
      setEntitiesLinkingIllion(reject((externalRef) => externalRef === legalEntityExternalRef))
    },
    [dispatch, versionedLoanApplicationId]
  )

  const {
    entityIdToRetrievalStatus = {},
    numOfBorrowersStartedRetrieval,
  } = getIllionRetrievalDetails(borrowersCompletedAssessment, affordabilityAssessments)

  useCompletedRetrievalDetection(
    numOfBorrowersStartedRetrieval,
    versionedLoanApplicationId,
    latestVersionId
  )

  return { sendIllionLink, entitiesLinkingIllion, entityIdToRetrievalStatus }
}
