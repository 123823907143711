import BaseService from '../baseService'

export class LosSecuritiesService extends BaseService {
  createLosSecurity(losSecurity) {
    const config = {
      json: { losSecurity },
    }

    return this.client.post('securities', config).json()
  }

  updateLosSecurity(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`securities/${id}`, config).json()
  }

  updateLosSecurityLinkedDetails(id, payload) {
    const config = {
      json: payload,
    }

    return this.client.put(`securities/linkedDetails/${id}`, config).json()
  }

  fetchSecurities(versionedId) {
    return this.client.get(`securities/${versionedId}`).json()
  }
}
