/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable complexity */

import { formatBoolean, formatCurrency } from 'utils/formatters'
import { isNotNilOrEmpty, isNilOrEmpty } from '@solta/ramda-extra'


const expenseMatch = (expenseA, expenseB) => {
  const { firstNameA, lastNameA } = expenseA.percentResponsible.owners[0].legalEntity
  const { firstNameB, lastNameB } = expenseB.percentResponsible.owners[0].legalEntity

  return expenseA.category === expenseB.category
    && expenseA.description === expenseB.description
    && firstNameA === firstNameB
    && lastNameA === lastNameB
}

// Depending on when the expense was added or removed the entity ID on the owner
// may be at of data causing the expense to be incorrectly filtered at. Updating
// the ID based on matching entity in the latest version. Perhaps in future the
// entity ref should be sent down from the back end and used instead.
const getLatestPercentResponsible = (expense, latestVersion) => {
  const allLatestExpenses = latestVersion.livingExpenses.concat(latestVersion.otherExpenses)

  const allLatestOwners = allLatestExpenses.flatMap(
    (expense) => expense.percentResponsible.owners
  )

  const latestOwners = expense.percentResponsible.owners.map((owner) => (
    allLatestOwners.find((latestOwner) => (
      latestOwner.legalEntity.firstName === owner.legalEntity.firstName
        && latestOwner.legalEntity.lastName === owner.legalEntity.lastName
    ))
  ))

  return {
    owners: latestOwners,
    proportions: expense.percentResponsible.proportions
  }
}

const versionedExpenses = (houseFinancialsVersions) => {
  const allLivingExpenses = houseFinancialsVersions.flatMap((version) => version.livingExpenses)

  const uniqueLivingExpenses = allLivingExpenses.reduce((previous, current) => {
    const match = previous.find((expense) => expenseMatch(expense, current))
    return match ? previous : previous.concat(current)
  }, [])


  const versionedLivingExpenses = uniqueLivingExpenses.map((expense) => {
    const versionAmounts = houseFinancialsVersions.map((version) => {
      const amount = version.livingExpenses
        .find((otherExpense) => expenseMatch(expense, otherExpense))
        ?.amount || 0

      return formatCurrency(amount)
    })

    const latestVersion = houseFinancialsVersions[houseFinancialsVersions.length - 1]
    const latestPercentResponsible = getLatestPercentResponsible(expense, latestVersion)

    return { ...expense, amount: versionAmounts, percentResponsible: latestPercentResponsible }
  })

  const allOtherExpenses = houseFinancialsVersions.flatMap((version) => version.otherExpenses)

  const uniqueOtherExpenses = allOtherExpenses.reduce((previous, current) => {
    const match = previous.find((expense) => expenseMatch(expense, current))
    return match ? previous : previous.concat(current)
  }, [])

  const versionedOtherExpenses = uniqueOtherExpenses.map((expense) => {
    const versionAmounts = houseFinancialsVersions.map((version) => {
      const amount = version.otherExpenses
        .find((otherExpense) => expenseMatch(expense, otherExpense))
        ?.amount || 0

      return formatCurrency(amount)
    })

    const latestVersion = houseFinancialsVersions[houseFinancialsVersions.length - 1]
    const latestPercentResponsible = getLatestPercentResponsible(expense, latestVersion)

    return { ...expense, amount: versionAmounts, percentResponsible: latestPercentResponsible }
  })

  return [{ livingExpenses: versionedLivingExpenses, otherExpenses: versionedOtherExpenses }]
}

// Functions below are to provide 'versioned' values for use with re-submission tool-tips
export const getVersionedHouseholdFinancials = (householdFinancialsVersions = []) => {
  if (!householdFinancialsVersions) return []
  return versionedExpenses(householdFinancialsVersions)
}

// Create versioned legal entities for 'Income' value versioning
export const getVersionedlegalEntities = (versions = []) => {
  if (!versions) return []
  const versionedLegalEntities = { individuals: [] }
  const lastSub = versions.length - 1

  const createNewObject = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {}

    for (const key in obj) {
      if (key) {
        const shouldNotPush =
          key === 'bonusAmount' ||
          key === 'grossSalaryAmount' ||
          key === 'commissionAmount' ||
          key === 'grossRegularOvertimeAmount' ||
          key === 'carAllowanceAmount' ||
          key === 'workAllowanceAmount' ||
          key === 'workersCompensationAmount' ||
          key === 'occupation' ||
          key === 'profitBeforeTax' ||
          key === 'profitAfterTax'

        if (obj[key] instanceof Object) {
          newObj[key] = createNewObject(obj[key])
        } else if (shouldNotPush) {
          newObj[key] = []
        } else {
          newObj[key] = obj[key]
        }
      }
    }
    return newObj
  }

  versions[lastSub].application.legalEntities.individuals.map((individual) => {
    const newObject = createNewObject(individual)
    versionedLegalEntities.individuals.push(newObject)
    return []
  })

  // map through varsions and add values as array elements for specific values to be versioned

  const addObjectValues = (obj, newObj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
        if (obj[key] instanceof Object) {
          addObjectValues(obj[key], newObj[key])
        }
        const shouldPush =
          key === 'bonusAmount' ||
          key === 'grossSalaryAmount' ||
          key === 'commissionAmount' ||
          key === 'grossRegularOvertimeAmount' ||
          key === 'carAllowanceAmount' ||
          key === 'workAllowanceAmount' ||
          key === 'workersCompensationAmount' ||
          key === 'occupation' ||
          key === 'profitBeforeTax' ||
          key === 'profitAfterTax'

        if (shouldPush && isNotNilOrEmpty(obj[key])) {
          if (typeof obj[key] === `string`) {
            newObj[key].push(obj[key])
          } else {
            newObj[key].push(formatCurrency(obj[key]))
          }
        } else if (shouldPush && isNilOrEmpty(obj[key])) {
          newObj[key].push(null)
        }
      }
    }
  }

  versions.map((version) => {
    version?.application?.legalEntities?.individuals?.map((individual) => {
      const pushIndex = versionedLegalEntities.individuals?.findIndex(
        (p) => p.externalRef === individual.externalRef
      )
      if (pushIndex === -1) return []
      addObjectValues(individual, versionedLegalEntities.individuals[pushIndex])
      return []
    })
    return []
  })

  return versionedLegalEntities
}

export const getVersionedFinancialsData = (versionedFinancials = []) => {
  // create a versionedFinancialsData blank object with versioned data set to []
  if (!versionedFinancials) return []
  const versionedFinancialsData = []
  const lastSub = versionedFinancials.length - 1

  const createNewObject = (obj) => {
    const newObj = Array.isArray(obj) ? [] : {}

    for (const key in obj) {
      if (key) {
        const shouldNotPush =
          key === 'propertyTypeName' ||
          key === 'rentalAmount' ||
          key === 'toBeUsedAsSecurity' ||
          key === 'value' ||
          key === 'clearingFromThisLoan' ||
          key === 'clearingFromThisLoanAmount' ||
          key === 'annualInterestRate' ||
          key === 'outstandingBalance' ||
          key === 'creditLimit' ||
          key === 'repaymentAmount' ||
          key === 'description' ||
          key === 'amount'

        if (obj[key] instanceof Object) {
          newObj[key] = createNewObject(obj[key])
        } else if (shouldNotPush) {
          newObj[key] = []
        } else {
          newObj[key] = obj[key]
        }
      }
    }
    return newObj
  }

  versionedFinancials[lastSub]?.map((financialType) => {
    if (financialType.financialType === 'real_estate_asset') {
      versionedFinancialsData.push({
        financials: [],
        financialType: 'real_estate_asset',
      })
      const pushIndex = versionedFinancialsData?.findIndex(
        (p) => p.financialType === 'real_estate_asset'
      )
      financialType?.financials?.map((asset) => {
        if (asset?.transaction !== 'Purchasing') {
          const newObject = createNewObject(asset)
          versionedFinancialsData[pushIndex]?.financials?.push(newObject)
          return []
        }
        return []
      })

      return []
    }

    if (financialType.financialType === 'non_real_estate_asset') {
      const newObject = createNewObject(financialType)
      versionedFinancialsData.push(newObject)
      return []
    }

    if (financialType.financialType === 'liability') {
      const newObject = createNewObject(financialType)
      versionedFinancialsData.push(newObject)
      return []
    }
    if (financialType.financialType === 'income') {
      const newObject = createNewObject(financialType)
      versionedFinancialsData.push(newObject)
      return []
    }
    return []
  })

  // map through submissions and populate versioned data values

  const addObjectValues = (obj, newObj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && newObj.hasOwnProperty(key)) {
        if (obj[key] instanceof Object) {
          addObjectValues(obj[key], newObj[key])
        }
        const shouldPush =
          key === 'propertyTypeName' ||
          key === 'rentalAmount' ||
          key === 'toBeUsedAsSecurity' ||
          key === 'value' ||
          key === 'clearingFromThisLoan' ||
          key === 'clearingFromThisLoanAmount' ||
          key === 'annualInterestRate' ||
          key === 'outstandingBalance' ||
          key === 'creditLimit' ||
          key === 'repaymentAmount' ||
          key === 'description' ||
          key === 'amount'

        const shouldPushCurrency =
          key === 'rentalAmount' ||
          key === 'value' ||
          key === 'repaymentAmount' ||
          key === 'outstandingBalance' ||
          key === 'creditLimit' ||
          key === 'amount'

        const shouldPushPercentage = key === 'annualInterestRate'

        if (shouldPush && isNotNilOrEmpty(obj[key])) {
          if (typeof obj[key] === `string`) {
            newObj[key].push(obj[key])
          } else if (key === 'toBeUsedAsSecurity') {
            newObj[key].push(formatBoolean(obj[key]))
          } else if (shouldPushCurrency) {
            newObj[key].push(formatCurrency(obj[key]))
          } else if (shouldPushPercentage) {
            newObj[key].push(`${obj[key]}%`)
          } else {
            newObj[key].push(obj[key])
          }
        } else if (shouldPush && isNilOrEmpty(obj[key])) {
          newObj[key].push(null)
        }
      }
    }
  }

  // The order of the financials data can change on resubmission, so we ensure a match is made
  // between the type of financials and the order that type appears in versionedFinancialsData

  const financialTypeMap = {
    real_estate_asset: 'real_estate_asset',
    non_real_estate_asset: 'non_real_estate_asset',
    liability: 'liability',
    income: 'income',
  }

  versionedFinancials.map((version) => {
    version.map((financialType) => {
      const pushIndex = versionedFinancialsData?.findIndex(
        (p) => p.financialType === financialTypeMap[financialType.financialType]
      )

      if (pushIndex > -1) {
        financialType?.financials
          ?.filter((property) => property.transaction !== 'Purchasing')
          .map((asset) => {
            let extReference = asset?.externalRef
            let objIndex = versionedFinancialsData[pushIndex]?.financials?.findIndex(
              (p) => p.externalRef === extReference
            )
            if (
              financialTypeMap[financialType?.financialType] === 'real_estate_asset'
            ) {
              extReference = asset?.address?.externalRef
              objIndex = versionedFinancialsData[pushIndex]?.financials?.findIndex(
                (p) => p.address?.externalRef === extReference
              )
            }

            if (objIndex === -1) return []
            addObjectValues(
              asset,
              versionedFinancialsData[pushIndex]?.financials[objIndex]
            )
            return []
          })
        return []
      }
      return []
    })
    return []
  })

  return versionedFinancialsData
}
