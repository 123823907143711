import { nanoid } from 'nanoid'

export const defaultLoanStructure = {
  structureId: nanoid(),
  product: {
    id: undefined,
    maxLoanSplits: undefined,
    minTermInYears: undefined,
    maxTermInYears: undefined,
    minLoanAmount: undefined,
    maxLoanAmount: undefined,
    name: undefined,
  },
  loanAmount: undefined,
  loanTermInYears: undefined,
  baseInterestRate: undefined,
  loading: 0,
  loanPurpose: undefined,
  occupancyType: undefined,
  totalInterestRate: undefined,
  repaymentAmount: undefined,
  estimatedSettlementDate: undefined,
  repaymentFrequency: undefined,
  dayOfTheMonth: undefined,
  dayOfTheWeek: undefined,
  dayOfTheFortnight: undefined,
  associatedBorrowers: [],
  guarantors: [],
  primaryApplicant: undefined,
  securityProperties: [],
  isCrossCollateral: false,
  isAllocated: false,
  // This is for the allocated loan and the scenario where the allocated loan
  // is being switched to edit mode
  // Used to store original loan structure before entering edit mode
  allocatedStructureBeforeEditing: {
    structureId: undefined,
    product: {
      id: undefined,
      maxLoanSplits: undefined,
      minTermInYears: undefined,
      maxTermInYears: undefined,
      minLoanAmount: undefined,
      maxLoanAmount: undefined,
      name: undefined,
    },
    loanAmount: undefined,
    loanTermInYears: undefined,
    baseInterestRate: undefined,
    loading: 0,
    loanPurpose: undefined,
    occupancyType: undefined,
    totalInterestRate: undefined,
    repaymentAmount: undefined,
    estimatedSettlementDate: undefined,
    repaymentFrequency: undefined,
    dayOfTheMonth: undefined,
    dayOfTheWeek: undefined,
    dayOfTheFortnight: undefined,
    associatedBorrowers: [],
    guarantors: [],
    securityProperties: [],
    isCrossCollateral: false,
    primaryApplicant: undefined,
  },
  isEditMode: true,
  recommendationReference: undefined,
  customErrors: undefined,
}

export const defaultRecommendedLoanStructure = {
  structureId: nanoid(),
  recommendationReference: undefined,
  product: undefined,
  loanTermInYears: undefined,
  loanAmount: undefined,
  borrowers: [],
  guarantors: [],
  securities: [],
  isEditMode: false,
}
