import { useDispatch } from 'react-redux'
import { createLoanSplit, updateLoanSplit } from 'modules/loanSplit'
import { useFormikContext, setNestedObjectValues } from 'formik'
import { isNotNilOrEmpty, remove } from '@solta/ramda-extra'

const mapLoanStructureErrors = (structureIdx, loanStructureErrors = []) => {
  return loanStructureErrors.map((error, errorIdx) => {
    if (errorIdx === structureIdx) return error

    return undefined
  })
}

const selectRepaymentDayByFrequency = (
  repaymentFrequency,
  dayOfTheMonth,
  dayOfTheWeek,
  dayOfTheFortnight
) => {
  switch (repaymentFrequency) {
    case 'weekly':
      return dayOfTheWeek
    case 'fortnightly':
      return dayOfTheFortnight
    default:
      return dayOfTheMonth
  }
}

const mapToAssociatedEntities = (associatedBorrowers = [], guarantors = []) => {
  const mappedEntities = []

  associatedBorrowers.forEach((borrower) => {
    mappedEntities.push({
      id: borrower?.id,
      applicantRole: 'borrower',
      entityType: borrower?.legalEntityType,
    })
  })

  guarantors.forEach((guarantor) => {
    mappedEntities.push({
      id: guarantor?.id,
      applicantRole: 'guarantor',
      entityType: guarantor?.legalEntityType,
    })
  })
  return mappedEntities
}

const mapToSecurities = (securityProperties = []) => {
  // TODO: Currently only capture property type securities
  return securityProperties.map((property) => ({
    id: property?.id,
    securityPercentage: property?.allocationPercentage,
    securityType: 'property',
  }))
}

const mapToLoanSplitPayload = (loanApplicationId, loanStructure = {}) => {
  const {
    product,
    loanAmount,
    loanTermInYears,
    repaymentFrequency,
    dayOfTheMonth,
    dayOfTheWeek,
    dayOfTheFortnight,
    primaryApplicant,
    isCrossCollateral,
    loading,
    loanPurpose,
    occupancyType,
    securityProperties = [],
    associatedBorrowers = [],
    guarantors = [],
  } = loanStructure

  return {
    loanApplicationId,
    externalLoanProductId: product?.id,
    loanAmount,
    loanTermYears: loanTermInYears,
    repaymentDay: selectRepaymentDayByFrequency(
      repaymentFrequency,
      dayOfTheMonth,
      dayOfTheWeek,
      dayOfTheFortnight
    ),
    repaymentFrequency,
    associatedEntities: mapToAssociatedEntities(associatedBorrowers, guarantors),
    primaryApplicantId: primaryApplicant,
    securities: mapToSecurities(securityProperties),
    isCrossCollateral,
    loading,
    loanPurpose,
    occupancyType,
  }
}

// This hook is not reusable but used for encapsulating logic
// It must be used under the formik provider as we are using formik hooks here
export const useLoanStructureAllocation = () => {
  const dispatch = useDispatch()
  const { values, setFieldValue, validateForm, setTouched } = useFormikContext()

  const validateStructure = async (structureIdx) => {
    const validationErrors = await validateForm()
    const desiredLoanStructureErrors = validationErrors?.loanStructures?.[structureIdx]

    if (isNotNilOrEmpty(desiredLoanStructureErrors)) {
      // Programmatically touch the error fields so that the errors display in the form
      // Because we are not submit the entire form which means formik won't perform touch and validation automaically
      setTouched(
        setNestedObjectValues(
          {
            // We only want to show the errors for the fields in the loan structure form that the user is interacting
            // indicated by the structureIdx
            loanStructures: mapLoanStructureErrors(
              structureIdx,
              validationErrors?.loanStructures
            ),
          },
          true
        )
      )

      return true
    }

    return false
  }

  const editAllocatedLoanStructure = (structureIdx) =>
    setFieldValue(`loanStructures.${structureIdx}.isEditMode`, true)

  const reshowRecommendedStructure = (loanStructure) => {
    const recommendedStructureIdx = values.recommendedLoanStructures.findIndex(
      (recommendedStructure) =>
        recommendedStructure.recommendationReference ===
        loanStructure.recommendationReference
    )

    setFieldValue(
      `recommendedLoanStructures.${recommendedStructureIdx}.isEditMode`,
      false
    )
  }

  const cancelEditingAllocatedStructure = (structureIdx) => {
    const desiredLoanStructure = values.loanStructures[structureIdx]

    setFieldValue(`loanStructures.${structureIdx}`, {
      ...desiredLoanStructure.allocatedStructureBeforeEditing,
      allocatedStructureBeforeEditing:
        desiredLoanStructure.allocatedStructureBeforeEditing,
      isAllocated: true,
      isEditMode: false,
    })
  }

  const cancelAllocatingNewStructure = (
    structureIdx,
    loanStructure,
    isRecommendedStructure
  ) => {
    setFieldValue('loanStructures', remove(structureIdx, 1, values.loanStructures))
    if (isRecommendedStructure) reshowRecommendedStructure(loanStructure)
  }

  const allocateNewLoanStructure = async (
    loanApplicationId,
    structureIdx,
    isRecommendedStructure
  ) => {
    const loanStructure = values?.loanStructures?.[structureIdx]
    const hasErrors = await validateStructure(structureIdx)

    if (hasErrors) return { hasError: true }

    const createPayload = mapToLoanSplitPayload(loanApplicationId, loanStructure)

    await dispatch(
      createLoanSplit({ ...createPayload, requestId: loanStructure?.structureId })
    )
    cancelAllocatingNewStructure(structureIdx, loanStructure, isRecommendedStructure)

    return { hasError: false }
  }

  const updateAllocatedLoanStructure = async (loanApplicationId, structureIdx) => {
    const loanStructure = values?.loanStructures?.[structureIdx]
    const hasErrors = await validateStructure(structureIdx)

    if (hasErrors) return { hasError: true }

    const updatePayload = mapToLoanSplitPayload(loanApplicationId, loanStructure)

    await dispatch(
      updateLoanSplit({
        ...updatePayload,
        loanSplitId: loanStructure?.structureId,
        requestId: loanStructure?.structureId,
      })
    )
    cancelEditingAllocatedStructure(structureIdx)

    return { hasError: false }
  }

  return {
    editAllocatedLoanStructure,
    cancelEditingAllocatedStructure,
    cancelAllocatingNewStructure,
    allocateNewLoanStructure,
    updateAllocatedLoanStructure,
  }
}
