import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import { losSecuritiesService } from 'apiService'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { createCustomError } from '@vega/error-standardizer'

export const fetchSecurities = createAsyncThunk(
  'securities',
  async (versionedId, { rejectWithValue }) => {
    try {
      const securities = await losSecuritiesService.fetchSecurities(versionedId)
      return securities
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const createLosSecurity = createAsyncThunk(
  'securities/createLosSecurity',
  async (losSecurity, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(losSecurity))
        throw createCustomError({
          type: 'securityCreateFailed',
          description: 'LosSecurity Create Failed',
        })

      await losSecuritiesService.createLosSecurity(losSecurity)

      return dispatch(fetchSecurities(losSecurity.versionedId))
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateLosSecurity = createAsyncThunk(
  'securities/updateLosSecurity',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      return await losSecuritiesService.updateLosSecurity(id, payload)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const updateLosSecurityLinkedDetails = createAsyncThunk(
  'securities/updateLosSecurityLinkedDetails',
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { propertyId, address, details, valuation, imageSrc, updatedAt } = payload
      return await losSecuritiesService.updateLosSecurityLinkedDetails(id, {
        propertyId,
        address,
        details,
        valuation,
        imageSrc,
        updatedAt,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const securityAdapter = createEntityAdapter()

const initialState = securityAdapter.getInitialState()

const securitySlice = createSlice({
  name: 'securities',
  initialState,
  extraReducers: {
    [fetchSecurities.fulfilled]: (state, { payload }) => {
      securityAdapter.setAll(state, payload)
    },
  },
})

const { reducer: securityReducer } = securitySlice
export { securityReducer }
