import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'

import { formatBoolean } from 'utils/formatters'

const SubtitleCovered = styled.p(
  s('font-semi-bold my-3 text-black text-lg', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '20px',
    fontSize: '14px',
    background: '#F7F7F2',
    borderRadius: '8px',

    /* Inside auto layout */
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',
  })
)

const Subtitle = styled.p(s(
    'font-semi-bold my-3 text-black text-lg',
    {
      letterSpacing: '0.01em',
      lineHeight: '21px',
      fontSize: '14px'
    }
  )
)

const SubtitleValue = styled.span(s(
  'font-semibold text-primary',
  { float: 'right' }
))

const Title = styled.h3(s(
  'mb-0 text-grey-900 text-xl',
  {
    letterSpacing: '-0.01em',
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: '-4px',
    marginBottom: '21px'
  }
))

export const InsuranceDeclaration = ({ declarations }) => {
  const {
    entityName,
    familyLifeAndHardship,
    content
  } = declarations;

  const hasMyCrmData = false;

  if (!hasMyCrmData) {
    return (
      <ContentCard style={s('mb-4')}>
        <Title >
          {`${entityName} insurance declarations`}
        </Title>

        <span style={s('text-sm font-semibold text-grey-600')}>
          Data not yet in myCRM payload
        </span>
      </ContentCard>
    )
  }

  return (
    <ContentCard style={s('mb-4')}>
      <Title >
        {`${entityName} insurance declarations`}
      </Title>

      <Subtitle>
        Are you confident you have adequate insurance cover to protect:
      </Subtitle>

      <Subtitle>
        Your family against injury, dealth or financial hardship? {''}

        <SubtitleValue>
          {formatBoolean(familyLifeAndHardship)}
        </SubtitleValue>
      </Subtitle>

      <SubtitleCovered>
        {familyLifeAndHardship ? 'Covered' : 'Not covered'}
      </SubtitleCovered>

      <Subtitle>
        Your home and contents against accident, damage or theft?

        <SubtitleValue>
          {content}
        </SubtitleValue>
      </Subtitle>

      <SubtitleCovered>
        {content ? 'Covered' : 'Not covered'}
      </SubtitleCovered>
    </ContentCard>
  )
}
