import React, { useState } from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Button as ButtonBase, DropdownCheckbox } from '@vega/components'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'
import { isEmpty } from '@solta/ramda-extra'
import { LOAN_APPLICATION } from '@neo/constants'
import OverlayDateRangePicker from '../common/OverlayDateRangePicker'

const { APPLICATION_STATUS_DISPLAY_TEXT, CRR_DISPLAY_TEXT } = LOAN_APPLICATION

const Container = styled.div(s('flex justify-between bg-transparent', { gap: 10 }))

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

const FilterCheckBox = styled(DropdownCheckbox)(
  s('', {
    minWidth: '195px',
    maxWidth: '195px',
  })
)

function FiltersBar({ filters, updateSearchProps, setSearchFilter }) {
  const [selectedStatusKeys, setSelectedStatusKeys] = useState(new Set())
  const [selectedCrrKeys, setSelectedCrrKeys] = useState(new Set())

  const onDatesChange = ([startDate, endDate]) => {
    if (!(startDate && endDate)) return
    updateSearchProps({
      filters: {
        startDate,
        endDate,
      },
    })
  }

  function handleStatusFilterChange(values) {
    setSelectedStatusKeys(values)

    updateSearchProps({
      filters: {
        status: Array.from(values),
      },
    })
  }

  function handleCrrFilterChange(values) {
    setSelectedCrrKeys(values)

    updateSearchProps({
      filters: {
        crr: Array.from(values),
      },
    })
  }

  function handleResetFilters() {
    setSelectedStatusKeys(new Set())
    setSelectedCrrKeys(new Set())

    updateSearchProps({
      filters: {
        status: '',
        crr: {},
        startDate: null,
        endDate: null,
      },
      sorting: null,
      searchTerm: null,
    })
    setSearchFilter({
      key: 'name',
      label: 'Applicant Name',
      searchLabel: 'Search applicant name',
    })
    document.getElementById('searchTerm').value = ''
  }

  return (
    <Container>
      <FilterCheckBox
        label={isEmpty(filters?.status) ? 'CRR' : 'CRR'}
        aria-label="crr"
        selectedKeys={selectedCrrKeys}
        onSelectionChange={handleCrrFilterChange}
      >
        {Object.entries(CRR_DISPLAY_TEXT).map(([key, value]) => (
          <Item key={key}>{value}</Item>
        ))}
      </FilterCheckBox>

      <FilterCheckBox
        label={isEmpty(filters?.status) ? 'Status' : 'Status'}
        aria-label="status"
        selectedKeys={selectedStatusKeys}
        onSelectionChange={handleStatusFilterChange}
      >
        {Object.entries(APPLICATION_STATUS_DISPLAY_TEXT).map(([key, value]) => (
          <Item key={key}>{value}</Item>
        ))}
      </FilterCheckBox>

      <OverlayDateRangePicker
        value={[filters?.startDate || new Date(), filters?.endDate || new Date()]}
        onChange={onDatesChange}
      />

      <Button
        StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
        onClick={handleResetFilters}
      >
        Reset
      </Button>
    </Container>
  )
}

export default FiltersBar
