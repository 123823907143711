/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { Table } from '@vega/components/src/v2'
import { s } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatters'
import { VersionedValue } from 'features/common'
import { calculateMonthlyAmount } from './utils'

const headerStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('p-0 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Details',
        accessor: 'details',
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={value} />
          </TextOverflowEllipsisBox>
        ),
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        tableHeaderStyles: headerStyleBase({ width: '30%' }),
        tableCellStyles: cellStyleBase({ width: '30%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        tableHeaderStyles: headerStyleBase({ width: '10%', textAlign: 'right' }),
        tableCellStyles: cellStyleBase({ width: '10%', flexDirection: 'row-reverse' }),
        Cell: ({ value }) => (
          <TextOverflowEllipsisBox>
            <VersionedValue values={value} />
          </TextOverflowEllipsisBox>
        ),
      },
    ],
    []
  )
}

export const OtherIncomesTable = ({ otherIncomes = [] }) => {
  const tableData = otherIncomes.reduce((tableDataSoFar, otherIncome) => {
    const { domainType, description, frequency, amount } = otherIncome

    const newAmount = amount.map((amnt) => {
      return formatCurrency(calculateMonthlyAmount(amnt, frequency))
    })

    const otherIncomeRow = {
      type: domainType,
      details: description,
      frequency: 'Monthly',
      amount: newAmount,
    }

    return tableDataSoFar.concat(otherIncomeRow)
  }, [])

  const totalMonthlyIncome = otherIncomes
    .map((otherIncome) => {
      const { amount, frequency } = otherIncome
      const newAmount = calculateMonthlyAmount(amount[amount.length - 1], frequency)
      return newAmount
    })
    .reduce((total, amount) => {
      return total + amount
    }, 0)

  if (tableData.length === 0) {
    return (
      <>
        <h3 style={{ color: '#490B2D' }}>Income</h3>

        <p style={s('text-grey-600 font-semibold text-sm')}>No data found</p>
      </>
    )
  }

  return (
    <>
      <h3 style={{ color: '#490B2D' }}>Income</h3>

      <Table
        columns={Columns()}
        data={tableData}
        tableHeight="initial"
        rootStyle={{ margin: 0 }}
      />

      <div style={{ textAlign: 'right' }}>
        <h5 style={{ color: '#490B2D' }}>
          Monthly total
          <span style={s('inline-block w-8')}>
            {formatCurrency(totalMonthlyIncome)}
          </span>
        </h5>
      </div>
    </>
  )
}
