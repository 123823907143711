import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { s, styled } from '@vega/styled/v2'
import {
  selectAllCreditReports,
  selectIsGetCreditReportPending,
  selectIsStartCreditReportPending,
  startCreditCheck,
} from 'modules/creditReport'
import { ReactComponent as CheckBase } from '@vega/components/src/assets/images/check.svg'
import { DottedCircleLoader, Button } from '@vega/components'
import { PersonalInfo } from './PersonalInfo'
import { Addresses } from './Addresses'
import { CreditCheckResult } from './CreditCheckResult'
import { ContentCard as ContentCardBase, Divider as DividerBase } from '../components'
import { EmploymentTable } from './EmploymentTable'
import { Row, Col } from '@vega/styled'
import { ReactComponent as EditIcon } from '@vega/components/src/assets/images/pencil.svg'
import { UpdateGuarantorForm } from './GuarantorForm/UpdateGuarantorForm'
import { formatPhoneNumber } from 'utils/formatters'

const Check = styled(CheckBase)({ color: '#515856' })
const Checkbox = styled.div(s('mr-4 h-1 w-1 rounded-md bg-secondary'))
const Container = styled.div()
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const CreditCheckContainer = styled.div(s('flex items-center mb-4'))
const Divider = styled(DividerBase)(s('my-5'))
const RequestLink = styled.div(
  s('text-base font-semibold text-primary', {
    cursor: 'pointer',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const EditButton = styled(Button)(
  s(
    'bg-white border-1 border-primary rounded-lg text-primary font-semibold self-end text-center py-2 px-4 text-sm mr-2',
    { '&:hover': { cursor: 'pointer', boxShadow: 'none !important' } }
  )
)

// eslint-disable-next-line complexity
const Applicant = ({
  applicant = {},
  previousApplicant = {},
  relatedCompanies = [],
  index,
  latestVersionId,
  cannotAddEntity = false,
  ...props
}) => {
  const dispatch = useDispatch()
  const { id: versionedLoanApplicationId } = useParams()
  const [showEditForm, setShowEditForm] = useState(false)

  const {
    dob,
    email,
    maritalStatus,
    phone,
    residencyStatus,
    citizenship,
    fullName,
    addresses,
    entityId,
    employments,
    externalRef,
    applicantRole,
  } = applicant

  const {
    fullName: preFullName,
    dob: predob,
    email: preEmail,
    maritalStatus: preMaritalStatus,
    phone: prePhone,
    residencyStatus: preResidencyStatus,
    citizenship: preCitizenship,
  } = previousApplicant

  const versionedApplicant = {
    ...applicant,
    dob: [predob || dob, dob],
    email: [preEmail || email, email],
    maritalStatus: [preMaritalStatus || maritalStatus, maritalStatus],
    phone: [
      prePhone !== undefined ? formatPhoneNumber(prePhone) : formatPhoneNumber(phone),
      formatPhoneNumber(phone),
    ],
    residencyStatus: [preResidencyStatus || residencyStatus, residencyStatus],
    citizenship: [preCitizenship || citizenship, citizenship],
    fullName: [preFullName || fullName, fullName],
  }

  const creditReports = useSelector(selectAllCreditReports)
  const isGetCreditReportPending = useSelector((state) =>
    selectIsGetCreditReportPending(state, entityId)
  )
  const isStartCreditCheckPending = useSelector((state) =>
    selectIsStartCreditReportPending(state, entityId)
  )

  const applicantReport = creditReports.find(
    (report) => report?.profile?.externalRef === externalRef
  )

  const openEditForm = () => {
    setShowEditForm(true)
  }
  const handleCreditCheck = ({ entityId, versionedLoanApplicationId, requestId }) =>
    dispatch(startCreditCheck({ entityId, versionedLoanApplicationId, requestId }))
  applicant.index = index
  return (
    <>
      {showEditForm && (
        <UpdateGuarantorForm
          setShowEditForm={setShowEditForm}
          latestVersionId={latestVersionId}
          versionedLoanApplicationId={versionedLoanApplicationId}
          {...applicant}
        />
      )}
      {!showEditForm && (
        <Container style={{ maxWith: '100%', flexBasis: '100%' }} {...props}>
          <ContentCard>
            <PersonalInfo {...versionedApplicant} />

            <Divider />

            {!applicantReport && !isStartCreditCheckPending && (
              <RequestLink
                onClick={() =>
                  handleCreditCheck({
                    entityId,
                    versionedLoanApplicationId,
                    requestId: entityId,
                  })
                }
              >
                Request Credit Check
              </RequestLink>
            )}

            {(isGetCreditReportPending || isStartCreditCheckPending) && (
              <DottedCircleLoader />
            )}

            {applicantReport && (
              <>
                <CreditCheckContainer>
                  <Checkbox>
                    <Check />
                  </Checkbox>
                  <RequestLink>Credit Check</RequestLink>
                </CreditCheckContainer>

                <CreditCheckResult creditReport={applicantReport} />
              </>
            )}

            <Divider />

            <Addresses addresses={addresses} applicantIndex={index} />

            <Divider />

            <EmploymentTable
              employments={employments}
              relatedCompanies={relatedCompanies}
            />
            {applicantRole === 'Guarantor' && cannotAddEntity === false && (
              <Row>
                <Col span={24}>
                  <div style={s('flex flex-row justify-end mt-3')}>
                    <EditButton
                      type="button"
                      variant="outlined"
                      onClick={openEditForm}
                      StartIcon={<EditIcon style={s('mr-2')} />}
                    >
                      Edit
                    </EditButton>
                  </div>
                </Col>
              </Row>
            )}
          </ContentCard>
        </Container>
      )}
    </>
  )
}

export { Applicant }
