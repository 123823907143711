import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { noteService } from 'apiService'
import { normalizeError } from '@vega/services'
import { createCustomError } from '@vega/error-standardizer'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const fetchNotes = createAsyncThunk(
  'note/fetchNotes',
  async ({ versionedLoanApplicationId, loanApplicationId }, { rejectWithValue }) => {
    try {
      return await noteService.fetchNotes(
        versionedLoanApplicationId,
        loanApplicationId
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateNote = createAsyncThunk(
  'note/updateNote',
  async ({ id, note, versionedLoanApplicationId, loanApplicationId }, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(note))
        throw createCustomError({
          type: 'noteUpdateFailed',
          description: 'Note Update Failed',
        })

      await noteService.updateNote(id, note)

      return dispatch(
        fetchNotes({
          versionedLoanApplicationId,
          loanApplicationId
        })
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const deleteNote = createAsyncThunk(
  'note/deleteNote',
  async ({ id, versionedLoanApplicationId, loanApplicationId }, { dispatch, rejectWithValue }) => {
    try {
      await noteService.deleteNote(id)

      return dispatch(
        fetchNotes(
          versionedLoanApplicationId,
          loanApplicationId
        )
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const createNote = createAsyncThunk(
  'note/createNote',
  async ({ versionedLoanApplicationId, loanApplicationId, source, content }, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(source || content || loanApplicationId))
        throw createCustomError({
          type: 'noteCreateFailed',
          description: 'Note Create Failed',
        })

      await noteService.createNote({
        versionedLoanApplicationId,
        loanApplicationId,
        source,
        content
      })

      return dispatch(
        fetchNotes({
          versionedLoanApplicationId,
          loanApplicationId
        })
      )

    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)
export const noteAdapter = createEntityAdapter()

const initialState = noteAdapter.getInitialState()

const noteSlice = createSlice({
  name: 'note',
  initialState,
  extraReducers: {
    [fetchNotes.fulfilled]: (state, { payload }) => {
      noteAdapter.setAll(state, payload)
    },
  },
})

const { reducer: noteReducer } = noteSlice
export { noteReducer }
