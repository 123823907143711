import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { userService } from 'apiService'
import { normalizeError } from '@vega/services'

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, limit = 20 } = searchParams
      return await userService.fetchUsers(
        {
          q,
          filters,
          start: limit * pageIndex,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const userAdapter = createEntityAdapter()

const initialState = userAdapter.getInitialState()

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.fulfilled]: (state, { payload }) => {
      const { items: users } = payload
      userAdapter.setAll(state, users)
    },
  },
})

export const { reducer: userReducer } = userSlice
