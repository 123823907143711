import React from 'react'
import { s } from '@vega/styled/v2'
import { IncomeUMISummary } from './incomes/IncomeUMISummary'
import { IncomeUMITable } from './incomes/IncomeUMITable'
import {
  monthlyGrossIncome,
  sensitisedMonthlyNetIncome,
  attributablyBusinessIncome,
  householdEssentialBenchmarkAmount, // TODO: move this function
} from './incomes/utils'
import { UMIExpenseSummary } from './expenses/UMIExpenseSummary'
import { UMIExpenseTable } from './expenses/UMIExpenseTable'
import { totalMonthlyExpenses } from './expenses/utils'
import { UMIHouseholdStructureTable } from './householdStructure/UMIHouseholdStructureTable'
import { UMILiabilityTable } from './liabilities/UMILiabilityTable'

export const UMISections = ({
  householdRef,
  incomes,
  expenses,
  structures,
  liabilities,
}) => {
  const monthlyNetTotal = incomes.reduce((acc, curr) => {
    if (curr.details.umiIncomeType === 'business') {
      return acc + attributablyBusinessIncome(curr) / 12
    }

    return acc + curr.details.verifiedAmount
  }, 0)


  return (
    <>
      <h2 style={s('mb-1')}>Income</h2>
      <IncomeUMISummary
        monthlyNetIncome={monthlyNetTotal}
        sensitisedMonthlyNetIncome={sensitisedMonthlyNetIncome(incomes)}
      />

      <IncomeUMITable householdRef={householdRef} incomes={incomes} />

      <h2 style={s('mb-1')}>Expenses</h2>
      <UMIExpenseSummary
        totalMonthlyExpenses={totalMonthlyExpenses(expenses)}
        totalMonthlyBenchmarkExpenses={Math.max(
          totalMonthlyExpenses(expenses),
          householdEssentialBenchmarkAmount(structures, monthlyGrossIncome(incomes))
        )}
      />
      <UMIExpenseTable
        householdRef={householdRef}
        expenses={expenses}
        benchmarkLivingExpenditure={100}
      />

      <h2 style={s('mb-1')}>Household structure</h2>
      <UMIHouseholdStructureTable
        structures={structures}
        monthlyIncome={monthlyGrossIncome(incomes)}
      />

      <h2 style={s('mb-1')}>Other financial liabilities</h2>
      <UMILiabilityTable householdRef={householdRef} liabilities={liabilities} />
    </>
  )
}
