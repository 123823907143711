import React from 'react'
import { s } from '@vega/styled/v2'

// Very similar if not the same component is used inline across the app, which
// might not be the cleanest solution. Defining at the top level of the
// servicability module at least stop the bleeding for new code
export const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}
