import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTableFetching } from 'hooks'
import { styled, s } from '@vega/styled/v2'
import { Table } from '@vega/components/src/v2'
import { Pagination } from '@vega/components/src/v2/Table/Pagination'
import {
  SectionTitle as SectionTitleBase,
  ContentCard as ContentCardBase,
} from '../components'
import { Rows, Columns } from './TableFormat'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import {
  searchActivityLogs,
  selectActivityLogs,
  selectTotal,
  selectIsSearchingActivityLogs,
} from 'modules/activityLog'

const { ACTIVITY_LOG } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('p-0', { overflow: 'hidden' }))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const ActivityLog = ({ loanApplicationId, ...props }) => {
  const dispatch = useDispatch()

  const activityLogs = useSelector(selectActivityLogs) || []
  const totalLogs = useSelector(selectTotal) || activityLogs.length
  const isSearchingActivityLogs = useSelector(selectIsSearchingActivityLogs)

  const fetchLogs = useCallback(
    ({ searchParams = {}, pageIndex = 0 }) =>
      dispatch(
        searchActivityLogs({
          searchParams,
          pageIndex,
          refId: loanApplicationId,
        })
      ),
    [dispatch, loanApplicationId]
  )

  const {
    searchProps: { searchParams },
    paginationProps,
    isFetchingData,
  } = useTableFetching(totalLogs, fetchLogs)

  React.useEffect(() => {
    fetchLogs({ searchParams, pageIndex: 0 })
  }, [dispatch, fetchLogs, loanApplicationId, searchParams])

  const data = Rows({
    activityLogs,
  })
  const columns = Columns()

  return (
    <Container id={ACTIVITY_LOG} {...props}>
      <SectionTitle title="Activity Log" />

      <ContentCard>
        <Table
          style={s('m-0')}
          tableHeight={550}
          columns={columns}
          data={data}
          loading={isSearchingActivityLogs || isFetchingData}
          fetchData={fetchLogs}
          loaderLabel="Fetching Activity Logs"
          useDefaultPagination={false}
        />
      </ContentCard>

      <Pagination {...paginationProps} />
    </Container>
  )
}

export { ActivityLog }
