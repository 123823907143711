import React from 'react'
import { Modal, Button } from '@vega/components'
import { ContentCard as ContentCardBase } from '@vega/components/src/v2'
import { styled, s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { noop } from '@solta/ramda-extra'

import { ReactComponent as CloseIconBase } from '@vega/components/src/assets/images/x.svg'
import { Formik, Form } from 'formik'
import { updateLosSecurity, fetchSecurities } from 'modules/security'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const ContentCard = styled(ContentCardBase)(s('p-6 pt-1', { width: 500, height: 210 }))

const CloseIcon = styled(CloseIconBase)(s('w-1 h-1 mt-5'), {
  '&:hover': { cursor: 'pointer' },
})

const CancelButton = styled(Button)(
  s('bg-white border-1 border-primary rounded-lg', {
    '&:hover': { cursor: 'pointer' },
  })
)

const CardTitle = styled.h3(s('mb-2'))
const DescriptionText = styled.p(s('text-sm mt-0'))

export const RemoveSecurityModal = ({ isOpen, setIsOpen, security }) => {
  const close = () => setIsOpen(false)
  const dispatch = useDispatch()
  const { id } = useParams()

  const updateStatus = async ({ id }, versionedId) => {
    close()
    const payload = {
      status: 'Removed',
    }
    await dispatch(updateLosSecurity({ id, payload }))

    dispatch(fetchSecurities(versionedId))
  }
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Formik enableReinitialize initialValues={{ search: '' }} onSubmit={noop}>
        <Form>
          <ContentCard>
            <Row gutter={[0, 0]}>
              <Col span={22}>
                <CardTitle style={s('text-red')}>Remove Security</CardTitle>
              </Col>
              <Col span={2}>
                <div style={s('flex flex-row justify-end')}>
                  <CloseIcon onClick={close} />
                </div>
              </Col>
              <Col span={24}>
                <DescriptionText style={s('', { marginBottom: 40 })}>
                  Are you sure you want to remove this security? This will be excluded
                  from the loan application.
                </DescriptionText>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div style={s('flex flex-row justify-end')}>
                  <CancelButton
                    onClick={() => {
                      close()
                    }}
                  >
                    <div style={s('flex flex-row')}>No, keep it</div>
                  </CancelButton>
                  <Button
                    style={s('bg-red ml-3')}
                    onClick={() => {
                      updateStatus(security, id)
                    }}
                  >
                    Yes, remove it
                  </Button>
                </div>
              </Col>
            </Row>
          </ContentCard>
        </Form>
      </Formik>
    </Modal>
  )
}
