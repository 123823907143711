import React from 'react'
import { DataDisplay } from '@vega/components/src/v2'

export const SecurityOwnerSegment = ({ owner, legalEntities }) => {
  const getEntityName = (applicantId) => {
    const listOfEntities = [
      ...(legalEntities?.companies || []),
      ...(legalEntities?.individuals || []),
      ...(legalEntities?.trusts || []),
    ]

    const foundEntity = listOfEntities.find(
      (entity) => entity?.entityId === applicantId
    )

    return foundEntity?.fullName
  }

  const ownerName = getEntityName(owner?.legalEntityId)

  return ownerName ? (
    <DataDisplay label={`${ownerName}'s Ownership`} value={`${owner?.percent}%`} />
  ) : (
    <DataDisplay />
  )
}
