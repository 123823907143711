import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { formatCurrency, formatStringToNumber } from 'utils/formatters'
import { ReactComponent as Calculator } from '@vega/components/src/assets/images/calculator.svg'

const CalculatorIcon = styled(Calculator)(s('h-4 w-4 mr-4 mt-1'), {
  path: { stroke: s('text-primary').color },
})

const Equation = styled('div')(s('flex mb-4'))
const EquationValue = styled('h2')(s('m-0 text-primary'))
const EquationLabel = styled('span')(s('text-sm text-grey-600 font-medium'))
const EquationSymbol = styled('h2')(s('mx-6 text-primary'))

export const NetEquityEquation = ({ assets, liabilities }) => {
  const totalAssets = assets
    .map((asset) => asset.estimatedValue.value)
    .reduce((total, amount) => {
      const lastSubmission = amount?.length - 1
      const newAmount = formatStringToNumber(amount[lastSubmission])
      return total + newAmount
    }, 0)

  const totalLiabilities = liabilities
    .map((liability) => liability.creditLimit)
    .reduce((total, amount) => {
      const lastSubmission = amount?.length - 1
      const newAmount = formatStringToNumber(amount[lastSubmission])
      return total + newAmount
    }, 0)

  const netEquity = Math.round(totalAssets) - Math.round(totalLiabilities)

  return (
    <Equation>
      <CalculatorIcon />

      <EquationValue>
        <EquationLabel>Total assets </EquationLabel>
        <br />
        {formatCurrency(totalAssets)}
      </EquationValue>

      <EquationSymbol>-</EquationSymbol>

      <EquationValue>
        <EquationLabel>Total liabilities</EquationLabel>
        <br />
        {formatCurrency(totalLiabilities)}
      </EquationValue>

      <EquationSymbol>=</EquationSymbol>

      <EquationValue>
        <EquationLabel>Net equity</EquationLabel>
        <br />
        {formatCurrency(netEquity)}
      </EquationValue>
    </Equation>
  )
}
