import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class LoanSplitService extends BaseService {
  read(id) {
    return this.client.get(`loan-structures/${id}`).json()
  }

  create(payload) {
    const config = {
      json: payload,
    }

    return this.client.post(`loan-structures`, config).json()
  }

  list(loanApplicationId) {
    const searchParams = QueryBuilder()
      .setParam('loanApplicationId', loanApplicationId)
      .build()

    return this.client.get(`loan-structures?${searchParams}`).json()
  }

  update(id, updatingProps) {
    const config = {
      json: updatingProps,
    }

    return this.client.put(`loan-structures/${id}`, config).json()
  }
}
