import React, { useState } from 'react'
import { s, styled } from '@vega/styled/v2'
import { useDispatch } from 'react-redux'
import { updateNote } from 'modules/note'

const Input = styled.textarea(
  s('h-full w-full border-0 bg-transparent py-3 pl-3'),
  s('text-grey-800 text-base font-medium'),
  {
    outline: 'none',
    '&::placeholder': s('text-grey-500'),
    '#search-clear': s('text-red-200'),

    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  ({ isReadOnly }) => isReadOnly && s('text-grey-800'),
  ({ isDisabled }) => isDisabled && s('text-grey-400')
)

const InputContainer = styled.div(s('rounded-lg bg-grey-100 border-1 border-grey-200'))

const CancelButton = styled.button(
  s('border-transparent bg-transparent text-primary px-5 py-2 font-semibold', {
    cursor: 'pointer',
  })
)
const SaveButton = styled.button(
  s(
    'text-primary bg-secondary text-primary px-5 py-2 font-semibold border-transparent rounded-md',
    {
      cursor: 'pointer',
    }
  )
)

export const EditDisplayNoteForm = ({ note, handleEditCancel }) => {
  const dispatch = useDispatch()
  const [noteInput, setNoteInput] = useState(note.content)

  function editNote(id, content) {
    dispatch(
      updateNote({
        id,
        note: {
          content,
        },
        loanApplicationId: note?.loanApplicationId,
      })
    )
  }

  return (
    <InputContainer>
      <Input
        id="edit-note-input"
        placeholder="Leave a note..."
        onChange={(e) => {
          setNoteInput(e.target.value)
        }}
        value={noteInput}
      />

      <div style={s('flex flex-row justify-end mr-3 mb-4 pb-2')}>
        <CancelButton onClick={handleEditCancel}>Cancel</CancelButton>

        <SaveButton onClick={() => editNote(note?.id, noteInput)}>
          Save Changes
        </SaveButton>
      </div>
    </InputContainer>
  )
}
