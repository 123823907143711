import React from 'react'
import { Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { ReactComponent as ChevronDown } from '@vega/components/src/assets/images/chevron-down.svg'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'

const ChevronDownIcon = styled(ChevronDown)(s('ml-1'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})

const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color, strokeWidth: 2 },
})

const ToggleButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary mb-2 h-3 w-8'),
  {
    cursor: 'pointer',
    backgroundColor: 'white',
    fontSize: 14,
    borderRadius: 6,
  }
)

export const DetailsToggle = ({ isExpanded, setIsExpanded }) => {
  return (
    <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
      <Row style={s('justify-center')}>
        <div>{ isExpanded ? 'Collapse' : 'Expand' }</div>

        {
          isExpanded ?
            ( <ChevronUpIcon width={20} height={20} /> ) :
            ( <ChevronDownIcon width={20} height={20} /> )
        }
      </Row>
    </ToggleButton>
  );
}
