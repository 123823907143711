import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { formatCurrency } from 'utils/formatters'
import { TextOverflowEllipsisBox } from '../TextOverflowEllipsisBox'
import { s } from '@vega/styled/v2'
import {
  costOfServicing,
  effectiveCostOfServicing,
  totalCostOfServicing,
  totalEffectiveCostOfServicing,
} from './utils'
import { deleteUmiReportItem, updateUmiReportItem } from 'modules/umiReportItems'

import { Formik, Field } from 'formik'

export const StudentLoanLeaseHirePurchaseTable = ({ liabilities }) => {
  const dispatch = useDispatch()
  const [currentLiabilities, setCurrentLiabilities] = useState(liabilities)

  if (liabilities.length === 0) {
    return (
      <p style={s('text-sm font-semibold text-grey-600')}>
        No student loan, lease or hire purchase liabilities currently recorded
      </p>
    )
  }

  const rows = liabilities.map((liability) => (
    <Formik
      key={liability.id}
      initialValues={liability}
      onSubmit={(values) => {
        setCurrentLiabilities(
          currentLiabilities
            .filter((liability) => liability.id !== values.id)
            .concat(values)
        )

        dispatch(
          updateUmiReportItem({
            umiReportItemId: values.id,
            umiReportItem: values,
          })
        )
      }}
    >
      {(props) => (
        <tr>
          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="select"
              name="details.studentLoanLeaseHirePurchaseType"
              onChange={(e) => {
                props.handleChange(e)
                props.handleSubmit()
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            >
              <option value="studentLoan">Student loan</option>
              <option value="lease">Lease</option>
              <option value="hirePurchase">Hire purchase</option>
              <option value="carLoan">Car loan</option>
            </Field>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.outstandingAmount"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.repaymentAmount"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.interestRate"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9' })}>
            <Field
              as="input"
              name="details.remainingTermYears"
              type="number"
              min={0}
              onChange={(e) => {
                // TODO: figure out how to debounce this
                props.handleChange(e)
                if (e.target.value !== '') {
                  props.handleSubmit()
                }
              }}
              style={s('rounded-lg py-2 px-4', { appearance: 'none' })}
            />
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
            <TextOverflowEllipsisBox>
              {formatCurrency(costOfServicing(props.values))}
            </TextOverflowEllipsisBox>
          </td>

          <td style={s('py-2', { borderBottom: '1px solid #F1F5F9', width: '10%' })}>
            <TextOverflowEllipsisBox>
              {formatCurrency(effectiveCostOfServicing(props.values))}
            </TextOverflowEllipsisBox>
          </td>

          <td
            style={s('py-2 text-right', {
              borderBottom: '1px solid #F1F5F9',
              width: '5%',
            })}
          >
            <button
              onClick={() => {
                dispatch(
                  deleteUmiReportItem({
                    umiReportItemId: liability.id,
                    versionedLoanApplicationId: liability.versionedLoanApplicationId,
                  })
                )
              }}
              style={s(
                'bg-grey-200 rounded-lg text-grey-600 border-0 font-bold px-2 py-1',
                { cursor: 'pointer' }
              )}
            >
              X
            </button>
          </td>
        </tr>
      )}
    </Formik>
  ))

  return (
    <div style={s('mb-4')}>
      <table style={s('w-full')}>
        <thead style={s('p-0 text-grey-800 text-left text-xs font-semibold')}>
          <tr>
            <th>Type</th>
            <th>Outstanding</th>
            <th>Repayment ($pm)</th>
            <th>Interest</th>
            <th>Remaining term</th>
            <th>Cost of servicing ($pm)</th>
            <th>Effective cost of servicing ($pm)</th>
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>

      {liabilities.length !== 0 && (
        <>
          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
            <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
              Total monthly cost of servicing
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalCostOfServicing(liabilities))}
            </span>
          </div>

          <div style={s('flex py-3', { borderBottom: '1px solid #F1F5F9' })}>
            <span style={s('font-semibold text-sm', { flexGrow: 1 })}>
              Total monthly cost of servicing (effective)
            </span>
            <span style={s('font-semibold text-sm')}>
              {formatCurrency(totalEffectiveCostOfServicing(liabilities))}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
