/* eslint-disable complexity */
import { Loading, Button } from '@vega/components'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import {
  getSecurityDetails,
  selectIsFetchingPropertyDetails,
  selectSearchedProperty,
} from 'modules/property'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { SectionTitle, ContentCard } from 'features/loanAssessment/info/components'
import { GeneralDetails } from './GeneralDetails'
import { PropertyDetails } from './PropertyDetails'
import { EstimateRange } from './EstimateRange'
import { PropertyFeatures } from './PropertyFeatures'
import { RatingValuation } from './RatingValuation'
import { LastKNownSale } from './LastKnownSale'
import { ComparableSales } from './ComparableSales'
import { VALOCITY_REPORT_SECTION_IDS } from 'features/loanAssessment/info/constants'
import { SidebarNavigationReport } from 'features/loanAssessment/sidebarNavigationReport'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { selectAllSecurities } from 'modules/security'

const {
  GENERAL_DETAILS,
  PROPERTY_DETAILS,
  IVAL_ESTIMATE_RANGE,
  PROPERTY_FEATURES,
  RISK_FLAGS,
  RATING_VALUATION,
  LAST_KNOWN_SALE,
  COMPARABLE_PROPERTY_SALES,
} = VALOCITY_REPORT_SECTION_IDS
const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)

const BackButtonText = styled(SectionTitle)(s('text-sm'))

const ValocityReport = () => {
  const dispatch = useDispatch()
  const { id, securityId } = useParams()
  const history = useHistory()

  const versionedApplication = useSelector(selectApplicationById(id))
  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const [activeSection, setActiveSection] = useState('general-details')

  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const isFetchingDetails = useSelector(selectIsFetchingPropertyDetails)
  const losSecurities = useSelector(selectAllSecurities) || []

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    const root = document.getElementById('router-root')
    if (root) {
      const updateCurrentNavigationItemToActive = (
        sectionId,
        topOffset,
        componentHeight
      ) => {
        const exitPoint = topOffset + componentHeight
        const currentScroll = root.scrollTop
        if (currentScroll >= topOffset + 195 && currentScroll < exitPoint + 195) {
          setActiveSection(sectionId)
        }
      }

      const handler = () => {
        Object.values(VALOCITY_REPORT_SECTION_IDS).forEach((sectionId) => {
          const element = document.getElementById(sectionId)

          if (element) {
            updateCurrentNavigationItemToActive(
              sectionId,
              element.offsetTop,
              element.clientHeight,
              'application-info'
            )
          }
        })
      }

      root.addEventListener('scroll', handler)

      return () => {
        root.removeEventListener('scroll', handler)
      }
    }
  }, [id])

  const security = losSecurities?.find((property) => property?.id === securityId)

  const { externalRef } = security?.linkedDetails || ''
  const { linkedDetails: originalLinkedDetails } = security || {}

  const ivalReport = useSelector(selectSearchedProperty(externalRef))

  const {
    categoryDescriptionPublic,
    territorialAuthorityName,
    titles,
    owners,
    legalDescription,
    landZone,
    landUse,
    natureofImprovementsLongForm,
    wallConstructionShort,
    roofConstructionShort,
    valuationReference,
    lastKnownSaleDate,
    lastKnownSalePrice,
    lastKnownSaleMethod,
    riskFlags,
    forecastStandardDeviation,
  } = ivalReport || {}

  const { landArea, floorArea, numOfBedrooms, numOfBathrooms, yearBuilt } =
    ivalReport?.details || {}

  const { capitalValue, landValue, improvementValues, lastValued } =
    ivalReport?.valuation || {}

  const propertyDetails = {
    valocityId: externalRef,
    propertyCatagory: categoryDescriptionPublic,
    territorialAuthority: territorialAuthorityName,
    titleNumbers: titles,
    owners,
    legalDescription,
  }

  const propertyFeatures = {
    landArea,
    floorArea,
    landZone,
    landUse,
    numOfBedrooms,
    numOfBathrooms,
    improvements: natureofImprovementsLongForm,
    yearBuilt,
    wallConstruction: wallConstructionShort,
    roofConstruction: roofConstructionShort,
  }

  const ratingValuation = {
    capitalValue,
    landValue,
    improvementValues,
    lastValued,
    valuationReference,
  }

  const lastKnownSale = {
    lastKnownSaleDate,
    lastKnownSalePrice,
    lastKnownSaleMethod,
  }

  const customEstimateValue = latestApplicationVersion?.customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )

  const linkedDetails = { forecastStandardDeviation, ...originalLinkedDetails }

  useEffect(() => {
    dispatch(
      getSecurityDetails({
        requestId: security?.id,
        propertyId: security?.linkedDetails?.externalRef,
      })
    )
  }, [dispatch, security])

  if (isFetchingApplication || isFetchingDetails) {
    return <Loading />
  }

  return (
    <div style={s('px-24')}>
      <BackButton variant="text" style={s('mb-8')} onClick={() => history.goBack()}>
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>
      <Row style={s('mt-16')}>
        <Col span={4} style={s('sticky', { top: 148 })}>
          <h1 style={s('mt-0')}>iVal Report</h1>
          <SidebarNavigationReport
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={24} id={GENERAL_DETAILS}>
              <SectionTitle title="General Details" style={s('mb-3')} />
              <GeneralDetails linkedDetails={linkedDetails} />
            </Col>

            <Col span={24} id={PROPERTY_DETAILS}>
              <SectionTitle title="Property Details" style={s('mb-3 mt-10')} />
              <PropertyDetails propertyDetails={propertyDetails} />
            </Col>

            <Col span={24} id={IVAL_ESTIMATE_RANGE}>
              <SectionTitle title="IVal Estimate Range" style={s('mb-3 mt-10')} />
              <EstimateRange
                customValue={customEstimateValue}
                valuations={security?.linkedDetails?.valuation}
              />
            </Col>

            <Col span={24} id={PROPERTY_FEATURES}>
              <SectionTitle title="Property Features" style={s('mb-3 mt-10')} />
              <PropertyFeatures propertyFeatures={propertyFeatures} />
            </Col>

            <Col span={24} id={RISK_FLAGS}>
              <SectionTitle title="Risk Flags" style={s('mb-3 mt-10')} />
              <ContentCard>
                <p style={s('text-base font-semibold text-center')}>
                  {riskFlags || 'No risk flags have been identified for this property'}
                </p>
              </ContentCard>
            </Col>

            <Col span={24} id={RATING_VALUATION}>
              <SectionTitle title="Rating Valuation" style={s('mb-3 mt-10')} />
              <RatingValuation ratingValuation={ratingValuation} />
            </Col>

            <Col span={24} id={LAST_KNOWN_SALE}>
              <SectionTitle title="Last Known Sale" style={s('mb-3 mt-10')} />
              <LastKNownSale lastKNownSale={lastKnownSale} />
            </Col>

            <Col span={24} id={COMPARABLE_PROPERTY_SALES}>
              <SectionTitle title="Comparable Property Sales" style={s('mb-3 mt-10')} />
              <ComparableSales properties={ivalReport?.evidence?.comparableSales} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export { ValocityReport }
