/* eslint-disable complexity */
import React from 'react'
import { s } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { OtherIncomesTable } from './OtherIncomesTable'
import { RealEstateTable } from './RealEstateTable'
import { NonRealAssetTable } from './NonRealAssetTable'
import { LiabilitiesTable } from './LiabilitiesTable'
import { NetPositionEquation } from './NetPositionEquation'
import { NetEquityEquation } from './NetEquityEquation'
import { InsuranceDeclaration } from './InsuranceDeclaration'
import { NoteSection } from 'features/common/NoteSection'
import { DetailsToggle } from './DetailsToggle'
import {
  financialsByType,
  financialsForEntities,
  entityInsuranceDeclaration,
  mergeIndividualsDetails,
  calculateMonthlyAmount,
} from './utils'

export const CompanyFinancialPosition = ({
  versionedLoanApplicationId,
  latestVersionId,
  company,
  legalEntities,
  versionedFinancials = [],
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)

  const companyIncomes = financialsForEntities(
    financialsByType(versionedFinancials, 'income'),
    [company]
  )

  const monthCompanyIncomes = companyIncomes.map((income) => {
    const lastSub = income.amount.length - 1
    const amount = calculateMonthlyAmount(income.amount[lastSub], income.frequency)
    return { ...income, monthlyAmount: amount }
  })

  const companyRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'real_estate_asset'),
    [company]
  )

  const companyNonRealAssets = financialsForEntities(
    financialsByType(versionedFinancials, 'non_real_estate_asset'),
    [company]
  )

  const allEntitys = [
    legalEntities?.individuals || [],
    legalEntities?.companies || [],
    legalEntities?.trusts || [],
  ].flat()

  const companyLiabilities = mergeIndividualsDetails(
    financialsForEntities(financialsByType(versionedFinancials, 'liability'), [
      company,
    ]),
    allEntitys
  )

  return (
    <>
      <div style={s('flex')}>
        <h1 style={{ color: '#490B2D', flexGrow: 1 }}>
          <span style={s('text-grey-600 block text-sm font-semibold')}>
            {"Company's financial position"}
          </span>
          {company.businessName}
        </h1>

        <DetailsToggle isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      </div>

      <NetPositionEquation incomes={monthCompanyIncomes} expenses={[]} />

      {isExpanded && (
        <ContentCard style={s('mb-4')}>
          <OtherIncomesTable otherIncomes={companyIncomes} />
        </ContentCard>
      )}

      <NetEquityEquation
        assets={companyRealAssets.concat(companyNonRealAssets)}
        liabilities={companyLiabilities}
      />

      {isExpanded && (
        <>
          <ContentCard style={s('mb-4')}>
            <RealEstateTable realAssetFinancials={companyRealAssets} />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <NonRealAssetTable nonRealAssetFinancials={companyNonRealAssets} />
          </ContentCard>

          <ContentCard style={s('mb-4')}>
            <LiabilitiesTable liabilities={companyLiabilities} />
          </ContentCard>

          <InsuranceDeclaration
            declarations={entityInsuranceDeclaration(company.businessName)}
          />
        </>
      )}

      <div style={s('mb-4')}>
        <NoteSection
          id="note-section"
          versionedLoanApplicationId={versionedLoanApplicationId}
          loanApplicationId={latestVersionId}
          source={`loan-applications/financial/company/${company.externalRef}`}
        />
      </div>
    </>
  )
}
