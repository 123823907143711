import { prop } from '@solta/ramda-extra'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { supportingDocumentAdapter } from './module'

export const {
  selectIsPending: selectIsCreateSupportingDocumentPending,
  selectHasFulfilled: selectHasCreateSupportingDocumentFulfilled,
  selectError: selectCreateSupportingDocumentsError,
} = createAsyncThunkSelectors('supportingDocument/createSupportingDocument')

export const {
  selectAll: selectAllSupportingDocuments,
  selectById: selectSupportingDocumentById,
  selectEntities: selectSupportingDocumentEntities,
} = supportingDocumentAdapter.getSelectors(prop('supportingDocument'))
