import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Col, Row } from '@vega/styled'
import { Button, NumberField, SelectField } from '@vega/components'
import { Formik, Form } from 'formik'
import { s } from '@vega/styled/v2'
import { createUmiReportItem } from 'modules/umiReportItems'

export const AddLiabilityForm = ({ householdRef, closeForm }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleSubmit = (newLiability) => {
    dispatch(
      createUmiReportItem({
        versionedLoanApplicationId: id,
        umiReportItem: newLiability
      })
    )

    closeForm()
  }

  const initialValues = {
    versionedLoanApplicationId: id,
    householdRef,
    umiRecordType: 'umiLiability',
    details: {
      umiLiabilityType: 'revolvingCredit',
      committedLimit: 0,
      outstandingAmount: 0,
      productType: 'buyNowPayLater',
      costOfServicing: 0,
      effectiveCostOfServicing: 0
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, ...props }) => (
        <Form onSubmit={props.handleSubmit}>
          <h4>Add financial liability</h4>

          <Row style={s('mb-4')}>
            <Col span={12}>
              <SelectField
                name="details.umiLiabilityType"
                label="Liability type"
                options={[
                  { label: 'Revolving credit', value: 'revolvingCredit' },
                  { label: 'Student loan, leases, hire purchase', value: 'studentLoanLeaseHirePurchase' },
                  { label: 'Long term loans and mortgages', value: 'longTermLoanMortgage' },
                  { label: 'Car loan', value: 'carLoan' },
                ]}
              />
            </Col>
          </Row>

          {
            values.details.umiLiabilityType === 'revolvingCredit' && (
              <>
                <h5>REVOLVING CREDIT</h5>
                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.productType"
                      label="Product type"
                      options={[
                        { label: 'Credit card', value: 'creditCard' },
                        { label: 'Overdraft', value: 'overdraft' },
                        { label: 'Buy now pay later', value: 'buyNowPayLater' },
                      ]}
                    />
                  </Col>

                  <Col span={12}>
                    <NumberField
                      name="details.committedLimit"
                      label="Commited limit"
                      placeholder=" Commited limit"
                      prefix="$"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <NumberField
                      name="details.outstandingAmount"
                      label="Outstanding amount"
                      placeholder=" Outstanding amount"
                      prefix="$"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          {
            values.details.umiLiabilityType === 'studentLoanLeaseHirePurchase' && (
              <>
                <h5>STUDENT LOAN / LEASE / HIRE PURCHASE</h5>
                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.studentLoanLeaseHirePurchaseType"
                      label="Type"
                      placeholder="Type"
                      options={[
                        { label: 'Student loan', value: 'studentLoan' },
                        { label: 'Lease', value: 'lease' },
                        { label: 'Hire purchase', value: 'hirePurchase' },
                      ]}
                    />
                  </Col>

                  <Col span={12}>
                    <NumberField
                      name="details.outstandingAmount"
                      label="Outstanding amount"
                      placeholder=" Outstanding amount"
                      prefix="$"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <NumberField
                      name="details.repaymentAmount"
                      label="Repayment amount"
                      placeholder=" Repayment amount"
                      prefix="$"
                    />
                  </Col>

                  <Col span={12}>
                    <NumberField
                      name="details.interestRate"
                      label="Interest rate"
                      placeholder="Interest rate"
                      suffix="%"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <NumberField
                      name="details.remainingTermYears"
                      label="Remaining term"
                      placeholder=" Remaining term"
                      suffix="years"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          {
            values.details.umiLiabilityType === 'longTermLoanMortgage' && (
              <>
                <h5>LONG TERM LOAN / MORTGAGE</h5>
                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <NumberField
                      name="details.committedLimit"
                      label="Committed limit"
                      placeholder=" Committed limit"
                      prefix="$"
                    />
                  </Col>

                  <Col span={12}>
                    <SelectField
                      name="details.repaymentType"
                      label="Repayment type"
                      aria-label="Repayment type"
                      placeholder="Repayment type"
                      options={[
                        { label: 'P & I', value: 'principalAndInterest' },
                        { label: 'IO 1 year', value: 'interestOnly1Year' },
                        { label: 'IO 2 year', value: 'interestOnly2Year' },
                        { label: 'IO 3 year', value: 'interestOnly3Year' },
                        { label: 'IO 4 year', value: 'interestOnly4Year' },
                        { label: 'IO 5 year', value: 'interestOnly5Year' },
                        { label: 'IO 6 year', value: 'interestOnly6Year' },
                        { label: 'IO 7 year', value: 'interestOnly7Year' },
                      ]}
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <SelectField
                      name="details.purpose"
                      label="Purpose"
                      placeholder="Purpose"
                      options={[
                        { label: 'OO', value: 'ownerOccupied' },
                        { label: 'RIL', value: 'residentialInvestmentLending' },
                        { label: 'Non-housing', value: 'nonHousing' },
                      ]}
                    />
                  </Col>

                  <Col span={12}>
                    <NumberField
                      name="details.remainingTermYears"
                      label="Remaining term"
                      placeholder=" Remaining term"
                      suffix="Years"
                    />
                  </Col>
                </Row>

                <Row style={s('mb-4')}>
                  <Col span={12}>
                    <NumberField
                      name="details.interestRate"
                      label="Interest rate"
                      placeholder=" Interest rate"
                      suffix="%"
                    />
                  </Col>
                </Row>
              </>
            )
          }

          <div style={s('flex', {flexDirection: 'row-reverse'})}>
            <Button
              type="submit"
              style={s('ml-2')}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              onClick={() => closeForm()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
