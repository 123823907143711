import { round } from 'mathjs'
import { isNilOrEmpty } from '@solta/ramda-extra'

export const calculateAverageLVR = (
  customValuations = [],
  loanAmount = 0,
  securities = []
) => {
  if (isNilOrEmpty(securities)) return '-'

  const activeSecurities = securities.filter((security) => security.status === 'Active')
  const securityValuations = activeSecurities.map((security) => {
    const matchingCustomValuation = customValuations.find(
      ({ financialId }) => financialId === security?.id
    )

    if (isNilOrEmpty(matchingCustomValuation)) {
      return security?.linkedDetails
        ? security?.linkedDetails?.valuation?.estimatedValue
        : security?.estimatedValue?.value
    }

    return matchingCustomValuation?.value
  })

  const totalSecuritiesValue = securityValuations.reduce(
    (totalAmount, value) => totalAmount + value || 0,
    0
  )

  const averageLvr = (loanAmount / totalSecuritiesValue) * 100

  return `${round(averageLvr, 2)}%`
}

export const getLoanStructureInformation = (loanSplits = [], requestedLoans = []) => {
  let loanStructure = ''
  let totalLoanAmount = 0
  let totalLoanDuration = 0

  if (isNilOrEmpty(loanSplits)) {
    loanStructure = requestedLoans.length > 1 ? 'Split' : 'Single'
    totalLoanAmount = requestedLoans.reduce(
      (totalAmount, { amountRequested }) => totalAmount + amountRequested || 0,
      0
    )
    totalLoanDuration = requestedLoans.reduce(
      (highestAmount, { term: { totalTermDuration } }) =>
        Math.max(highestAmount, totalTermDuration),
      0
    )

    return { loanStructure, totalLoanAmount, totalLoanDuration }
  }

  loanStructure = requestedLoans.length > 1 ? 'Split' : 'Single'
  totalLoanAmount = requestedLoans.reduce(
    (totalAmount, { amountRequested }) => totalAmount + amountRequested || 0,
    0
  )
  totalLoanDuration = loanSplits.reduce(
    (highestAmount, { loanTermInYears }) => Math.max(highestAmount, loanTermInYears),
    0
  )

  return { loanStructure, totalLoanAmount, totalLoanDuration }
}

export const getBorrowerNamesString = (legalEntities = []) => {
  if (isNilOrEmpty(legalEntities)) return '-'

  const borrowers = legalEntities.filter(
    (legalEntity) => legalEntity?.applicantRole === 'Borrower'
  )

  const borrowerNames = borrowers.map((borrower) => borrower?.fullName).join(', ')

  return borrowerNames || '-'
}

export const mapUsersToDropdownFormat = (users = []) => {
  const userDropdownOptions = {}

  // eslint-disable-next-line no-return-assign
  users.forEach(({ id, fullName }) => (userDropdownOptions[id] = fullName))

  return userDropdownOptions
}
