/* eslint-disable no-console */
import { prop } from '@solta/ramda-extra'
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { propertyService } from 'apiService'
import { normalizeError } from '@vega/services'
import { append } from 'ramda'

export const getSecurityDetails = createAsyncThunk(
  'property/getSecurityDetails',
  async ({ propertyId }, { rejectWithValue }) => {
    try {
      const propertyDetails = await propertyService.getPropertyDetails(propertyId)

      return propertyDetails
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const propertiesAdapter = createEntityAdapter()

const {
  selectAll: selectProperties,
  selectById: selectPropertyById,
} = propertiesAdapter.getSelectors(prop('property'))

const initialState = propertiesAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
  searchedProperties: [],
  inspectedPropertyId: undefined,
})

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: {
    [getSecurityDetails.fulfilled]: (state, action) => {
      const desiredProperty = state.searchedProperties.find(
        (property) => property.propertyId === action.payload?.propertyId
      )
      if (desiredProperty) return

      state.searchedProperties = append(action.payload, state.searchedProperties)
    },
  },
})

const { reducer: propertiesReducer } = propertiesSlice
export { propertiesReducer, selectProperties, selectPropertyById }
