import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { normalizeError } from '@vega/services'

import { creditReportService } from 'apiService'

export const getCreditReport = createAsyncThunk(
  'creditReport/getCreditReport',
  async ({ versionedLoanApplicationId, externalRef }, { rejectWithValue }) => {
    try {
      return await creditReportService.getCreditReport(
        versionedLoanApplicationId,
        externalRef
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const startCreditCheck = createAsyncThunk(
  'creditReport/startCreditCheck',
  async ({ entityId, versionedLoanApplicationId }, { rejectWithValue }) => {
    try {
      return await creditReportService.startCreditCheck(entityId, versionedLoanApplicationId)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const creditReportAdapter = createEntityAdapter()

const initialState = creditReportAdapter.getInitialState()

const creditReportSlice = createSlice({
  name: 'creditReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCreditReport.fulfilled, (state, { payload }) => {
        if (payload) creditReportAdapter.upsertOne(state, payload)
      })
      .addCase(startCreditCheck.fulfilled, (state, { payload }) =>
        creditReportAdapter.upsertOne(state, payload)
      )
  },
})

const { reducer: creditReportReducer } = creditReportSlice

export { creditReportReducer }
