import React from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { FieldArray, useFormikContext } from 'formik'
import { LoanAllocationForm } from './LoanAllocationForm'
import { s } from '@vega/styled/v2'
import { AllocatedLoanStructureBox } from './structurePreviewBox'
import { useLoanStructureAllocation } from './useLoanStructureAllocation'
import { capitaliseFirstLetterOfAllWords, isBetween } from 'utils/formatters'

const mapEntities = (borrowers = []) =>
  borrowers.map((borrower) => ({
    id: borrower?.entityId,
    fullName: borrower?.fullName,
    legalEntityType: borrower?.legalEntityType,
  }))

const mapSecurities = (securities = []) =>
  securities.map((security) => {
    const { address, linkedDetails, finalAssetValue } = security

    return {
      id: security?.id,
      value: finalAssetValue,
      address:
        capitaliseFirstLetterOfAllWords(linkedDetails?.address) || address?.fullAddress,
    }
  })

const getActiveProducts = (allProducts = []) => {
  return allProducts.filter(({ status, fields }) => {
    const { validFrom, validTo } = fields || {}

    return (
      status === 'active' &&
      isBetween(new Date(), new Date(validFrom), new Date(validTo))
    )
  })
}

const mapActiveProducts = (activeProducts = []) =>
  activeProducts.map((product) => {
    const { fields = {} } = product
    const { minTerm, maxTerm, ...otherFields } = fields

    return {
      ...product,
      fields: {
        ...otherFields,
        minTermInYears: minTerm / 12,
        maxTermInYears: maxTerm / 12,
      },
    }
  })

export const LoanAllocationSection = ({
  loanApplication,
  allBorrowers = [],
  allGuarantors = [],
  allProducts = [],
  securities = [],
}) => {
  const { id } = loanApplication

  const mappedAllBorrowers = mapEntities(allBorrowers)
  const mappedAllGuarantors = mapEntities(allGuarantors)
  const mappedAllSecurities = mapSecurities(securities)

  const { values } = useFormikContext()

  const activeProducts = getActiveProducts(allProducts) || []
  const mappedActiveProducts = mapActiveProducts(activeProducts)

  const {
    editAllocatedLoanStructure,
    cancelEditingAllocatedStructure,
    cancelAllocatingNewStructure,
    allocateNewLoanStructure,
    updateAllocatedLoanStructure,
  } = useLoanStructureAllocation()

  return (
    <FieldArray
      name="loanStructures"
      render={() => {
        return (
          <div>
            {values.loanStructures.map((loanStructure, idx) => {
              const {
                structureId,
                isAllocated,
                product,
                repaymentFrequency,
                loanTermInYears,
                dayOfTheMonth,
                dayOfTheWeek,
                dayOfTheFortnight,
                loanAmount,
                loading,
                securityProperties = [],
                associatedBorrowers = [],
                guarantors = [],
                primaryApplicant,
                totalInterestRate,
              } = loanStructure

              const isRecommendedStructure = isNotNilOrEmpty(
                loanStructure.recommendationReference
              )

              const cancelAllocatingStructure = (
                structureIdx,
                loanStructure,
                isRecommendedStructure
              ) => {
                if (loanStructure.isAllocated) {
                  cancelEditingAllocatedStructure(structureIdx)

                  return
                }

                cancelAllocatingNewStructure(idx, loanStructure, isRecommendedStructure)
              }

              return loanStructure.isAllocated && !loanStructure.isEditMode ? (
                <AllocatedLoanStructureBox
                  {...{
                    key: structureId,
                    isAllocated,
                    product,
                    repaymentFrequency,
                    loanTermInYears,
                    loanAmount,
                    totalSecurities: securityProperties.length,
                    entities: associatedBorrowers,
                    primaryApplicant,
                    totalInterestRate,
                    dayOfTheMonth,
                    dayOfTheWeek,
                    loading,
                    dayOfTheFortnight,
                    loanStructureNumber: idx + 1,
                    associatedBorrowers,
                    allBorrowers,
                    guarantors,
                    securities: securityProperties,
                    editAllocatedLoanStructure: () =>
                      editAllocatedLoanStructure(idx, structureId),
                  }}
                  style={s('mb-8')}
                />
              ) : (
                <LoanAllocationForm
                  key={structureId}
                  idx={idx}
                  structureId={structureId}
                  loanStructureNumber={idx + 1}
                  fieldPrefix={`loanStructures.${idx}`}
                  canAllocateLoanStructure={true}
                  isRecommendedStructure={isRecommendedStructure}
                  activeProducts={mappedActiveProducts}
                  allBorrowers={mappedAllBorrowers}
                  allGuarantors={mappedAllGuarantors}
                  allSecurities={mappedAllSecurities}
                  handleCancelBtnClicked={() =>
                    cancelAllocatingStructure(
                      idx,
                      values.loanStructures?.[idx],
                      isRecommendedStructure
                    )
                  }
                  allocateLoanStructure={async () => {
                    if (isAllocated) {
                      return updateAllocatedLoanStructure(id, idx)
                    }

                    return allocateNewLoanStructure(id, idx, isRecommendedStructure)
                  }}
                />
              )
            })}
          </div>
        )
      }}
    />
  )
}
