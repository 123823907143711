import { prop } from '@solta/ramda-extra'
import { noteAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectNotes = prop('note')

export const { selectIsPending: selectIsFetchingNotes } = createAsyncThunkSelectors(
  'note/fetchNotes'
)

export const { selectAll: selectAllNotes } = noteAdapter.getSelectors(selectNotes)
