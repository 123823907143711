import React from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { ContentCard } from '../components'
import { s, styled } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { Button, toast } from '@vega/components'
import { ReactComponent as LinkIcon } from '@vega/components/src/assets/images/link.svg'
import { ReactComponent as ClipBoardIcon } from '@vega/components/src/assets/images/clipboard.svg'
import { formatDateString } from 'utils/formatters'
import { AFFORDABILITY_ASSESSMENT_CONSTANTS } from '@neo/constants'
import { ASSESSMENT_STATUS } from '@neo/constants/src/affordabilityAssessment'
import { ReactComponent as TickIcon } from '@vega/components/src/assets/images/tick.svg'
import { copyToClipboard } from 'utils/copyToClipboard'
import { UMIExportButton } from './UMIExportButton'

const { ASSESSMENT_URI_TYPE } = AFFORDABILITY_ASSESSMENT_CONSTANTS

const ApplicantGroupDescription = styled.p(s('mt-0 text-grey-600'))
const SectionTitle = styled.p(s('text-primary text-xs font-semibold'))
const Timestamp = styled.p(s('text-grey-600 text-xs'))

const LinkIllionButton = styled(Button)(
  ({ hasIllionRetrievalCompleted }) =>
    hasIllionRetrievalCompleted &&
    s('border-secondary', {
      backgroundColor: `${s('bg-secondary').backgroundColor} !important`,
    })
)

const getAnalyticalDashboardUrl = (affordabilityAssessments, individualDetail) => {
  return affordabilityAssessments
    .find(
      (assessment) => assessment.legalEntityExternalRef === individualDetail.externalRef
    )?.uris?.find(
      (uri) => uri.name === ASSESSMENT_URI_TYPE.ANALYTICAL_DASHBOARD
    )?.value || ''
}

const ActionPanel = ({
  individualsGroup = [],
  affordabilityAssessments = [],
  updateXai,
  sendIllionLink,
  entityIdToIllionRetrievalStatus = {},
  entitiesLinkingIllion = [],
  isUpdatingXai = false,
  ...props
}) => {
  // Individuals in same group share the same dashboard url
  const analyticalDashboardUrl = getAnalyticalDashboardUrl(affordabilityAssessments, individualsGroup?.[0])
  const { assessmentReference, financialsUpdatedAt, status } =
    individualsGroup?.[0]?.affordabilityAssessment || {}

  const isUpdatedXai =
    status === ASSESSMENT_STATUS.FINANCIALS_UPDATED &&
    isNotNilOrEmpty(financialsUpdatedAt)

  const openDashboardLink = (url) => {
    if (isNotNilOrEmpty(url)) window.open(url)
  }

  return (
    <ContentCard {...props}>
      <ApplicantGroupDescription>
        Consists of{' '}
        <span style={s('font-semibold text-grey-800')}>
          {individualsGroup?.map((individualDetail, idx) => {
            const shouldRenderComma = individualsGroup?.length !== idx + 1

            return `${individualDetail?.fullName}${shouldRenderComma ? ', ' : ''}`
          })}
        </span>
      </ApplicantGroupDescription>

      <SectionTitle>XAI FUNCTIONS</SectionTitle>
      <Row style={s('mb-1')} gutter={[0, 0]}>
        <Col style={s('mr-4')}>
          <Button
            style={s('min-w-6')}
            onClick={() => updateXai(assessmentReference)}
            loading={isUpdatingXai}
            variant="outlined"
          >
            Update Xai
          </Button>
        </Col>

        <Col>
          <Button
            StartIcon={<LinkIcon style={s('mr-1')} />}
            onClick={() => openDashboardLink(analyticalDashboardUrl)}
            variant="outlined"
          >
            View Xai
          </Button>
        </Col>
      </Row>

      {isUpdatedXai && (
        <Timestamp style={s('m-0 mb-5')}>
          Last updated {formatDateString(financialsUpdatedAt, '-', 'h:mma, dd/MM/yyyy')}
        </Timestamp>
      )}

      <SectionTitle>ILLION CONNECTION STATUS</SectionTitle>

      <div style={s('mb-5')}>
        {individualsGroup?.map((individual) => {
          const matchingAssessment = affordabilityAssessments.find(
            (assessment) => assessment.legalEntityExternalRef === individual.externalRef
          )

          const illionLink = matchingAssessment?.uris?.find(
            (uri) => uri?.name === 'bankStatementRetrieval'
          )?.value

          const hasIllionRetrievalStarted =
            entityIdToIllionRetrievalStatus[individual?.externalRef] === 'started'

          const hasIllionRetrievalCompleted =
            entityIdToIllionRetrievalStatus[individual?.externalRef] === 'completed'

          const isIndividualLinkingXai = entitiesLinkingIllion.includes(
            individual?.externalRef
          )

          return (
            <Row gutter={[0, 0]} style={s('mb-4')} key={individual?.entityId}>
              <Col>
                <LinkIllionButton
                  style={s('mr-4 w-12')}
                  disabled={hasIllionRetrievalCompleted}
                  loading={isIndividualLinkingXai || hasIllionRetrievalStarted}
                  StartIcon={
                    hasIllionRetrievalCompleted && <TickIcon style={s('mr-1')} />
                  }
                  hasIllionRetrievalCompleted={hasIllionRetrievalCompleted}
                  onClick={() => sendIllionLink(individual.externalRef)}
                  variant="outlined"
                >
                  <span className="textEllipsis">
                    {hasIllionRetrievalCompleted
                      ? `${individual?.personName?.firstName} Linked`
                      : `Send Link To ${individual?.personName?.firstName}`}
                  </span>
                </LinkIllionButton>
              </Col>

              <Col>
                <Button
                  StartIcon={<ClipBoardIcon width="20" style={s('mr-1')} />}
                  variant="outlined"
                  onClick={() => {
                    copyToClipboard(illionLink)
                    toast('Copied To Clipboard')
                  }}
                  style={s('px-3')}
                >
                  Copy Link
                </Button>
              </Col>
            </Row>
          )
        })}
      </div>

      <UMIExportButton
        isUpdatedXai={isUpdatedXai}
        assessmentReference={assessmentReference}
        financialsUpdatedAt={financialsUpdatedAt}
      />
    </ContentCard>
  )
}

export { ActionPanel }
