import { prop } from '@solta/ramda-extra'
import { productsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const {
  selectIsPending: selectIsFetchProductsPending,
} = createAsyncThunkSelectors('product/fetchProducts')

export const { selectAll: selectAllProducts } = productsAdapter.getSelectors(
  prop('product')
)
