/* eslint-disable no-prototype-builtins */
/* eslint-disable complexity */
import { isNilOrEmpty } from '@solta/ramda-extra'
import { formatCurrency } from 'utils/formatters'

export const compareObjects = (oldObject, newObject) => {
  const differenceObject = Array.isArray(newObject) ? [] : {}

  for (const [key, newValue] of Object.entries(newObject)) {
    const oldValue = oldObject ? oldObject[key] : undefined

    if (oldObject && oldObject.hasOwnProperty(key)) {
      if (
        typeof newValue === 'object' &&
        typeof oldValue === 'object' &&
        newValue !== null &&
        oldValue !== null
      ) {
        const nestedDifference = compareObjects(oldValue, newValue)
        if (Object.keys(nestedDifference).length > 0) {
          differenceObject[key] = nestedDifference
        }
      } else if (['rentalAmount', 'value'].includes(key)) {
        differenceObject[key] = [formatCurrency(oldValue), formatCurrency(newValue)]
      } else if (key === 'percent') {
        differenceObject[key] = [`${oldValue}%`, `${newValue}%`]
      } else if (newValue === oldValue) {
        differenceObject[key] = [newValue]
      } else {
        differenceObject[key] = [oldValue, newValue]
      }
    } else if (typeof newValue === 'object' && newValue !== null) {
      const nestedDifference = compareObjects(null, newValue)
      if (Object.keys(nestedDifference).length > 0) {
        differenceObject[key] = nestedDifference
      }
    } else if (key === 'percent') {
      differenceObject[key] = [null, `${newValue}%`]
    } else if (['rentalAmount', 'value'].includes(key)) {
      differenceObject[key] = [null, formatCurrency(newValue)]
    } else {
      differenceObject[key] = [null, newValue]
    }
  }

  return differenceObject
}

export const versioningSecurity = (security) => {
  if (isNilOrEmpty(security.snapShot)) {
    return false
  }

  const { snapShot, address, ...securityCopy } = { ...security }
  const snapShotCopy = { ...snapShot }
  delete snapShotCopy.address

  const versionedSecurity = compareObjects(snapShotCopy, securityCopy)

  return versionedSecurity
}
