import { buildEntityName } from 'utils/entity'
import { isNotNilOrEmpty } from '@solta/ramda-extra'

const getCurrentAddress = (linkedAddress, initialAddress) => {
  return isNotNilOrEmpty(linkedAddress) ? linkedAddress : initialAddress
}

const mapSecurity = (security, loanApplication) => {
  const { estimatedValue, linkedDetails, id: securityId, address = {} } = security
  const { customSecurityValuations } = loanApplication

  const customSecurityValuation = customSecurityValuations?.find(
    (item) => item.financialId === securityId
  )

  const linkedAddress = linkedDetails?.address

  const customEstimatedValue = customSecurityValuation?.value
  const ivalEstimatedValue = linkedDetails?.valuation?.estimatedValue
  const myCrmEstimatedValue = estimatedValue?.value

  const currentFullAddress = getCurrentAddress(linkedAddress, address?.fullAddress)

  return {
    ...security,
    address: {
      ...address,
      fullAddress: currentFullAddress,
    },
    finalAssetValue:
      customEstimatedValue || ivalEstimatedValue || myCrmEstimatedValue || undefined,
  }
}

export const mapLegalEntity = (legalEntity) => {
  return {
    ...legalEntity,
    fullName: buildEntityName(
      {
        firstName: legalEntity?.personName?.firstName,
        lastName: legalEntity?.personName?.surname,
      },
      legalEntity?.trustName,
      legalEntity?.businessName,
      legalEntity?.companyName
    ),
  }
}

const mapLoanApplicationVersion = (applicationVersion) => {
  return {
    ...applicationVersion,
    application: {
      ...applicationVersion.application,
      securities: applicationVersion?.application?.securities?.map((s) =>
        mapSecurity(s, applicationVersion)
      ),
      legalEntities: {
        individuals: applicationVersion?.application?.legalEntities?.individuals?.map(mapLegalEntity),
        trusts: applicationVersion?.application?.legalEntities?.trusts?.map(mapLegalEntity),
        companies: applicationVersion?.application?.legalEntities?.companies?.map(mapLegalEntity),
      },
    }
  }
}

const mapLoanApplication = (loanApplication) => {
  return {
    ...loanApplication,
    versions: loanApplication?.versions.map((version) =>
      mapLoanApplicationVersion(version)
    )
  }
}

export { mapLoanApplication }
