/* eslint-disable complexity */
/* eslint-disable no-prototype-builtins */
import React from 'react'
import { Formik as FormProvider, Form } from 'formik'
import { s, styled } from '@vega/styled/v2'
import {
  TextField as TextFieldBase,
  SelectField,
  NumberField,
  MultiSelectField,
  Button,
} from '@vega/components'
import { useDispatch } from 'react-redux'
import { Col, Row } from '@vega/styled'
import {
  propertyType,
  primaryUsage,
  primaryPurpose,
  holdings,
  estimateBasis,
  rentalIncomeFrequency,
} from './dropdown'
import { calculateMonthlyAmount } from '../financial/utils'
import { updateLosSecurity, fetchSecurities } from 'modules/security'
import { capitaliseFirstLetterOfAllWords } from 'utils'

import * as Yup from 'yup'

const rowStyles = s('mt-4')
const sectionStyles = s('mt-8')

const SubTitle = styled.h3(
  s('text-xs font-normal text-primary font-semibold mt-4 mb-0', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

export const TextField = styled((props) => (
  <TextFieldBase containerStyle={s('flex-1')} {...props} />
))()

const Label = styled.span(
  s('inline-block text-sm font-normal text-grey-800 font-normal mb-2', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const Title = styled.span(
  s('text-grey-900 text-2xl font-extrabold font-semibold inline-block ')
)

const ContentCard = styled.div(
  s('mb-5 mt-10 px-5 rounded-lg bg-white p-8 w-full', {
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
  })
)

const mapToEntityDropdownOptions = (entities) =>
  entities.map(({ id, fullName }) => ({
    key: id,
    label: fullName,
  }))

const validationSchema = Yup.object().shape({
  value: Yup.number().required('Estimated value is required'),
  estimateBasis: Yup.string().required('Estimate basis is required'),
  propertyTypeName: Yup.string().required('Property type is required'),
  primaryUsage: Yup.string().required('Primary usage is required'),
  primaryPurpose: Yup.string().required('Primary purpose is required'),
  holding: Yup.string().required('Holding is required'),
  frequency: Yup.string(),
  owners: Yup.array().min(1, 'Associated entities is required'),
})

const fillEmptyObj = (data) => {
  const emptyObj = {
    estimatedValue: {
      estimatedCGTLiability: null,
      value: null,
      valuedDate: null,
      xValuer: null,
      estimateBasis: '',
    },
    propertyType: {
      categoryTypeName: null,
      propertyTypeCode: null,
      propertyTypeName: '',
    },
    primaryUsage: '',
    holding: '',
    percentOwned: { proportions: 'Equal', owners: [] },
    isSecurity: true,
    primaryPurpose: '',
    rentalIncome: [
      {
        endDate: null,
        evidenceOfTenancy: true,
        netRentalAmount: null,
        rentalAmount: null,
        shortTermRentalAccommodation: null,
        shortTermRentalDescription: null,
        startDate: null,
        xOwner: null,
        _id: '63fe85abf9652d00239f971d',
        frequency: 'Monthly',
      },
    ],
  }

  emptyObj.estimatedValue.value = data.value
  emptyObj.estimatedValue.estimateBasis = data.estimateBasis
  emptyObj.primaryUsage = data.primaryUsage
  emptyObj.primaryPurpose = data.primaryPurpose
  emptyObj.propertyType.propertyTypeName = data.propertyTypeName
  emptyObj.holding = data.holding
  emptyObj.rentalIncome[0].rentalAmount = data.rentalAmount
    ? calculateMonthlyAmount(data.rentalAmount, data.frequency)
    : '-'

  data.owners.map((owner) => {
    emptyObj.percentOwned.owners.push({
      percent: Math.round(100 / data.owners.length),
      legalEntityId: owner.id,
    })
    return []
  })

  return emptyObj
}

export const UpdateSecurityForm = ({
  setShowEditForm,
  security,
  allBorrowers = [],
  allGuarantors = [],
}) => {
  const dispatch = useDispatch()

  const {
    id,
    estimatedValue,
    propertyType: propertyTypes,
    primaryUsage: primaryUsages,
    primaryPurpose: primaryPurposes,
    holding,
    rentalIncome,
    percentOwned,
    address,
    linkedDetails,
    versionedId,
    source,
  } = security || {}

  const handleSubmit = async (data) => {
    setShowEditForm(false)
    const payload = fillEmptyObj(data)
    await dispatch(updateLosSecurity({ id, payload }))
    dispatch(fetchSecurities(versionedId))
  }

  const allBorrowersAndGuarantors = allBorrowers.concat(allGuarantors)

  const borrowerDropdownOptions = mapToEntityDropdownOptions(
    allBorrowersAndGuarantors || []
  )

  const disableSecurityValue = source === 'MyCRM'
  const estimateValueLabel =
    source === 'MyCRM' ? 'MyCRM Estimated Value' : 'Estimated Value'

  const onwers = percentOwned.owners.map((owner) => {
    const borrower = allBorrowersAndGuarantors?.find(
      (borrower) => borrower.id === owner.legalEntityId
    )
    return {
      fullName: borrower.fullName,
      id: borrower.id,
    }
  })

  return (
    <ContentCard>
      <FormProvider
        initialValues={{
          value: estimatedValue.value,
          estimateBasis: estimatedValue.estimateBasis,
          propertyTypeName: propertyTypes.propertyTypeName,
          primaryUsage: primaryUsages,
          primaryPurpose: primaryPurposes,
          rentalAmount:
            rentalIncome[0]?.rentalAmount === '-'
              ? null
              : rentalIncome[0]?.rentalAmount,
          holding,
          frequency: rentalIncome[0]?.rentalAmount === '-' ? '' : 'Monthly',
          owners: onwers,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <Title>
              {linkedDetails?.address
                ? capitaliseFirstLetterOfAllWords(linkedDetails?.address)
                : address?.fullAddress}
            </Title>
            <Row>
              <Col span={24}>
                <Row style={sectionStyles}>
                  <Col span={24}>
                    <SubTitle>SECURITY VALUE</SubTitle>
                    <Row style={rowStyles}>
                      <Col span={12}>
                        <Label>{estimateValueLabel}</Label>
                        <NumberField
                          name="value"
                          placeholder="$"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.value}
                          isDisabled={disableSecurityValue}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Estimate Basis</Label>
                        <SelectField
                          name="estimateBasis"
                          options={estimateBasis.map((basis) => ({
                            label: basis.label,
                            value: basis.label,
                            key: basis.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select an estimate basis'}
                          disabled={disableSecurityValue}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row style={sectionStyles}>
                  <Col span={24}>
                    <SubTitle>PROPERTY DETAILS</SubTitle>
                    <Row style={rowStyles}>
                      <Col span={12}>
                        <Label>Property Type</Label>
                        <SelectField
                          name="propertyTypeName"
                          options={propertyType.map((property) => ({
                            label: property.label,
                            value: property.label,
                            key: property.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select a property type'}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Primary Usage</Label>
                        <SelectField
                          name="primaryUsage"
                          options={primaryUsage.map((usage) => ({
                            label: usage.label,
                            value: usage.label,
                            key: usage.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select a primary usage'}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Primary Purpose</Label>
                        <SelectField
                          name="primaryPurpose"
                          options={primaryPurpose.map((purpose) => ({
                            label: purpose.label,
                            value: purpose.label,
                            key: purpose.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select a primary purpose'}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Holding</Label>
                        <SelectField
                          name="holding"
                          options={holdings.map((holding) => ({
                            label: holding.label,
                            value: holding.label,
                            key: holding.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select a holding'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={sectionStyles}>
                  <Col span={24}>
                    <SubTitle>PROPOSED RENTAL INCOME</SubTitle>
                    <Row style={rowStyles}>
                      <Col span={12}>
                        <Label>Gross Rental Income Amount</Label>
                        <NumberField
                          name="rentalAmount"
                          placeholder="$"
                          containerStyle={{ flexBasis: 0, flexGrow: 1 }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.rentalAmount}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Payment Frequency</Label>
                        <SelectField
                          name="frequency"
                          options={rentalIncomeFrequency.map((frequency) => ({
                            label: frequency.label,
                            value: frequency.label,
                            key: frequency.key,
                          }))}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={'Select a frequency'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={sectionStyles}>
                  <Col span={24}>
                    <SubTitle>SECURITY INFORMATION</SubTitle>
                    <Row style={rowStyles}>
                      <Col span={24}>
                        <Label>Associated Entities</Label>
                        <MultiSelectField
                          items={borrowerDropdownOptions}
                          name="owners"
                          placeholder="Select associated entities"
                          onChange={(selectedKeys) => {
                            if (!allBorrowers) return
                            setFieldValue(
                              `owners`,
                              selectedKeys?.map((selectedEntityId) =>
                                allBorrowersAndGuarantors?.find(
                                  (borrower) => borrower.id === selectedEntityId
                                )
                              )
                            )
                          }}
                          displayTextSelector={(selectedKeys) => {
                            return selectedKeys.map((selectedEntityId) => {
                              const selectedBorrower = allBorrowersAndGuarantors.find(
                                (borrower) => borrower.id === selectedEntityId
                              )

                              return selectedBorrower?.fullName
                            })
                          }}
                          valueSelector={(values) => values?.map((value) => value?.id)}
                          variant="pill"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={s('flex items-center justify-end mt-8')}>
              <Button
                variant="outlined"
                onClick={() => setShowEditForm(false)}
                type="button"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" style={s('ml-4')}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </FormProvider>
    </ContentCard>
  )
}
