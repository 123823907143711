const benchmarks = {
  'oneAdult': {
    0: [
      { threshold: -1, benchmark: 687 },
      { threshold: 2000, benchmark: 901 },
      { threshold: 3000, benchmark: 1062 },
      { threshold: 4000, benchmark: 1157 },
      { threshold: 5000, benchmark: 1228 },
      { threshold: 6000, benchmark: 1284 },
      { threshold: 7000, benchmark: 1331 },
      { threshold: 8000, benchmark: 1371 },
      { threshold: 9000, benchmark: 1407 },
      { threshold: 10000, benchmark: 1438 },
      { threshold: 12500, benchmark: 1482 },
      { threshold: 15000, benchmark: 1537 },
      { threshold: 20000, benchmark: 1600 },
      { threshold: 25000, benchmark: 1732 }
    ],
    1: [
      { threshold: -1, benchmark: 773 },
      { threshold: 2000, benchmark: 1019 },
      { threshold: 3000, benchmark: 1202 },
      { threshold: 4000, benchmark: 1337 },
      { threshold: 5000, benchmark: 1439 },
      { threshold: 6000, benchmark: 1519 },
      { threshold: 7000, benchmark: 1587 },
      { threshold: 8000, benchmark: 1645 },
      { threshold: 9000, benchmark: 1695 },
      { threshold: 10000, benchmark: 1739 },
      { threshold: 12500, benchmark: 1803 },
      { threshold: 15000, benchmark: 1881 },
      { threshold: 20000, benchmark: 1972 },
      { threshold: 25000, benchmark: 2161 },
    ],
    2: [
      { threshold: -1, benchmark: 848 },
      { threshold: 2000, benchmark: 1127 },
      { threshold: 3000, benchmark: 1320 },
      { threshold: 4000, benchmark: 1475 },
      { threshold: 5000, benchmark: 1592 },
      { threshold: 6000, benchmark: 1686 },
      { threshold: 7000, benchmark: 1763 },
      { threshold: 8000, benchmark: 1829 },
      { threshold: 9000, benchmark: 1887 },
      { threshold: 10000, benchmark: 1939 },
      { threshold: 12500, benchmark: 2011 },
      { threshold: 15000, benchmark: 2101 },
      { threshold: 20000, benchmark: 2205 },
      { threshold: 25000, benchmark: 2423 },
    ],
    3: [
      { threshold: -1, benchmark: 923 },
      { threshold: 2000, benchmark: 1223 },
      { threshold: 3000, benchmark: 1438 },
      { threshold: 4000, benchmark: 1593 },
      { threshold: 5000, benchmark: 1710 },
      { threshold: 6000, benchmark: 1804 },
      { threshold: 7000, benchmark: 1881 },
      { threshold: 8000, benchmark: 1947 },
      { threshold: 9000, benchmark: 2005 },
      { threshold: 10000, benchmark: 2057 },
      { threshold: 12500, benchmark: 2129 },
      { threshold: 15000, benchmark: 2219 },
      { threshold: 20000, benchmark: 2323 },
      { threshold: 25000, benchmark: 2541 },
    ],
    4: [
      { threshold: -1, benchmark: 987 },
      { threshold: 2000, benchmark: 1309 },
      { threshold: 3000, benchmark: 1545 },
      { threshold: 4000, benchmark: 1701 },
      { threshold: 5000, benchmark: 1818 },
      { threshold: 6000, benchmark: 1911 },
      { threshold: 7000, benchmark: 1988 },
      { threshold: 8000, benchmark: 2055 },
      { threshold: 9000, benchmark: 2113 },
      { threshold: 10000, benchmark: 2164 },
      { threshold: 12500, benchmark: 2236 },
      { threshold: 15000, benchmark: 2326 },
      { threshold: 20000, benchmark: 2430 },
      { threshold: 25000, benchmark: 2648 },
    ],
    5: [
      { threshold: -1, benchmark: 1052 },
      { threshold: 2000, benchmark: 1395 },
      { threshold: 3000, benchmark: 1642 },
      { threshold: 4000, benchmark: 1797 },
      { threshold: 5000, benchmark: 1914 },
      { threshold: 6000, benchmark: 2008 },
      { threshold: 7000, benchmark: 2085 },
      { threshold: 8000, benchmark: 2151 },
      { threshold: 9000, benchmark: 2209 },
      { threshold: 10000, benchmark: 2261 },
      { threshold: 12500, benchmark: 2333 },
      { threshold: 15000, benchmark: 2423 },
      { threshold: 20000, benchmark: 2527 },
      { threshold: 25000, benchmark: 2745 },
    ]
  },
  'twoAdults': {
    0: [
      { threshold: -1, benchmark: 987 },
      { threshold: 2000, benchmark: 1309 },
      { threshold: 3000, benchmark: 1545 },
      { threshold: 4000, benchmark: 1659 },
      { threshold: 5000, benchmark: 1744 },
      { threshold: 6000, benchmark: 1811 },
      { threshold: 7000, benchmark: 1868 },
      { threshold: 8000, benchmark: 1916 },
      { threshold: 9000, benchmark: 1959 },
      { threshold: 10000, benchmark: 1997 },
      { threshold: 12500, benchmark: 2049 },
      { threshold: 15000, benchmark: 2115 },
      { threshold: 20000, benchmark: 2191 },
      { threshold: 25000, benchmark: 2350 },
    ],
    1: [
      { threshold: -1, benchmark: 1052 },
      { threshold: 2000, benchmark: 1395 },
      { threshold: 3000, benchmark: 1642 },
      { threshold: 4000, benchmark: 1803 },
      { threshold: 5000, benchmark: 1924 },
      { threshold: 6000, benchmark: 2019 },
      { threshold: 7000, benchmark: 2100 },
      { threshold: 8000, benchmark: 2169 },
      { threshold: 9000, benchmark: 2229 },
      { threshold: 10000, benchmark: 2281 },
      { threshold: 12500, benchmark: 2356 },
      { threshold: 15000, benchmark: 2450 },
      { threshold: 20000, benchmark: 2558 },
      { threshold: 25000, benchmark: 2782 },
    ],
    2: [
      { threshold: -1, benchmark: 1116 },
      { threshold: 2000, benchmark: 1481 },
      { threshold: 3000, benchmark: 1738 },
      { threshold: 4000, benchmark: 1934 },
      { threshold: 5000, benchmark: 2079 },
      { threshold: 6000, benchmark: 2195 },
      { threshold: 7000, benchmark: 2293 },
      { threshold: 8000, benchmark: 2376 },
      { threshold: 9000, benchmark: 2449 },
      { threshold: 10000, benchmark: 2513 },
      { threshold: 12500, benchmark: 2603 },
      { threshold: 15000, benchmark: 2717 },
      { threshold: 20000, benchmark: 2847 },
      { threshold: 25000, benchmark: 3119 },
    ],
    3: [
      { threshold: -1, benchmark: 1180 },
      { threshold: 2000, benchmark: 1556 },
      { threshold: 3000, benchmark: 1835 },
      { threshold: 4000, benchmark: 2030 },
      { threshold: 5000, benchmark: 2176 },
      { threshold: 6000, benchmark: 2292 },
      { threshold: 7000, benchmark: 2390 },
      { threshold: 8000, benchmark: 2472 },
      { threshold: 9000, benchmark: 2545 },
      { threshold: 10000, benchmark: 2610 },
      { threshold: 12500, benchmark: 2700 },
      { threshold: 15000, benchmark: 2813 },
      { threshold: 20000, benchmark: 2943 },
      { threshold: 25000, benchmark: 3216 },
    ],
    4: [
      { threshold: -1, benchmark: 1234 },
      { threshold: 2000, benchmark: 1631 },
      { threshold: 3000, benchmark: 1921 },
      { threshold: 4000, benchmark: 2116 },
      { threshold: 5000, benchmark: 2262 },
      { threshold: 6000, benchmark: 2378 },
      { threshold: 7000, benchmark: 2475 },
      { threshold: 8000, benchmark: 2558 },
      { threshold: 9000, benchmark: 2631 },
      { threshold: 10000, benchmark: 2695 },
      { threshold: 12500, benchmark: 2786 },
      { threshold: 15000, benchmark: 2899 },
      { threshold: 20000, benchmark: 3029 },
      { threshold: 25000, benchmark: 3302 },
    ],
    5: [
      { threshold: -1, benchmark: 1288 },
      { threshold: 2000, benchmark: 1706 },
      { threshold: 3000, benchmark: 2007 },
      { threshold: 4000, benchmark: 2202 },
      { threshold: 5000, benchmark: 2348 },
      { threshold: 6000, benchmark: 2464 },
      { threshold: 7000, benchmark: 2561 },
      { threshold: 8000, benchmark: 2644 },
      { threshold: 9000, benchmark: 2717 },
      { threshold: 10000, benchmark: 2781 },
      { threshold: 12500, benchmark: 2871 },
      { threshold: 15000, benchmark: 2985 },
      { threshold: 20000, benchmark: 3115 },
      { threshold: 25000, benchmark: 3387 },
    ]
  },
  'greaterThanTwoAdults': {
    0: [
      { threshold: -1, benchmark: 1267 },
      { threshold: 2000, benchmark: 1689 },
      { threshold: 3000, benchmark: 1995 },
      { threshold: 4000, benchmark: 2127 },
      { threshold: 5000, benchmark: 2225 },
      { threshold: 6000, benchmark: 2302 },
      { threshold: 7000, benchmark: 2369 },
      { threshold: 8000, benchmark: 2424 },
      { threshold: 9000, benchmark: 2474 },
      { threshold: 10000, benchmark: 2518 },
      { threshold: 12500, benchmark: 2578 },
      { threshold: 15000, benchmark: 2654 },
      { threshold: 20000, benchmark: 2742 },
      { threshold: 25000, benchmark: 2926 },
    ],
    1: [
      { threshold: -1, benchmark: 1312 },
      { threshold: 2000, benchmark: 1745 },
      { threshold: 3000, benchmark: 2052 },
      { threshold: 4000, benchmark: 2237 },
      { threshold: 5000, benchmark: 2376 },
      { threshold: 6000, benchmark: 2485 },
      { threshold: 7000, benchmark: 2578 },
      { threshold: 8000, benchmark: 2657 },
      { threshold: 9000, benchmark: 2726 },
      { threshold: 10000, benchmark: 2786 },
      { threshold: 12500, benchmark: 2872 },
      { threshold: 15000, benchmark: 2980 },
      { threshold: 20000, benchmark: 3104 },
      { threshold: 25000, benchmark: 3361 },
    ],
    2: [
      { threshold: -1, benchmark: 1366 },
      { threshold: 2000, benchmark: 1811 },
      { threshold: 3000, benchmark: 2128 },
      { threshold: 4000, benchmark: 2361 },
      { threshold: 5000, benchmark: 2533 },
      { threshold: 6000, benchmark: 2670 },
      { threshold: 7000, benchmark: 2787 },
      { threshold: 8000, benchmark: 2885 },
      { threshold: 9000, benchmark: 2972 },
      { threshold: 10000, benchmark: 3048 },
      { threshold: 12500, benchmark: 3155 },
      { threshold: 15000, benchmark: 3291 },
      { threshold: 20000, benchmark: 3445 },
      { threshold: 25000, benchmark: 3768 },
    ],
    3: [
      { threshold: -1, benchmark: 1420 },
      { threshold: 2000, benchmark: 1866 },
      { threshold: 3000, benchmark: 2205 },
      { threshold: 4000, benchmark: 2437 },
      { threshold: 5000, benchmark: 2610 },
      { threshold: 6000, benchmark: 2747 },
      { threshold: 7000, benchmark: 2864 },
      { threshold: 8000, benchmark: 2961 },
      { threshold: 9000, benchmark: 3048 },
      { threshold: 10000, benchmark: 3125 },
      { threshold: 12500, benchmark: 3232 },
      { threshold: 15000, benchmark: 3367 },
      { threshold: 20000, benchmark: 3521 },
      { threshold: 25000, benchmark: 3845 },
    ],
    4: [
      { threshold: -1, benchmark: 1464 },
      { threshold: 2000, benchmark: 1931 },
      { threshold: 3000, benchmark: 2271 },
      { threshold: 4000, benchmark: 2503 },
      { threshold: 5000, benchmark: 2676 },
      { threshold: 6000, benchmark: 2813 },
      { threshold: 7000, benchmark: 2929 },
      { threshold: 8000, benchmark: 3027 },
      { threshold: 9000, benchmark: 3114 },
      { threshold: 10000, benchmark: 3190 },
      { threshold: 12500, benchmark: 3298 },
      { threshold: 15000, benchmark: 3433 },
      { threshold: 20000, benchmark: 3587 },
      { threshold: 25000, benchmark: 3911 },
    ],
    5: [
      { threshold: -1, benchmark: 1508 },
      { threshold: 2000, benchmark: 1996 },
      { threshold: 3000, benchmark: 2347 },
      { threshold: 4000, benchmark: 2579 },
      { threshold: 5000, benchmark: 2752 },
      { threshold: 6000, benchmark: 2889 },
      { threshold: 7000, benchmark: 3005 },
      { threshold: 8000, benchmark: 3103 },
      { threshold: 9000, benchmark: 3190 },
      { threshold: 10000, benchmark: 3266 },
      { threshold: 12500, benchmark: 3373 },
      { threshold: 15000, benchmark: 3509 },
      { threshold: 20000, benchmark: 3663 },
      { threshold: 25000, benchmark: 3986 },
    ]
  },
  'cohabits': {
    0: [
      { threshold: -1, benchmark: 0 },
    ],
    1: [
      { threshold: -1, benchmark: 0 },
    ],
    2: [
      { threshold: -1, benchmark: 0 },
    ],
    3: [
      { threshold: -1, benchmark: 0 },
    ],
    4: [
      { threshold: -1, benchmark: 0 },
    ],
    5: [
      { threshold: -1, benchmark: 0 },
    ]
  }
}

export const benchmarkLivingExpenditure = (householdStructure, totalIncome) => {
  if (!householdStructure.details.numberOfAdults || !householdStructure.details.numberOfDependants) {
    return 0
  }

  const relevantBenchmarks = benchmarks
    [householdStructure.details.numberOfAdults]
    [householdStructure.details.numberOfDependants]

  return relevantBenchmarks
    .sort((a, b) => b.threshold - a.threshold)
    .find(benchmark => totalIncome > benchmark.threshold)
    .benchmark
}
