import React, { useEffect, useRef, useState } from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import { selectProfile } from '@vega/redux.profile'
import { s, styled } from '@vega/styled/v2'
import { useOnClickOutside } from '@vega/components'
import { ReactComponent as Bell } from '@vega/components/src/assets/images/bell.svg'
import { listNotifications, selectAllNotifications } from 'modules/notification'
import { NotificationsButton } from './NotificationsButton'
import { NotificationItem } from './NotificationItem'

const BellIcon = styled(Bell)(s('mb-5 text-grey-700 w-4'))
const ContentContainer = styled.div(
  s('bg-white rounded-b-lg w-full', {
    height: 392,
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  })
)
const EmptyContentContainer = styled.div(
  s(
    'bg-white flex flex-column items-center justify-center p-5 rounded-b-lg text-center w-full',
    { height: 392 }
  )
)
const Header = styled.div(
  s('bg-secondary flex items-center px-5 py-6 rounded-t-lg', { maxHeight: 72 })
)
const NewNotificationTag = styled.div(
  s('bg-accent-pink ml-3 px-3 rounded-md text-grey-900', {
    paddingBottom: 6,
    paddingTop: 6,
  })
)
const PopUpContainer = styled.div(
  s('absolute pin-t-10 rounded-lg z-2', {
    boxShadow:
      '0px 4px 4px 0px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
    width: 420,
  })
)
const Root = styled.div(s('flex flex-column items-end relative'))
const Text = styled.p(
  s('font-normal m-0 text-base text-grey-900', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Title = styled.h3(
  s('m-0 text-primary text-xl', { letterSpacing: '-0.01em', lineHeight: 1.2 })
)

export const Notifications = ({ ...props }) => {
  const dispatch = useDispatch()
  const notificationsRef = useRef()
  const [isExpanded, setIsExpanded] = useState(false)

  const notifications = useSelector(selectAllNotifications)
  const { id: profileId } = useSelector(selectProfile)

  const unseenNotifications = notifications.filter(({ accessRecords }) => {
    const desiredAccessRecord = accessRecords.find(
      (record) => record.profileId === profileId
    )

    if (isNilOrEmpty(desiredAccessRecord) || !desiredAccessRecord?.seen) return true

    return false
  })

  const unseenNotificationsLength = unseenNotifications.length

  useOnClickOutside(notificationsRef, () => setIsExpanded(false), [
    document.getElementById('notifications-button'),
  ])

  useEffect(() => {
    dispatch(listNotifications())
  }, [dispatch])

  return (
    <Root>
      <NotificationsButton
        id="notifications-button"
        numberOfNotifications={unseenNotificationsLength}
        onClick={() => setIsExpanded((previousState) => !previousState)}
      />

      {isExpanded && (
        <PopUpContainer ref={notificationsRef} {...props}>
          <Header>
            <Title>Notifications</Title>

            {unseenNotificationsLength > 0 && (
              <NewNotificationTag>
                <Text style={s('text-sm')}>{unseenNotificationsLength} new</Text>
              </NewNotificationTag>
            )}
          </Header>

          {unseenNotificationsLength > 0 ? (
            <ContentContainer>
              {unseenNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  profileId={profileId}
                />
              ))}
            </ContentContainer>
          ) : (
            <EmptyContentContainer>
              <BellIcon />

              <Text style={s('font-semibold mb-1')}>No notifications</Text>

              <Text>
                New notifications will be added here when they become available
              </Text>
            </EmptyContentContainer>
          )}
        </PopUpContainer>
      )}
    </Root>
  )
}
