import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Col, Row } from '@vega/styled'
import { buildAddress, formatCurrency, formatStringToNumber } from 'utils/formatters'
import { VersionedValue } from 'features/common'
import { calculateMonthlyAmount } from './utils'

const InfoSegment = styled.div(s('flex flex-column my-4'), {
  borderBottom: '1px solid #E4E6E6',
})

const formatFIName = (financialInstitution, otherFIName) =>
  financialInstitution === 'Other' ? otherFIName : financialInstitution

const formatLoanClearing = (clearingFromThisLoan, clearingFromThisLoanAmount) =>
  clearingFromThisLoan[clearingFromThisLoan.length - 1] === 'Yes'
    ? clearingFromThisLoanAmount
    : ['No']

export const LiabilitiesTable = ({ liabilities = [], loanSecurities = [] }) => {
  const liabilityRows = liabilities
    .sort((a, b) => {
      const sortValue = (liability) => {
        switch (liability.domainType) {
          case 'Mortgage Loan':
            return 2
          case 'Credit Card':
            return 1
          default:
            return 0
        }
      }

      return sortValue(b) - sortValue(a)
    })
    .map((liability) => {
      const {
        id,
        domainType,
        clearingFromThisLoan,
        clearingFromThisLoanAmount,
        annualInterestRate,
        outstandingBalance,
        creditLimit,
        repayments,
        accountNumber,
        percentOwned,
      } = liability

      const ownershipLabel = () => {
        const { owners } = percentOwned

        const contributingOwners = owners
          .filter((owner) => owner.percent > 0)
          .map((owner) => {
            return {
              name: owner.legalEntity.firstName || owner.legalEntity.businessName,
              percent: owner.percent,
            }
          })

        if (contributingOwners.length === 1) {
          return `${contributingOwners[0].name} ${contributingOwners[0].percent}%`
        }

        return contributingOwners.map((owner) => `${owner.percent}%`).join(' - ')
      }

      const formattedFIName = formatFIName(
        accountNumber.financialInstitution,
        accountNumber.otherFIName
      )

      const formattedLoanClearing = formatLoanClearing(
        clearingFromThisLoan,
        clearingFromThisLoanAmount
      )

      const matchingSecurity = loanSecurities.find((security) => {
        return liability.securities
          .map((security) => security.xSecurity)
          .includes(security.externalRef)
      })

      const assetLabel = !matchingSecurity
        ? 'Not Specified'
        : buildAddress(
            {
              streetName: matchingSecurity.address?.standard?.streetName,
              streetNumber: matchingSecurity.address?.standard?.streetNumber,
              streetType: matchingSecurity.address?.standard?.streetType,
            },
            matchingSecurity?.address?.suburb,
            matchingSecurity?.address?.city,
            matchingSecurity?.address?.countryISO,
            matchingSecurity?.address?.standard?.unit,
            matchingSecurity?.address?.newZealandPostCode
          )

      const repaymentLabel = () => {
        const amounts =
          repayments[0]?.repaymentAmount?.map((repayment) => {
            const amount = calculateMonthlyAmount(
              repayment,
              repayments[0].repaymentFrequency
            )
            return `$${amount} Monthly`
          }) || []
        return amounts || []
      }

      const LiabilityTitle = styled.div({
        fontWeight: 500,
        flexBasis: '160px',
        flexShrink: 0,
      })

      const liabilityText = s(
        'p-0 inline-block text-grey-800 text-sm text-left bg-white'
      )

      const RightAlignedTitle = styled.div({
        fontWeight: 500,
        flexBasis: '160px',
        flexShrink: 0,
      })
      const RightAlignedValue = styled.div({
        width: '-webkit-fill-available',
        textAlign: 'right',
      })

      return (
        <div key={id}>
          <Row>
            <Col span={8}>
              <div style={s('flex')}>
                <LiabilityTitle>Type</LiabilityTitle>
                <span style={liabilityText}>{domainType}</span>
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Lender</LiabilityTitle>
                <span style={liabilityText}>{formattedFIName}</span>
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Asset</LiabilityTitle>
                <span style={liabilityText}>{assetLabel}</span>
              </div>
            </Col>

            <Col span={8}>
              <div style={s('flex')}>
                <LiabilityTitle>Rate</LiabilityTitle>
                <VersionedValue
                  values={annualInterestRate}
                  valueStyles={liabilityText}
                />
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Clearing?</LiabilityTitle>
                <span>
                  <VersionedValue
                    values={formattedLoanClearing}
                    valueStyles={liabilityText}
                  />
                </span>
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Ownership</LiabilityTitle>
                <span style={liabilityText}>{ownershipLabel()}</span>
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Documented Loan Term</LiabilityTitle>
                <span style={liabilityText}>-</span>
              </div>

              <div style={s('flex')}>
                <LiabilityTitle>Revolving?</LiabilityTitle>
                <span style={liabilityText}>-</span>
              </div>
            </Col>

            <Col span={8}>
              <div style={s('flex')}>
                <RightAlignedTitle>Repayment</RightAlignedTitle>
                <RightAlignedValue>
                  <VersionedValue
                    values={repaymentLabel()}
                    valueStyles={liabilityText}
                  />
                </RightAlignedValue>
              </div>

              <div style={s('flex')}>
                <RightAlignedTitle>Balance</RightAlignedTitle>
                <RightAlignedValue>
                  <VersionedValue
                    values={outstandingBalance}
                    valueStyles={liabilityText}
                  />
                </RightAlignedValue>
              </div>

              <div style={s('flex')}>
                <RightAlignedTitle>Limit</RightAlignedTitle>

                <RightAlignedValue>
                  <VersionedValue values={creditLimit} valueStyles={liabilityText} />
                </RightAlignedValue>
              </div>
            </Col>
          </Row>
          <InfoSegment />
        </div>
      )
    })

  const totalBalance = liabilities.reduce((total, liability) => {
    const lastSubmission = liability?.outstandingBalance?.length - 1
    const amount = liability?.outstandingBalance?.[lastSubmission] ?? '0'
    const newAmount = formatStringToNumber(amount)
    return total + newAmount
  }, 0)

  const totalLimt = liabilities.reduce((total, liability) => {
    const lastSubmission = liability.creditLimit?.length - 1
    const amount = liability.creditLimit?.[lastSubmission] ?? '0'
    const newAmount = formatStringToNumber(amount)
    return total + newAmount
  }, 0)

  const monthyRepayment = liabilities.reduce((total, liability) => {
    const lastSubmission = liability.repayments[0].repaymentAmount?.length - 1
    const amount = calculateMonthlyAmount(
      liability.repayments[0].repaymentAmount?.[lastSubmission] ?? '0',
      liability.repayments[0].repaymentFrequency
    )
    return total + amount
  }, 0)

  if (liabilityRows.length === 0) {
    return (
      <>
        <h3>Liabilities</h3>

        <p style={s('text-grey-600 text-sm font-semibold')}>No data found</p>
      </>
    )
  }

  const ownershipDescription = () => {
    return [
      ...new Set(
        liabilities
          .map((asset) => asset.percentOwned.owners)
          .flat()
          .filter((owner) => owner.percent > 0)
          .map((owner) => {
            return owner.legalEntity.businessName
              ? owner.legalEntity.businessName
              : `${owner.legalEntity.firstName} ${owner.legalEntity.lastName}`
          })
      ),
    ].join(' - ')
  }

  return (
    <>
      <h3 style={{ color: '#490B2D' }}>Liabilities</h3>

      {liabilityRows}

      <div style={s('text-right')}>
        <h5 style={{ color: '#490B2D' }}>
          Total balance
          <span style={s('inline-block w-8')}>{formatCurrency(totalBalance)}</span>
        </h5>

        <h5 style={{ color: '#490B2D' }}>
          Total limit
          <span style={s('inline-block w-8')}>{formatCurrency(totalLimt)}</span>
        </h5>

        <h5 style={{ color: '#490B2D' }}>
          Monthly repayment
          <span style={s('inline-block w-8')}>{formatCurrency(monthyRepayment)}</span>
        </h5>
      </div>

      <span style={s('text-sm text-grey')}>*Ownership = {ownershipDescription()}</span>
    </>
  )
}
