import React from 'react'
import { CircumstanceChange } from './CircumstanceChange'

// TODO: can this be moved up to householdFinancialPosition now?
export const CircumstanceChanges = ({ circumstances = [] }) => {
  const individualsCircumstances = circumstances.map((individual = {}, index) => {
    const circumstance = {
      name: individual.personName.firstName
    };

    return (
      <CircumstanceChange
        key={index}
        circumstance={circumstance}
      />
    );
  });

  return (
    <>
      {individualsCircumstances}
    </>
  )
}
