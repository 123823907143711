/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { useHistory, useParams } from 'react-router-dom'
import { SectionTitle } from '../info/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import { fetchGoogleDoc, selectGoogleDocById } from 'modules/google'
import { selectAllLoanSplits } from 'modules/loanSplit'
import { fetchProducts, selectAllProducts } from 'modules/product'
import { NoteSection } from 'features/common/NoteSection'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { ConditionalOfferForm } from './ConditionalOfferForm'
import { Button, Loading } from '@vega/components'
import { GoogleDocs } from './GoogleDocs'
import { GOOGLE_CONSTANTS } from '@neo/constants'

const {
  TEMPLATE: { OFFER },
} = GOOGLE_CONSTANTS

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

export const Offer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const versionedApplication = useSelector(selectApplicationById(id))
  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const allocatedLoanStructures = useSelector(selectAllLoanSplits)
  const products = useSelector(selectAllProducts)
  const googleDoc = useSelector((state) => selectGoogleDocById(state, id))
  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)

  const payload = { templateName: OFFER }

  useEffect(() => {
    dispatch(fetchGoogleDoc({ id, payload }))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  if (isFetchingApplication) return <Loading />

  return (
    <div style={s('px-24')}>
      <div style={s('flex flex-row')}>
        <BackButton
          variant="text"
          style={s('mb-8')}
          onClick={() => history.push(`/loan-applications/${id}/structure`)}
        >
          <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
        </BackButton>
      </div>

      <Row>
        <Col span={24}>
          <h1 style={s('font-semibold mb-7')} className="sohne">
            Offer
          </h1>
        </Col>

        <Col span={24}>
          <SectionTitle title="Conditional Offer" />
        </Col>

        <Col span={24}>
          <ConditionalOfferForm
            loanApplication={latestApplicationVersion}
            loanSplits={allocatedLoanStructures}
            products={products}
          />
        </Col>

        <Col span={24}>
          <NoteSection
            id="note-section"
            versionedLoanApplicationId={id}
            loanApplicationId={latestVersionId}
            source="loan-applications/offer/conditional"
          />
        </Col>

        <Col span={24}>
          <SectionTitle title="Offer Letter" style={s('mt-12')} />
        </Col>

        <Col span={24}>
          <GoogleDocs googleDoc={googleDoc} />
        </Col>

        <Col span={24}>
          <SectionTitle title="Unconditional Offer" style={s('mt-12')} />
        </Col>

        <Col span={24}>
          <NoteSection
            id="note-section"
            versionedLoanApplicationId={id}
            loanApplicationId={latestVersionId}
            source="loan-applications/offer/unconditional"
          />
        </Col>
      </Row>
    </div>
  )
}
