import BaseService from '../baseService'
import { QueryBuilder } from '../utils'

export class LendingEntityService extends BaseService {
  listLegalEntities(loanApplicationId) {
    const listParams = QueryBuilder()
      .setParam('loanApplicationId', loanApplicationId)
      .build()

    return this.client.get(`legal-entities?${listParams}`).json()
  }
}
