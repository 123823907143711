import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { Tooltip } from '@mui/material'

const Label = styled.p(
  s('font-normal m-0 text-grey-700 text-sm', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Value = styled.p(
  s('font-semibold m-0 text-base text-grey-800', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

/**
 * @typedef {Object} Props
 * @property {string} label
 * @property {string | number}[] value
 */

/** @param {Props} props */
const VersionedDataDisplay = ({
  label,
  values = [],
  labelStyles = {},
  valueStyles = {},
  placement = 'bottom-start',
  testId,
  ...props
}) => {
  const singleVersionStyles = labelStyles
  const multipleVersionStyles = s('bg-yellow-light')
  const previousValue = values.length > 1 ? values[values.length - 2] : '-'
  const displayValue = values.length === 0 ? '-' : values[values.length - 1]

  if (values.length === 1 || displayValue === previousValue) {
    return (
      <div {...props}>
        <Label style={labelStyles}>{label}</Label>
        <Value style={valueStyles}>
          <span style={singleVersionStyles}>{displayValue}</span>
        </Value>
      </div>
    )
  }

  return (
    <div {...props}>
      <Label style={labelStyles}>{label}</Label>
      <Tooltip title={`Previously ${previousValue}`} placement={placement}>
        <Value data-test-id={testId} style={valueStyles}>
          <span style={multipleVersionStyles}>{displayValue}</span>
        </Value>
      </Tooltip>
    </div>
  )
}

export { VersionedDataDisplay }
