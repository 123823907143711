import React from 'react'
import { ContentCard, DataDisplay } from 'features/loanAssessment/info/components'
import { Row, Col } from '@vega/styled'
import { s } from '@vega/styled/v2'
import { formatCurrency, formatDateString } from 'utils/formatters'
import { isNilOrEmpty } from '@solta/ramda-extra'

const LastKNownSale = ({ lastKNownSale }) => {
  const { lastKnownSaleDate, lastKnownSalePrice, lastKnownSaleMethod } = lastKNownSale

  return (
    <ContentCard>
      <Row>
        <Col span={8}>
          <DataDisplay
            label="Sale Date"
            value={formatDateString(lastKnownSaleDate)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Sale Price"
            value={formatCurrency(lastKnownSalePrice)}
            valueStyles={s('mb-8')}
          />
        </Col>
        <Col span={8}>
          <DataDisplay
            label="Sale Method"
            value={isNilOrEmpty(lastKnownSaleMethod) ? lastKnownSaleMethod : '-'}
            valueStyles={s('mb-8')}
          />
        </Col>
      </Row>
    </ContentCard>
  )
}

export { LastKNownSale }
