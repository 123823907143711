import React from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { ContentCard } from 'features/loanAssessment/info/components'
import { AdverseChange } from './AdverseChange'

const Subtitle = styled.p(
  s('font-semi-bold my-3 text-black text-lg', {
    letterSpacing: '0.01em',
    lineHeight: '21px',
    fontSize: '14px'
  })
)

const SubtitleValue = styled.span(s('font-semibold text-primary', { float: 'right', marginRight: '-8px' }))

const Title = styled.h3(
  s('mb-0 text-grey-900 text-xl', { letterSpacing: '-0.01em', fontSize: '20px', lineHeight: '24px', marginTop: '-4px', marginBottom: '21px' })
)

const HighlightedSubTitle = styled.p(
  s('font-semi-bold my-3 text-black text-lg', {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '20px',
    fontSize: '14px',
    background: '#F7F7F2',
    borderRadius: '8px',
    /* Inside auto layout */
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',
  })
)

export const CircumstanceChange = ({ circumstance ={} }) => {
  const {
    name,
    anticipatedChanges,
    formattedanticipatedChanges,
    anticipatedRetairingAge,
    repaymentProposal,
    changes,
  } = circumstance;

  const hasMyCrmData = false;

  if (!hasMyCrmData) {
    return (
      <ContentCard style={s('mb-4')}>
        <Title >
          {`${name} - Financial Circumstance Changes`}
        </Title>

        <span style={s('text-sm font-semibold text-grey-600')}>
          Data not yet in myCRM payload
        </span>
      </ContentCard>
    )
  }
  return (
    <ContentCard style={s('mb-4')}>
      <Title >
        {name} - Financial Circumstance Changes
      </Title>

      <Subtitle>
        Does the applicant plan or anticipate Change(s) (other than retirement)
        to their future financial circumstances that could adveresly impact
        their capability to repay the loan?

        <SubtitleValue>
          {formattedanticipatedChanges}
        </SubtitleValue>
      </Subtitle>

      <Subtitle>
        Does the applicant plan to retire during the term of the loan?{''}

        <SubtitleValue>{formattedanticipatedChanges}</SubtitleValue>
      </Subtitle>

      <HighlightedSubTitle>
        At what age does {name} plan to retire?

        <SubtitleValue>
          {anticipatedRetairingAge}
        </SubtitleValue>
      </HighlightedSubTitle>

      <HighlightedSubTitle >
        How would the applicant propose to repay the loan?

        <SubtitleValue>
          {repaymentProposal}
        </SubtitleValue>
      </HighlightedSubTitle>

      <Row gutter={[12, 16]}>
        {anticipatedChanges && changes.map((change, index) => (
          <Col key={index} span={8}>
            <AdverseChange change={change} />
          </Col>
        ))}
      </Row>
    </ContentCard>
  );
};
