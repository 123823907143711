import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { Business } from './Business'
import {
  SectionTitle as SectionTitleBase,
  ContentCard as ContentCardBase,
} from '../components'

const { BUSINESSES } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const ContentCard = styled(ContentCardBase)(s('py-6 px-5'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const Text = styled.p(s('my-0'))

const Businesses = ({ businesses = [], ...props }) => {
  return (
    <Container id={BUSINESSES} {...props}>
      <SectionTitle title="Business(es)" />

      <ContentCard>
        {isNilOrEmpty(businesses) ? (
          <Text>No business information to display</Text>
        ) : (
          businesses?.map((business, index, { length }) => {
            const lastBusiness = index === length - 1
            return (
              <Business
                key={index}
                business={business}
                index={index}
                style={s(lastBusiness ? '' : 'mb-6')}
              />
            )
          })
        )}
      </ContentCard>
    </Container>
  )
}

export { Businesses }
