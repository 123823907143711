import BaseService from '../baseService'

export class UmiReportItemService extends BaseService {
  fetchUmiReportItems(versionedLoanApplicationId) {
    if (!versionedLoanApplicationId)
      throw new Error('Please provide a valid versioned loan application id')

    return this.client
      .get(`umi-reports/${versionedLoanApplicationId}`)
      .json()
  }

  createUmiReportItem(umiReportItem) {
    if (!umiReportItem)
      throw new Error('Please provide a report item to create')

    return this.client.post('umi-reports', { json: umiReportItem }).json()
  }

  updateUmiReportItem(versionedLoanApplicationId, reportItem) {
    if (!versionedLoanApplicationId)
      throw new Error('Please provide a valid versioned loan application id')

    if (!reportItem)
      throw new Error('Please provide a report item to create')

    return this.client.put(
      `umi-reports/${versionedLoanApplicationId}`,
      { json: reportItem }
    ).json()
  }

  deleteUmiReportItem(reportItemId) {
    if (!reportItemId)
      throw new Error('Please provide a valid versioned loan application id')

    return this.client.delete(`umi-reports/${reportItemId}`).json()
  }

  refreshUmiReportItems(versionedLoanApplicationId) {
    if (!versionedLoanApplicationId)
      throw new Error('Please provide a valid versioned loan application id')

    return this.client.post(`umi-reports/refresh/${versionedLoanApplicationId}`).json()
  }
}
