import React, { useEffect } from 'react'
import { isNilOrEmpty } from '@solta/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { fetchProducts } from 'modules/product'
import { LoanSummaryChart } from './LoanSummaryChart'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import { fetchLoanSplits, selectAllLoanSplits } from 'modules/loanSplit'
import { Loading, Button } from '@vega/components'
import { LoanBuilder } from './loanBuilder'
import { SectionTitle, SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { selectAllSecurities } from 'modules/security'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

export const Structure = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const versionedApplication = useSelector(selectApplicationById(id))
  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const allocatedLoanStructures = useSelector(selectAllLoanSplits)
  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)
  const losSecurities = useSelector(selectAllSecurities) || []

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
    dispatch(fetchLoanSplits(id))
  }, [dispatch, id])

  if (isFetchingApplication) return <Loading />

  const { totalLoanAmount, customSecurityValuations = [] } = latestApplicationVersion

  const securities =
    losSecurities
      .filter((security) => security.status === 'Active')
      .map((security) => {
        const customSecurityValuation = customSecurityValuations?.find(
          (item) => item.financialId === security.id
        )
        const customEstimatedValue = customSecurityValuation?.value
        const ivalEstimatedValue = security.linkedDetails?.valuation?.estimatedValue
        const myCrmEstimatedValue = security.estimatedValue?.value

        return {
          ...security,
          finalAssetValue:
            customEstimatedValue ||
            ivalEstimatedValue ||
            myCrmEstimatedValue ||
            undefined,
        }
      }) || []

  const totalAllocatedLoanAmount = allocatedLoanStructures.reduce(
    (total, { loanAmount }) => total + loanAmount,
    0
  )

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/security`)}
      >
        <ChevronLeft /> <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      <Row style={s('px-24')}>
        <Col span={24}>
          <h1 style={s('font-semibold mb-10')} className="sohne">
            Loan Structure
          </h1>
        </Col>

        <SectionTitle title="Loan Summary" style={s('ml-2 mb-3')} />
        <LoanSummaryChart
          allocatedLoanStructures={allocatedLoanStructures}
          totalRequestedLoanAmount={totalLoanAmount}
          totalAllocatedLoanAmount={totalAllocatedLoanAmount}
        />

        <LoanBuilder
          loanApplication={latestApplicationVersion}
          allocatedLoanStructures={allocatedLoanStructures}
          totalRequestedLoanAmount={totalLoanAmount}
          totalAllocatedLoanAmount={totalAllocatedLoanAmount}
          isLoanBuilderReady={isLoanBuilderReady(securities)}
          securities={securities}
        />
      </Row>
    </div>
  )
}

function isLoanBuilderReady(securities = []) {
  return allSecuritiesAreLinked()

  function allSecuritiesAreLinked() {
    return (
      securities.filter((security) => isNilOrEmpty(security?.linkedDetails)).length ===
      0
    )
  }
}
