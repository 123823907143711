// TODO: check if the function using this is in the right place
import { benchmarkLivingExpenditure } from '../householdStructure/utils'

const ACC_LEVY_RATE = 0.0153
const ACC_LEVY_MAX = 2132.57

export const netToGrossTaxNz = (netIncome) => {
  const taxNets = [0, 12530, 40580, 55980, 129680, 999999999]
  const taxRates = [0.105, 0.175, 0.3, 0.33, 0.39]

  return taxNets.reduce((totalTax, currentBracket, index) => {
    const taxableAmountLeft = Math.max(
      netIncome - currentBracket,
      0 // Nothing left to tax
    )

    const maxAmountToTaxAtCurrentBracket =
      index === taxNets.length - 1
        ? currentBracket // If last bracket can go on indefinitely
        : taxNets[index + 1] - currentBracket // Amount upto the next bracket

    const currentTaxRate =
      index > taxRates.length - 1 ? taxRates[taxRates.length - 1] : taxRates[index]

    const taxToAdd =
      Math.min(taxableAmountLeft, maxAmountToTaxAtCurrentBracket) / (1 - currentTaxRate)

    return totalTax + taxToAdd
  }, 0)
}

export const progressiveTaxNz = (grossIncome, customTaxRates) => {
  const taxMins = [0, 14000, 48000, 70000, 180000, 999999999]
  const taxRates = customTaxRates || [0.105, 0.175, 0.3, 0.33, 0.39]

  return taxMins.reduce((totalTax, currentBracket, index) => {
    const taxableAmountLeft = Math.max(
      grossIncome - currentBracket,
      0 // Nothing left to tax
    )

    const maxAmountToTaxAtCurrentBracket =
      index === taxMins.length - 1
        ? currentBracket // If last bracket can go on indefinitely
        : taxMins[index + 1] - currentBracket // Amount upto the next bracket

    const currentTaxRate =
      index > taxRates.length - 1 ? taxRates[taxRates.length - 1] : taxRates[index]

    const taxToAdd =
      Math.min(taxableAmountLeft, maxAmountToTaxAtCurrentBracket) * currentTaxRate

    return totalTax + taxToAdd
  }, 0)
}

export const grossToNetNZ = (grossIncome, kiwiSaver = 0, inclAcc = true) => {
  const netIncome =
    grossIncome - progressiveTaxNz(grossIncome) - grossIncome * kiwiSaver

  return inclAcc ? netIncome - Math.min(grossIncome * ACC_LEVY_RATE, ACC_LEVY_MAX) : netIncome
}

const netToGrossBisection = (netIncome, kiwiSaverRate = 0) => {
  let xL = 0
  let xU = 20000000
  let i = 0
  let xN
  const tol = 0.01
  const maxIter = 100

  while (i < maxIter) {
    xN = (xL + xU) / 2

    if (Math.abs(grossToNetNZ(xN, kiwiSaverRate) - netIncome) < tol) {
      break
    }

    if (grossToNetNZ(xN, kiwiSaverRate) - netIncome > 0) {
      xU = xN
    } else {
      xL = xN
    }

    i += 1
  }

  return xN
}

export const incMonthly = (incAmount, incFreq, incTax, incType, kiwiSaverRate) => {
  const kiwiSaver = Number.parseFloat(kiwiSaverRate, 10) || 0

  const frequencies = {
    weekly: 52,
    fortnightly: 26,
    monthly: 12,
    quarterly: 4,
    halfYearly: 2,
    annually: 1,
  }

  const yearlyAmount = frequencies[incFreq] * incAmount

  if (incTax === 'net') {
    return incType === 'salary'
      ? netToGrossBisection(incAmount, kiwiSaver / 100)
      : netToGrossTaxNz(yearlyAmount) / 12
  }

  return yearlyAmount / 12
}

export const haircuts = {
  salary: 1,
  businessIncome: 1,
  rentals: 0.8,
  overtime: 0.85,
  beneficiaries: 1,
  nonNzIncome: 0.8,
  commission: 0.8,
  interest: 1,
  dividends: 1,
  pension: 1,
  boarders: 0.8,
  childSupport: 0.8,
}

export const householdEssentialBenchmarkAmount = (
  householdStructures = [],
  totalIncome
) => {
  return householdStructures.reduce(
    (total, structure) => total + benchmarkLivingExpenditure(structure, totalIncome),
    0
  )
}

export const ebit = (
  netProfitBeforeTax,
  interestPaid,
  shareholderSalaries,
  homeOffice,
  depreciation
) => {
  return netProfitBeforeTax
    + interestPaid
    + shareholderSalaries
    + homeOffice
    + depreciation
}

export const businessIncomeEbit = (income) => {
  const yearOneEbit = income.details.yearOneNetProfitBeforeTax
    + income.details.yearOneInterestPaid
    + income.details.yearOneShareholderSalaries
    + income.details.yearOneHomeOffice
    + income.details.yearOneDepreciation

  const yearTwoEbit = income.details.yearTwoNetProfitBeforeTax
    + income.details.yearTwoInterestPaid
    + income.details.yearTwoShareholderSalaries
    + income.details.yearTwoHomeOffice
    + income.details.yearTwoDepreciation

  if (yearOneEbit > 0 && yearTwoEbit > 0) {
    return (yearOneEbit + yearTwoEbit) / 2
  }

  return 0
}

export const attributablyBusinessIncome = (income) => {
  return businessIncomeEbit(income) * (income.details.ownershipPercentage / 100)
}

export const sensitisedMonthlyNetIncome = (incomes) => {
  let personalIncome = 0
  let businessIncome = 0
  let taxableIncome = 0
  let levy = 0
  let kiwiSaverAmount = 0

  const businessIncomes = incomes
    .filter((income) => income.details.umiIncomeType === 'business')

  businessIncomes.forEach((income) => {
    businessIncome += attributablyBusinessIncome(income) * haircuts.businessIncome
  })

  const nonBusinessIncomes = incomes
    .filter((income) => income.details.umiIncomeType !== 'business')

  // eslint-disable-next-line complexity
  nonBusinessIncomes.forEach((income) => {
    const amount = incMonthly(
      income.details.verifiedAmount,
      income.details.frequency,
      income.details.taxType,
      income.details.source,
      income.details.kiwiSaverRate
    )

    if (income.details.source === 'businessIncome') {
      businessIncome += amount * haircuts.businessIncome
    }

    if (income.details.source === 'salary') {
      personalIncome += amount * haircuts.salary
      taxableIncome += amount * haircuts.salary
      levy += amount * 12 * ACC_LEVY_RATE
      kiwiSaverAmount += amount * haircuts.salary * (income.kiwiSaverRate || 0)

    } else {
      personalIncome += amount * (haircuts[income.details.source] || 0)
      taxableIncome += amount * (haircuts[income.details.source] || 0)
    }
  })

  const tax = Math.max(progressiveTaxNz(taxableIncome * 12) / 12, 0)

  const taxedPersonalIncome = personalIncome - tax

  const taxedBusinessIncome = Math.max(businessIncome * (1 - 0.28), 0)

  const minimumLevyAmount = Math.min(levy, ACC_LEVY_MAX) / 12

  return taxedPersonalIncome + taxedBusinessIncome - kiwiSaverAmount - minimumLevyAmount
}

export const monthlyNetIncome = (incomes) => {
  let personalIncome = 0
  let businessIncome = 0
  let taxableIncome = 0
  let levy = 0
  let kiwiSaverAmount = 0

  /* eslint-disable */
  incomes.forEach((income) => {
    const amount = incMonthly(
      income.details.declaredAmount,
      income.details.frequency,
      income.details.taxType,
      income.details.source,
      income.details.kiwiSaverRate
    )

    if (income.details.source === 'businessIncome') {
      businessIncome += amount
    } else if (income.details.source === 'salary') {
      personalIncome += amount
      taxableIncome += amount
      levy += amount * 12 * ACC_LEVY_RATE
      kiwiSaverAmount += amount * (income.details.kiwiSaverRate || 0)
    } else if (income.details.umiIncomeType === 'business') {
      businessIncome += attributablyBusinessIncome(income)
    } else {
      personalIncome += amount
      taxableIncome += amount
    }
  })

  const tax = Math.max(progressiveTaxNz(taxableIncome * 12) / 12, 0)

  const taxedPersonalIncome = personalIncome - tax

  const taxedBusinessIncome = Math.max(businessIncome * (1 - 0.28), 0)

  const minimumLevyAmount = Math.min(levy, ACC_LEVY_MAX) / 12

  return taxedPersonalIncome + taxedBusinessIncome - kiwiSaverAmount - minimumLevyAmount
}

export const monthlyGrossIncome = (incomes) => {
  return incomes.reduce((total, income) => {
    switch (income.details.umiIncomeType) {
      case 'primary':
        return (
          total +
          incMonthly(
            income.details.verifiedAmount,
            income.details.frequency,
            income.details.taxType,
            income.details.source,
            income.details.kiwiSaverRate
          )
        )
      case 'secondary':
        return (
          total +
          incMonthly(
            income.details.verifiedAmount,
            income.details.frequency,
            income.details.taxType,
            income.details.type,
            income.details.kiwiSaverRate
          )
        )
      case 'business':
        return (
          total +
          attributablyBusinessIncome(income) /
            incomes.filter((income) => income.details.umiIncomeType === 'business').length
        )
      default:
        return total
    }
  }, 0)
}
