export const revolvingCreditLiabilities = (liabilities) =>
  liabilities.filter(
    (liability) => liability.details.umiLiabilityType === 'revolvingCredit'
  )

export const studentLoanLeaseHirePurchaseLiabilities = (liabilities) =>
  liabilities.filter(
    (liability) => liability.details.umiLiabilityType === 'studentLoanLeaseHirePurchase'
  )

export const longTermLoanMortgageLiabilities = (liabilities) =>
  liabilities.filter(
    (liability) => liability.details.umiLiabilityType === 'longTermLoanMortgage'
  )

const pmtJS = (rate, nper, pv) => {
  return (rate / ((1 + rate) ** nper - 1)) * (-pv * (1 + rate) ** nper)
}

const monthlyPayments = (type, rate, amt, term, calcType) => {
  const interestOnlyDictionary = {
    principalAndInterest: 0,
    interestOnly1Year: 1,
    interestOnly2Year: 2,
    interestOnly3Year: 3,
    interestOnly4Year: 4,
    interestOnly5Year: 5,
    interestOnly6Year: 6,
    interestOnly7Year: 7,
  }

  const interestOnlyTerm = interestOnlyDictionary[type]

  if (calcType === 'effective') {
    const minimumTerm = Math.max(term - interestOnlyTerm, 1)
    return pmtJS(rate / 100 / 12, minimumTerm * 12, -amt)
  }

  if (interestOnlyTerm === 0) {
    return pmtJS(rate / 100 / 12, term * 12, -amt)
  }

  return (amt * (rate / 100)) / 12
}

export const costOfServicing = (liability) => {
  if (liability.details.umiLiabilityType === 'revolvingCredit') {
    const rate = liability.details.productType === 'overdraft' ? 0.025 : 0.03
    return rate * liability.details.outstandingAmount
  }

  if (liability.details.umiLiabilityType === 'studentLoanLeaseHirePurchase') {
    if (liability.details.repaymentAmount > 0) {
      return liability.details.repaymentAmount
    }
    return monthlyPayments(
      'principalAndInterest',
      liability.details.interestRate,
      liability.details.outstandingAmount,
      liability.details.remainingTermYears,
      'actual'
    )
  }

  return monthlyPayments(
    liability.details.repaymentType,
    liability.details.interestRate,
    liability.details.committedLimit,
    liability.details.remainingTermYears,
    'actual'
  )
}

export const sensitisedInterestRate = (liability) => {
  // Note: these values came from a propotype spreadsheet model and as of
  // adapting were all the same, it is currently unclear as to if they will
  // ever change so feel to remove this complexity if future if they are
  // still all the same

  const rateAdjustments = [
    {
      purpose: 'nonHousing',
      repaymentInterestOnly: true,
      adjustment: 2.5,
      minimumRate: 6,
    },
    {
      purpose: 'nonHousing',
      repaymentInterestOnly: false,
      adjustment: 2.5,
      minimumRate: 6,
    },
    {
      purpose: 'ownerOccupied',
      repaymentInterestOnly: true,
      adjustment: 2.5,
      minimumRate: 6,
    },
    {
      purpose: 'ownerOccupied',
      repaymentInterestOnly: false,
      adjustment: 2.5,
      minimumRate: 6,
    },
    {
      purpose: 'residentialInvestmentLending',
      repaymentInterestOnly: true,
      adjustment: 2.5,
      minimumRate: 6,
    },
    {
      purpose: 'residentialInvestmentLending',
      repaymentInterestOnly: false,
      adjustment: 2.5,
      minimumRate: 6,
    },
  ]

  const relevantAdjustment = rateAdjustments.find(
    (adjustment) =>
      liability.details.purpose === adjustment.purpose &&
      adjustment.repaymentInterestOnly ===
        (liability.details.repaymentType !== 'principalAndInterest')
  )

  if (!relevantAdjustment) {
    throw new Error(
      'Servicability:liability:sensitisedInterestRate - Could not find relevantAdjustment'
    )
  }

  return Math.max(
    liability.details.interestRate + relevantAdjustment.adjustment,
    relevantAdjustment.minimumRate
  )
}

export const effectiveCostOfServicing = (liability) => {
  if (liability.details.umiLiabilityType === 'revolvingCredit') {
    const exposure = Math.max(
      liability.details.committedLimit,
      liability.details.outstandingAmount
    )
    const rate = liability.details.productType === 'overdraft' ? 0.025 : 0.03
    return rate * exposure
  }

  if (liability.details.umiLiabilityType === 'studentLoanLeaseHirePurchase') {
    if (liability.details.repaymentAmount > 0) {
      return liability.details.repaymentAmount
    }
    return monthlyPayments(
      'principalAndInterest',
      liability.details.interestRate,
      liability.details.outstandingAmount,
      liability.details.remainingTermYears,
      'effective'
    )
  }

  return monthlyPayments(
    liability.details.repaymentType,
    sensitisedInterestRate(liability),
    liability.details.committedLimit,
    liability.details.remainingTermYears,
    'effective'
  )
}

export const totalCostOfServicing = (liabilities) => {
  return liabilities.reduce((total, liability) => total + costOfServicing(liability), 0)
}

export const totalEffectiveCostOfServicing = (liabilities) => {
  return liabilities.reduce(
    (total, liability) => total + effectiveCostOfServicing(liability),
    0
  )
}
