import BaseService from '../baseService'

import { isNil } from '@solta/ramda-extra'

export class NoteService extends BaseService {
  fetchNotes(versionedLoanApplicationId, loanApplicationId) {
    if (isNil(versionedLoanApplicationId) && isNil(loanApplicationId))
      throw new Error('Please provide either a valid versioned loan application id or valid loan application id')

    const filterFragments = []

    if (!isNil(versionedLoanApplicationId))
      filterFragments.push(`filter[versionedLoanApplicationId]=${versionedLoanApplicationId}`)

    if (!isNil(loanApplicationId))
      filterFragments.push(`filter[loanApplicationId]=${loanApplicationId}`)

    return this.client
      .get(`notes?${filterFragments.join('&')}`)
      .json()
  }

  updateNote(id, note) {
    const config = {
      json: note,
    }

    return this.client.put(`notes/${id}`, config).json()
  }

  deleteNote(id) {
    return this.client.delete(`notes/${id}`).json()
  }

  createNote({ versionedLoanApplicationId, loanApplicationId, source, content }) {
    const config = {
      json: {
        versionedLoanApplicationId,
        loanApplicationId,
        source,
        content
      },
    }

    return this.client.post(`notes`, config).json()
  }
}
