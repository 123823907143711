import { umiReportItemAdapter } from './module'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const { selectIsPending: selectIsFetchingUMIReport } = createAsyncThunkSelectors(
  'umiReportItems/fetchUmiReportItems'
)

export const {
  selectIsPending: selectIsRefreshingUMIReport,
  selectError: selectRefreshUMIReportError,
} = createAsyncThunkSelectors('umiReportItems/refreshUmiReportItems')

export const { selectAll: selectAllUmiReportItems } = umiReportItemAdapter.getSelectors(
  (state) => state.umiReportItems
)
