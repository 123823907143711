/* eslint-disable complexity */
import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { styled, s } from '@vega/styled'
import { Toaster, useActivityTracker } from '@vega/components'

import InactivityModal from '@vega/components/src/InactivityModal/InactivityModal'
import { routes } from './routes'
import { AppSwitcher } from 'features/common/AppSwitcher'
import { AccountSettings } from 'features/accountSettings'
import { LoanAppRouter } from 'navigation/LoanAppRouter'
import { PersistentNavigationDrawer } from 'navigation/PersistentNavigationDrawer'

const Root = styled.div(s('flex flex-column h-full bg-green-50'))
const AppContainer = styled.div(s('flex flex-row h-full p-0'))

const RootRouter = () => {
  const [userIsInactive, setUserIsInactive] = useState(false)

  useActivityTracker(() => {
    if (!userIsInactive) setUserIsInactive(true)
  })

  return (
    <Root>
      <AppSwitcher />

      <AppContainer>
        <PersistentNavigationDrawer />
        <Switch>
          <Route path={routes.loanApp.root} component={LoanAppRouter} />
          <Route path={routes.account} component={AccountSettings} />

          <Redirect to={routes.loanApp.root} />
        </Switch>
      </AppContainer>

      <Toaster />
      <InactivityModal isOpen={userIsInactive} setIsOpen={setUserIsInactive} />
    </Root>
  )
}

export default RootRouter
