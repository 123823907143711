import { nanoid } from 'nanoid'
import { propEq, mergeDeepRight } from '@solta/ramda-extra'
import { defaultRecommendedLoanStructure } from '../shared'

export class RecommendedStructuresMapper {
  constructor(recommendedLoans = [], allBorrowers, allGuarantors) {
    this.recommendedLoans = recommendedLoans
    this.allBorrowers = allBorrowers
    this.allGuarantors = allGuarantors
  }

  map() {
    return this.recommendedLoans.map((l) => this.mapRecommendedLoan(l))
  }

  mapRecommendedLoan(recommendedLoanStructure) {
    const { term, guarantors, borrowers, amountRequested } = recommendedLoanStructure

    const { totalTermDuration } = term || {}

    return mergeDeepRight(defaultRecommendedLoanStructure, {
      recommendationReference: nanoid(),
      product: undefined,
      loanTermInYears: totalTermDuration,
      loanAmount: amountRequested,
      associatedBorrowers: this.mapBorrowers(borrowers.owners, this.allBorrowers),
      guarantors: this.mapGuarantors(guarantors, this.allGuarantors),
      securityProperties: [],
      isEditMode: false,
    })
  }

  mapGuarantors(guarantors = [], enrichedGuarantors = []) {
    const mappedGuarantors = []

    guarantors.forEach((guarantor) => {
      const matchedGuarantor = enrichedGuarantors.find(
        propEq('externalRef', guarantor?.xGuarantor)
      )

      if (matchedGuarantor) mappedGuarantors.push(this.mapAnEntity(matchedGuarantor))
    })

    return mappedGuarantors
  }

  mapBorrowers(borrowers = [], enrichedBorrowers = []) {
    const mappedBorrowers = []

    borrowers.forEach((entity) => {
      const matchedBorrower = enrichedBorrowers.find(
        propEq('externalRef', entity?.externalRef)
      )

      if (matchedBorrower) mappedBorrowers.push(this.mapAnEntity(matchedBorrower))
    })

    return mappedBorrowers
  }

  mapAnEntity(entity = {}) {
    return {
      id: entity?.entityId,
      fullName: entity?.fullName,
      legalEntityType: entity?.legalEntityType,
    }
  }
}
