/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { s, styled } from '@vega/styled/v2'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { Button, Loading } from '@vega/components'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import {
  fetchLoanApplication,
  selectApplicationById,
} from 'modules/versionedApplication'
import {
  fetchLoanFinancials,
  selectIsFetchLoanFinancialsPending,
  selectHouseholds,
  selectAllVersionedFinancials,
} from 'modules/financial'
import { HouseholdFinancialPosition } from './HouseholdFinancialPosition'
import { CompanyFinancialPosition } from './CompanyFinancialPosition'
import { TrustFinancialPosition } from './TrustFinancialPosition'
import {
  getVersionedHouseholdFinancials,
  getVersionedlegalEntities,
  getVersionedFinancialsData,
} from './versionedFinancialValues'

const BackButton = styled(Button)(s('border-transparent p-0 flex flex-row'))
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

export const Financial = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const versionedApplication = useSelector(selectApplicationById(id))
  const { latestVersionId } = versionedApplication
  const latestApplicationVersion =
    versionedApplication.versions.find(
      (version) => version.applicationId === latestVersionId
    ).application || {}

  const showFullHistory = true

  const versionedFinancials = useSelector(selectAllVersionedFinancials) ?? []
  const loanFinancials = versionedFinancials[versionedFinancials?.length - 1] || []
  const versionedFinancialsData = getVersionedFinancialsData(versionedFinancials) || []

  const householdFinancials = useSelector(selectHouseholds) ?? []
  const isFetchingFinancials = useSelector(selectIsFetchLoanFinancialsPending)

  const versionedLegalEntities = getVersionedlegalEntities(
    versionedApplication.versions
  )

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(fetchLoanFinancials({ id, showFullHistory }))
  }, [dispatch, id, showFullHistory])

  if (isFetchingFinancials) return <Loading />

  const {
    legalEntities = [],
    relatedCompanies = [],
    securities = [],
  } = latestApplicationVersion

  const householdIds = [
    ...new Set(
      (legalEntities.individuals || [])
        .filter((individual) => individual.applicantRole !== 'Guarantor')
        .map((individual) => individual.household.externalRef)
    ),
  ]

  const householdFinancialPositions = householdIds.map((householdId) => {
    const householdIndividuals = versionedLegalEntities.individuals.filter(
      (individual) => individual.household.externalRef === householdId
    )

    const versionsForHousehold = householdFinancials.reduce((financialsSoFar, version) => {
      const potentialMatch = version.find(
        (householdFinancials) => householdFinancials.externalRef === householdId
      )

      return potentialMatch ? financialsSoFar.concat(potentialMatch) : financialsSoFar
    }, [])

    const versionedHouseholdFinancials = getVersionedHouseholdFinancials(versionsForHousehold)

    return (
      <HouseholdFinancialPosition
        key={householdId}
        versionedLoanApplicationId={id}
        latestVersionId={latestVersionId}
        householdIndividuals={householdIndividuals}
        allIndividuals={legalEntities.individuals}
        householdFinancials={versionedHouseholdFinancials}
        loanFinancials={loanFinancials}
        versionedFinancials={versionedFinancialsData}
        loanSecurities={securities}
        relatedCompanies={[relatedCompanies]}
      />
    )
  })

  const trustFinancialPositions = (legalEntities.trusts || []).map((trust) => {
    return (
      <TrustFinancialPosition
        key={trust.externalRef}
        versionedLoanApplicationId={id}
        latestVersionId={latestVersionId}
        trust={trust}
        loanFinancials={loanFinancials}
        versionedFinancials={versionedFinancialsData}
      />
    )
  })

  const companyFinancialPositions = (legalEntities.companies || []).map((company) => {
    return (
      <CompanyFinancialPosition
        key={company.externalRef}
        versionedLoanApplicationId={id}
        latestVersionId={latestVersionId}
        company={company}
        legalEntities={legalEntities}
        loanFinancials={loanFinancials}
        versionedFinancials={versionedFinancialsData}
      />
    )
  })

  const guarantorIds = [
    ...new Set(
      (legalEntities.individuals || [])
        .filter((individual) => individual.applicantRole === 'Guarantor')
        .map((individual) => individual.household.externalRef)
    ),
  ]

  const guarantorFinancialPositions = guarantorIds.map((guarantorId) => {
    const householdIndividuals = versionedLegalEntities.individuals.filter(
      (individual) => individual.household.externalRef === guarantorId
    )

    const versionsForGaurantor = householdFinancials.reduce((financialsSoFar, version) => {
      const potentialMatch = version.find(
        (householdFinancials) => householdFinancials.externalRef === guarantorId
      )

      return potentialMatch ? financialsSoFar.concat(potentialMatch) : financialsSoFar
    }, [])

    const versionedHouseholdFinancials = getVersionedHouseholdFinancials(versionsForGaurantor)

    return (
      <HouseholdFinancialPosition
        key={guarantorId}
        versionedLoanApplicationId={id}
        latestVersionId={latestVersionId}
        householdIndividuals={householdIndividuals}
        loanFinancials={loanFinancials}
        versionedFinancials={versionedFinancialsData}
        householdFinancials={versionedHouseholdFinancials}
        relatedCompanies={[relatedCompanies]}
        isGuarantor={true}
      />
    )
  })

  // NOTE: Total Assets at the bottom is temporary and just to keep the smoke
  // tests happy while the NEO devs get a better handle on the e2e process
  return (
    <div style={s('px-24')}>
      <BackButton
        variant="text"
        style={s('mb-8')}
        onClick={() => history.push(`/loan-applications/${id}/info`)}
      >
        <ChevronLeft />
        <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      {householdFinancialPositions}
      {trustFinancialPositions}
      {companyFinancialPositions}
      {guarantorFinancialPositions}
      <p>Total Assets</p>
    </div>
  )
}
