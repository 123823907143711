/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import { s, styled } from '@vega/styled/v2'
import { isNilOrEmpty, noop } from '@solta/ramda-extra'
import { formatDateString, formatCRRValue, formatFullName } from 'utils/formatters'
import { LOAN_APPLICATION } from '@neo/constants'
import { ReactComponent as ChevronRight } from '@vega/components/src/assets/images/chevron-right.svg'
import { Button } from '@vega/components'
import { buildEntityName } from 'utils/entity'

const { APPLICATION_STATUS_DISPLAY_TEXT, APPLICATION_STATUS } = LOAN_APPLICATION

const ChevronRightIcon = styled(ChevronRight)(
  s('m-0 h-auto', {
    cursor: 'pointer',
    path: { stroke: s('text-primary').color },
  })
)

const StatusPill = styled.div(
  s('rounded-xl px-3 py-1 min-h-2 flex items-center justify-center bg-grey-200')
)

const CRRPill = styled.div(
  s(
    'bg-accent-pink rounded-2xl px-3 py-1 h-2 flex items-center justify-center font-semibold'
  ),
  { whiteSpace: 'nowrap' }
)

const headerStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-800 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-800 text-sm text-left flex items-center bg-white', {
    cursor: 'initial',
    ...customStyles,
  })

const TextOverflowEllipsisBox = ({ children }) => {
  return (
    <div
      style={s('flex items-center', {
        overflow: 'hidden',
      })}
    >
      <span className="textEllipsis">{children}</span>
    </div>
  )
}

const formatAssignedTo = (assignedTo) => {
  if (isNilOrEmpty(assignedTo)) return '-'

  const { firstName, lastName } = assignedTo

  return formatFullName({ firstName, lastName })
}

const MAX_AMOUNT_OF_DISPLAY_APPLICANTS = 3

const buildApplicantsNames = (applicants = []) => {
  if (isNilOrEmpty(applicants)) return '-'

  // TODO: Ideally we want to show all applicants
  // but somehow it will break react table width
  const applicantsNames = applicants
    .filter((entity) => entity.applicantRole !== 'Guarantor')
    ?.map((applicant) => {
      const { businessName, companyName, trustName, firstName, lastName } = applicant

      return buildEntityName(
        { firstName, lastName },
        trustName,
        businessName,
        companyName
      )
    })
    ?.slice(0, MAX_AMOUNT_OF_DISPLAY_APPLICANTS)
    ?.join(', ')

  const shouldAppendEllipsis = applicants.length > MAX_AMOUNT_OF_DISPLAY_APPLICANTS
  if (shouldAppendEllipsis) return `${applicantsNames}...`

  return applicantsNames
}

export const Columns = ({ handleAction = noop }) => {
  return React.useMemo(
    () => [
      {
        Header: 'Last submitted',
        accessor: (row) => formatDateString(row.latestSubmissionDate, '-', 'dd/MM/yyyy, hh:mm:ss'),
        tableHeaderStyles: headerStyleBase({ width: '16%' }),
        tableCellStyles: cellStyleBase({ width: '16%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: '#',
        accessor: (row) => row?.neoApplicationNumber,
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Applicants',
        accessor: (row) => buildApplicantsNames(row?.applicants),
        tableHeaderStyles: headerStyleBase({ width: '17%' }),
        tableCellStyles: cellStyleBase({ width: '17%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Assigned To',
        accessor: (row) => formatAssignedTo(row?.assignedTo),
        tableHeaderStyles: headerStyleBase({ width: '18%' }),
        tableCellStyles: cellStyleBase({ width: '18%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'Broker Name',
        accessor: 'brokerName',
        tableHeaderStyles: headerStyleBase({ width: '12%' }),
        tableCellStyles: cellStyleBase({ width: '12%' }),
        Cell: ({ value }) => <TextOverflowEllipsisBox>{value}</TextOverflowEllipsisBox>,
      },
      {
        Header: 'CRR',
        accessor: (row) =>
          formatCRRValue(row.creditRiskRating?.letter, row.creditRiskRating?.number),
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '3%' })),
        tableCellStyles: cellStyleBase(
          s('justify-center font-semibold', { width: '3%' })
        ),
        Cell: ({ value: crr }) => {
          return <CRRPill>{crr}</CRRPill>
        },
      },
      {
        Header: 'Status',
        accessor: (row) => row?.status?.currentStatus,
        tableHeaderStyles: headerStyleBase(s('text-center', { width: '20%' })),
        tableCellStyles: cellStyleBase(
          s('justify-center font-semibold', { width: '20%' })
        ),
        Cell: ({ value: status }) => {
          return (
            <StatusPill status={status}>
              <span style={s('text-xs font-normal uppercase')}>
                {APPLICATION_STATUS_DISPLAY_TEXT[status] || '-'}
              </span>
            </StatusPill>
          )
        },
      },
      {
        Header: 'Action',
        accessor: (row) => row?.id,
        tableHeaderStyles: headerStyleBase({ width: '7%' }),
        tableCellStyles: cellStyleBase({ width: '7%' }),
        Cell: ({ value: applicationId }) => (
          <Button
            variant="text"
            EndIcon={<ChevronRightIcon width={16} type="button" />}
            onClick={() => handleAction(applicationId)}
          >
            View
          </Button>
        ),
      },
    ],
    [handleAction]
  )
}

export const Rows = ({ applications }) => {
  return React.useMemo(
    () =>
      applications.map((application) => {
        const customRowStyle =
          application?.status === APPLICATION_STATUS.SettlementCompleted
            ? s('text-grey-light')
            : undefined
        return { ...application, customRowStyle }
      }),
    [applications]
  )
}
