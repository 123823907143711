import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { affordabilityAssessmentAdapter, creditReportAdapter } from './module'
import { prop } from '@solta/ramda-extra'

const selectAffordabilityAssessments = prop('affordabilityAssessment')

export const {
  selectAll: selectAllAffordabilityAssessments
} = affordabilityAssessmentAdapter.getSelectors(selectAffordabilityAssessments)

export const {
  selectIsPending: selectIsConductingAssessment,
  selectHasFulfilled: selectIsConductAssessmentFulfilled,
} = createAsyncThunkSelectors('affordabilityAssessment/conductAffordabilityAssessment')

export const {
  selectIsPending: selectIsUpdatingAssessment,
  selectHasFulfilled: selectIsUpdateAssessmentFulfilled,
} = createAsyncThunkSelectors('affordabilityAssessment/updateAffordabilityAssessment')

export const {
  selectIsPending: selectIsRetrievingFinancialsData,
} = createAsyncThunkSelectors('affordabilityAssessment/retrieveFinancialsData')

export const {
  selectIsPending: selectIsReadingFinancialData,
} = createAsyncThunkSelectors('affordabilityAssessment/retrieveFinancialsData')

export const {
  selectById
} = creditReportAdapter.getSelectors(
  (s) => s.affordabilityAssessment
)

export const selectFinancialDataByRef = (assessmentReference) => (state) =>
  selectById(state, assessmentReference)
