import { prop } from '@solta/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const selectRepayment = prop('calculation')

export const {
  selectIsPending: selectIsCalculatingRepayment,
} = createAsyncThunkSelectors('calculation/calculateRepayment')

export const selectTotalRepayment = createSelector(
  selectRepayment,
  prop('totalRepayment')
)
