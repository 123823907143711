import { isNilOrEmpty } from '@solta/ramda-extra'
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { loanSplitService } from 'apiService'
import { normalizeError } from '@vega/services'
import * as mapToGlobalState from './mapToGlobalState'

export const createLoanSplit = createAsyncThunk(
  'loanSplit/createLoanSplit',
  async ({ requestId, ...payload }, { rejectWithValue }) => {
    try {
      const { loading, ...otherPayload } = payload || {}

      const result = await loanSplitService.create({
        ...otherPayload,
        customProduct: {
          interestModifier: loading * 100,
        },
      })

      return result
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateLoanSplit = createAsyncThunk(
  'loanSplit/updateLoanSplit',
  async ({ requestId, loanSplitId, ...payload }, { rejectWithValue }) => {
    try {
      const { loading, ...otherPayload } = payload || {}

      await loanSplitService.update(loanSplitId, {
        ...otherPayload,
        customProduct: {
          interestModifier: loading * 100,
        },
      })
      const updatedLoanSplit = loanSplitService.read(loanSplitId)

      return updatedLoanSplit
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchLoanSplits = createAsyncThunk(
  'loanSplit/fetchLoanSplits',
  async (loanApplicationId, { rejectWithValue }) => {
    try {
      const result = await loanSplitService.list(loanApplicationId)

      return result
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const loanSplitsAdapter = createEntityAdapter()

const initialState = loanSplitsAdapter.getInitialState()

const loanSplitSlice = createSlice({
  name: 'loanSplit',
  initialState,
  extraReducers: {
    [createLoanSplit.fulfilled]: (state, action) => {
      const newLoanSplit = action.payload

      if (isNilOrEmpty(newLoanSplit)) return

      const mappedLoanSplit = mapToGlobalState.mapLoanSplit(newLoanSplit)
      loanSplitsAdapter.addOne(state, mappedLoanSplit)
    },

    [updateLoanSplit.fulfilled]: (state, action) => {
      const updatedLoanSplit = action.payload

      if (isNilOrEmpty(updatedLoanSplit)) return

      const mappedLoanSplit = mapToGlobalState.mapLoanSplit(updatedLoanSplit)
      loanSplitsAdapter.upsertOne(state, mappedLoanSplit)
    },

    [fetchLoanSplits.fulfilled]: (state, action) => {
      const loanSplits = action.payload

      const mappedLoanSplits = loanSplits.map(mapToGlobalState.mapLoanSplit)
      loanSplitsAdapter.setAll(state, mappedLoanSplits)
    },
  },
})

const { reducer: loanSplitReducer } = loanSplitSlice
export { loanSplitReducer }
