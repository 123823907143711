import BaseService from '../baseService'

export class GoogleService extends BaseService {
  createGoogleDoc(payload) {
    const config = {
      json: { payload },
    }

    return this.client.post('google-docs', config).json()
  }

  fetchGoogleDoc(id, { templateName }) {
    return this.client.get(`google-docs/${id}?templateName=${templateName}`).json()
  }
}
