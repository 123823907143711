import React, { useEffect } from 'react'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'
import { s, styled } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchLoanApplication,
  selectApplicationById,
  selectIsFetchApplicationPending,
} from 'modules/versionedApplication'
import { PreSettlementSection } from './PreSettlementSection'
import { PostSettlementSection } from './PostSettlementSection'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import { Button, Loading } from '@vega/components'

const BackButton = styled(Button)(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('text-sm'))

export const SettlementForm = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const history = useHistory()

  const versionedApplication = useSelector(selectApplicationById(id))
  const isFetchingApplication = useSelector(selectIsFetchApplicationPending)

  useEffect(() => {
    dispatch(fetchLoanApplication(id))
  }, [dispatch, id])

  if (isFetchingApplication) return <Loading />

  const { latestVersionId } = versionedApplication
  const latestApplicationVersion = versionedApplication
    .versions
    .find((version) => version.applicationId === latestVersionId)
    .application || {}

  return (
    <div style={s('ml-8')}>
      <BackButton
        variant="text"
        style={s('mb-8 ml-24')}
        onClick={() => history.push(`/loan-applications/${id}/offer`)}
      >
        <ChevronLeft />
        <BackButtonText title="Previous step" style={s('mt-1')} />
      </BackButton>

      <Row style={s('px-24')} gutter={[0, 10]}>
        <Col span={24}>
          <h1 style={s('font-semibold mb-4 ml-1')} className="sohne">
            Settlement
          </h1>
        </Col>

        <PreSettlementSection
          latestVersionId={latestVersionId}
          application={latestApplicationVersion}
        />

        {isNotNilOrEmpty(latestApplicationVersion.preSettlement) && (
          <PostSettlementSection
            latestVersionId={latestVersionId}
            application={latestApplicationVersion}
          />
        )}
      </Row>
    </div>
  )
}
