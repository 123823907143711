import React, { useState } from 'react'
import { Col, Row } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { Applicant } from './Applicant'
import { APPLICATION_INFO_SECTION_IDS } from '../constants'
import { SectionTitle as SectionTitleBase } from '../components'
import { Button } from '@vega/components'
import { GuarantorForm } from './GuarantorForm/GuarantorForm'

const { APPLICANTS } = APPLICATION_INFO_SECTION_IDS

const Container = styled.div({ marginBottom: 52 })
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const Applicants = ({
  applicants = [],
  relatedCompanies = [],
  previousApplicants = [],
  latestVersionId = '',
  versionedApplicationId = '',
  cannotAddEntity = false,
  ...props
}) => {
  const [showGuarantorForm, setshowGuarantorForm] = useState(false)

  const openForm = () => {
    setshowGuarantorForm(true)
  }

  return (
    <Container id={APPLICANTS} {...props}>
      <SectionTitle title="Applicant(s)" />

      <Row gutter={[16, 16]}>
        {applicants
          ?.sort((a, b) => a.applicantRole.localeCompare(b.applicantRole))
          .map((applicant, index) => {
            const previousApplicant = previousApplicants.find(
              (previousApplicant) =>
                previousApplicant.externalRef === applicant.externalRef
            )
            return (
              <Col
                style={{ maxWidth: '100%', flexBasis: '100%' }}
                key={index}
                span={12}
              >
                <Applicant
                  applicant={applicant}
                  relatedCompanies={relatedCompanies}
                  index={index}
                  latestVersionId={latestVersionId}
                  cannotAddEntity={cannotAddEntity}
                  previousApplicant={previousApplicant}
                />
              </Col>
            )
          })}
      </Row>
      {showGuarantorForm && (
        <GuarantorForm
          showGuarantorForm={setshowGuarantorForm}
          latestVersionId={latestVersionId}
          versionedApplicationId={versionedApplicationId}
        />
      )}
      <div>
        <p style={s('mb-3 ')}>
          <strong>Need to add another Security Guarantor?</strong>
        </p>
        <Button onClick={openForm} style={s('mb-3')} disabled={cannotAddEntity}>
          Add Security Guarantor
        </Button>
      </div>
    </Container>
  )
}

export { Applicants }
