import { prop } from '@solta/ramda-extra'

import { googleAdapter } from './module'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectGoogleDocs = prop('googleDocs')

export const { selectById: selectGoogleDocById } = googleAdapter.getSelectors(
  selectGoogleDocs
)

export const { selectIsPending: selectIsFetchingGoogleDoc } = createAsyncThunkSelectors(
  'google/fetchGoogleDoc'
)

export const { selectIsPending: selectIsCreatingGoogleDoc } = createAsyncThunkSelectors(
  'google/createGoogleDoc'
)
