import { isNilOrEmpty } from '@solta/ramda-extra'

export const mapLoanSplit = (loanStructure) => {
  const {
    id,
    loanApplicationId,
    externalLoanProductId,
    loanAmount,
    loanTermYears,
    repaymentDay,
    repaymentFrequency,
    associatedEntities,
    primaryApplicantId,
    loanPurpose,
    occupancyType,
    securities,
    isCrossCollateral,
    customProduct,
  } = loanStructure

  return {
    id,
    loanApplicationId,
    productId: externalLoanProductId,
    loanAmount,
    loanTermInYears: loanTermYears,
    repaymentDay,
    repaymentFrequency,
    loanPurpose,
    occupancyType,
    associatedBorrowers: associatedEntities.filter(
      (entity) => entity?.applicantRole === 'borrower'
    ),
    guarantors: associatedEntities.filter(
      (entity) => entity?.applicantRole === 'guarantor'
    ),
    primaryApplicantId,
    securities,
    isCrossCollateral,
    loading: isNilOrEmpty(customProduct?.interestModifier)
      ? customProduct?.interestModifier
      : customProduct?.interestModifier / 100,
  }
}
